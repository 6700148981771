import { HTMLProps } from 'react';
import { As } from '../../utils/types';

export type TextColor = 'normal' | 'cyan' | 'purple' | 'white' | 'green' | 'yellow' | 'red';
type TextSize = 'normal' | 'small' | 'xSmall' | 'large' | 'xLarge' | 'x2Large' | 'x4Large';
type TextWeight = 'normal' | 'medium' | 'bold' | 'semibold';
type TextVariant = 'normal' | 'italic';
type TextBrightness = 'normal' | 'light' | 'dark';
type TextOpacity = '100' | '75' | '50' | '25';
type TextLeading = 'normal' | 'none' | 'snug' | 'relaxed' | 'loose';
type TextBreak = 'normal' | 'words' | 'all';
type TextWhitespace = 'normal' | 'preWrap';

const textColorMapping: Record<TextColor, string> = {
  normal: 'text',
  cyan: 'text-cyan',
  purple: 'text-purple',
  white: 'text-white',
  green: 'text-green',
  yellow: 'text-yellow',
  red: 'text-red',
};

const textSizeMapping: Record<TextSize, string> = {
  normal: 'text-base',
  large: 'text-lg',
  xLarge: 'text-xl',
  x2Large: 'text-2xl',
  x4Large: 'text-4xl',
  small: 'text-sm',
  xSmall: 'text-xs',
};

const textWeightMapping: Record<TextWeight, string> = {
  normal: '',
  medium: 'font-medium',
  bold: 'font-bold',
  semibold: 'font-semibold',
};

const textVariantMapping: Record<TextVariant, string> = {
  normal: '',
  italic: 'italic',
};

const textBrightnessMapping: Record<TextBrightness, string> = {
  normal: '',
  light: '-light',
  dark: '-dark',
};

const textOpacityMapping: Record<TextOpacity, string> = {
  100: '',
  75: 'opacity-75',
  50: 'opacity-50',
  25: 'opacity-25',
};

const textLeadingMapping: Record<TextLeading, string> = {
  normal: 'leading-6',
  none: 'leading-none',
  snug: 'leading-snug',
  relaxed: 'leading-relaxed',
  loose: 'leading-loose',
};

const textBreakMapping: Record<TextBreak, string> = {
  normal: 'break-normal',
  words: 'break-words',
  all: 'break-all',
};

const textWhitespaceMapping: Record<TextWhitespace, string> = {
  normal: 'whitespace-normal',
  preWrap: 'whitespace-pre-wrap',
};

type ValidTags = 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

type TextProps = As<ValidTags> & {
  size?: TextSize;
  weight?: TextWeight;
  variant?: TextVariant;
  color?: TextColor;
  brightness?: TextBrightness;
  opacity?: TextOpacity;
  leading?: TextLeading;
  truncate?: boolean;
  breakMode?: TextBreak;
  whitespace?: TextWhitespace;
  children: React.ReactNode;
};

function generateTextClassName(
  size: TextSize,
  weight: TextWeight,
  variant: TextVariant,
  color: TextColor,
  brightness: TextBrightness,
  leading: TextLeading | undefined,
  truncate: boolean,
  opacity: TextOpacity,
  breakMode: TextBreak,
  whitespace: TextWhitespace,
): string {
  const sizeClass = textSizeMapping[size];
  const weightClass = textWeightMapping[weight];
  const variantClass = textVariantMapping[variant];
  const colorClass = textColorMapping[color];
  const colorValueClass =
    colorClass === 'text-white' ? colorClass : `${colorClass}${textBrightnessMapping[brightness]}`;
  const opacityClass = textOpacityMapping[opacity];
  const leadingClass = leading ? textLeadingMapping[leading] : '';
  const breakClass = textBreakMapping[breakMode];
  const whitespaceClass = truncate ? '' : textWhitespaceMapping[whitespace];

  const truncateClass = truncate ? 'truncate' : '';

  const className =
    `${sizeClass} ${weightClass} ${variantClass} ${colorValueClass} ${opacityClass} ${leadingClass} ${truncateClass} ${breakClass} ${whitespaceClass}`.trim();

  return className;
}

export default function Text({
  as = 'span',
  size = 'normal',
  weight = 'normal',
  variant = 'normal',
  color = 'normal',
  brightness = 'normal',
  leading = undefined,
  truncate = false,
  opacity = '100',
  breakMode = 'words',
  whitespace = 'normal',
  children,
}: TextProps) {
  const _className = generateTextClassName(
    size,
    weight,
    variant,
    color,
    brightness,
    leading,
    truncate,
    opacity,
    breakMode,
    whitespace,
  );

  const Tag: string = as;
  const attributes: HTMLProps<HTMLElement> = { className: _className, children };

  return <Tag {...attributes} />;
}
