import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import HeaderSubHeader from '../headers/HeaderSubHeader';

export interface INameValueItem {
  name: string;
  value: string;
}

export interface IDescriptionListItem extends INameValueItem {
  columnSpan?: 1 | 2;
  type?: 'general' | 'email' | 'phone';
}

interface IDescriptionListProps {
  columns?: 1 | 2 | 3;
  items: IDescriptionListItem[];
}

export function DescriptionList({ items, columns = 2 }: IDescriptionListProps) {
  return (
    <div className={`grid grid-flow-row-dense md:grid-cols-${columns} gap-y-3 gap-x-5`}>
      {items &&
        items.map((item) => (
          <HeaderSubHeader size="small" text={item.name} subText={item.value} key={item.name} />
        ))}
    </div>
  );
}

export function DescriptionListLoader({ speed = 2 }: Pick<IContentLoaderProps, 'speed'>) {
  return (
    <ContentLoader
      speed={speed}
      backgroundColor="#eeeff1"
      foregroundColor="#e0e3e6"
      style={{ width: '100%', height: '100px' }}
    >
      <rect x="0" y="4" rx="4" ry="4" width="40%" height="16" />
      <rect x="0" y="28" rx="4" ry="4" width="100%" height="16" />
      <rect x="0" y="60" rx="4" ry="4" width="40%" height="16" />
      <rect x="0" y="84" rx="4" ry="4" width="100%" height="16" />
    </ContentLoader>
  );
}
