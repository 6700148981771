import { useMsal } from '@azure/msal-react';
import { Transition, Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logoY.svg';
import Notifications from '../Notifications/Notifications';
import { Filler } from '../../shared/components/layout/Filler';
import Text from '../../shared/components/text/Text';

const navigation = [
  {
    name: 'Contacts',
    href: 'contacts',
    children: [],
  },
  { name: 'Networks', href: 'networks', children: [] },
  { name: 'Your Profile', href: '/profile', children: [] },
  {
    name: 'Privacy & Terms',
    href: '',
    children: [
      { name: 'Privacy Notice', href: '/user_agreements/Privacy_Notice_v1.0.pdf' },
      { name: 'Terms of Use', href: '/user_agreements/Terms_of_use_v1.0.pdf' },
    ],
  },
];

export default function HeaderMobile() {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleNavigateMobileLink = (url: string) => {
    navigate(url, { replace: true });
    setMobileMenuOpen(false);
  };

  return (
    <>
      <header className="flex items-center flex-shrink-0 space-x-3 h-16">
        {/* Logo area */}
        <div className="p-5 pl-4 h-full">
          <Link
            to="/"
            className="flex items-start justify-start"
          >
            <img className="h-8 w-auto sm:h-8" src={logo} alt="Yoin" />
          </Link>
        </div>
        <Filler />
        <Notifications />

        {/* Menu button area */}
        <div className="pr-4 flex items-center">
          {/* Mobile menu button */}
          <button
            type="button"
            className="-mr-2 inline-flex items-center justify-center p-2"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            open={mobileMenuOpen}
            className="fixed inset-0 z-40 md:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
              enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
              enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
              leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
              leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
              leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
            >
              <nav
                className="fixed z-40 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg"
                aria-label="Global"
              >
                <div className="h-12 flex items-end justify-end px-6">
                  <button
                    type="button"
                    className="-mr-2 inline-flex items-center justify-center"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close main menu</span>
                    <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                  </button>
                </div>
                <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                  {navigation.map((item) =>
                    item.children.length ? (
                      <Fragment key={item.name}>
                        <div className="block rounded-md py-2 px-3">
                          <Text as="span" brightness="dark" weight="medium">
                            {item.name}
                          </Text>
                        </div>
                        {item.children.map((child) => (
                          <Fragment key={child.name}>
                            <a
                              href={child.href}
                              target="_blank"
                              rel="noreferrer"
                              className="block rounded-md py-2 px-3 ml-4 text-base font-medium text"
                            >
                              {child.name}
                            </a>
                          </Fragment>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment key={item.name}>
                        <button
                          type="button"
                          onClick={() => handleNavigateMobileLink(item.href)}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                        >
                          {item.name}
                        </button>
                      </Fragment>
                    ),
                  )}

                  <Fragment key="SignOut">
                    <button
                      type="button"
                      onClick={() => instance.logoutRedirect()}
                      className="block rounded-md py-2 px-3 text-base font-medium text"
                    >
                      Sign out
                    </button>
                  </Fragment>
                </div>
              </nav>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </header>
    </>
  );
}
