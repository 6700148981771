import { ChangeEvent, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '../../shared/components/buttons/Button';
import SelectList from '../../shared/components/lists/SelectList';
import Modal from '../../shared/components/modal/Modal';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SearchBar from '../../shared/components/search/SearchBar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import useDebounce from '../../shared/hooks/useDebounce';
import { ActivityEntityType } from '../../shared/model/IActitityDto';
import {
  useMyPersonProfileQuery,
  usePersonProfileQuery,
} from '../Contacts/queries/personProfileQueries';
import { useContactQuery } from '../Contacts/queries/contactQueries';
import { useNetworkMembersWithFilterQuery } from '../Networks/queries/networkMemberQueries';
import { userProfileQueryBase } from '../Contacts/queries/userProfileQueries';

export interface IListItem {
  id: string;
  headline: string;
  information: string;
  photoUrl: string;
}

const inMemoryfilterPredicate = (searchTerm: string, item: IListItem) => {
  if (!searchTerm) {
    return true;
  }

  return (
    item.headline.toLocaleLowerCase().indexOf(searchTerm) >= 0 ||
    item.information.toLocaleLowerCase().indexOf(searchTerm) >= 0
  );
};

function AssignToContactSelectList({
  contactId,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  contactId: string;
  searchTerm: string;
  selectedItem?: IListItem;
  onSelectItem: (item: IListItem) => void;
}) {
  const myProfileQuery = useMyPersonProfileQuery();
  const contactQuery = useContactQuery(contactId);
  // Workaround while tenancy migration is not done FIXME
  const contactPersonProfileQuery = usePersonProfileQuery(contactQuery.data?.personProfileId);
  const [listItems, setListItems] = useState<IListItem[]>();

  useEffect(() => {
    if (!myProfileQuery.data || !contactQuery.data || !contactPersonProfileQuery.data) {
      return;
    }

    const cleanedSearchTerm = searchTerm.toLocaleLowerCase().trim();
    const result: IListItem[] = [
      {
        id: myProfileQuery.data.userId,
        headline: `${myProfileQuery.data.firstName} ${myProfileQuery.data.lastName}`,
        information: myProfileQuery.data.emailAddress,
        photoUrl: myProfileQuery.data.photoUrl,
      },
      {
        id: contactPersonProfileQuery.data.userId,
        headline: `${contactQuery.data.firstName} ${contactQuery.data.lastName}`,
        information: contactQuery.data.emailAddress,
        photoUrl: contactQuery.data.photoUrl,
      },
    ];
    const filtered = result.filter((item) => inMemoryfilterPredicate(cleanedSearchTerm, item));
    setListItems(filtered);
  }, [myProfileQuery.data, contactQuery.data, contactPersonProfileQuery.data, searchTerm]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ id }) => id}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

function AssignToNetworkMemberSelectList({
  networkId,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  networkId: string;
  searchTerm: string;
  selectedItem?: IListItem;
  onSelectItem: (item: IListItem) => void;
}) {
  const queryClient = useQueryClient();
  const cleanedSearchTerm = searchTerm.toLocaleLowerCase().trim();
  const networkMembersQuery = useNetworkMembersWithFilterQuery(
    networkId,
    (member) => member.name.toLocaleLowerCase().indexOf(cleanedSearchTerm) >= 0,
  );
  // Workaround while tenancy migration is not done FIXME
  const [listItems, setListItems] = useState<IListItem[]>();
  useEffect(() => {
    async function GetListItems() {
      if (!networkMembersQuery.data) {
        return;
      }

      const result: IListItem[] = await Promise.all(
        networkMembersQuery.data.map(async (member) => {
          const userProfile = await queryClient.fetchQuery(userProfileQueryBase(member.userId));

          return {
            id: member.userId,
            headline: member.name,
            information: member.name,
            photoUrl: userProfile.photoUrl,
          };
        }),
      );

      setListItems(result);
    }

    GetListItems();
  }, [networkMembersQuery.data]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ id }) => id}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

interface IProps {
  parentId: string;
  activityId: string;
  activityEntityType: ActivityEntityType;
  isOpen: boolean;
  isPending: boolean;
  onCloseCallback: (result?: IListItem) => void;
}

export default function ActivityAssignToModal({
  parentId,
  activityId,
  activityEntityType,
  isOpen,
  isPending,
  onCloseCallback,
}: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState<IListItem | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
    setSelectedAssignee(undefined);
  };

  const handleAssigne = () => {
    if (!parentId || !activityId) {
      return;
    }
    onCloseCallback(selectedAssignee);
  };
  return (
    <Modal isOpen={isOpen} showCloseButton onCloseClick={() => onCloseCallback()}>
      <div className="mt-6">
        <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
      </div>
      <ScrollBarWrapper>
        <div className="mt-4 max-h-96 text-left">
          {activityEntityType === ActivityEntityType.Contact ? (
            <AssignToContactSelectList
              contactId={parentId}
              searchTerm={debouncedSearchTerm}
              selectedItem={selectedAssignee}
              onSelectItem={setSelectedAssignee}
            />
          ) : (
            <AssignToNetworkMemberSelectList
              networkId={parentId}
              searchTerm={debouncedSearchTerm}
              selectedItem={selectedAssignee}
              onSelectItem={setSelectedAssignee}
            />
          )}
        </div>
      </ScrollBarWrapper>
      <div className="mt-2">
        <Button
          color={ButtonColors.Cyan}
          text="Assign"
          onClick={handleAssigne}
          isDisabled={!selectedAssignee || isPending}
        />
      </div>
    </Modal>
  );
}
