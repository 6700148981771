import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  DistributedTracingModes,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticationResult, EventType, InteractionType } from '@azure/msal-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { msalInstance, pwdResetRequest } from './shared/auth/authConfig';
import { applicationInsightsInstrumentationKey, appVersion } from './shared/utils/configuration';
import { getNewestAccount } from './shared/utils/msalUtils';

if (applicationInsightsInstrumentationKey) {
  const reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: applicationInsightsInstrumentationKey,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
      enableCorsCorrelation: true,
      correlationHeaderExcludedDomains: ['api.giphy.com'],
      distributedTracingMode: DistributedTracingModes.W3C,
    },
  });
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer((telemetryItem: ITelemetryItem) => {
    telemetryItem.tags = telemetryItem.tags || [];
    telemetryItem.tags.push({ 'ai.cloud.role': 'Yoin Networks Web' });
    telemetryItem.tags.push({ 'ai.application.ver': appVersion });
  });
}

// If there isn't any active account, set the newest account as active.
if (!msalInstance.getActiveAccount()) {
  const accounts = msalInstance.getAllAccounts();
  msalInstance.setActiveAccount(getNewestAccount(accounts));
}

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    const payload = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(payload.account);
  } else if (
    event.eventType === EventType.LOGIN_FAILURE &&
    event.interactionType === InteractionType.Redirect
  ) {
    // AADB2C90118 is the error code from B2C when the user clicks the "Forgot password?" link
    if (event.error && event.error.message.indexOf('AADB2C90118') > -1) {
      let email = '';
      const loginHintIdx = event.error.message.indexOf('login_hint');
      if (loginHintIdx > -1) {
        // Get email address from login_hint:<> in event.error!.message, example:
        // errorMessage:
        // "AADB2C90118: The user has forgotten their password.
        // Correlation ID: fd4cb997-6b05-4a8e-85aa-8b8cc251cfdf
        // Timestamp: 2023-08-10 13:53:23Z
        // login_hint: Erik.Svedlund@Yoin.tech"
        const emailStartIdx = loginHintIdx + 12;
        email = event.error.message.substring(emailStartIdx, event.error.message.length - 2);
      }

      msalInstance.loginRedirect({
        ...pwdResetRequest,
        loginHint: email,
      });
    }
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 20 seconds to allow for less fetches when utilising queries in multiple locations
      // or using initial data for single resource fetches
      staleTime: 20 * 1000,
    },
  },
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </MsalProvider>
    ,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
