import { Outlet } from 'react-router';
import MainPanelDesktop from '../../shared/components/page/MainPanelDesktop';
import MainPanelMobile from '../../shared/components/page/MainPanelMobile';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import PipelineSidePanel from './PipelineSidePanel';
import PipelineLayout from './PipelineLayout';
import { PipelineContextProvider } from './context/pipeline-context';

export default function Pipelines() {
  return (
    <PipelineContextProvider>
      <WrapperDesktop>
        <MainPanelDesktop>
          <PipelineLayout>
            <Outlet />
          </PipelineLayout>
        </MainPanelDesktop>
        <PipelineSidePanel />
      </WrapperDesktop>
      <WrapperMobile>
        <MainPanelMobile>Mobile sales coming soon</MainPanelMobile>
      </WrapperMobile>
    </PipelineContextProvider>
  );
}
