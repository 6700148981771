import { IToast } from './model/IToast';
import ToastListItem from './ToastListItem';

interface IProps {
  toasts: Array<IToast>;
}

export default function ToastList({ toasts }: IProps) {
  return (
    <>
      {toasts.map((toast) => (
        <ToastListItem key={toast.id} toast={toast} />
      ))}
    </>
  );
}
