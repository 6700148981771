import { useEffect } from 'react';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';

import PipelineEditPipeline from './PipelineEditPipeline';
import {
  ActivePipelineSettings,
  SidePanelViewType,
  usePipelineStoreContext,
} from './context/pipeline-context';
import SidePanel from '../../shared/components/page/SidePanel';
import { ViewName } from '../../shared/store/slices/viewSlice';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import useColorSetting, { ColorSetting, ResourceType } from '../../shared/hooks/useColorSetting';
import PipelineEditItem from './PipelineEditItem';
import { useContactQuery } from '../Contacts/queries/contactQueries';
import { IContactDto } from '../../shared/model/IContactDto';
import networkDummy from '../../assets/images/network-icon.svg';
import ProfileAvatar from '../Profile/ProfileAvatar';

function PipelineCogImage() {
  return (
    <div className="bg-cyan-dark flex flex-col justify-center items-center rounded-full w-14 h-14">
      <Cog6ToothIcon className="w-9 h-9 text-white" />
    </div>
  );
}

function PipelineClosedSidePanel({
  activeSidePanelView,
  activePipelineSettings,
  contact,
}: {
  activeSidePanelView: SidePanelViewType;
  activePipelineSettings: ActivePipelineSettings;
  contact?: IContactDto;
}) {
  if (activeSidePanelView === 'EditPipeline') {
    return <PipelineCogImage />;
  }

  if (activePipelineSettings.item.resourceType === 'Contact') {
    return (
      <ProfileAvatar
        avatarProps={{
          src: contact?.photoUrl,
          widthClass: 'w-14',
          heightClass: 'h-14',
        }}
      />
    );
  }

  if (activePipelineSettings.item.resourceType === 'Network') {
    return <img src={networkDummy} alt="Network" className="rounded-full w-14 h-14" />;
  }

  return <PipelineCogImage />;
}

export default function PipelineSidePanel() {
  const { activePipelineSettings, activeSidePanelView } = usePipelineStoreContext();

  const currentView: ViewName = 'pipeline';
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const viewSettings = getViewSettings(currentView);

  // This is needed to know if the contact is passive or not
  const contactQuery = useContactQuery(
    activePipelineSettings.item.resourceId,
    activePipelineSettings.item.resourceType === 'Contact' &&
      !!activePipelineSettings.item.resourceId,
  );

  useEffect(() => {
    setSidePanelOpen(currentView, true);
  }, [activePipelineSettings]);

  const sidePanelViewMapping = {
    EditPipeline: <PipelineEditPipeline pipelineId={activePipelineSettings.pipelineId} />,
    EditItem: <PipelineEditItem />,
  };

  const calculateColorSettings = () => {
    let resourceType: ResourceType = 'pipeline';

    if (activePipelineSettings.item.resourceType === 'Network') {
      resourceType = 'network';
    } else if (activePipelineSettings.item.resourceType === 'Contact') {
      resourceType = contactQuery.data?.connectionId
        ? (resourceType = 'contact')
        : (resourceType = 'contact-passive');
    }

    if (activeSidePanelView === 'EditPipeline') {
      resourceType = 'pipeline';
    }

    const colorSettings: ColorSetting = useColorSetting(resourceType);
    return colorSettings;
  };

  const colorSettings: ColorSetting = calculateColorSettings();

  return (
    <>
      {(activePipelineSettings.stageId || activePipelineSettings.pipelineId) && (
        <SidePanel
          renderClosed={() => (
            <PipelineClosedSidePanel
              activeSidePanelView={activeSidePanelView}
              activePipelineSettings={activePipelineSettings}
              contact={contactQuery.data}
            />
          )}
          colorSetting={colorSettings}
          isOpen={viewSettings.isSidePanelOpen}
          openSidePanel={() => setSidePanelOpen(currentView, true)}
          closeSidePanel={() => setSidePanelOpen(currentView, false)}
          onToggle={() => toggleSidePanel(currentView)}
        >
          {sidePanelViewMapping[activeSidePanelView]}
        </SidePanel>
      )}
    </>
  );
}
