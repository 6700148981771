import { MouseEventHandler, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import Modal from '../../shared/components/modal/Modal';
import { ToastType } from '../../shared/components/toasts/constants/ToastTypes';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { AppContext } from '../../shared/context/context';
import { IContactDto } from '../../shared/model/IContactDto';
import { deleteContact } from '../../shared/services/contactService';
import { createToast } from '../../shared/services/toastService';
import { EventBus } from '../../shared/utils/eventBus';
import ContactActionButtons from './ContactActionButtons';
import ContactEditContact from './ContactEditContact';
import { useConnectWithProfileMutation } from '../Request/queries/connectionRequestQueries';
import ContactUserInformation from './ContactUserInformation';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Text from '../../shared/components/text/Text';

interface IProps {
  contact: IContactDto;
  isConnectionInitiated: boolean;
}
export default function ContactPassive({ contact, isConnectionInitiated }: IProps) {
  const navigate = useNavigate();

  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [showDeleteContactWarning, setShowDeleteContactWarning] = useState(false);
  const { dispatch } = useContext(AppContext);

  const connectWithPersonProfile = useConnectWithProfileMutation();

  const handleEditContact = () => {
    setIsEditContactModalOpen(true);
  };

  const handleCloseEditContactModal = () => {
    setIsEditContactModalOpen(false);
    EventBus.getInstance().dispatch('refreshContactList');
  };

  const handleDeleteContact = () => {
    setShowDeleteContactWarning(true);
  };

  const handleContinueDeleteContact = () => {
    setShowDeleteContactWarning(false);
    deleteContact(contact?.id as string).then(() => {
      setTimeout(() => {
        EventBus.getInstance().dispatch('refreshContactList');
        navigate('/contacts', { replace: true });
      }, 1000);

      dispatch(createToast('Success', ToastType.Success, 'Contact Deleted'));
    });
  };

  const handleCancelDeleteContact = () => {
    setShowDeleteContactWarning(false);
  };

  const handleCreateConnectionRequest: MouseEventHandler<Element> = () => {
    if (!contact?.personProfileId) {
      return;
    }
    connectWithPersonProfile.mutate({
      personProfileId: contact.personProfileId,
      firstName: contact.firstName,
      lastName: contact.lastName,
    });
  };

  return (
    <div className="mt-8">
      <Text as="h1" size="x2Large" leading="loose">
        {contact?.firstName} {contact?.lastName}
      </Text>
      <div className="flex flex-1 mr-4">
        <ContactUserInformation user={contact as IContactDto} />
      </div>
      <div className="flex flex-row mt-6">
        <ContactActionButtons
          contact={contact}
          isConnectionInitiated={isConnectionInitiated}
          onCreateConnectionRequest={handleCreateConnectionRequest}
          onEditContact={handleEditContact}
          onDeleteContact={handleDeleteContact}
        />
      </div>

      <Modal
        isOpen={isEditContactModalOpen}
        showCloseButton={false}
        onCloseClick={() => handleCloseEditContactModal()}
      >
        <ContactEditContact
          callback={handleCloseEditContactModal}
          contact={contact as IContactDto}
        />
      </Modal>
      <ConfirmDialog isOpen={showDeleteContactWarning} title="Remove Contact">
        <Text as="p"> Are you sure you want to remove this contact?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Remove" onClick={handleContinueDeleteContact} />
          <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelDeleteContact} />
        </ButtonGroup>
      </ConfirmDialog>
    </div>
  );
}
