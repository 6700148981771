import { contactKeys } from '../../features/Contacts/queries/contactQueries';
import { networkKeys } from '../../features/Networks/queries/networkQueries';
import {
  acceptInvitation,
  rejectInvitation,
} from '../../features/Pipelines/services/pipelineMemberService';
import {
  NotificationArea,
  NotificationResponseType,
  NotificationType,
} from '../constants/NotificationConstants';
import { Action, INotificationDto, IResourceLink, ResourceType } from '../model/INotificationDto';
import { INotificationResponseDto } from '../model/INotificationResponseDto';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';
import { acceptConnectionRequest, rejectConnectionRequest } from './connectionRequestService';
import { connectWithPersonProfile } from './contactService';
import { acceptNetworkInvitation, rejectNetworkInvitation } from './networkService';
import {
  acceptRecommendContactRequest,
  rejectRecommendContactRequest,
} from './recommendContactRequestService';

import { acceptShareContactRequest, rejectShareContactRequest } from './shareContactRequestService';

const getResourceLink = (type: ResourceType, id: string, query?: string): IResourceLink => {
  let text;
  let baseUrl;
  switch (type) {
    case ResourceType.Contact:
      text = 'Go to contact';
      baseUrl = '/contacts/';
      break;
    case ResourceType.Network:
      text = 'Go to network';
      baseUrl = '/networks/';
      break;
    case ResourceType.Profile:
      text = 'Go to profile';
      baseUrl = '/contacts/external/';
      break;
    case ResourceType.Conversation:
      text = 'Go to conversation';
      baseUrl = '/conversations/'; // Actually not an existing route but not using that.
      break;
    case ResourceType.Pipeline:
      text = 'Go to pipeline';
      baseUrl = '/boards/';
      break;
    default:
      throw Error('Type not found');
  }

  return {
    url: baseUrl + id + (query ? `?${query}` : ''),
    id,
    text,
    type,
  };
};

/**
 * Creates a complete notification message including language support (will come later)
 * @param notification takes in the notification from the API
 * @returns a cloned notification that contains a proper message and action
 */
const finalizeNotification = (notification: INotificationDto): INotificationDto => {
  const notificationClone = { ...notification };
  // Connection
  if (
    notification.type === NotificationType.Request &&
    notification.area === NotificationArea.Connection
  ) {
    if (notification.payload !== null) {
      notificationClone.message = `${notification.payload['source.displayName']} wants to connect with you.`;
      notificationClone.acceptMessage = 'The connection request was accepted.';
    }
    notificationClone.actions = [
      new Action(
        'Accept',
        NotificationResponseType.Accept,
        () => acceptConnectionRequest(notification.payload['resource.id']),
        [contactKeys.lists()],
      ),
      new Action('Reject', NotificationResponseType.Reject, () =>
        rejectConnectionRequest(notification.payload['resource.id']),
      ),
    ];
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Connection &&
    notification.response === NotificationResponseType.Accept
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} has connected with you.`;
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Connection &&
    notification.response === NotificationResponseType.Reject
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} has rejected to connect with you.`;
  }

  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Connection
  ) {
    notificationClone.message = `You have been connected with ${notification.payload['source.displayName']}.`;
  }

  // Recommend contact
  if (
    notification.type === NotificationType.Request &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'RecommendContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants you to connect with ${notification.payload['resource.displayName']}.`;
    notificationClone.acceptMessage = `A connection request has been sent to ${notification.payload['resource.displayName']}.`;
    notificationClone.actions = [
      new Action(
        'Connect',
        NotificationResponseType.Accept,
        () => acceptRecommendContactRequest(notification.payload['resource.id']),
        [contactKeys.lists()],
      ),
      new Action('Ignore', NotificationResponseType.Reject, () =>
        rejectRecommendContactRequest(notification.payload['resource.id']),
      ),
    ];
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Profile, notification.payload['resource.child.id'])
      : undefined;
  }

  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'RecommendContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants you to connect with ${notification.payload['resource.displayName']}, you are already connected.`;
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'RecommendContactRequestAccepted' &&
    notification.response === NotificationResponseType.Accept
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your recommendation of ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Profile, notification.payload['resource.child.id'])
      : undefined;
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'RecommendContactRequestRejected' &&
    notification.response === NotificationResponseType.Reject
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} rejected your recommendation of ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Profile, notification.payload['resource.child.id'])
      : undefined;
  }

  // Share contact
  if (
    notification.type === NotificationType.Request &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'ShareContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants to share contact ${notification.payload['resource.displayName']} with you.`;
    notificationClone.acceptMessage = `Contact ${notification.payload['resource.displayName']} has been added.`;
    notificationClone.actions = [
      new Action(
        'Add',
        NotificationResponseType.Accept,
        () => acceptShareContactRequest(notification.payload['resource.id']),
        [contactKeys.lists()],
      ),
    ];
    if (notification.payload['resource.child.id']) {
      notificationClone.actions.push(
        new Action(
          'Connect',
          NotificationResponseType.Accept,
          async () => {
            await acceptShareContactRequest(notification.payload['resource.id']);
            return connectWithPersonProfile(notification.payload['resource.child.id']);
          },
          [contactKeys.lists()],
        ),
      );
    }
    notificationClone.actions.push(
      new Action('Ignore', NotificationResponseType.Reject, () =>
        rejectShareContactRequest(notification.payload['resource.id']),
      ),
    );
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Profile, notification.payload['resource.child.id'])
      : undefined;
  }

  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'ShareContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants you to share contact  ${notification.payload['resource.displayName']}.`;
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'ShareContactRequestAccepted' &&
    notification.response === NotificationResponseType.Accept
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your sharing of contact ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Profile, notification.payload['resource.child.id'])
      : undefined;
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'ShareContactRequestRejected' &&
    notification.response === NotificationResponseType.Reject
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} rejected your sharing of contact ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Profile, notification.payload['resource.child.id'])
      : undefined;
  }

  // Invite to network
  if (
    notification.type === NotificationType.Request &&
    notification.area === NotificationArea.Network
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} has invited you to the network ${notification.payload['resource.displayName']}.`;
    notificationClone.acceptMessage = 'The network invitation was accepted';
    notificationClone.actions = [
      new Action(
        'Accept',
        NotificationResponseType.Accept,
        () => acceptNetworkInvitation(notification.payload['resource.id']),
        [networkKeys.lists()],
      ),
      new Action('Reject', NotificationResponseType.Reject, () =>
        rejectNetworkInvitation(notification.payload['resource.id']),
      ),
    ];
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Network &&
    notification.response === NotificationResponseType.Accept
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your invitation to the network ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Network, notification.payload['resource.child.id'])
      : undefined;
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Network &&
    notification.response === NotificationResponseType.Reject
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} rejected your invitation to the network ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Network, notification.payload['resource.child.id'])
      : undefined;
  }

  // Network deleted
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Network &&
    notification.reason === 'NetworkDeleted'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} deleted the network ${notification.payload['resource.displayName']}.`;
  }

  // Network member left
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Network &&
    notification.reason === 'NetworkMemberRemoved'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} left the network ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(ResourceType.Network, notification.payload['resource.id'])
      : undefined;
  }

  // Network member added
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Network &&
    notification.reason === 'NetworkMemberAdded'
  ) {
    notificationClone.message = `${notification.payload['resource.child.displayName']} joined the network ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(ResourceType.Network, notification.payload['resource.id'])
      : undefined;
  }

  // Network member added by accepting invitation
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Network &&
    notification.reason === 'InviteNetworkRequestAccepted'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} joined the network ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(ResourceType.Network, notification.payload['resource.id'])
      : undefined;
  }

  // Activities
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'ActivityAssigned'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} have assigned you to the activity ${notification.payload['resource.child.displayName']}.`;
    notificationClone.resourceLink = getResourceLink(
      ResourceType.Contact,
      notification.payload['resource.id'],
      'tab=tab_activities',
    );
  }

  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Network &&
    notification.reason === 'ActivityAssigned'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} have assigned you to the activity '${notification.payload['resource.child.displayName']}' in the network '${notification.payload['resource.displayName']}'.`;
    notificationClone.resourceLink = getResourceLink(
      ResourceType.Network,
      notification.payload['resource.id'],
      'tab=tab_activities',
    );
  }

  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Contact &&
    notification.reason === 'ActivityCompleted'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} completed the activity ${notification.payload['resource.child.displayName']}.`;
    notificationClone.resourceLink = getResourceLink(
      ResourceType.Contact,
      notification.payload['resource.id'],
      'tab=tab_activities',
    );
  }

  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Network &&
    notification.reason === 'ActivityCompleted'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} completed the activity '${notification.payload['resource.child.displayName']}' in the network '${notification.payload['resource.displayName']}'.`;
    notificationClone.resourceLink = getResourceLink(
      ResourceType.Network,
      notification.payload['resource.id'],
      'tab=tab_activities',
    );
  }

  // Invitation
  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Invitation &&
    notification.response === NotificationResponseType.Accept
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your invitation to Yoin. You are now connected.`;
  }

  // Conversations
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Message &&
    (notification.reason === 'ReactionCreated' || notification.reason === 'ReactionUpdated')
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} reacted (${notification.payload['resource.child.child.displayName']}) to your message "${notification.payload['resource.child.displayName']}".`;
    notificationClone.resourceLink = getResourceLink(
      ResourceType.Conversation,
      notification.payload['resource.id'],
      `messageId=${notification.payload['resource.child.id']}`,
    );
  }

  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Message &&
    notification.reason === 'Mention'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} mentioned you in a message.`;
    notificationClone.resourceLink = getResourceLink(
      ResourceType.Conversation,
      notification.payload['resource.id'],
      `messageId=${notification.payload['resource.child.id']}`,
    );
  }

  // Invite to pipeline
  if (
    notification.type === NotificationType.Request &&
    notification.area === NotificationArea.Pipeline
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} has invited you to the pipeline ${notification.payload['resource.displayName']}.`;
    notificationClone.acceptMessage = 'The pipeline invitation was accepted';
    notificationClone.actions = [
      new Action(
        'Accept',
        NotificationResponseType.Accept,
        () => acceptInvitation(notification.payload['resource.id']),
        [networkKeys.lists()],
      ),
      new Action('Reject', NotificationResponseType.Reject, () =>
        rejectInvitation(notification.payload['resource.id']),
      ),
    ];
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Pipeline &&
    notification.response === NotificationResponseType.Accept
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your invitation to the pipeline ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Pipeline, notification.payload['resource.child.id'])
      : undefined;
  }

  if (
    notification.type === NotificationType.Response &&
    notification.area === NotificationArea.Pipeline &&
    notification.response === NotificationResponseType.Reject
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} rejected your invitation to the pipeline ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(ResourceType.Pipeline, notification.payload['resource.child.id'])
      : undefined;
  }

  // Pipeline deleted
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Pipeline &&
    notification.reason === 'PipelineDeleted'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} deleted the pipeline ${notification.payload['resource.displayName']}.`;
  }

  // Pipeline member left
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Pipeline &&
    notification.reason === 'PipelineMemberRemoved'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} left the pipeline ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(ResourceType.Pipeline, notification.payload['resource.id'])
      : undefined;
  }

  // Pipeline member added
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Pipeline &&
    notification.reason === 'PipelineMemberAdded'
  ) {
    notificationClone.message = `${notification.payload['resource.child.displayName']} joined the pipeline ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(ResourceType.Pipeline, notification.payload['resource.id'])
      : undefined;
  }

  // Network member added by accepting invitation
  if (
    notification.type === NotificationType.General &&
    notification.area === NotificationArea.Pipeline &&
    notification.reason === 'InvitePipelineRequestAccepted'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} joined the pipeline ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(ResourceType.Pipeline, notification.payload['resource.id'])
      : undefined;
  }

  return notificationClone;
};

export const respond = (id: string, data: INotificationResponseDto) =>
  apiClient.put(`v1/notifications/${id}/respond`, data).then((response) => response.data.data);

export const getNotifications = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/notifications${queryString}`)
    .then<IPagedResult<INotificationDto>>((response) => response.data);
};

export const readNotification = (id: string) =>
  apiClient.put(`v1/notifications/${id}/read`).then((response) => response.data.data);

export default finalizeNotification;
