import { forwardRef } from 'react';
import { NumberFormatBase } from 'react-number-format';

const CurrencyInput = forwardRef(({ ...rest }, ref) => {
  const format = (numStr: string | number) => {
    if (numStr === '') return '';
    return new Intl.NumberFormat('sv-SE', {
      maximumFractionDigits: 0,
    }).format(numStr as number);
  };

  return (
    <NumberFormatBase
      getInputRef={ref}
      {...rest}
      format={format}
      className="shadow-sm placeholder:text-gray-400 block w-full border-gray-300 rounded-md disabled:bg-gray-200 focus:ring-0 focus:border-zinc-400"
    />
  );
});

export default CurrencyInput;
