import TabButtonItem from '../../shared/components/tabs/TabButtonItem';
import usePipelineInfoActionButtons from './hooks/usePipelineInfoActionButtons';

interface IProps {
  pipelineId: string;
}
export default function PipelineInfoActionButtons({ pipelineId }: IProps) {
  const menuItemActionButtons = usePipelineInfoActionButtons(pipelineId);
  return <TabButtonItem isActive={false} menuItemActionButtons={menuItemActionButtons} />;
}
