import { Menu, Transition } from '@headlessui/react';
import { useMsal } from '@azure/msal-react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import ProfileAvatar from '../Profile/ProfileAvatar';
import { useMyPersonProfileQuery } from '../Contacts/queries/personProfileQueries';
import Text from '../../shared/components/text/Text';
import { classNames } from '../../shared/utils/stringUtils';

export default function HeaderNavigation() {
  const { instance } = useMsal();
  const { data: myProfile } = useMyPersonProfileQuery();
  const [isTermsMenuOpen, setIsTermsMenuOpen] = useState(false);

  const handleToggleConditionsMenu = () => {
    setIsTermsMenuOpen((current) => !current);
  };

  return (
    <div className="min-w-0 flex items-center justify-between ml-5">
      <div className="flex-shrink-0 flex items-center space-x-10">
        <div className="flex items-center space-x-8 relative">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="bg-white rounded-full flex text-sm">
              <span className="sr-only">Open user menu</span>
              <ProfileAvatar
                avatarProps={{
                  src: myProfile?.photoUrl,
                  alt: myProfile?.firstName,
                  widthClass: 'w-7',
                  heightClass: 'h-7',
                }}
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profile"
                        className={classNames(
                          active ? 'bg-gray-light' : '',
                          'block px-4 py-2 text-sm ',
                        )}
                      >
                        Your Profile
                      </Link>
                    )}
                  </Menu.Item>

                  <div className="block px-4 py-2  cursor-pointer ">
                    <button
                      type="button"
                      onClick={handleToggleConditionsMenu}
                      className="flex flex-row "
                    >
                      <Text as="span" size="small">
                        Privacy & Terms
                      </Text>
                      <span className="mt-0.5 ml-1">
                        {isTermsMenuOpen ? (
                          <ChevronUpIcon className="h-4 w-4 text-light" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 text-light" />
                        )}
                      </span>
                    </button>
                    {isTermsMenuOpen && (
                      <div className="px-2 py-3">
                        <ul className="space-y-2">
                          <li>
                            <Text as="span" size="small">
                              <a href="/user_agreements/Privacy_Notice_v1.0.pdf" target="_blank">
                                Privacy Notice
                              </a>
                            </Text>
                          </li>
                          <li>
                            <Text as="span" size="small">
                              <a href="/user_agreements/Terms_of_use_v1.0.pdf" target="_blank">
                                Terms of Use
                              </a>
                            </Text>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/"
                        className={classNames(
                          active ? 'bg-gray-light' : '',
                          'block px-4 py-2 text-sm',
                        )}
                        onClick={() => instance.logoutRedirect()}
                      >
                        <button type="button" className="text-sm font-medium text">
                          Sign out
                        </button>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
