import { useParams } from 'react-router';
import Messaging from '../Messaging/Messaging';
import MainPanelMobile from '../../shared/components/page/MainPanelMobile';
import NetworkInfo from './NetworkInfo';
import MainPanelDesktop from '../../shared/components/page/MainPanelDesktop';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import NetworkInfoHeaderMobile from './mobile/NetworkInfoHeaderMobile';

import { NetworkContextProvider } from './context/network-context';
import { useNetworkConversationQuery } from '../Messaging/queries/conversationQueries';
import { useMessagesQuery } from '../Messaging/queries/messageQueries';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import {
  createActivityAction,
  MessageEditorAction,
} from '../Messaging/editor/messageEditorMenuActions';
import { useNavigateToCurrentBasePath } from '../../shared/hooks/useNavigateToCurrentBasePath';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import { ViewName } from '../../shared/store/slices/viewSlice';
import SidePanel from '../../shared/components/page/SidePanel';
import useColorSetting, { ColorSetting } from '../../shared/hooks/useColorSetting';
import NetworkClosedSidePanel from './NetworkClosedSidePanel';

export default function Network() {
  const currentView: ViewName = 'network';
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const viewSettings = getViewSettings(currentView);

  const colorSettings: ColorSetting = useColorSetting('network');

  const messageEditorActions: MessageEditorAction[] = [
    createActivityAction('?tab=tab_activities', () => setSidePanelOpen(currentView, true)),
  ];

  const params = useParams();
  const networkId = params.id as string;

  const conversationQuery = useNetworkConversationQuery(networkId);
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);

  const navigateToCurrentBasePath = useNavigateToCurrentBasePath();

  if (conversationQuery.isSuccess && messagesQuery.isSuccess) {
    return (
      <>
        <NetworkContextProvider>
          <WrapperDesktop>
            <MainPanelDesktop>
              <Messaging
                messages={messagesQuery}
                conversation={conversationQuery.data}
                externalMenuActions={messageEditorActions}
              />
            </MainPanelDesktop>
            <SidePanel
              renderClosed={() => <NetworkClosedSidePanel networkId={networkId} />}
              isOpen={viewSettings.isSidePanelOpen}
              openSidePanel={() => setSidePanelOpen(currentView, true)}
              closeSidePanel={() => setSidePanelOpen(currentView, false)}
              onToggle={() => toggleSidePanel(currentView)}
              colorSetting={colorSettings}
            >
              <NetworkInfo
                networkId={networkId}
                onDeleteNetwork={navigateToCurrentBasePath}
                onLeaveNetwork={navigateToCurrentBasePath}
              />
            </SidePanel>
          </WrapperDesktop>
          <WrapperMobile>
            <MainPanelMobile>
              <NetworkInfoHeaderMobile networkId={networkId} />
              <NetworkInfo
                networkId={networkId}
                onDeleteNetwork={navigateToCurrentBasePath}
                onLeaveNetwork={navigateToCurrentBasePath}
              />
            </MainPanelMobile>
          </WrapperMobile>
        </NetworkContextProvider>
      </>
    );
  }
  return (
    <ErrorComponent
      queryResults={[conversationQuery, messagesQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The network could not be found.' }]}
    />
  );
}
