/**
 * Divides an array into chunks of a specified size.
 *
 * @param array The array to be chunked.
 * @param chunkSize The size of each chunk.
 * @returns An array of chunks, where each chunk is an array containing elements from the array.
 * @template T The type of the elements in the array.
 */

export function chunkArray<T>(array: T[], chunkSize: number) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}
