import Text, { TextColor } from '../text/Text';

interface IProps {
  value: string | number;
  textColor: TextColor;
  content: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

export default function KpiIndicator({ value, textColor, content, isActive, onClick }: IProps) {
  return (
    <button
      type="button"
      className="flex flex-1 items-center flex-col text-center hover:brightness-125"
      onClick={onClick}
    >
      <Text size="x2Large" color={textColor} weight={isActive ? 'bold' : 'semibold'}>
        {value}
      </Text>
      {content}
    </button>
  );
}
