import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { groupBy, sortBy } from 'lodash';
import { AppContext } from '../../../shared/context/context';
import { networkConversationFilter } from '../../../shared/filters/filters';
import { useIsMe } from '../../../shared/hooks/accountHooks';
import { IConversationDto } from '../../../shared/model/IConversationDto';
import { ICreateUpdateNetworkDto, INetworkDto } from '../../../shared/model/INetwork';
import sortConversation, { EntityType } from '../../../shared/services/conversationService';
import {
  createNetwork,
  deleteNetwork,
  getMyNetworks,
  getNetwork,
  updateNetwork,
  getNetworks,
} from '../../../shared/services/networkService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { ToastType } from '../../../shared/components/toasts/constants/ToastTypes';
import { createToast } from '../../../shared/services/toastService';
import { IPagedResult } from '../../../shared/model/IPagedResult';

type NetworkSearchField = 'Name';

interface INetworkKeyProps {
  id?: string;
  limit?: number;
  searchTerm?: string;
  name?: string;
  searchFields?: NetworkSearchField[];
}

interface ICreateUpdateNetworkProps {
  id?: string;
  network: ICreateUpdateNetworkDto;
}

export const networkKeys = {
  all: ['networks'] as const,
  lists: () => [...networkKeys.all, 'list'] as const,
  list: (props: INetworkKeyProps) => [...networkKeys.lists(), props] as const,
  details: () => [...networkKeys.all, 'detail'] as const,
  detail: (id: string) => [...networkKeys.details(), id] as const,
};
const STALE_TIME = 1000 * 60;

const networkBaseQuery = (props: INetworkKeyProps) => ({
  queryKey: networkKeys.list(props),
  queryFn: () =>
    getNetworks(
      new QueryParam('limit', props.limit || 250),
      new QueryParam('id', props.id),
      new QueryParam('searchTerm', props.searchTerm),
      new QueryParam('name', props.name),
      ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
    ),
  staleTime: STALE_TIME,
  placeholderData: keepPreviousData,
});

export function useChatNetworkQuery(searchTerm?: string) {
  const { state } = useContext(AppContext);
  const isMe = useIsMe();
  return useQuery({
    ...networkBaseQuery({ searchTerm }),
    enabled: !!state.conversations,
    select: (data: IPagedResult<INetworkDto>) => {
      const networksConversations = state.conversations?.filter(networkConversationFilter);

      const networks = data.data as INetworkDto[];

      const networkSorted = sortConversation(
        networksConversations as IConversationDto[],
        networks,
        EntityType.network,
        isMe,
      );

      return networkSorted;
    },
  });
}

export function useListMyNetworksQuery(searchTerm?: string) {
  const isMe = useIsMe();
  const { state } = useContext(AppContext);
  return useQuery({
    queryKey: networkKeys.list({ searchTerm }),
    queryFn: () => getMyNetworks(new QueryParam('searchTerm', searchTerm)),
    enabled: !!state.conversations,
    select: (_result: IPagedResult<INetworkDto>) => {
      const networks = _result.data;

      const networkConversations = state.conversations?.filter(
        networkConversationFilter,
      ) as IConversationDto[];

      const result = sortConversation(networkConversations, networks, EntityType.network, isMe);

      return result;
    },
  });
}

function alphabeticalNetworksSelector(_result: IPagedResult<INetworkDto>) {
  const networks = _result.data;

  const sortedNetworks = sortBy(networks, [(network: INetworkDto) => network.name.toLowerCase()]);
  const groupedNetworks = groupBy(sortedNetworks, (network) =>
    network.name.charAt(0).toUpperCase(),
  );
  return groupedNetworks;
}

export function useAlphabeticallySortedNetworkQuery(searchTerm?: string) {
  return useQuery({
    ...networkBaseQuery({ searchTerm }),
    select: alphabeticalNetworksSelector,
  });
}

export function useNetworkQuery(networkId?: string, enabled = true) {
  return useQuery({
    queryKey: networkKeys.detail(networkId as string),
    queryFn: () => getNetwork(networkId as string),
    enabled: !!networkId && enabled,
  });
}

export function useCreateUpdateNetworkMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, network }: ICreateUpdateNetworkProps) => {
      if (id) {
        return updateNetwork(network, id);
      }
      return createNetwork(network);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: networkKeys.lists() });
      if (id) {
        queryClient.invalidateQueries({ queryKey: networkKeys.detail(id) });
      }
    },
    onError: useDispatchApiError(),
    retry: 3,
  });
}

export function useDeleteNetworkMutation() {
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);

  return useMutation({
    mutationFn: ({ networkId }: { networkId: string; networkName: string }) =>
      deleteNetwork(networkId).then((response) => response.data),
    onSuccess: (_, { networkName }) => {
      queryClient.invalidateQueries({ queryKey: networkKeys.lists() });
      dispatch(
        createToast('Success', ToastType.Success, `The network ${networkName} was been deleted.`),
      );
    },
    onError: useDispatchApiError(),
  });
}
