import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { ShareContactRequestStatuses } from '../../../shared/model/IShareContactRequestDto';
import {
  acceptShareContactRequest,
  getShareContactRequests,
  rejectShareContactRequest,
} from '../../../shared/services/shareContactRequestService';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { shareContact } from '../../../shared/services/contactService';
import { contactKeys } from '../../Contacts/queries/contactQueries';

export interface IUseShareContactRequestProps {
  searchType?: 'Sent' | 'Received';
  status?: ShareContactRequestStatuses[];
  externalRef?: string;
  targetPersonProfileId?: string;
  requestorPersonProfileId?:string;
}

const shareContactRequestKeys = {
  all: ['share-contact-request'] as const,
  lists: () => [...shareContactRequestKeys.all, 'list'] as const,
  list: (props: IUseShareContactRequestProps) =>
    [...shareContactRequestKeys.lists(), props] as const,
};

export function useListShareContactRequestsQuery({
  targetPersonProfileId,
  requestorPersonProfileId,
  searchType,
  externalRef,
  status,
}: IUseShareContactRequestProps) {
  return useQuery({
    queryKey: shareContactRequestKeys.list({
      searchType,
      externalRef,
      status,
    }),
    queryFn: () =>
      getShareContactRequests(
        new QueryParam('externalRef', externalRef),
        new QueryParam('searchType', searchType),
        new QueryParam('targetPersonProfileId', targetPersonProfileId),
        new QueryParam('requestorPersonProfileId', requestorPersonProfileId),
        ...(status?.map((_status) => new QueryParam('status', _status)) ?? []),
      ),
  });
}

interface IShareContactProps {
  contactId: string;
  targetAccountIds: string[];
  externalRef?: string;
}

export function useShareContactMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contactId, targetAccountIds, externalRef }: IShareContactProps) =>
      shareContact(contactId, targetAccountIds, externalRef),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shareContactRequestKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}

interface IShareContactRequestActionProps {
  id: string;
}

export function useAcceptShareContactRequestMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IShareContactRequestActionProps) => acceptShareContactRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shareContactRequestKeys.lists() });
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}

export function useRejectShareContactRequestMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IShareContactRequestActionProps) => rejectShareContactRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shareContactRequestKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
