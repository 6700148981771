import { LinkIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { MouseEventHandler } from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ButtonColors } from '../../shared/constants/ButtonColors';

interface IProps {
  isConnectionInitiated: boolean;
  isConnectionAccepted: boolean;
  isFollowing: boolean;
  onFollowContact: MouseEventHandler;
  onCreateConnectionRequest: MouseEventHandler;
}

export default function ContactExternalProfileActionButtons({
  isConnectionInitiated,
  isConnectionAccepted,
  isFollowing,
  onFollowContact,
  onCreateConnectionRequest,
}: IProps) {
  return (
    <ButtonGroup>
      {!isConnectionInitiated && !isConnectionAccepted && (
        <Button
          color={ButtonColors.Cyan}
          text="Connect"
          icon={<UserPlusIcon className="w-5 h-5 mr-2" />}
          onClick={onCreateConnectionRequest}
        />
      )}
      {isConnectionInitiated && !isConnectionAccepted && (
        <Button
          color={ButtonColors.White}
          text="Sent"
          icon={<UserPlusIcon className="w-5 h-5 mr-2" />}
          isDisabled
          onClick={() => null}
        />
      )}

      {isConnectionInitiated && isConnectionAccepted && (
        <Button
          color={ButtonColors.White}
          text="Already Connected"
          icon={<UserPlusIcon className="w-5 h-5 mr-2" />}
          isDisabled
          onClick={() => null}
        />
      )}

      {!isConnectionAccepted && !isFollowing && (
        <Button
          color={ButtonColors.White}
          text="Follow"
          icon={<LinkIcon className="w-5 h-5 mr-2" />}
          onClick={onFollowContact}
        />
      )}

      {!isConnectionAccepted && isFollowing && (
        <Button
          color={ButtonColors.White}
          text="Already following"
          icon={<LinkIcon className="w-5 h-5 mr-2" />}
          isDisabled
          onClick={() => null}
        />
      )}
    </ButtonGroup>
  );
}
