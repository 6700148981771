import { formatDistanceToNow } from 'date-fns';
import { IConversationReadModel } from '../model/IConversationReadModel';
import { IMessageReadModel, IUpdateMessageDto } from '../model/IMessageReadModel';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';
import { IContactDto } from '../model/IContactDto';
import { IConversationDto } from '../model/IConversationDto';
import { INetworkDto } from '../model/INetwork';
import IParticipantReadModel from '../model/IPartisipantsReadModel';
import getTime from '../utils/dateUtils';
import { IsMeByUserIdFunc } from '../hooks/accountHooks';

export enum EntityType {
  contact,
  network,
}

export const getConversationMessage = (conversationId: string, messageId: string) => apiClient
    .get<IMessageReadModel>(`v1/conversations/${conversationId}/messages/${messageId}`)
    .then((response) => response.data);

export const getConversationMessages = (conversationId: string, ...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();

  return apiClient
    .get<IPagedResult<IMessageReadModel>>(
      `v1/conversations/${conversationId}/messages${queryString}`,
    )
    .then((response) => response.data);
};

export const createConversationMessage = (conversationId: string, message: IMessageReadModel) =>
  apiClient
    .post(`v1/conversations/${conversationId}/messages`, message)
    .then<IMessageReadModel>((response) => response.data);

export const updateConversationMessage = (
  conversationId: string,
  messageId: string,
  message: IUpdateMessageDto,
) =>
  apiClient
    .patch(`v1/conversations/${conversationId}/messages/${messageId}`, message, {
      headers: { 'Content-Type': 'application/merge-patch+json' },
    })
    .then<IMessageReadModel>((response) => response.data);

export const getConversationById = (id: string) =>
  apiClient.get(`v1/conversations/${id}`).then<IConversationReadModel>((response) => response.data);

export const getConversations = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();

  return apiClient
    .get<IPagedResult<IConversationReadModel>>(`v1/conversations${queryString}`)
    .then((response) => response.data);
};

export const deleteMessage = (conversationId: string, messageId: string) =>
  apiClient.delete(`v1/conversations/${conversationId}/messages/${messageId}`);

export const setConversationRead = (conversationId: string) =>
  apiClient.put(`v1/conversations/${conversationId}/read`);

export default function sortConversation<T extends INetworkDto | IContactDto>(
  conversations: IConversationDto[],
  entities: T[],
  entityType: EntityType,
  isMe: IsMeByUserIdFunc,
) {
  const entitiesSorted: T[] = [];
  for (let i = 0; i < entities.length; i += 1) {
    let conversationForEntity;

    if (entityType === EntityType.contact) {
      conversationForEntity = conversations?.find(
        (c) => c.connectionId === (entities[i] as IContactDto).connectionId,
      );
    }

    if (entityType === EntityType.network) {
      conversationForEntity = conversations?.find((c) => c.networkId === entities[i].id);
    }

    const entityClone = { ...entities[i] };

    if (conversationForEntity && conversationForEntity.latestMessage) {
      entityClone.latestMessage = conversationForEntity.latestMessage;
      entityClone.latestMessage.createdTimeFriendly = formatDistanceToNow(
        new Date(conversationForEntity.latestMessage.createdTime),
      );

      const currentUserAsParticipant = conversationForEntity.participants.find(
        (participant: IParticipantReadModel) => isMe(participant.userId),
      );

      if (currentUserAsParticipant) {
        entityClone.latestMessage.isUnread =
          new Date(entityClone.latestMessage.createdTime).getTime() >
          new Date(currentUserAsParticipant.lastReadTime).getTime();
      }
    } else {
      entityClone.latestMessage = {} as IMessageReadModel;
      entityClone.latestMessage.text = '-';
    }

    entitiesSorted.push(entityClone);
  }

  entitiesSorted.sort(
    (a: T, b: T) => getTime(b.latestMessage?.createdTime) - getTime(a.latestMessage?.createdTime),
  );

  return entitiesSorted;
}

export const setReaction = (conversationId: string, messageId: string, reactionValue: string) =>
  apiClient.put(`v1/conversations/${conversationId}/messages/${messageId}/reaction`, {
    reactionValue,
  });

export const deleteReaction = (conversationId: string, messageId: string) =>
  apiClient.delete(`v1/conversations/${conversationId}/messages/${messageId}/reaction`);
