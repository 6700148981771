import { FieldErrors, FieldName, FieldValues, Path } from 'react-hook-form';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import React from 'react';
import Label from '../text/Label';
import Text from '../text/Text';

interface InputFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  infoText?: string;
  children: React.ReactNode;
  errors?: FieldErrors;
}

export default function FieldWrapper<T extends object>({
  name,
  label,
  infoText,
  errors,
  children,
}: InputFieldProps<T>) {
  // Conversion is needed for ErrorMessage component to accept strict
  // type for name
  const errorName = name as unknown as FieldName<
    FieldValuesFromFieldErrors<FieldErrors<FieldValues>>
  >;

  return (
    <>
      <Label htmlFor={name} text={label} infoText={infoText} />

      {children}
      {errors && (
        <Text size="xSmall" color="red">
          <ErrorMessage errors={errors} name={errorName} />
        </Text>
      )}
    </>
  );
}
