import { useAccount } from '@azure/msal-react';
import { useCallback } from 'react';
import {
  useMyPersonProfileQuery,
} from '../../features/Contacts/queries/personProfileQueries';
import { getUserId } from '../utils/msalUtils';

export type IsMeFunc = (accountId: string) => boolean;
export type IsMeByUserIdFunc = (userId: string) => boolean;

export function useAccountId() {
  return useAccount()?.localAccountId;
}

export function useIsMe(): IsMeFunc {
  const activeAccountId = useAccountId();
  // Memoize the returned function to be able to use it inside useEffect hooks.
  return useCallback((accountId: string) => accountId === activeAccountId, [activeAccountId]);
}

export function useMyUserId() {
  const account = useAccount();
  const userId = getUserId(account?.idTokenClaims);
  const { data: personProfile } = useMyPersonProfileQuery(!userId);
  if (userId) {
    return userId;
  }

  return personProfile?.userId;
}

export function useIsMeByUserId(): IsMeByUserIdFunc {
  const activeUserId = useMyUserId();
  // Memoize the returned function to be able to use it inside useEffect hooks.
  return useCallback((userId: string) => userId === activeUserId, [activeUserId]);
}
