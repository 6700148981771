import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import Layout from './features/Layout/Layout';
import UrlNotFound from './features/UrlNotFound/UrlNotFound';
import Contacts from './features/Contacts/Contacts';
import Contact from './features/Contacts/Contact';
import Profile from './features/Profile/Profile';
import ErrorBoundary from './shared/components/error-boundary/ErrorBoundary';
import ContactExternalProfile from './features/Contacts/ContactExternalProfile';
import Networks from './features/Networks/Networks';
import Network from './features/Networks/Network';
import Index from './features/Index/Index';
import AuthRequired from './shared/auth/AuthRequired';
import { AppProvider } from './shared/context/context';
import { SignIn } from './features/SignUpSignIn/SignIn';
import { SignUp } from './features/SignUpSignIn/SignUp';
import AcceptInvitation from './features/SignUpSignIn/AcceptInvitation';
import Pipelines from './features/Pipelines/Pipelines';
import SelectPrompt from './shared/components/prompts/SelectPrompt';
import Pipeline from './features/Pipelines/Pipeline';
import PipelineSelectLayout from './features/Pipelines/PipelineSelectLayout';
import { Organizations } from './features/Organizations/Organizations';
import pipelineDummy from './assets/mocks/pipelinesMock.png';

function Routes() {
  return (
    <ErrorBoundary>
      <ReactRouterDomRoutes>
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="acceptinvitation/:invitationId" element={<AcceptInvitation />} />
        <Route
          path="/*"
          element={
            <AuthRequired>
              <AppProvider>
                <Layout />
              </AppProvider>
            </AuthRequired>
          }
        >
          <Route index element={<Index />} />
          <Route path="contacts" element={<Contacts />}>
            <Route path=":id" element={<Contact />} />
            <Route path="external/:id" element={<ContactExternalProfile />} />
            <Route
              path="*"
              element={
                <SelectPrompt
                  imageUrl={pipelineDummy}
                  text={
                    <>
                      Invite your contacts to start chatting, sharing files, and more.
                      <br /> Click on the plus icon now to get started
                    </>
                  }
                />
              }
            />
          </Route>

          <Route path="networks" element={<Networks />}>
            <Route path=":id" element={<Network />} />
            <Route
              path="*"
              element={
                <SelectPrompt
                  imageUrl={pipelineDummy}
                  text={
                    <>
                      Start building your place of business by creating a space to chat, share and
                      work with ideas.
                      <br /> Click on the plus icon and let the collaboration begin
                    </>
                  }
                />
              }
            />
          </Route>
          <Route path="boards" element={<Pipelines />}>
            <Route path=":id" element={<Pipeline />} />
            <Route path="*" element={<PipelineSelectLayout />} />
          </Route>
          <Route path="organizations" element={<Organizations />} />
          <Route path="profile" element={<Profile />} />
          <Route path="*" element={<UrlNotFound />} />
        </Route>
      </ReactRouterDomRoutes>
    </ErrorBoundary>
  );
}

export default Routes;
