import Text from '../text/Text';

interface ISharedFeatureHeaderProps {
  children: React.ReactNode;
}

export default function Header1({ children }: ISharedFeatureHeaderProps) {
  return (
    <Text as="h1" size="x4Large" weight="bold" color="cyan" brightness="dark">
      {children}
    </Text>
  );
}
