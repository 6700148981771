import { MouseEventHandler, ReactElement } from 'react';
import { ButtonColors } from '../../constants/ButtonColors';
import { useButtonStyle } from './styles/useButtonStyle';

export type ButtonStyle = 'filled' | 'outline';

interface IProps {
  color: ButtonColors;
  text: string;
  icon?: ReactElement;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  buttonStyle?: ButtonStyle;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function Button({
  text,
  icon = undefined,
  isDisabled = false,
  onClick,
  ...rest
}: IProps) {
  const styleProps = useButtonStyle(rest);

  return (
    <button type="button" onClick={onClick} disabled={isDisabled} {...styleProps}>
      {icon}
      {text}
    </button>
  );
}
