import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import RequestItem from './RequestItem';
import RequestItemsList from './RequestItemsList';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import { RequestLoader } from './RequestLoader';
import { usePipelineQuery } from '../Pipelines/queries/pipelineQueries';
import { useDeletePipelineInviteRequestMutation } from './queries/pipelineInviteQueries';
import { IInvitePipelineRequestDto } from '../../shared/model/IConnectionRequestDto';
import { useUserProfileQuery } from '../Contacts/queries/userProfileQueries';

interface IRequestItemProps {
  targetUserId: string;
  createdTime: Date | string;
  sharedPipelineId: string;
}

function RequestItemWrapper({ targetUserId, sharedPipelineId, createdTime }: IRequestItemProps) {
  const userProfileQuery = useUserProfileQuery(targetUserId);
  const pipelineQuery = usePipelineQuery(sharedPipelineId);

  if (userProfileQuery.isLoading || pipelineQuery.isLoading) {
    return <RequestLoader />;
  }

  if (userProfileQuery.isSuccess && pipelineQuery.isSuccess) {
    const requestorName = `${userProfileQuery.data.firstName} ${userProfileQuery.data.lastName}`;
    const pipelineName = pipelineQuery.data.name;

    return (
      <RequestItem
        title={requestorName}
        linkToPersonProfileId={userProfileQuery.data.accountId}
        photoUrl={userProfileQuery.data.photoUrl}
      >
        Invite to pipeline &apos;{pipelineName}&apos; sent <TimeAgo date={createdTime} /> ago.
      </RequestItem>
    );
  }

  return <div />;
}

interface IProps {
  pipelineInviteRequests: IInvitePipelineRequestDto[];
}

export default function InviteToPipelineRequest({ pipelineInviteRequests }: IProps) {
  const deletePipelineInviteRequestMutation = useDeletePipelineInviteRequestMutation();
  return (
    <div>
      <ul>
        {pipelineInviteRequests.map((request) => (
          <RequestItemsList
            key={request.id}
            id={request.id}
            renderBody={
              <RequestItemWrapper
                targetUserId={request.targetUserId}
                createdTime={request.createdTime}
                sharedPipelineId={request.sharedPipelineId}
              />
            }
            renderButton={
              <Button
                color={ButtonColors.White}
                text="Cancel"
                onClick={() => deletePipelineInviteRequestMutation.mutate(request)}
              />
            }
          />
        ))}
      </ul>
    </div>
  );
}
