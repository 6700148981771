import { DocumentCheckIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

export type MessageEditorAction = {
  label: string;
  to: string;
  icon: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export function createContactNoteAction(
  to: string,
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
): MessageEditorAction {
  return {
    to,
    label: 'Add note',
    icon: <PencilSquareIcon className="h-6 w-6" />,
    onClick,
  };
}

export function createActivityAction(
  to: string,
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
): MessageEditorAction {
  return {
    to,
    label: 'Add task',
    icon: <DocumentCheckIcon className="h-6 w-6" />,
    onClick,
  };
}
