import { IInviteNetworkRequestDto } from '../model/IConnectionRequestDto';
import { ICreateUpdateNetworkDto, INetworkDto } from '../model/INetwork';
import { INetworkMember } from '../model/INetworkMember';
import { IPagedResult } from '../model/IPagedResult';
import { IPrivilegesDto } from '../model/IPrivilegesDto';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export const getNetworks = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get<IPagedResult<INetworkDto>>(`v1/networks${queryString}`)
    .then<IPagedResult<INetworkDto>>((response) => response.data);
};

export const getMyNetworks = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/networks/my${queryString}`)
    .then<IPagedResult<INetworkDto>>((response) => response.data);
};

export const getNetwork = (id: string) =>
  apiClient.get(`v1/networks/${id}`).then<INetworkDto>((response) => response.data);

export const createNetwork = (network: ICreateUpdateNetworkDto) =>
  apiClient.post('v1/networks', network).then<INetworkDto>((response) => response.data);

export const updateNetwork = (network: ICreateUpdateNetworkDto, id: string) =>
  apiClient
    .patch(`v1/networks/${id}`, network, {
      headers: { 'content-type': 'application/merge-patch+json' },
    })
    // Need to return INetworkDto to match with createNetwork.
    .then<INetworkDto>(() => ({
      tenantId: '',
      hasUnreadMessages: false,
      latestMessage: undefined,
      memberCount: 0,
      ...network,
      id,
    }));

export const deleteNetwork = (id: string) => apiClient.delete(`v1/networks/${id}`);

export const getMembers = (id: string) =>
  apiClient
    .get(`v1/networks/${id}/members`)
    .then<Array<INetworkMember>>((response) => response.data.data);

export const deleteNetworkMember = (id: string, userId: string) =>
  apiClient.delete(`v1/networks/${id}/members/${userId}`);

export const inviteToNetwork = (
  networkId: string | undefined,
  targetUserId: string | undefined,
) => {
  const inviteNetworkDto = { targetUserId };
  return apiClient
    .post(`v1/networks/${networkId}/invite`, inviteNetworkDto)
    .then((response) => response.data.data);
};

export const acceptNetworkInvitation = (id: string) =>
  apiClient.post(`v1/invitenetworkrequests/${id}/accept`);

export const rejectNetworkInvitation = (id: string) =>
  apiClient.post(`v1/invitenetworkrequests/${id}/reject`);

export const getNetworkPrivileges = (id: string) =>
  apiClient.get(`v1/networks/${id}/privileges`).then<IPrivilegesDto>((result) => result.data);

export const getInviteNetworkRequest = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/invitenetworkrequests/${queryString}`)
    .then<Array<IInviteNetworkRequestDto>>((response) => response.data.data);
};

export const deleteInviteNetworkRequest = (id: string) =>
  apiClient.delete(`v1/invitenetworkrequests/${id}`);
