import { IConversationDto } from '../model/IConversationDto';
import { IConversationReadModel } from '../model/IConversationReadModel';
import { IMessageReadModel } from '../model/IMessageReadModel';
import { getConversationMessages, getConversations } from '../services/conversationService';
import QueryParam from '../utils/query-string-builder/QueryParam';

const processConversationData = (_conversations: IConversationReadModel[]) => {
  const conversations = [] as IConversationDto[];
  const promises = [];

  for (let i = 0; i < _conversations.length; i += 1) {
    promises.push(getConversationMessages(_conversations[i].id, new QueryParam('Limit', 1)));
  }

  const promiseList = Promise.all(promises);

  return promiseList.then((_conversationResult) => {
    const flattenedConversations = _conversationResult.flatMap((m) => m.data);

    for (let i = 0; i < _conversations.length; i += 1) {
      const latestMessage = flattenedConversations.find(
        (m) => m.conversationId === _conversations[i].id,
      ) as IMessageReadModel;

      conversations.push({
        id: _conversations[i].id,
        connectionId: _conversations[i].connectionId,
        participants: _conversations[i].participants,
        latestMessage,
        networkId: _conversations[i].networkId,
      });
    }

    return conversations;
  });
};

const setupConversations = () =>
  getConversations().then((_conversations) => {
    const conversationMessages = processConversationData(_conversations.data);
    return conversationMessages;
  });

export default setupConversations;
