import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSpinDelay } from 'spin-delay';
import Modal, { ModalSize } from '../../shared/components/modal/Modal';
import SearchBar from '../../shared/components/search/SearchBar';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import NetworkCreateEdit from './NetworkCreateEdit';
import NavigationList from '../../shared/components/navigation/NavigationList';
import NavigationBar from '../../shared/components/navigation/NavigationBar';
import NavigationHeader from '../../shared/components/navigation/NavigationHeader';
import ActionHeader from '../../shared/components/headers/ActionHeader';
import NavigationBarMobile from '../../shared/components/navigation/NavigationBarMobile';
import { AppContext } from '../../shared/context/context';
import useIsBaseRoute from '../../shared/hooks/useIsNavigationMode';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import { useAlphabeticallySortedNetworkQuery, useChatNetworkQuery } from './queries/networkQueries';
import useDebounce from '../../shared/hooks/useDebounce';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import { useStandaloneEditor } from '../../shared/lexical/useStandaloneEditor';
import { useLexicalMarkdownToPlainText } from '../../shared/lexical/markdown/useLexicalMarkdownToPlainText';
import { MESSAGE_EDITOR_CONFIG } from '../Messaging/editor/messageEditorConfig';
import ActionHeaderNetwork from '../../shared/components/headers/ActionHeaderNetwork';
import Text from '../../shared/components/text/Text';

export default function Networks() {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useCheckMobileScreen();

  const sortedNetwork = useAlphabeticallySortedNetworkQuery(debouncedSearchTerm);
  const { data: networks, isLoading: isNetworksLoading } = useChatNetworkQuery(debouncedSearchTerm);

  const { editor } = useStandaloneEditor(MESSAGE_EDITOR_CONFIG);
  const markdownToPlainText = useLexicalMarkdownToPlainText(editor);

  const handleOpenCreateEntityModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateNetworkModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateNetworkSuccess = (networkId: string) => {
    setIsCreateModalOpen(false);
    navigate(networkId);
  };

  const isLoading = useSpinDelay(isNetworksLoading || !state.conversations, {
    delay: 1000,
    minDuration: 0,
  });

  const [activeTab, setActiveTab] = useState<'chat' | 'sorted'>('chat');

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const isBaseRoute = useIsBaseRoute();

  useEffect(() => {
    if (!networks?.length || !matchPath(location.pathname, '/networks') || isMobile) return;
    navigate(networks[0].id);
  }, [networks?.length]);

  const renderGroupedNetwork = () => {
    if (!sortedNetwork.data) return null;

    return Object.entries(sortedNetwork?.data).map(([groups, _network]) => (
      <div key={groups}>
        <div className="ml-3 mt-2">
          <Text as="h3" weight="medium">
            {groups}
          </Text>
        </div>

        <NavigationList
          data={_network}
          isLoading={isLoading}
          imageType="Network"
          itemIdProperty={({ id }) => id}
          itemUrlProperty={({ id }) => id}
          itemHeadlineProperty={({ name }) => name}
          itemInformationProperty={({ name }) => name}
          itemSecondaryInformationProperty={({ latestMessage }) =>
            latestMessage?.createdTimeFriendly ?? ''
          }
          itemImgSrcProperty={() => ''}
          highlightInformationProperty={({ latestMessage }) => latestMessage?.isUnread ?? false}
          showHighlightIndicator={({ latestMessage }) => latestMessage?.isUnread ?? false}
        />
      </div>
    ));
  };

  const navigationSection = () => (
    <>
      <NavigationHeader>
        <ActionHeader text="Networks" onClick={handleOpenCreateEntityModal} icon="plus" />
        <ActionHeaderNetwork activeTab={activeTab} onClick={setActiveTab} />
        <SearchBar
          onSearchChange={handleOnSearchChange}
          searchTerm={searchTerm}
          placeholder="Search to find Networks"
        />
      </NavigationHeader>
      {/* // TODO: Class should not be here, it should be based on structure */}
      <ScrollBarWrapper className="mt-3">
        {activeTab === 'chat' && (
          <NavigationList
            imageType="Network"
            data={networks || []}
            isLoading={isLoading}
            itemIdProperty={({ id }) => id}
            itemUrlProperty={({ id }) => id}
            itemHeadlineProperty={({ name }) => `${name}`}
            itemInformationProperty={({ latestMessage }) =>
              latestMessage ? markdownToPlainText(latestMessage.text) : ''
            }
            itemSecondaryInformationProperty={({ latestMessage }) =>
              latestMessage?.createdTimeFriendly ?? ''
            }
            itemImgSrcProperty={() => ''}
            highlightInformationProperty={({ latestMessage }) => latestMessage?.isUnread ?? false}
            showHighlightIndicator={({ latestMessage }) => latestMessage?.isUnread ?? false}
          />
        )}

        {activeTab === 'sorted' && renderGroupedNetwork()}
      </ScrollBarWrapper>
    </>
  );

  return (
    <>
      <WrapperDesktop>
        <NavigationBar>{navigationSection()}</NavigationBar>
      </WrapperDesktop>
      <WrapperMobile>
        {isBaseRoute && <NavigationBarMobile>{navigationSection()}</NavigationBarMobile>}
      </WrapperMobile>

      <Outlet />

      <Modal
        isOpen={isCreateModalOpen}
        showCloseButton={false}
        onCloseClick={() => handleCloseCreateNetworkModal()}
        modalSize={ModalSize.Small}
      >
        <NetworkCreateEdit
          onSuccess={handleCreateNetworkSuccess}
          onCancel={handleCloseCreateNetworkModal}
        />
      </Modal>
    </>
  );
}
