import { Fragment, useState, useContext, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { AppContext } from '../../context/context';
import { deleteToast } from '../../services/toastService';
import ToastList from './ToastList';

export default function Toasts() {
  const { state, dispatch } = useContext(AppContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (state.toastMessages.length === 0) {
      setShow(false);
      return;
    }

    const [lastMessage] = state.toastMessages.slice(-1);
    if (lastMessage.autoClose) {
      setTimeout(() => {
        dispatch(deleteToast(lastMessage.id));
      }, lastMessage.delay);
    }

    setShow(true);
  }, [state.toastMessages]);

  return (
    <div
      aria-live="assertive"
      className="fixed z-[99] inset-0 flex items-start px-4 py-6 pointer-events-none sm:p-6 "
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="w-96 mt-12">
            <ToastList toasts={state.toastMessages} />
          </div>
        </Transition>
      </div>
    </div>
  );
}
