import { IPagedResult } from '../model/IPagedResult';
import { IPersonProfileDto } from '../model/IPersonProfileDto';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export const getMyProfile = () =>
  apiClient.get('v1/profiles/my').then<IPersonProfileDto>((response) => response.data);

export const getProfile = (id: string) =>
  apiClient.get(`v1/profiles/${id}`).then<IPersonProfileDto>((response) => response.data);

export const getAllProfiles = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/profiles${queryString}`)
    .then<IPagedResult<IPersonProfileDto>>((response) => response.data);
};
export const updateMyProfile = (profile: IPersonProfileDto) => apiClient
    .patch('v1/profiles/my', profile, {
      headers: { 'Content-Type': 'application/merge-patch+json' },
    })
    .then<IPersonProfileDto>((response) => response.data);

export const uploadMyProfilePicture = (imageFile: Blob) => {
  const formData = new FormData();

  formData.append('file', imageFile);

  return apiClient
    .post('v1/profiles/my/photo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data);
};

export const deleteMyProfilePicture = () => apiClient.delete('v1/profiles/my/photo').then((response) => response.data);
