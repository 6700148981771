import { ReactElement } from 'react';
import WrapperDesktop from '../wrappers/WrapperDesktop';
import HorizontalDivider from '../dividers/HorizontalDivider';
import Text from '../text/Text';

interface IProps {
  imageUrl?: string;
  text: ReactElement;
}
export default function SelectPrompt({ imageUrl, text }: IProps) {
  return (
    <WrapperDesktop>
      <section className="flex-1 overflow-y-auto fade-in-element">
        <div className="flex flex-col justify-center items-center text-center h-full mx-auto px-4 sm:px-6 lg:px-8">
          <img src={imageUrl} alt={imageUrl} className="max-w-md" />
          <HorizontalDivider />
          <Text as="h1" size="xLarge" brightness="normal" leading="snug">
            {text}
          </Text>
        </div>
      </section>
    </WrapperDesktop>
  );
}
