import { MouseEventHandler, ReactElement } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ButtonColors } from '../../constants/ButtonColors';

const colorLookup: { [key in ButtonColors]: string } = {
  [ButtonColors.White]: 'text-white disabled:text-white hover:text-gray-200',
  [ButtonColors.Gray]: 'text disabled:text-disabled hover:text-light',
  [ButtonColors.Green]: 'text-green disabled:text-green-light hover:text-green-dark',
  [ButtonColors.Cyan]: 'text-cyan-alternative disabled:text-cyan-light hover:text-cyan-dark',
  [ButtonColors.Red]: 'text-red disabled:text-red-light hover:text-red-dark',
};

interface IProps {
  color: ButtonColors;
  icon: ReactElement;
  isDisabled?: boolean;
  orientation?: 'horizontal' | 'vertical';
  grow?: boolean;
  title?: string;
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | SubmitHandler<any>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
}

export default function IconButton({
  color,
  icon,
  isDisabled = false,
  grow = false,
  title = '',
  orientation = 'vertical',
  label,
  onClick,
  onMouseDown,
}: IProps) {
  const baseClass = `flex items-center justify-center ${grow ? 'grow' : ''} ${
    orientation === 'vertical' ? 'flex-col' : 'flex-row'
  } disabled:opacity-30`;
  return (
    <button
      type="button"
      onClick={onClick}
      onMouseDown={onMouseDown}
      disabled={isDisabled}
      className={`${baseClass} ${colorLookup[color]}`}
      title={title}
    >
      {icon}
      {label && <span className="text-xs leading-snug">{label}</span>}
    </button>
  );
}
