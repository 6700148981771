import { useQuery } from '@tanstack/react-query';
import { getMyConnections } from '../../../shared/services/connectionService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';

export interface IUseConnectionsProps {
  limit?: number;
  accountId?: string;
}

const connectionKeys = {
  all: ['connections'] as const,
  lists: () => [...connectionKeys.all, 'list'] as const,
  list: (props: IUseConnectionsProps) => [...connectionKeys.lists(), props] as const,
};

export function useHasConnectionQuery(accountId?: string) {
  return useQuery({
    queryKey: connectionKeys.list({ accountId }),
    queryFn: () => getMyConnections(new QueryParam('accountId', accountId)),
    select: (result) => result.data.length > 0,
    enabled: !!accountId,
  });
}
