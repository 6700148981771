import { useQuery } from '@tanstack/react-query';
import { IPipelinePrivileges } from '../models/IPipelineDto';
import { getPrivileges } from '../services/pipelineMemberService';

const pipelinePrivilegesKeys = {
  all: ['pipeline-privileges'] as const,
  details: () => [...pipelinePrivilegesKeys.all, 'detail'] as const,
  detail: (id: string) => [...pipelinePrivilegesKeys.details(), id] as const,
};

export function usePipelinePrivilegesQuery(id?: string) {
  return useQuery({
    queryKey: pipelinePrivilegesKeys.detail(id as string),
    queryFn: () => getPrivileges(id as string),
    enabled: !!id,
    select: (data): IPipelinePrivileges => ({
      canEdit: data.privileges.includes('Pipeline.ReadWrite'),
      canDelete: data.privileges.includes('Pipeline.Delete'),
      canReadMembers: data.privileges.includes('Pipeline.Member.Read'),
      canInviteMember: data.privileges.includes('Pipeline.Member.Invite'),
      canRemoveMember: data.privileges.includes('Pipeline.Member.Remove'),
      canReadBudgets: data.privileges.includes('Pipeline.Budget.Read'),
      canEditBudgets: data.privileges.includes('Pipeline.Budget.ReadWrite'),
      canDeleteBudgets: data.privileges.includes('Pipeline.Budget.Delete'),
    }),
  });
}
