import { ChangeEventHandler, useState } from 'react';
import { REGEX_EMAIL } from '../../../constants/RegexValidations';
import ValidationErrorMessage from './ValidationErrorMessage';
import Label from '../../text/Label';
import { Input } from '../../form/Input';

interface IProps {
  text: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export default function TextFieldEmailInput({ text, onChange, label }: IProps) {
  const [error, setError] = useState(false);

  const validateEmail = () => {
    const { value } = document.getElementById('EmailAddress') as HTMLInputElement;
    if (value.match(REGEX_EMAIL)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Label
        htmlFor="emailAddress"
        text={label}
        extra={
          <>{error && <ValidationErrorMessage message="Input is not a proper email address" />}</>
        }
      />

      <div className="mt-1">
        <Input
          type="text"
          id="EmailAddress"
          onKeyUp={validateEmail}
          placeholder="Email"
          value={text}
          className={`shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md ${
            error === true ? 'focus:ring-red-500 focus:border-red-500 border-red-500' : ''
          }`}
          onChange={onChange}
        />
      </div>
    </>
  );
}
