import networkDummy from '../../assets/images/network-icon.svg';
import ContactInfoContextMenu from '../Contacts/ContactInfoContextMenu';
import useNetworkInfoActionButtons from './hooks/useNetworkInfoActionButtons';

interface IProps {
  networkId: string;
}

export default function NetworkClosedSidePanel({ networkId }: IProps) {
  const menuItemActionButtons = useNetworkInfoActionButtons(networkId);

  return (
    <>
      <img src={networkDummy} alt="Network" className="rounded-full w-12 h-12" />
      <ContactInfoContextMenu menuItemActionButtons={menuItemActionButtons} dotsColor="white" />
    </>
  );
}
