import { useEffect, useState } from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useFollowMutation, useUpdateContactMutation } from './queries/contactQueries';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import Button from '../../shared/components/buttons/Button';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import { IContactDto } from '../../shared/model/IContactDto';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import { getAllProfiles } from '../../shared/services/profileService';
import QueryParam from '../../shared/utils/query-string-builder/QueryParam';
import ProfileAvatar from '../Profile/ProfileAvatar';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import useDebounce from '../../shared/hooks/useDebounce';
import { useConnectWithProfileMutation } from '../Request/queries/connectionRequestQueries';
import { useCreateInvitationMutation } from '../Request/queries/invitationQueries';
import Text from '../../shared/components/text/Text';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { Card } from '../../shared/components/cards/Card';

/* We are using the optional callback function for communicating with the modal via the
   parent component "Contacts". I'm sure we can find a more beautiful solution to this later */

interface IProps {
  contact: IContactDto;
  callback?: () => void;
}

export default function ContactEditContact({ contact, callback }: IProps) {
  const [showUnsavedDataWarning, setShowUnsavedDataWarning] = useState(false);
  const [invokeCallbackOnSubmit, setInvokeCallbackOnSubmit] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isUserAlreadyRegistered, setIsUserAlreadyRegistered] = useState(false);
  const [isIAmSureToSendChecked, setIsIAmSureToSendChecked] = useState(false);
  const [existingPerson, setExistingPerson] = useState({} as IPersonProfileDto);

  const connectWithProfileMutation = useConnectWithProfileMutation();
  const createInvitationMutation = useCreateInvitationMutation();
  const followMutation = useFollowMutation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getFieldState,
    formState: { errors, isDirty, isValid },
  } = useForm<IContactDto>({
    mode: 'onChange',
    defaultValues: {
      firstName: contact.firstName,
      lastName: contact.lastName,
      emailAddress: contact.emailAddress,
      phoneNumber: contact.phoneNumber,
      location: contact.location,
      company: contact.company,
      title: contact.title,
      additionalInformation: contact.additionalInformation,
    },
  });

  const inviteEmailAddress = watch('emailAddress');
  const emailFieldState = getFieldState('emailAddress');
  const debouncedEmailState = useDebounce({
    email: inviteEmailAddress,
    invalid: emailFieldState.invalid,
  });
  const updateContactMutation = useUpdateContactMutation();

  const resetState = () => {
    setIsIAmSureToSendChecked(false);
    setIsEmailVerified(false);
    setIsUserAlreadyRegistered(false);
    setExistingPerson({} as IPersonProfileDto);
  };

  useEffect(() => {
    if (debouncedEmailState.invalid) return;

    resetState();

    if (debouncedEmailState.email !== '') {
      getAllProfiles(
        new QueryParam('searchTerm', debouncedEmailState.email),
        new QueryParam('Limit', 1),
      ).then((result) => {
        if (result.data.length === 1) {
          setIsEmailVerified(true);
          setIsUserAlreadyRegistered(true);
          setExistingPerson(result.data[0]);
        } else {
          setIsEmailVerified(true);
        }
      });
    }
  }, [debouncedEmailState.email, debouncedEmailState.invalid]);

  const invokeCallback = () => {
    if (typeof callback !== 'undefined') {
      callback();
    }
  };

  const handleContinueEditing = () => {
    setShowUnsavedDataWarning(false);
  };

  const handleContinueClosing = () => {
    reset();
    invokeCallback();
  };

  const onSubmit: SubmitHandler<IContactDto> = (updatedContact) => {
    updateContactMutation.mutate(
      { contact: updatedContact, id: contact.id },
      {
        onSuccess: () => {
          reset({}, { keepValues: true });
          if (invokeCallbackOnSubmit) {
            invokeCallback();
          }
        },
      },
    );
  };

  const handleInviteUser = () => {
    createInvitationMutation.mutate(
      { email: inviteEmailAddress },
      {
        onSuccess: () => handleContinueClosing(),
      },
    );
  };

  const handleCancelEditContact = () => {
    if (isDirty) {
      setShowUnsavedDataWarning(true);
    } else {
      reset({}, { keepValues: true });
      invokeCallback();
    }
  };

  const handleCreateConnectionRequest = () => {
    followMutation.mutate({
      personProfileId: existingPerson.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      contactId: contact.id,
    });

    connectWithProfileMutation.mutate(
      { personProfileId: existingPerson.id, ...existingPerson },
      { onSuccess: () => handleContinueClosing() },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="text-left space-y-8">
        <div>
          <Text as="h1" size="xLarge" leading="normal" weight="medium">
            Invite Contact
          </Text>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Email"
                name="emailAddress"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Must be a valid email"
                validationRules={{ pattern: REGEX_EMAIL }}
              />
            </div>
            <div className="flex h-full items-end sm:col-span-3">
              <div className="mt-2">
                <Button
                  color={ButtonColors.White}
                  text="Invite"
                  icon={<EnvelopeIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}
                  onClick={handleInviteUser}
                  isDisabled={!isIAmSureToSendChecked}
                />
              </div>
            </div>
          </div>
          <HorizontalDivider />
          <Text as="p" size="small" brightness="light">
            {!isUserAlreadyRegistered ? (
              <>
                {!isEmailVerified || existingPerson.emailAddress ? (
                  <>Enter an email to see if the contact exists in Yoin.</>
                ) : (
                  <>
                    The contact does not exist in Yoin. You can invite the contact to Yoin by
                    pressing the &quot;Invite&quot;-button.
                  </>
                )}
              </>
            ) : (
              <>
                {isEmailVerified && existingPerson.emailAddress === debouncedEmailState.email
                  ? `The user ${existingPerson.firstName} ${existingPerson.lastName} exists`
                  : 'Enter an email to see if the contact exists in Yoin.'}
              </>
            )}
          </Text>
          {isEmailVerified && !isUserAlreadyRegistered && !debouncedEmailState.invalid && (
            <div className="relative flex items-start my-4">
              <div className="flex h-5 items-center">
                <input
                  id="verify"
                  aria-describedby="verify-description"
                  name="verify"
                  type="checkbox"
                  onChange={(e) => setIsIAmSureToSendChecked(e.target.checked)}
                  className="h-4 w-4 rounded border text-cyan"
                />
              </div>
              <div className="ml-3">
                <Text as="p" size="small" brightness="normal">
                  I am sure I want to send an invite to this email address
                </Text>
              </div>
            </div>
          )}
          {isEmailVerified &&
            isUserAlreadyRegistered &&
            !debouncedEmailState.invalid &&
            existingPerson.emailAddress === debouncedEmailState.email && (
              <div className="flex flex-row flex-1 space-x-0 md:space-x-3">
                <Card as="div" className="flex space-x-2">
                  <div className="hidden md:flex items-center space-x-2">
                    <ProfileAvatar
                      avatarProps={{
                        src: existingPerson.photoUrl,
                        widthClass: 'w-10',
                        heightClass: 'h-10',
                      }}
                    />
                    <div className="flex flex-col">
                      <Text as="span" size="small" weight="medium">
                        {`${existingPerson.firstName} ${existingPerson.lastName}`}
                      </Text>
                      <Text as="span" size="xSmall" brightness="light">
                        {existingPerson.emailAddress}
                      </Text>
                    </div>
                  </div>
                  <Button
                    color={ButtonColors.Cyan}
                    text="Connect"
                    onClick={handleCreateConnectionRequest}
                  />
                </Card>
              </div>
            )}
        </div>
        <div>
          <Text as="h3" size="large" leading="normal" weight="medium">
            Personal Information
          </Text>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="First Name"
                name="firstName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required and between 2 and 50 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 50 }}
              />
            </div>
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Last Name"
                name="lastName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required and between 2 and 50 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 50 }}
              />
            </div>
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Phone Number"
                name="phoneNumber"
                type="text"
                register={register}
                errorMessage="Friendly message must be between 2 and 50 characters"
                validationRules={{ required: false, minLength: 2, maxLength: 50 }}
              />
            </div>
            <div className="sm:col-span-3" />
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Location"
                name="location"
                type="text"
                register={register}
                errorMessage="Friendly message must be between 2 and 50 characters"
                validationRules={{ required: false, minLength: 2, maxLength: 50 }}
              />
            </div>
            <div className="sm:col-span-3" />
            <div className="sm:col-span-3">
              <TextFieldInput labelText="Company" name="company" type="text" register={register} />
            </div>
            <div className="sm:col-span-3">
              <TextFieldInput labelText="Title" name="title" type="text" register={register} />
            </div>
          </div>
        </div>
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="mt-1">
                <TextAreaInput
                  name="additionalInformation"
                  register={register}
                  labelText="Additional Information"
                />
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end gap-x-3">
              <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelEditContact} />
              <SubmitButton
                text="Save"
                onClick={() => setInvokeCallbackOnSubmit(true)}
                isDisabled={!isValid || updateContactMutation.isPending}
              />
            </div>
          </div>
        </div>
      </form>
      <ConfirmDialog isOpen={showUnsavedDataWarning} title="You have unsaved data">
        <Text as="p">Your changes will be lost if you leave</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Cyan} text="Leave" onClick={handleContinueClosing} />
          <Button
            color={ButtonColors.White}
            text="Continue Editing"
            onClick={handleContinueEditing}
          />
        </ButtonGroup>
      </ConfirmDialog>
    </>
  );
}
