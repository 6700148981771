import { useSearchParams } from 'react-router-dom';
import TabContent from '../../../shared/components/tabs/TabContent';
import TabNavItem from '../../../shared/components/tabs/TabNavItem';
import { ActivityEntityType } from '../../../shared/model/IActitityDto';
import Activities from '../../Activities/Activities';
import TabButtonItem from '../../../shared/components/tabs/TabButtonItem';
import { UseMessagesQueryResult, useMessagesQuery } from '../../Messaging/queries/messageQueries';
import Messaging from '../../Messaging/Messaging';
import NetworkTabBody from '../NetworkTabBody';
import NetworkTabBodyMembers from '../NetworkTabBodyMembers';
import TabContentWrapper from '../../../shared/components/tabs/TabContentWrapper';
import TabHeaderWrapper from '../../../shared/components/tabs/TabHeaderWrapper';
import Attachments from '../../../shared/components/attachments/Attachments';
import useNetworkInfoActionButtons from '../hooks/useNetworkInfoActionButtons';
import { useNetworkQuery } from '../queries/networkQueries';
import { useNetworkConversationQuery } from '../../Messaging/queries/conversationQueries';

import {
  MessageEditorAction,
  createActivityAction,
} from '../../Messaging/editor/messageEditorMenuActions';
import useNetworkTabNavigation from '../hooks/useNetworkTabNavigation';

interface IProps {
  networkId: string;
}

export default function NetworkInfoTabsMobile({ networkId }: IProps) {
  const [, setSearchParams] = useSearchParams();

  const activeTabId = useNetworkTabNavigation();

  const networkQuery = useNetworkQuery(networkId);
  const network = networkQuery.data;

  const handleSetActiveTab = (id: string) => {
    setSearchParams({ tab: id });
  };

  const conversationQuery = useNetworkConversationQuery(networkId);

  const conversation = conversationQuery.data;
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);

  const isContextMenuItemActive = activeTabId === 'tab_info' || activeTabId === 'tab_attachments';

  const menuItemActionButtons = useNetworkInfoActionButtons(networkId);

  const messageEditorActions: MessageEditorAction[] = [createActivityAction('?tab=tab_activities')];

  return (
    <>
      <TabHeaderWrapper>
        <TabNavItem
          id="tab_chat"
          activeId={activeTabId}
          title="Chat"
          setActiveTab={handleSetActiveTab}
        />

        <TabNavItem
          id="tab_members"
          activeId={activeTabId}
          title="Members"
          setActiveTab={handleSetActiveTab}
        />

        <TabNavItem
          id="tab_activities"
          activeId={activeTabId}
          title="Tasks"
          setActiveTab={handleSetActiveTab}
        />
        <TabButtonItem
          menuItemActionButtons={menuItemActionButtons}
          isActive={isContextMenuItemActive}
        />
      </TabHeaderWrapper>
      <TabContentWrapper>
        <TabContent id="tab_chat" activeTabId={activeTabId}>
          {conversation && (
            <Messaging
              messages={messagesQuery as UseMessagesQueryResult}
              conversation={conversation}
              externalMenuActions={messageEditorActions}
            />
          )}
        </TabContent>

        <TabContent id="tab_info" activeTabId={activeTabId}>
          <NetworkTabBody network={network} />
        </TabContent>

        <TabContent id="tab_members" activeTabId={activeTabId}>
          <NetworkTabBodyMembers networkId={networkId} />
        </TabContent>

        <TabContent id="tab_activities" activeTabId={activeTabId}>
          {network && <Activities parentId={network.id} type={ActivityEntityType.Network} />}
        </TabContent>

        <TabContent id="tab_attachments" activeTabId={activeTabId}>
          <Attachments conversationId={conversation?.id as string} />
        </TabContent>
      </TabContentWrapper>
    </>
  );
}
