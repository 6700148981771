import { useContext, useEffect } from 'react';
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AppContext } from '../../shared/context/context';
import { useInfiniteApiQuery } from '../../shared/hooks/useInfiniteApiQuery';
import { INotificationDto } from '../../shared/model/INotificationDto';
import finalizeNotification, {
  getNotifications,
  readNotification,
} from '../../shared/services/notificationService';
import QueryParam from '../../shared/utils/query-string-builder/QueryParam';
import { updateInfiniteQueryData } from '../../shared/utils/queryClientUtils';

/**
 * Creates a new list of notifications based on the current state. Determines if a notification
 * is new or if an old one needs to be updated
 * @param notification takes in a notification
 * @param notifications takes in a list of notifications
 * @returns a cloned list of notifications
 */
const updateOrAddNotification = (
  notification: INotificationDto,
  notifications: INotificationDto[] | undefined,
): INotificationDto[] => {
  const notificationsClone = notifications ? [...notifications] : ([] as INotificationDto[]);
  const index = notificationsClone.findIndex((n) => n.id === notification.id);

  if (index === -1) {
    notificationsClone.unshift(notification);
    return notificationsClone;
  }

  notificationsClone.splice(index, 1, notification);
  return notificationsClone;
};

export type UseNotificationsQueryResult = UseInfiniteQueryResult<
  InfiniteData<INotificationDto[], string | undefined>,
  unknown
> & {
  setIsResponded: (id: string) => void;
};

export interface IUseNotificationProps {
  limit?: number;
  hasResponse?: boolean;
  notificationType?: string;
}

interface IReadNotificationProps {
  id: string;
}

const keys = {
  all: ['notfications'] as const,
  lists: () => [...keys.all, 'list'] as const,
  list: (props: IUseNotificationProps) => [...keys.lists(), props] as const,
  details: () => [...keys.all, 'detail'] as const,
  detail: (id: string) => [...keys.details(), id] as const,
};

const useNotificationDefaults: IUseNotificationProps = {
  limit: 20,
};

export function useNotificationsQuery(
  options?: IUseNotificationProps,
): UseNotificationsQueryResult {
  const _options = {
    ...useNotificationDefaults,
    ...options,
  };

  const queryClient = useQueryClient();
  const queryResult = useInfiniteApiQuery({
    queryKey: keys.list(_options),
    queryFn: () =>
      getNotifications(
        new QueryParam('limit', _options.limit),
        new QueryParam('notificationType', _options.notificationType),
        new QueryParam('hasResponse', _options.hasResponse?.toString()),
      ),
    select: (data) => ({
      pageParams: data.pageParams,
      pages: data.pages.map((page) => page.data.map((n) => finalizeNotification(n))),
    }),
    staleTime: Infinity,
    useKeepPreviousData: true,
  });

  const setIsResponded = (id: string) => {
    updateInfiniteQueryData<INotificationDto>(queryClient, keys.list(_options), (data) =>
      data.map((n) => {
        if (n.id !== id) return n;

        const notificationClone: INotificationDto = { ...n, isResponded: true };
        return notificationClone;
      }),
    );
  };

  return {
    setIsResponded,
    ...queryResult,
  };
}

export function useNotificationSubscription(options?: IUseNotificationProps) {
  const _options = {
    ...useNotificationDefaults,
    ...options,
  };
  const { state } = useContext(AppContext);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!state.connection.on) return undefined;

    state.connection.on('ReceiveNotification', (newNotification: INotificationDto) => {
      updateInfiniteQueryData<INotificationDto>(
        queryClient,
        keys.list(_options),
        (data) => updateOrAddNotification(newNotification, data),
      );
    });

    // eslint-disable-next-line consistent-return
    return () => {
      state.connection.off('ReceiveNotification');
      // Invalidate queries but don't refetch automatically
      queryClient.invalidateQueries({ queryKey: keys.all, refetchType: 'none' });
    };
  }, [queryClient, state.connection]);
}

export function useReadNotificationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IReadNotificationProps) => readNotification(id),
    onMutate: ({ id }) => {
      // optimistic update
      let oldData: INotificationDto | undefined;
      updateInfiniteQueryData<INotificationDto>(queryClient, keys.lists(), (data) =>
        data.map((n) => {
          if (n.id !== id) return n;
          oldData = n;
          const notificationClone: INotificationDto = { ...n, isRead: true };
          return notificationClone;
        }),
      );
      return oldData;
    },
    onError: (_, { id }, oldData: INotificationDto | undefined) => {
      // rollback optimistic update
      if (typeof oldData === 'undefined') return;
      updateInfiniteQueryData<INotificationDto>(queryClient, keys.lists(), (data) =>
        data.map((n) => {
          if (n.id !== id) return n;
          return oldData;
        }),
      );
    },
  });
}
