import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ShareIcon, UsersIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import {
  ShareIcon as ShareIconSolid,
  UsersIcon as UsersIconSolid,
  ChartBarIcon as ChartBarIconSolid,
} from '@heroicons/react/24/solid';
import { ISidebarNavItem } from '../../shared/model/ISidebarNavItem';
import { AppContext } from '../../shared/context/context';
import { contactConversationFilter, networkConversationFilter } from '../../shared/filters/filters';
import SidebarNavNotification from './SidebarNavNotification';
import BorderActiveVertical from '../../shared/components/indicators/BorderActiveVertical';
import { classNames } from '../../shared/utils/stringUtils';

export default function SidebarNav() {
  const [sidebarNavItems, setSidebarNavItems] = useState([
    {
      name: 'Contacts',
      route: 'contacts',
      icon: UsersIcon,
      activeIcon: UsersIconSolid,
      current: false,
    },
    {
      name: 'Networks',
      route: 'networks',
      icon: ShareIcon,
      activeIcon: ShareIconSolid,
      current: false,
    },
    {
      name: 'Boards',
      route: 'boards',
      icon: ChartBarIcon,
      activeIcon: ChartBarIconSolid,
      current: false,
    },
  ] as Array<ISidebarNavItem>);

  const location = useLocation();

  const { state } = useContext(AppContext);
  const [numberOfUnreadContactNotifications, setNumberOfUnreadContactNotifications] = useState(0);
  const [numberOfUnreadNetworkNotifications, setNumberOfUnreadNetworkNotifications] = useState(0);

  useEffect(() => {
    const sidebarNavItemsCopy = sidebarNavItems.map((navItem) => {
      const navItemCopy = { ...navItem };
      const currentRoute = location.pathname.substring(1, location.pathname.length);

      navItemCopy.current = false;

      if (
        (navItemCopy.isRootNode && currentRoute.toLocaleLowerCase() === '') ||
        (!navItemCopy.isRootNode &&
          currentRoute.toLowerCase().startsWith(navItemCopy.route.toLowerCase()))
      ) {
        navItemCopy.current = true;
      }

      return navItemCopy;
    });

    setSidebarNavItems(sidebarNavItemsCopy);
  }, [location]);

  useEffect(() => {
    if (!state.conversations) return;

    setNumberOfUnreadContactNotifications(
      state.conversations?.length
        ? state.conversations
            ?.filter(contactConversationFilter)
            .filter((m) => m.latestMessage?.isUnread === true).length
        : 0,
    );

    setNumberOfUnreadNetworkNotifications(
      state.conversations?.length
        ? state.conversations
            ?.filter(networkConversationFilter)
            .filter((m) => m.latestMessage?.isUnread === true).length
        : 0,
    );
  }, [state.conversations]);

  return (
    <nav
      aria-label="Sidebar"
      className="hidden md:block md:overflow-y-auto overflow-x-hidden rounded-tr-lg p-2 pl-0 border bg-white"
    >
      <div className="flex flex-col relative h-full w-16 2xl:w-52 space-y-2 rounded-lg p-6">
        {sidebarNavItems
          .filter((item) => !item.isHidden)
          .map((item) => (
            <Link
              key={item.name}
              to={item.route}
              className={classNames(
                item.current
                  ? 'text-cyan-dark rounded-none font-bold '
                  : 'text hover:text-cyan-dark',
                'flex-shrink-0 inline-flex h-10 rounded-lg w-full relative',
                item.className ? item.className : '',
              )}
            >
              <span className="flex flex-row items-center">
                {numberOfUnreadContactNotifications > 0 && item.name === 'Contacts' && (
                  <SidebarNavNotification
                    numberOfNewNotifications={numberOfUnreadContactNotifications}
                  />
                )}
                {numberOfUnreadNetworkNotifications > 0 && item.name === 'Networks' && (
                  <SidebarNavNotification
                    numberOfNewNotifications={numberOfUnreadNetworkNotifications}
                  />
                )}
                {item.current ? (
                  <>
                    <item.activeIcon
                      className={classNames('w-7 h-7', item.current ? 'text-cyan' : '')}
                      aria-hidden="true"
                    />
                    <span className="font-bold ml-4 hidden 2xl:block ">{item.name}</span>
                    <BorderActiveVertical thickness="thick" rightOffset={-33} />
                  </>
                ) : (
                  <>
                    <item.icon
                      className={classNames('w-7 h-7', item.current ? 'text-cyan-dark' : '')}
                      aria-hidden="true"
                    />
                    <span className="font-semibold ml-4 hidden 2xl:block">{item.name}</span>
                  </>
                )}
              </span>
            </Link>
          ))}
      </div>
    </nav>
  );
}
