import { Outlet } from 'react-router-dom';
import Toasts from '../../shared/components/toasts/Toasts';
import Header from './Header';
import SidebarNav from './SidebarNav';

export default function Layout() {
  return (
    <>
      <div className="flex flex-col h-dscreen overflow-hidden">
        <Header />

        <div className="flex flex-1 overflow-hidden">
          <SidebarNav />

          {/* Main area */}
          <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
            <Outlet />
          </div>
        </div>
      </div>
      <Toasts />
    </>
  );
}
