import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import useIsBaseRoute from '../../shared/hooks/useIsNavigationMode';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

export default function Header() {
  const isBaseRoute = useIsBaseRoute();
  return (
    <>
      <WrapperDesktop>
        <HeaderDesktop />
      </WrapperDesktop>
      {isBaseRoute && (
        <WrapperMobile>
          <HeaderMobile />
        </WrapperMobile>
      )}
    </>
  );
}
