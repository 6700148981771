import { useEffect, useState } from 'react';

interface ITextSwitchItem<T> {
  label: string;
  value: T;
}

interface ISwitchProps<T> {
  items: ITextSwitchItem<T>[];
  onChange: (value: T) => void;
}

export default function Switch<T>({ items, onChange }: ISwitchProps<T>) {
  const [activeItem, setActiveItem] = useState(items[0].value);

  const handleOnChange = (value: T) => {
    setActiveItem(value);
  };

  useEffect(() => {
    onChange(activeItem);
  }, [activeItem]);

  return (
    <div className="flex flex-row rounded-full bg-gray p-0.5 items-center justify-center h-9">
      {items.map((item) => (
        <button
          key={item.label}
          type="button"
          className={`flex items-center rounded-full text-light px-2 text-sm h-8 cursor-pointer ${
            activeItem === item.value ? ' bg-cyan text-white font-semibold' : ''
          }`}
          onClick={() => handleOnChange(item.value)}
        >
          <span className="px-1">{item.label}</span>
        </button>
      ))}
    </div>
  );
}
