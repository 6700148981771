import { INetworkDto } from '../../shared/model/INetwork';
import HeaderSubHeader from '../../shared/components/headers/HeaderSubHeader';
import { IPipelineEditItemProps } from '../Pipelines/models/IPipelineEditItemProps';
import Howto from '../../shared/components/message-popups/HowTo';

interface IProps {
  network: INetworkDto | undefined | null;
  pipelineEditItemProps?: IPipelineEditItemProps;
}

export default function NetworkTabBody({ network, pipelineEditItemProps }: IProps) {
  return (
    <div className="flex-1 min-h-0 flex flex-col sm:px-0">
      <div className="max-w-4xl mt-4">
        <HeaderSubHeader size="small" text="Description" subText={network?.description || ''} />
      </div>
      {pipelineEditItemProps?.renderEditItem && (
        <div className="mt-4">{pipelineEditItemProps.renderEditItem}</div>
      )}
      <Howto
        title="Info"
        description="View the description of your network.
         You can edit this and the network's name from
         the menu located in the top right corner of network's name this sidebar."
      />
    </div>
  );
}
