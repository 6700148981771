import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router';
import IconButton from '../../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import { IContactDto } from '../../../shared/model/IContactDto';
import { IPersonProfileDto } from '../../../shared/model/IPersonProfileDto';
import ProfileAvatar from '../../Profile/ProfileAvatar';
import ContactInfoHeaderActionsMobile from './ContactInfoHeaderActionsMobile';
import Text from '../../../shared/components/text/Text';

interface IProps {
  user?: IContactDto | IPersonProfileDto;
}

export default function ContactInfoHeaderMobile({ user }: IProps) {
  const navigate = useNavigate();

  const navigateBackToRoot = () => {
    navigate('/contacts', { replace: true });
  };

  const handleNavigateToProfile = () => {
    navigate({
      pathname: '.',
      search: '?tab=tab_info',
    });
  };

  return (
    <header className="flex items-center h-14 py-10">
      <IconButton
        color={ButtonColors.Gray}
        icon={<ChevronLeftIcon className="mx-2 h-8 w-8" aria-hidden="true" />}
        onClick={navigateBackToRoot}
      />
      <button type="button" onClick={handleNavigateToProfile} className="flex flex-1 items-center text-left">
        <ProfileAvatar
          avatarProps={{
            src: user?.photoUrl,
            alt: user?.firstName,
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />

        <div className="flex flex-col w-full ml-4">
          <Text as="h3" size="normal" weight="medium">
            {`${user?.firstName} ${user?.lastName}`}
          </Text>
          {user?.title && (
            <Text as="span" size="small" brightness="light">
              {user?.title}
            </Text>
          )}
        </div>
      </button>

      <ContactInfoHeaderActionsMobile user={user} />
    </header>
  );
}
