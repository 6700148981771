import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useInfiniteApiQuery } from '../../../hooks/useInfiniteApiQuery';
import { useDispatchApiError } from '../../../hooks/useDispatchApiError';
import {
  createLink,
  deleteLink,
  getLinks,
  renameLink,
} from '../../../services/conversationLinkService';
import { attachmentKeys } from './attachmentQueries';

interface ILinkAttachmentsProps {
  conversationId: string;
  searchTerm?: string;
}

export const linkAttachmentKeys = {
  all: ['link-attachments'] as const,
  pagedLists: () => [...linkAttachmentKeys.all, 'pagedList'] as const,
  pagedList: (props: ILinkAttachmentsProps) => [...linkAttachmentKeys.pagedLists(), props] as const,
};

export function useLinkAttachmentsQuery(props: ILinkAttachmentsProps) {
  return useInfiniteApiQuery({
    queryKey: linkAttachmentKeys.pagedList(props),
    queryFn: () => getLinks(props.conversationId, props.searchTerm),
  });
}

interface ICreateLinkAttachmentProps {
  conversationId: string;
  location: string;
}

export function useCreateLinkAttachmentMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ conversationId, location }: ICreateLinkAttachmentProps) =>
      createLink(conversationId, location),
    onSuccess: (_, { conversationId }) => {
      queryClient.invalidateQueries({ queryKey: linkAttachmentKeys.pagedList({ conversationId }) });
      queryClient.invalidateQueries({ queryKey: attachmentKeys.pagedList({ conversationId }) });
    },
    onError: useDispatchApiError(),
  });
}

interface IDeleteLinkAttachmentProps {
  id: string;
  conversationId: string;
}

export function useDeleteLinkAttachmentMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, conversationId }: IDeleteLinkAttachmentProps) =>
      deleteLink(conversationId, id),
    onSuccess: (_, { conversationId }) => {
      queryClient.invalidateQueries({ queryKey: linkAttachmentKeys.pagedList({ conversationId }) });
      queryClient.invalidateQueries({ queryKey: attachmentKeys.pagedList({ conversationId }) });
    },
    onError: useDispatchApiError(),
  });
}

interface IRenameLinkAttachmentProps {
  id: string;
  conversationId: string;
  name: string;
}

export function useRenameLinkAttachmentMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, conversationId, name }: IRenameLinkAttachmentProps) =>
      renameLink(conversationId, id, name),
    onSuccess: (_, { conversationId }) => {
      queryClient.invalidateQueries({ queryKey: linkAttachmentKeys.pagedList({ conversationId }) });
      queryClient.invalidateQueries({ queryKey: attachmentKeys.pagedList({ conversationId }) });
    },
    onError: useDispatchApiError(),
  });
}
