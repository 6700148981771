import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import RequestItem from './RequestItem';
import RequestItemsList from './RequestItemsList';
import { IInviteNetworkRequestDto } from '../../shared/model/IConnectionRequestDto';
import { useDeleteNetworkInviteRequestMutation } from './queries/networkInviteQueries';
import { useNetworkQuery } from '../Networks/queries/networkQueries';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import { RequestLoader } from './RequestLoader';
import { useUserProfileQuery } from '../Contacts/queries/userProfileQueries';

interface IRequestItemProps {
  targetUserId: string;
  createdTime: Date | string;
  sharedNetworkId: string;
}

function RequestItemWrapper({
  targetUserId,
  sharedNetworkId,
  createdTime,
}: IRequestItemProps) {
  const userProfileQuery = useUserProfileQuery(targetUserId);
  const networkQuery = useNetworkQuery(sharedNetworkId);

  if (userProfileQuery.isLoading || networkQuery.isLoading) {
    return <RequestLoader />;
  }

  if (userProfileQuery.isSuccess && networkQuery.isSuccess) {
    const requestorName = `${userProfileQuery.data.firstName} ${userProfileQuery.data.lastName}`;
    const networkName = networkQuery.data.name;

    return (
      <RequestItem
        title={requestorName}
        linkToPersonProfileId={userProfileQuery.data.accountId}
        photoUrl={userProfileQuery.data.photoUrl}
      >
        Invite to network &apos;{networkName}&apos; sent <TimeAgo date={createdTime} /> ago.
      </RequestItem>
    );
  }

  return <div />;
}

interface IProps {
  networkInviteRequests: IInviteNetworkRequestDto[];
}

export default function InviteToNetworkRequest({ networkInviteRequests }: IProps) {
  const deleteNetworkInviteRequestMutation = useDeleteNetworkInviteRequestMutation();
  return (
    <div>
      <ul>
        {networkInviteRequests.map((request) => (
          <RequestItemsList
            key={request.id}
            id={request.id}
            renderBody={
              <>
                <RequestItemWrapper
                  targetUserId={request.targetUserId}
                  createdTime={request.createdTime}
                  sharedNetworkId={request.sharedNetworkId}
                />
              </>
            }
            renderButton={
              <Button
                color={ButtonColors.White}
                text="Cancel"
                onClick={() => deleteNetworkInviteRequestMutation.mutate(request)}
              />
            }
          />
        ))}
      </ul>
    </div>
  );
}
