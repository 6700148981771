import { CallLink } from '../../../shared/components/links/CallLink';
import { MailLink } from '../../../shared/components/links/EmailLink';
import { IContactDto } from '../../../shared/model/IContactDto';
import { IPersonProfileDto } from '../../../shared/model/IPersonProfileDto';

interface IProps {
  user: IContactDto | IPersonProfileDto | undefined | null;
}

export default function ContactInfoHeaderActionsMobile({ user }: IProps) {
  return (
    <div className="md:hidden flex content-center justify-center text-center mr-3">
      <MailLink email={user?.emailAddress} />
      <CallLink phoneNumber={user?.phoneNumber} />
    </div>
  );
}
