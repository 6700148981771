import TabButtonItem from '../../shared/components/tabs/TabButtonItem';
import useNetworkInfoActionButtons from './hooks/useNetworkInfoActionButtons';

interface INetworkInfoActionButtonProp {
  networkId: string;
}
export default function NetworkInfoActionButtons({ networkId }: INetworkInfoActionButtonProp) {
  const menuItemActionButtons = useNetworkInfoActionButtons(networkId);

  return <TabButtonItem isActive={false} menuItemActionButtons={menuItemActionButtons} />;
}
