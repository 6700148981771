import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import TabContent from '../../../shared/components/tabs/TabContent';
import TabNavItem from '../../../shared/components/tabs/TabNavItem';
import { ContactType, IContactDto } from '../../../shared/model/IContactDto';
import ContactPrivateNotes from '../ContactPrivateNotes';
import { ActivityEntityType } from '../../../shared/model/IActitityDto';
import Activities from '../../Activities/Activities';
import { IConversationReadModel } from '../../../shared/model/IConversationReadModel';
import TabButtonItem from '../../../shared/components/tabs/TabButtonItem';
import { EventBus } from '../../../shared/utils/eventBus';
import { UseMessagesQueryResult } from '../../Messaging/queries/messageQueries';
import Messaging from '../../Messaging/Messaging';
import ContactShareContact from '../ContactShareContact';
import MenuItemActionButton from '../../../shared/components/tabs/MenuItemActionButton';
import TabContentWrapper from '../../../shared/components/tabs/TabContentWrapper';
import TabHeaderWrapper from '../../../shared/components/tabs/TabHeaderWrapper';
import { IMenuItemActionButton } from '../../../shared/model/IMenuItemActionButton';
import { ToastType } from '../../../shared/components/toasts/constants/ToastTypes';
import { AppContext } from '../../../shared/context/context';
import { deleteContact } from '../../../shared/services/contactService';
import { createToast } from '../../../shared/services/toastService';
import Button from '../../../shared/components/buttons/Button';
import ButtonGroup from '../../../shared/components/buttons/ButtonGroup';
import ConfirmDialog from '../../../shared/components/dialogs/ConfirmDialog';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../../shared/constants/ButtonGroupJustifyTypes';
import Modal from '../../../shared/components/modal/Modal';
import ContactEditContact from '../ContactEditContact';
import { useConnectWithProfileMutation } from '../../Request/queries/connectionRequestQueries';
import Attachments from '../../../shared/components/attachments/Attachments';
import HorizontalDivider from '../../../shared/components/dividers/HorizontalDivider';
import Text from '../../../shared/components/text/Text';
import {
  MessageEditorAction,
  createActivityAction,
  createContactNoteAction,
} from '../../Messaging/editor/messageEditorMenuActions';

import useContactDefaultTabNavigation from '../hooks/useContactDefaultTabNavigation';
import { ContactInfoTab } from '../ContactInfoTab';
import { IPipelineEditItemProps } from '../../Pipelines/models/IPipelineEditItemProps';

interface IProps {
  contact: IContactDto;
  messages: UseMessagesQueryResult;
  conversation?: IConversationReadModel;
  isConnectionInitiated?: boolean;
  pipelineEditItemProps?: IPipelineEditItemProps;
}

export default function ContactInfoTabsMobile({
  contact,
  messages,
  conversation,
  isConnectionInitiated,
  pipelineEditItemProps,
}: IProps) {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const [searchParams] = useSearchParams();
  const defaultTab = useContactDefaultTabNavigation(!!contact?.connectionId);
  const activeTabId = searchParams.get('tab') || defaultTab;

  const [showRemoveContactWarning, setShowRemoveContactWarning] = useState(false);
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);

  const connectWithPersonProfileMutation = useConnectWithProfileMutation();

  const handleSetActiveTab = (id: string) => {
    setSearchParams({ tab: id });
  };

  const handleEditContact = () => {
    setIsEditContactModalOpen(true);
  };

  const handleCloseEditContactModal = () => {
    setIsEditContactModalOpen(false);
    EventBus.getInstance().dispatch('refreshContactList');
  };

  useEffect(() => {
    const onNavigateToProfile = EventBus.getInstance().on('navigateToProfile', () => {
      handleSetActiveTab('tab_info');
    });

    return () => {
      onNavigateToProfile.unregister();
    };
  }, []);

  const isContextMenuItemActive = activeTabId === 'tab_info' || activeTabId === 'tab_attachments';

  const handleContinueDeleteContact = () => {
    setShowRemoveContactWarning(false);
    deleteContact(contact?.id as string)
      .then(() => {
        setTimeout(() => {
          EventBus.getInstance().dispatch('refreshContactList');
          navigate('/contacts', { replace: true });
        }, 1000);

        dispatch(createToast('Success', ToastType.Success, 'Contact Removed'));
      })
      .catch(() => {
        dispatch(createToast('Error', ToastType.Error, 'Could not remove contact'));
      });
  };

  const handleShowDeleteWarning = () => {
    setShowRemoveContactWarning(true);
  };

  const handleCancelDeleteContact = () => {
    setShowRemoveContactWarning(false);
  };

  const menuItemActionButtons: IMenuItemActionButton[] = [];

  if (contact?.connectionId) {
    menuItemActionButtons.push({
      id: 'Attachments',
      children: (
        <MenuItemActionButton
          text="Attachments"
          onClick={() => handleSetActiveTab('tab_attachments')}
        />
      ),
    });
  }

  if (contact.type === ContactType.Follow && !contact.connectionId && !isConnectionInitiated) {
    menuItemActionButtons.push({
      id: 'Connect',
      children: (
        <MenuItemActionButton
          text="Connect"
          onClick={() =>
            connectWithPersonProfileMutation.mutate({
              personProfileId: contact.personProfileId as string,
              firstName: contact.firstName,
              lastName: contact.lastName,
            })
          }
        />
      ),
    });
  }

  if (contact.type === ContactType.Follow && !contact.connectionId && isConnectionInitiated) {
    menuItemActionButtons.push({
      id: 'Connecting..',
      children: <MenuItemActionButton text="Connecting.." disabled onClick={() => undefined} />,
    });
  }

  if (contact?.type === ContactType.Passive) {
    menuItemActionButtons.push({
      id: 'Edit',
      children: <MenuItemActionButton text="Edit" onClick={handleEditContact} />,
    });
  }

  menuItemActionButtons.push({
    id: 'Remove Contact',
    children: <MenuItemActionButton text="Remove Contact" onClick={handleShowDeleteWarning} />,
  });

  const messageEditorActions: MessageEditorAction[] = [
    createContactNoteAction('?tab=tab_notes'),
    createActivityAction('?tab=tab_activities'),
  ];

  return (
    <>
      <TabHeaderWrapper>
        {contact?.connectionId && (
          <>
            <TabNavItem
              id="tab_chat"
              activeId={activeTabId}
              title="Chat"
              setActiveTab={handleSetActiveTab}
            />
            <TabNavItem
              id="tab_info"
              activeId={activeTabId}
              title="Info"
              setActiveTab={handleSetActiveTab}
            />
          </>
        )}
        <TabNavItem
          id="tab_notes"
          activeId={activeTabId}
          title="Notes"
          setActiveTab={handleSetActiveTab}
        />
        <TabNavItem
          id="tab_activities"
          activeId={activeTabId}
          title="Tasks"
          setActiveTab={handleSetActiveTab}
        />

        <TabNavItem
          id="tab_share"
          activeId={activeTabId}
          title="Share"
          setActiveTab={handleSetActiveTab}
        />

        <TabButtonItem
          isActive={isContextMenuItemActive}
          menuItemActionButtons={menuItemActionButtons}
        />
      </TabHeaderWrapper>
      <TabContentWrapper>
        <TabContent id="tab_chat" activeTabId={activeTabId}>
          {conversation && (
            <Messaging
              messages={messages as UseMessagesQueryResult}
              conversation={conversation}
              externalMenuActions={messageEditorActions}
            />
          )}
        </TabContent>
        <TabContent id="tab_info" activeTabId={activeTabId}>
          <ContactInfoTab contact={contact} pipelineEditItemProps={pipelineEditItemProps} />
        </TabContent>
        <TabContent id="tab_notes" activeTabId={activeTabId}>
          <ContactPrivateNotes contact={contact ?? ({} as IContactDto)} showHeader={false} />
        </TabContent>
        <TabContent id="tab_activities" activeTabId={activeTabId}>
          {contact && <Activities parentId={contact.id} type={ActivityEntityType.Contact} />}
        </TabContent>
        <TabContent id="tab_share" activeTabId={activeTabId}>
          <ContactShareContact contact={contact} />
        </TabContent>
        {conversation && (
          <TabContent id="tab_attachments" activeTabId={activeTabId}>
            <Attachments conversationId={conversation.id as string} />
          </TabContent>
        )}
      </TabContentWrapper>
      <ConfirmDialog isOpen={showRemoveContactWarning} title="Remove Contact">
        <Text as="p">Are you sure you want to remove this contact?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Remove" onClick={handleContinueDeleteContact} />
          <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelDeleteContact} />
        </ButtonGroup>
      </ConfirmDialog>
      <Modal
        isOpen={isEditContactModalOpen}
        showCloseButton={false}
        onCloseClick={() => handleCloseEditContactModal()}
      >
        <ContactEditContact
          callback={handleCloseEditContactModal}
          contact={contact as IContactDto}
        />
      </Modal>
    </>
  );
}
