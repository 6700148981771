import React, { ReactNode } from 'react';
import { ContactProfileLink } from '../Contacts/ContactProfileLink';
import ProfileAvatar from '../Profile/ProfileAvatar';
import Text from '../../shared/components/text/Text';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';

interface RequestItemsProps {
  title: string;
  photoUrl?: string;
  linkToPersonProfileId?: string;
  children: ReactNode;
}

export default function RequestItem({
  title,
  photoUrl,
  linkToPersonProfileId,
  children,
}: RequestItemsProps) {
  return (
    <>
      {linkToPersonProfileId ? (
        <ContactProfileLink personProfileId={linkToPersonProfileId}>
          <ProfileAvatar
            avatarProps={{
              src: photoUrl || '',
              widthClass: 'w-10',
              heightClass: 'h-10',
            }}
          />
        </ContactProfileLink>
      ) : (
        <ProfileAvatar
          avatarProps={{
            src: photoUrl || '',
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />
      )}
      <div className="flex flex-col max-w-xs whitespace-normal">
        {linkToPersonProfileId ? (
          <ContactProfileLink personProfileId={linkToPersonProfileId}>
            <Text as="span" size="large" weight="medium">
              {title}
            </Text>
          </ContactProfileLink>
        ) : (
          <Text as="span" size="large" weight="medium">
            {title}
          </Text>
        )}
        <HorizontalDivider distance="small" />
        <Text as="span" size="xSmall" brightness="light">
          {children}
        </Text>
      </div>
    </>
  );
}
