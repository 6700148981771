import { Link } from 'react-router-dom';
import ProfileAvatar from '../../../features/Profile/ProfileAvatar';
import { ButtonColors } from '../../constants/ButtonColors';
import { IProfile } from '../../model/IProfile';
import Button from '../buttons/Button';
import ButtonGroup from '../buttons/ButtonGroup';
import { ButtonGroupJustifyTypes } from '../../constants/ButtonGroupJustifyTypes';
import Text from '../text/Text';
import { Card, ICardProps } from './Card';
import { ContactProfileLink } from '../../../features/Contacts/ContactProfileLink';

type CardAction = {
  key: string;
  label: string;
  icon?: React.ReactElement;
  color: ButtonColors; // Can this be generalized?
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

type CardActions = {
  actions: CardAction[];
  actionsPosition: 'inline' | 'below';
};

type IProfileCardProps = Pick<ICardProps, 'as' | 'width'> &
  Partial<CardActions> & {
    profile: IProfile;
  };

interface ICardHeaderProps {
  linkToPersonProfileId?: string;
  linkToContactId?: string;
}
interface IProps {
  children: React.ReactNode;
}

function ContactsLink({
  linkToPersonProfileId,
  linkToContactId,
  children,
}: IProps & ICardHeaderProps) {
  if (linkToPersonProfileId) {
    return <ContactProfileLink personProfileId={linkToPersonProfileId ?? ''}>{children}</ContactProfileLink>;
  }
  if (!linkToPersonProfileId && linkToContactId) {
    return <Link to={`/contacts/${linkToContactId}`}>{children}</Link>;
  }
  return <>{children}</>;
}

function CardHeader({
  firstName,
  lastName,
  emailAddress,
  photoUrl,
  actions,
  actionsPosition,
  linkToPersonProfileId,
  linkToContactId,
}: ICardHeaderProps &
  Pick<IProfile, 'firstName' | 'lastName' | 'emailAddress' | 'photoUrl'> &
  Partial<CardActions>) {
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className="flex items-center space-x-3">
      <div className="flex-shrink-0">
        <ContactsLink
          linkToPersonProfileId={linkToPersonProfileId ?? ''}
          linkToContactId={linkToContactId ?? ''}
        >
          <ProfileAvatar
            avatarProps={{
              src: photoUrl,
              widthClass: 'w-10',
              heightClass: 'h-10',
              alt: fullName,
            }}
          />
        </ContactsLink>
      </div>

      <div className="overflow-hidden px-1">
        <Text as="p" size="small" weight="medium" truncate>
          <ContactsLink
            linkToPersonProfileId={linkToPersonProfileId ?? ''}
            linkToContactId={linkToContactId ?? ''}
          >
            {fullName}
          </ContactsLink>
        </Text>
        <Text as="p" size="xSmall" brightness="light" truncate>
          {emailAddress}
        </Text>
      </div>

      {actions && actionsPosition === 'inline' && (
        <ButtonGroup>
          {actions.map((action) => (
            <Button
              key={action.key}
              color={action.color}
              text={action.label}
              onClick={action.onClick}
              isDisabled={action.isDisabled}
            />
          ))}
        </ButtonGroup>
      )}
    </div>
  );
}

export function ProfileCard({
  profile,
  actions,
  actionsPosition,
  as,
  width,
  linkToPersonProfileId,
  linkToContactId,
}: ICardHeaderProps & IProfileCardProps) {
  const cardProps = {
    width: width ?? 'fit',
    as: actions && actionsPosition === 'below' ? 'div' : as,
  };
  const card = (
    <Card {...cardProps}>
      <CardHeader
        {...profile}
        actions={actions}
        actionsPosition={actionsPosition}
        linkToPersonProfileId={linkToPersonProfileId}
        linkToContactId={linkToContactId}
      />
    </Card>
  );
  if (actions && actionsPosition === 'below') {
    const OuterTag = as ?? 'li';
    const children = (
      <>
        {card}
        <ButtonGroup justify={ButtonGroupJustifyTypes.Start}>
          {actions.map((action) => (
            <Button
              key={action.key}
              color={action.color}
              text={action.label}
              onClick={action.onClick}
              isDisabled={action.isDisabled}
            />
          ))}
        </ButtonGroup>
      </>
    );
    return <OuterTag>{children}</OuterTag>;
  }

  return <>{card}</>;
}
