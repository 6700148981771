import { DraggableProvided } from 'react-beautiful-dnd';
import { HTMLProps } from 'react';
import clsx from 'clsx';
import { As } from '../../utils/types';

type ValidTags = 'div' | 'li';

export type ICardProps = As<ValidTags> & {
  className?: string;
  width?: 'fit' | 'full' | 'small' | 'medium' | 'large';
  draggableProvided?: DraggableProvided;
  isInteractive?: boolean;
  showBorder?: boolean;
  onClick?: (args?: unknown) => void;
  children: React.ReactNode;
};
/**
 * Card component for displaying a card.
 * By passing the draggableProvided prop, the card will be draggable and have a drag handle style.
 * */
export function Card({
  as = 'li',
  className,
  width,
  draggableProvided,
  isInteractive = true,
  showBorder = false,
  ...rest
}: ICardProps) {
  const classes = clsx(
    'p-2 rounded-lg bg-white border-slate-300 border-[1px] my-2 h-fit',
    className,
    isInteractive &&
      'cursor-pointer hover:outline-cyan-500 hover:outline hover:outline-offset-[-2px]',
    draggableProvided &&
      'relative pl-6 before:w-5 before:rounded-tl-md before:rounded-bl-md before:cursor-move before:absolute before:h-full before:top-[0px] before:left-0 drag-dots',
    showBorder && 'outline-cyan-500 outline outline-offset-[-2px]',
  );

  let sizeClasses = '';

  if (width) {
    switch (width) {
      case 'full':
        sizeClasses = 'w-full';
        break;
      case 'small':
        sizeClasses = 'w-full sm:w-64';
        break;
      case 'medium':
        sizeClasses = 'w-full sm:w-80';
        break;
      case 'large':
        sizeClasses = 'w-full sm:w-96';
        break;
      case 'fit':
      default:
        sizeClasses = 'w-full sm:w-fit';
        break;
    }
  }

  const _className = clsx(classes, sizeClasses);

  const Tag: string = as;

  const attributes: HTMLProps<HTMLElement> = {
    ref: draggableProvided?.innerRef,
    className: _className,
    ...draggableProvided?.draggableProps,
    ...draggableProvided?.dragHandleProps,
    ...rest,
  };

  return <Tag {...attributes} />;
}
