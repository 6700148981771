import { useState } from 'react';
import SidePanelHeadline from '../../shared/components/page/SidePanelHeadline';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import useColorSetting, { stringToResourceTypeMapping } from '../../shared/hooks/useColorSetting';
import PipelineEditItemForm from './PipelineEditItemForm';
import { IPipelineItemDto } from './models/IPipelineItemDto';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';

interface IPipelineEditItemFallbackProps {
  pipelineItem: IPipelineItemDto;
}

export default function PipelineEditItemFallback({ pipelineItem }: IPipelineEditItemFallbackProps) {
  const colorSetting = useColorSetting(stringToResourceTypeMapping[pipelineItem?.resourceType]);

  const [activeTab, setActiveTab] = useState('tab_info');

  return (
    <>
      <SidePanelHeadline
        headline={pipelineItem?.name}
        imageType={pipelineItem?.resourceType}
        colorSetting={colorSetting}
      />
      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="tab_info"
            activeId={activeTab}
            title="Info"
            setActiveTab={() => setActiveTab('tab_info')}
          />
        </TabHeaderWrapper>
        <TabContentWrapper>
          <TabContent id="tab_info" activeTabId={activeTab}>
            <PipelineEditItemForm pipelineItem={pipelineItem} />
          </TabContent>
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
