import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import Label from '../text/Label';
import SpacingContainer from '../spacing/SpacingContainer';

export interface IProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  value: string;
  register: UseFormRegister<T>;
}

export default function RadioButton<T extends FieldValues>({
  name,
  label,
  value,
  register,
}: IProps<T>) {
  return (
    <SpacingContainer orientation="horizontal" size="small">
      <input
        type="radio"
        className="h-4 w-4 border-gray-300 text-cyan"
        value={value}
        {...register(name, { required: true })}
      />
      <Label htmlFor={value} text={label} />
    </SpacingContainer>
  );
}
