import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { groupBy } from 'lodash';
import { IReaction } from '../../shared/model/IReaction';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { useIsMeByUserId } from '../../shared/hooks/accountHooks';
import { useDeleteReactionMutation } from './queries/messageQueries';
import { IMessageReadModel } from '../../shared/model/IMessageReadModel';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import IconButton from '../../shared/components/buttons/IconButton';
import Text from '../../shared/components/text/Text';

interface IProps {
  reactions: IReaction[];
  message: IMessageReadModel;
}

export default function MessageReactionListPopover({ reactions, message }: IProps) {
  const isMe = useIsMeByUserId();
  const sortedReactions = reactions.sort((a, b) => {
    if (isMe(a.userId)) return -1;
    return isMe(b.userId) ? 1 : 0;
  });
  const reactionEntries = Object.entries(groupBy(sortedReactions, 'reactionValue'));
  const [hoveredReactionId, setHoveredReactionId] = useState<string | null>(null);
  const deleteReactionMutation = useDeleteReactionMutation();

  const handleDeleteReaction = (reaction: IReaction) => {
    if (!isMe(reaction.userId)) return;
    deleteReactionMutation.mutate({ message });
  };

  return (
    <ScrollBarWrapper className="max-h-50 bg-white rounded-md shadow-md p-3 w-max">
      {reactionEntries.map(([reactionValue, reactionGroup]) => (
        <React.Fragment key={`ReactionGroup-${reactionGroup[0].id}`}>
          {reactionGroup.map((reaction: IReaction) => (
            <button
              type="button"
              key={reaction.id}
              className={`${
                isMe(reaction.userId) ? 'cursor-pointer' : 'cursor-default'
              } flex items-center border-0 outline-0`}
              onClick={() => handleDeleteReaction(reaction)}
              onMouseEnter={() => setHoveredReactionId(reaction.id)}
              onMouseLeave={() => setHoveredReactionId(null)}
            >
              <div className="w-6 h-6 mr-2">
                {hoveredReactionId === reaction.id && isMe(reaction.userId) ? (
                  <IconButton
                    color={ButtonColors.Red}
                    icon={<XMarkIcon className="w-full h-full" />}
                  />
                ) : (
                  <span className="text-xl" role="img" aria-label={reactionValue}>
                    {reactionValue}
                  </span>
                )}
              </div>

              {isMe(reaction.userId) ? (
                <Text as="span" size="small" brightness="dark" weight="medium">
                  You
                </Text>
              ) : (
                <Text as="span" size="small" brightness="dark" weight="medium">
                  {reaction.displayName}
                </Text>
              )}
            </button>
          ))}
        </React.Fragment>
      ))}
    </ScrollBarWrapper>
  );
}
