import { useRef, useState } from 'react';
import Text from '../../shared/components/text/Text';
import { useListShareContactRequestsQuery } from '../Request/queries/shareContactRequestQueries';
import { IContactDto } from '../../shared/model/IContactDto';
import {
  ExpandableCard,
  ExpandableCardRefType,
} from '../../shared/components/cards/ExpandableCard';
import logo from '../../assets/images/company-icon.svg';
import share from '../../assets/images/share-contact.svg';
import KpiIndicator from '../../shared/components/kpi/KpiIndicator';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import ContactUserTabBody from './ContactUserTabBody';
import { IPipelineEditItemProps } from '../Pipelines/models/IPipelineEditItemProps';
import { Card } from '../../shared/components/cards/Card';
import { CardList } from '../../shared/components/cards/CardList';
import { ShareContactRequestStatuses } from '../../shared/model/IShareContactRequestDto';
import { ContactSharedContactsKpiList } from './ContactSharedContactsKpiList';
import Howto from '../../shared/components/message-popups/HowTo';

interface IProps {
  contact: IContactDto;
  pipelineEditItemProps?: IPipelineEditItemProps;
}
type View = ShareContactRequestStatuses | 'All' | undefined;

export function ContactInfoTab({ contact, pipelineEditItemProps }: IProps) {
  const cardRef = useRef<ExpandableCardRefType>(null);
  const [viewState, setViewState] = useState<View>(undefined);
  const shareContactRequestsQuery = useListShareContactRequestsQuery({
    externalRef: contact.id,
    searchType: 'Sent',
    status: ['Accepted', 'Pending', 'Rejected'],
  });

  const handleToggle = (status: View) => {
    setViewState(status);
  };

  const renderSharedContactsInfo = () => {
    if (
      (viewState && shareContactRequestsQuery.data && shareContactRequestsQuery.data.length > 0) ||
      (viewState && shareContactRequestsQuery.data)
    ) {
      return (
        <ScrollBarWrapper>
          <ContactSharedContactsKpiList key={contact.id} contact={contact} status={viewState} />
        </ScrollBarWrapper>
      );
    }

    return null;
  };

  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <ScrollBarWrapper>
        <Text as="p" size="small" weight="medium" truncate>
          About
        </Text>
        <ExpandableCard
          as="div"
          ref={cardRef}
          renderHead={() => (
            <div className="flex-1 overflow-x-hidden">
              <div className="flex flex-row space-x-4">
                <img className="w-8 h-8 " src={logo} alt={logo} />
                <Text as="p" size="small" brightness="normal" weight="bold" whitespace="normal">
                  {contact?.company ? contact.company : 'Company name'}
                </Text>
              </div>
            </div>
          )}
        >
          <div className="mt-2 flex flex-wrap ">
            <ContactUserTabBody user={contact} />
          </div>
        </ExpandableCard>

        <Text as="p" size="small" weight="medium" truncate>
          Activity
        </Text>

        <ExpandableCard
          as="div"
          ref={cardRef}
          defaultOpen
          renderHead={() => (
            <div className="flex-1 overflow-x-hidden">
              <div className="flex flex-row space-x-4">
                <img className="w-8 h-8 " src={share} alt={share} />
                <Text as="p" size="small" brightness="normal" weight="bold" whitespace="normal">
                  Shared contacts
                </Text>
              </div>

              <div className="flex flex-row space-x-3   px-10 ">
                {shareContactRequestsQuery.data && (
                  <>
                    <KpiIndicator
                      value={shareContactRequestsQuery?.data?.length}
                      textColor="cyan"
                      content={<>Total</>}
                      isActive={viewState === 'All'}
                      onClick={() => handleToggle('All')}
                    />
                    <KpiIndicator
                      value={
                        shareContactRequestsQuery.data.filter(
                          (item: { status: string }) => item.status === 'Accepted',
                        ).length
                      }
                      textColor="green"
                      content={<>Accepted</>}
                      isActive={viewState === 'Accepted'}
                      onClick={() => handleToggle('Accepted')}
                    />
                    <KpiIndicator
                      value={
                        shareContactRequestsQuery?.data?.filter(
                          (item: { status: string }) => item.status === 'Pending',
                        ).length
                      }
                      textColor="yellow"
                      content={<>Pending</>}
                      isActive={viewState === 'Pending'}
                      onClick={() => handleToggle('Pending')}
                    />
                    <KpiIndicator
                      value={
                        shareContactRequestsQuery?.data?.filter(
                          (item: { status: string }) => item.status === 'Rejected',
                        ).length
                      }
                      textColor="red"
                      content={<>Rejected</>}
                      isActive={viewState === 'Rejected'}
                      onClick={() => handleToggle('Rejected')}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        >
          {renderSharedContactsInfo()}
        </ExpandableCard>

        {pipelineEditItemProps?.renderEditItem && (
          <>
            <Text as="p" size="small" weight="medium" truncate>
              Pipline
            </Text>
            <CardList>
              <Card isInteractive={false}>{pipelineEditItemProps.renderEditItem}</Card>
            </CardList>
          </>
        )}
      </ScrollBarWrapper>
      <Howto
        title="Info"
        description="View your contact's information and the number of shared contacts."
      />
    </div>
  );
}
