import {
  AccountInfo,
  AuthError,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { acquireApiTokenRequest, b2cPolicies } from '../auth/authConfig';
import { stringBuilder } from './stringUtils';

interface ITokenClaims {
  iat?: number;
  exp?: number;
  tfp?: string;
  given_name?: string;
  family_name?: string;
  emails?: string[];
  userId?: string;
}

export function getPolicy(claims: ITokenClaims | undefined) {
  if (!claims) return undefined;
  return claims.tfp;
}

export function hasSignInPolicy(claims: ITokenClaims | undefined) {
  return getPolicy(claims) === b2cPolicies.names.signUpSignIn;
}

export function getDisplayName(claims: ITokenClaims | undefined) {
  return stringBuilder(' ').add(claims?.given_name).add(claims?.family_name).toString();
}

export function getEmail(claims: ITokenClaims | undefined) {
  if (!claims?.emails) return undefined;
  return claims.emails[0];
}

export function getUserId(claims: ITokenClaims | undefined) {
  if (!claims?.userId) return undefined;
  return claims.userId;
}

// Finds the account with the newest valid id token and returns it. Returns null oterwise.
export const getNewestAccount = (accounts: AccountInfo[]) => {
  if (accounts.length <= 0) return null;

  // Sort accounts by iat (issued at time), get the newest:
  const newestAccount = accounts.sort((account1, account2) => {
    const { iat: iatClaim1 } = account1.idTokenClaims as ITokenClaims;
    const { iat: iatClaim2 } = account2.idTokenClaims as ITokenClaims;
    return (iatClaim1 ?? 0) - (iatClaim2 ?? 0);
  })[accounts.length - 1];

  return newestAccount;
};

// Retrieves access token from cache, if possible, or IDP using a refresh token.
// If token can't be retrieved the user is redirected to the sign in form.
// In case of unexpected error the active account is logged out.
export async function getAccessToken(msalInstance: IPublicClientApplication) {
  const account = msalInstance.getActiveAccount();
  if (!account) return '';

  return msalInstance
    .acquireTokenSilent(acquireApiTokenRequest)
    .then((tokenData) => tokenData.accessToken)
    .catch(async (error: AuthError) => {
      const { correlationId } = error;
      if (
        !hasSignInPolicy(account.idTokenClaims) ||
        !(error instanceof InteractionRequiredAuthError)
      ) {
        msalInstance.logoutRedirect({ account, correlationId, postLogoutRedirectUri: '/signin' });
      } else {
        msalInstance.acquireTokenRedirect({ ...acquireApiTokenRequest, correlationId });
      }

      throw error;
    });
}
