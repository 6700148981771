import { createContext, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePopoverStyle } from '../popovers/styles/usePopoverStyle';
import { Popover, PopoverContent, PopoverTrigger } from '../popovers/Popover';
import Text from '../text/Text';
import { DatePicker } from '../dates/DatePicker';

const MenuContext = createContext<React.Dispatch<React.SetStateAction<boolean>> | null>(null);

function renderMenuItem(label: string, icon?: JSX.Element, definition?: string) {
  return (
    <>
      {icon}
      <span className="ml-3 flex-auto truncate hover:text-white">{label}</span>
      {definition && (
        <span className="ml-3 flex-none text-xs hover:text-white">
          <kbd className="font-sans hover:text-white">{definition}</kbd>
        </span>
      )}
    </>
  );
}

const menuItemClassName =
  'flex items-center select-none rounded-md px-3 py-3 hover:bg-cyan hover:text-white cursor-pointer w-full text-left ';

interface IContextMenuProps {
  icon: JSX.Element;
  label?: string;
  children: React.ReactNode;
}

export function ContextMenu({ icon, label, children }: IContextMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const styleProps = usePopoverStyle({});
  return (
    <MenuContext.Provider value={setIsOpen}>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger>
          <div className="flex flex-col items-center">
            {icon}
            {label && <Text size="xSmall">{label}</Text>}
          </div>
        </PopoverTrigger>
        <PopoverContent align="start">
          <div {...styleProps}>
            <ul className="text-sm text">{children}</ul>
          </div>
        </PopoverContent>
      </Popover>
    </MenuContext.Provider>
  );
}

interface IContextMenuItemProps {
  icon?: JSX.Element;
  label: string;
  definition?: string;
  onClick: () => void;
}

export function ContextMenuItem({ icon, label, definition, onClick }: IContextMenuItemProps) {
  const setIsMenuOpen = useContext(MenuContext);
  const handleOnClick = () => {
    onClick();
    if (setIsMenuOpen) setIsMenuOpen(false);
  };
  return (
    <li>
      <button type="button" className={menuItemClassName} onClick={handleOnClick}>
        {renderMenuItem(label, icon, definition)}
      </button>
    </li>
  );
}

interface IContextMenuDatePickerProps {
  icon: JSX.Element;
  label: string;
  definition?: string;
  initialDate: string;
  hasTime?: boolean;
  onSelect: (date: Date) => void;
}

export function ContextMenuDatePicker({
  icon,
  label,
  definition,
  initialDate,
  hasTime,
  onSelect,
}: IContextMenuDatePickerProps) {
  const setIsMenuOpen = useContext(MenuContext);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleSelect = (date: Date) => {
    onSelect(date);
    if (setIsMenuOpen) setIsMenuOpen(false);
  };

  return (
    <li>
      <button
        type="button"
        className={menuItemClassName}
        onClick={() => setIsCalendarOpen((current) => !current)}
      >
        {renderMenuItem(label, icon, definition)}
      </button>
      {isCalendarOpen && (
        <DatePicker onSelect={handleSelect} initialDate={initialDate} hasTime={hasTime} />
      )}
    </li>
  );
}

interface IContextMenuLinkItem {
  icon?: JSX.Element;
  label: string;
  definition?: string;
  to: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

export function ContextMenuLinkItem({
  icon,
  label,
  definition,
  to,
  onClick,
}: IContextMenuLinkItem) {
  return (
    <li>
      <Link to={to} onClick={onClick}>
        <div className={menuItemClassName}>{renderMenuItem(label, icon, definition)}</div>
      </Link>
    </li>
  );
}

export function ContextMenuDivider() {
  return (
    <li>
      <div className="bg-gray-light mt-2 mb-2 h-[1px] w-full" />
    </li>
  );
}
