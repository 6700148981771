import { PencilIcon, UserPlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MouseEventHandler } from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { IContactDto, ContactType } from '../../shared/model/IContactDto';

interface IProps {
  contact: IContactDto;
  isConnectionInitiated: boolean;
  onCreateConnectionRequest: MouseEventHandler;
  onEditContact: MouseEventHandler;
  onDeleteContact: MouseEventHandler;
}

export default function ContactActionButtons({
  contact,
  isConnectionInitiated,
  onCreateConnectionRequest,
  onEditContact,
  onDeleteContact,
}: IProps) {
  return (
    <ButtonGroup>
      {contact?.type === ContactType.Follow && !contact.connectionId && !isConnectionInitiated && (
        <Button
          buttonStyle="filled"
          color={ButtonColors.Cyan}
          text="Connect"
          icon={<UserPlusIcon className="w-5 h-5 mr-2" />}
          onClick={onCreateConnectionRequest}
        />
      )}
      {contact?.type === ContactType.Follow && !contact.connectionId && isConnectionInitiated && (
        <Button
          color={ButtonColors.White}
          text="Sent"
          icon={<UserPlusIcon className="w-5 h-5 mr-2" />}
          isDisabled
          onClick={onCreateConnectionRequest}
        />
      )}
      {contact?.type === ContactType.Passive && (
        <Button
          color={ButtonColors.Cyan}
          text="Edit"
          icon={<PencilIcon className="w-5 h-5 mr-2" />}
          onClick={onEditContact}
        />
      )}
      <Button
        color={ButtonColors.Red}
        text="Remove Contact"
        icon={<XMarkIcon className="w-5 h-5 mr-2" />}
        onClick={onDeleteContact}
      />
    </ButtonGroup>
  );
}
