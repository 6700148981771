import { useState } from 'react';
import { DocumentIcon, EllipsisHorizontalIcon, LinkIcon } from '@heroicons/react/24/outline';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ConversationAttachmentType,
  IConversationAttachmentDto,
} from '../../model/IConversationAttachmentDto';
import { useAttachmentsQuery } from './queries/attachmentQueries';
import {
  useDeleteLinkAttachmentMutation,
  useRenameLinkAttachmentMutation,
} from './queries/linkAttachmentQueries';
import {
  useDeleteFileAttachmentMutation,
  useRenameFileAttachmentMutation,
} from './queries/fileAttachmentQueries';
import { getFileLease } from '../../services/conversationFileService';
import { downloadUrlByLink } from '../../utils/fileUtils';
import ListEmptyState from '../lists/ListEmptyState';
import ScrollBarWrapper from '../scrolling/ScrollBarWrapper';
import { CardList } from '../cards/CardList';

import { Filler } from '../layout/Filler';
import { ContextMenu, ContextMenuItem } from '../context-menu/ContextMenu';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import Button from '../buttons/Button';
import { ButtonColors } from '../../constants/ButtonColors';
import RenameAttachmentModal from './RenameAttachmentModal';

import Text from '../text/Text';
import { ButtonGroupJustifyTypes } from '../../constants/ButtonGroupJustifyTypes';
import ButtonGroup from '../buttons/ButtonGroup';
import HorizontalDivider from '../dividers/HorizontalDivider';
import { Card } from '../cards/Card';
import { ErrorComponent } from '../error-boundary/ErrorComponent';

interface IProps {
  conversationId: string;
  searchTerm?: string;
}

export default function AttachmentList({ conversationId, searchTerm }: IProps) {
  const [attachmentToDelete, setAttachmentToDelete] = useState<IConversationAttachmentDto>();
  const [attachmentToRename, setAttachmentToRename] = useState<IConversationAttachmentDto>();

  const attachmentsQuery = useAttachmentsQuery({ conversationId, searchTerm });

  const renameLinkAttachmentMutation = useRenameLinkAttachmentMutation();
  const renameFileAttachmentMutation = useRenameFileAttachmentMutation();
  const deleteLinkAttachmentMutation = useDeleteLinkAttachmentMutation();
  const deleteFileAttachmentMutation = useDeleteFileAttachmentMutation();

  const handleRename = (attachment: IConversationAttachmentDto, newName?: string) => {
    if (!newName) {
      setAttachmentToRename(undefined);
      return;
    }
    if (attachment.type === ConversationAttachmentType.File) {
      renameFileAttachmentMutation.mutate(
        { ...attachment, name: newName },
        {
          onSettled: () => {
            setAttachmentToRename(undefined);
          },
        },
      );
    } else if (attachment.type === ConversationAttachmentType.Link) {
      renameLinkAttachmentMutation.mutate(
        { ...attachment, name: newName },
        {
          onSettled: () => {
            setAttachmentToRename(undefined);
          },
        },
      );
    }
  };

  const handleDelete = (attachment: IConversationAttachmentDto) => {
    if (attachment.type === ConversationAttachmentType.File) {
      deleteFileAttachmentMutation.mutate(attachment, {
        onSettled: () => {
          setAttachmentToDelete(undefined);
        },
      });
    } else if (attachment.type === ConversationAttachmentType.Link) {
      deleteLinkAttachmentMutation.mutate(attachment, {
        onSettled: () => {
          setAttachmentToDelete(undefined);
        },
      });
    }
  };

  const handleDownload = (attachment: IConversationAttachmentDto) => {
    getFileLease(attachment.conversationId, attachment.id).then(({ url }) => {
      downloadUrlByLink(url, attachment.name);
    });
  };

  const absoluteUrl = (url: string) => {
    if (/^http[s]?:\/\//gi.test(url)) {
      return url;
    }

    return `http://${url}`;
  };

  if (attachmentsQuery.isLoading) {
    return null;
  }

  if (attachmentsQuery.isSuccess) {
    const flattenedAttachments = attachmentsQuery.data.pages.flat();

    return (
      <>
        {!flattenedAttachments.length && (
          <ListEmptyState>
            When you send files or links in the chat they will end up here.
          </ListEmptyState>
        )}
        <ScrollBarWrapper id="attachmentsScroll">
          <InfiniteScroll
            scrollableTarget="attachmentsScroll"
            next={() => attachmentsQuery.fetchNextPage()}
            hasMore={attachmentsQuery.hasNextPage || false}
            loader={
              <div className="py-4 text-center">
                <Text as="p" size="small">
                  Loading...
                </Text>
              </div>
            }
            dataLength={flattenedAttachments.length}
            style={{ overflow: '' }} // This fixes overflow problem with context menu.
          >
            <CardList>
              {flattenedAttachments.map((attachment) => (
                <Card className="flex space-x-2" key={attachment.id}>
                  {attachment.type === ConversationAttachmentType.Link ? (
                    <LinkIcon className="flex-none w-5 h-5" />
                  ) : (
                    <DocumentIcon className="flex-none w-5 h-5" />
                  )}

                  <Text as="span" size="small" truncate>
                    {attachment.type === ConversationAttachmentType.Link ? (
                      <a href={absoluteUrl(attachment.location)} rel="noreferrer" target="_blank">
                        {attachment.name}
                      </a>
                    ) : (
                      attachment.name
                    )}
                  </Text>

                  <Filler />
                  <div className="whitespace-nowrap pr-4 text-sm text text-right relative">
                    <ContextMenu icon={<EllipsisHorizontalIcon className="w-5 h-5" />}>
                      {attachment.type === ConversationAttachmentType.File && (
                        <ContextMenuItem
                          label="Download"
                          onClick={() => handleDownload(attachment)}
                        />
                      )}
                      <ContextMenuItem
                        label="Rename"
                        onClick={() => setAttachmentToRename(attachment)}
                      />
                      <ContextMenuItem
                        label="Delete"
                        onClick={() => setAttachmentToDelete(attachment)}
                      />
                    </ContextMenu>
                  </div>
                </Card>
              ))}
            </CardList>
          </InfiniteScroll>
        </ScrollBarWrapper>
        {attachmentToDelete && (
          <ConfirmDialog isOpen={!!attachmentToDelete} title="Remove member!">
            <Text as="p" leading="snug">
              {`Are you sure that you want to remove the ${
                attachmentToDelete?.type === ConversationAttachmentType.File ? 'file' : 'link'
              } ${attachmentToDelete?.name}?`}
            </Text>
            <HorizontalDivider distance="large" />
            <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
              <Button
                color={ButtonColors.Red}
                text="Remove"
                onClick={() => handleDelete(attachmentToDelete)}
              />
              <Button
                color={ButtonColors.White}
                text="Cancel"
                onClick={() => setAttachmentToDelete(undefined)}
              />
            </ButtonGroup>
          </ConfirmDialog>
        )}
        {attachmentToRename && (
          <RenameAttachmentModal
            attachment={attachmentToRename}
            isOpen={!!attachmentToRename}
            onCloseCallback={handleRename}
          />
        )}
      </>
    );
  }

  return <ErrorComponent queryResults={[attachmentsQuery]} />;
}
