import { Controller, useForm } from 'react-hook-form';
import {
  addYears,
  endOfYear,
  format,
  isWithinInterval,
  startOfYear,
  isValid as isValidDate,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { usePipelineStoreContext } from './context/pipeline-context';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import Divider from '../../shared/components/dividers/Divider';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import {
  useCreatePipelineBudgetMutation,
  usePipelineQuery,
  useUpdatePipelineBudgetMutation,
} from './queries/pipelineQueries';
import { IPipelineBudgetDto, IPipelineCreateBudgetDto } from './models/IPipelineDto';
import CurrencyInput from '../../shared/components/inputs/number/CurrencyInput';
import convertFormattedCurrencyToNumber from '../../shared/utils/numberUtils';
import { usePipelinePrivilegesQuery } from './queries/pipelinePriviligesQueries';
import { DescriptionList } from '../../shared/components/lists/DescriptionList';
import Modal from '../../shared/components/modal/Modal';
import { Card } from '../../shared/components/cards/Card';
import { CardList } from '../../shared/components/cards/CardList';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { ExpandableCard } from '../../shared/components/cards/ExpandableCard';
import Button from '../../shared/components/buttons/Button';
import Text from '../../shared/components/text/Text';
import { Pill } from '../../shared/components/indicators/Pill';
import HeaderSubHeader from '../../shared/components/headers/HeaderSubHeader';
import FieldWrapper from '../../shared/components/form/FieldWrapper';
import TextInput from '../../shared/components/form/TextInput';
import { validateIfBudgetIsWithinAcceptedRange } from './validation/budgetValidation';
import Howto from '../../shared/components/message-popups/HowTo';

type BudgetFormProps = {
  budget?: IPipelineBudgetDto;
  onClose: () => void;
};

function BudgetForm({ budget, onClose }: BudgetFormProps) {
  const { activePipelineSettings } = usePipelineStoreContext();
  const pipeline = usePipelineQuery(activePipelineSettings.pipelineId);

  const budgets = pipeline.data?.budgets;

  const budgetsSorted = budgets?.sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
  );

  const endOfDateRange = budgetsSorted?.at(-1)?.endDate;
  const nextYear = endOfDateRange ? addYears(new Date(endOfDateRange), 1) : new Date();

  const form = useForm<IPipelineCreateBudgetDto>({
    mode: 'onChange',
    defaultValues: {
      startDate: format(startOfYear(nextYear), 'yyyy-MM-dd'),
      endDate: format(endOfYear(nextYear), 'yyyy-MM-dd'),
      amount: 0,
    },
    values: budget,
  });

  const {
    control,
    getValues,
    watch,
    trigger,
    formState: { errors, isDirty, isValid },
  } = form;

  const createPipelineBudgetMutation = useCreatePipelineBudgetMutation();
  const updatePipelineBudgetMutation = useUpdatePipelineBudgetMutation();

  const watchVariables = watch(['startDate', 'endDate']);

  const save = () => {
    if (!isDirty || !isValid) {
      return;
    }

    const updatedForm = {
      ...getValues(),
      amount: convertFormattedCurrencyToNumber(getValues().amount),
    };

    if (budget) {
      updatePipelineBudgetMutation.mutate({
        pipelineId: activePipelineSettings.pipelineId,
        budgetId: budget.id,
        pipelineBudget: updatedForm,
      });

      onClose();
      return;
    }

    createPipelineBudgetMutation.mutate({
      pipelineId: activePipelineSettings.pipelineId,
      pipelineBudget: updatedForm,
    });

    onClose();
  };

  const validateDate = () => {
    const startDate = getValues('startDate');
    const endDate = getValues('endDate');

    if (!isValidDate(new Date(startDate)) || !isValidDate(new Date(endDate))) {
      return 'The date must be a valid date';
    }

    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      return 'The end date must be after the start date';
    }

    return validateIfBudgetIsWithinAcceptedRange(startDate, endDate, budgetsSorted, budget);
  };

  useEffect(() => {
    const subscription = watch(() => {
      trigger('startDate');
      trigger('endDate');
    });
    return () => subscription.unsubscribe();
  }, [watchVariables]);

  return (
    <SpacingContainer>
      <div className="grid grid-cols-3 gap-4">
        <SpacingContainerItem>
          <FieldWrapper
            label="Start Date"
            name="startDate"
            infoText={'Must be a valid date written like "1990-01-01"'}
            errors={errors}
          >
            <Controller
              name="startDate"
              control={control}
              rules={{ required: true, validate: validateDate }}
              render={({ field }) => <TextInput {...field} />}
            />
          </FieldWrapper>
        </SpacingContainerItem>
        <SpacingContainerItem>
          <FieldWrapper
            label="End Date"
            name="endDate"
            infoText={'Must be a valid date written like "1990-01-01"'}
          >
            <Controller
              name="endDate"
              control={control}
              rules={{ required: true, validate: validateDate }}
              render={({ field }) => <TextInput {...field} />}
            />
          </FieldWrapper>
        </SpacingContainerItem>
        <SpacingContainerItem>
          <FieldWrapper
            label="Amount"
            name="amount"
            infoText="Amount must be greater than 0"
            errors={errors}
          >
            <Controller
              name="amount"
              control={control}
              rules={{
                required: true,
                min: { value: 1, message: 'Amount must be greater than 0' },
              }}
              render={({ field }) => <CurrencyInput {...field} />}
            />
          </FieldWrapper>
        </SpacingContainerItem>
      </div>
      <Divider />
      <ButtonGroup>
        <Button color={ButtonColors.Cyan} text="Save" isDisabled={!isValid} onClick={save} />
        <Button color={ButtonColors.White} text="Cancel" onClick={() => onClose()} />
      </ButtonGroup>
    </SpacingContainer>
  );
}

interface IBudgetFormModalProps {
  budget: IPipelineBudgetDto;
  onClose: () => void;
}

export function BudgetFormModal({ budget, onClose }: IBudgetFormModalProps) {
  return <BudgetForm budget={budget} onClose={onClose} />;
}

export default function PipelineEditBudget() {
  const { activePipelineSettings } = usePipelineStoreContext();
  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);
  const privilegesQuery = usePipelinePrivilegesQuery(activePipelineSettings.pipelineId);

  const [isCreateEditBudgetModalOpen, setIsCreateEditBudgetModalOpen] = useState(false);
  const [budgetBeingEdited, setBudgetBeingEdited] = useState<IPipelineBudgetDto>();

  const currentBudget = pipelineQuery?.data?.budgets.find((b) =>
    isWithinInterval(new Date(), {
      start: new Date(b.startDate),
      end: new Date(b.endDate),
    }),
  );

  const budgets = pipelineQuery?.data?.budgets.filter((b) => b.id !== currentBudget?.id);

  if (pipelineQuery.isPending || privilegesQuery.isPending) {
    return <></>;
  }

  if (pipelineQuery.isSuccess && privilegesQuery.isSuccess) {
    return (
      <>
        {privilegesQuery.data.canEditBudgets && (
          <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
            <Button
              onClick={() => {
                setBudgetBeingEdited(undefined);
                setIsCreateEditBudgetModalOpen(true);
              }}
              text="Add budget"
              color={ButtonColors.White}
            />
          </ButtonGroup>
        )}

        {currentBudget && (
          <Card as="div" isInteractive={false} showBorder>
            <div className="flex flex-row items-center justify-between space-x-2">
              <Pill color="green" label="Active" />
              {privilegesQuery.data.canEditBudgets && (
                <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
                  <Button
                    onClick={() => {
                      setBudgetBeingEdited(currentBudget);
                      setIsCreateEditBudgetModalOpen(true);
                    }}
                    text="Edit"
                    color={ButtonColors.White}
                  />
                </ButtonGroup>
              )}
            </div>

            <div className="p-2">
              <DescriptionList
                columns={3}
                items={[
                  { name: 'Start date', value: currentBudget?.startDate ?? '-' },
                  { name: 'End date', value: currentBudget?.endDate ?? '-' },
                  {
                    name: 'Amount',
                    value: `${
                      currentBudget
                        ? new Intl.NumberFormat('sv-SE', {
                            maximumFractionDigits: 0,
                          }).format(currentBudget.amount as number)
                        : '-'
                    } ${currentBudget?.currencyIsoCode ? currentBudget.currencyIsoCode : '[N/A]'}`,
                  },
                ]}
              />
            </div>
          </Card>
        )}

        <CardList>
          {budgets?.map((budget) => (
            <ExpandableCard
              key={budget.id}
              renderHead={() => (
                <div className="flex flex-row items-center justify-start space-x-2">
                  <Pill
                    color={
                      new Date(budget.startDate).getTime() > new Date().getTime()
                        ? 'purple'
                        : 'yellow'
                    }
                    label={
                      new Date(budget.startDate).getTime() > new Date().getTime()
                        ? 'Future'
                        : 'Past'
                    }
                  />
                  <Text weight="medium" size="xSmall">
                    {budget.startDate} - {budget.endDate}
                  </Text>
                </div>
              )}
            >
              <div className="p-2">
                <HeaderSubHeader
                  text="Amount"
                  size="small"
                  subText={`${
                    budget
                      ? new Intl.NumberFormat('sv-SE', {
                          maximumFractionDigits: 0,
                        }).format(budget.amount as number)
                      : '-'
                  }
                ${budget?.currencyIsoCode ?? '[N/A]'}`}
                />

                {privilegesQuery.data.canEditBudgets && (
                  <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
                    <Button
                      onClick={() => {
                        setBudgetBeingEdited(budget);
                        setIsCreateEditBudgetModalOpen(true);
                      }}
                      text="Edit"
                      color={ButtonColors.White}
                    />
                  </ButtonGroup>
                )}
              </div>
            </ExpandableCard>
          ))}
        </CardList>

        <Modal
          isOpen={isCreateEditBudgetModalOpen}
          showCloseButton={false}
          onCloseClick={() => setIsCreateEditBudgetModalOpen(false)}
        >
          <BudgetFormModal
            budget={budgetBeingEdited as IPipelineBudgetDto}
            onClose={() => setIsCreateEditBudgetModalOpen(false)}
          />
        </Modal>
        <Howto
          title="Budget"
          description={
            "Change your previous, current, and future budget to align with the board's targets"
          }
        />
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[pipelineQuery, privilegesQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The pipeline could not be found.' }]}
    />
  );
}
