import { Droppable } from 'react-beautiful-dnd';
import PipelineCreateItem from './PipelineCreateItem';
import PipelineItemList from './PipelineStageItemList';
import { IStageDto } from './models/IStageDto';
import { usePipelineStoreContext } from './context/pipeline-context';
import HeaderSubHeader from '../../shared/components/headers/HeaderSubHeader';
import ButtonElement from '../../shared/components/buttons/ButtonLikeElement';
import { useStageQuery } from './queries/stageQueries';
import { usePipelineQuery } from './queries/pipelineQueries';

// TODO: Find a general DND solution for the Drag and Drop that works
// for moving between stages and within a stage
const getDragStyle = (isDraggingOver: boolean) => ({
  border: isDraggingOver ? '2px dashed #06b6d4' : '',
  height: isDraggingOver ? 'max-content' : '',
});

interface IPipelineStageColumnProps {
  stageId: string;
  children: React.ReactNode;
}

function PipelineStageColumnContainer({ stageId, children }: IPipelineStageColumnProps) {
  const { activePipelineSettings } = usePipelineStoreContext();
  const isActive = activePipelineSettings.stageId === stageId;

  return (
    <Droppable droppableId={stageId} direction="vertical">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getDragStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
        >
          <div
            className={`w-64 h-fit rounded-lg bg-white px-4 py-2 relative !border-t-4 border ${
              isActive ? ' !border-cyan-500 !border-t-4' : ''
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </Droppable>
  );
}

function PipelineStageHeader({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-row items-start justify-between">{children}</div>;
}

interface IPipelineStageProps {
  stage: IStageDto;
}

export default function PipelineStage({ stage }: IPipelineStageProps) {
  const { selectStage, activePipelineSettings } = usePipelineStoreContext();
  const stageQuery = useStageQuery(stage.pipelineId, stage.id);
  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);

  if (!stageQuery.isSuccess) return null;

  const value = `${new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 0 }).format(
    stageQuery.data.value,
  )} ${pipelineQuery?.data?.currencyIsoCode}`;

  const name = `${stageQuery.data.name} (${stageQuery.data.weight}%)`;

  return (
    <PipelineStageColumnContainer stageId={stage.id}>
      <PipelineStageHeader>
        <ButtonElement onClick={() => selectStage(stage.id)}>
          <HeaderSubHeader text={name} subText={value} />
        </ButtonElement>
        <PipelineCreateItem stageId={stage.id} />
      </PipelineStageHeader>
      <PipelineItemList stageId={stage.id} />
    </PipelineStageColumnContainer>
  );
}
