import { IMessageReadModel } from './IMessageReadModel';
import { IShareContactRequestDto } from './IShareContactRequestDto';

export enum ContactType {
  Follow = 'Follow',
  Passive = 'Passive',
}

export interface IContactDto {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  location: string;
  company: string;
  title: string;
  additionalInformation: string;
  type: ContactType;
  personProfileId?: string;
  connectionId?: string;
  note: string;
  photoUrl: string;
  // The following variables are added only in frontend:
  latestMessage: IMessageReadModel;
  hasUnreadMessages: boolean;
  shareInformation?: IShareContactRequestDto;
}
