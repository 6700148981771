import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ChangeEventHandler, MouseEventHandler } from 'react';

interface IProps {
  searchTerm: string;
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler;
  includeSearchIcon?: boolean;
  placeholder?: string;
}

export default function SearchBar({
  searchTerm = '',
  onSearchChange,
  onClick = undefined,
  includeSearchIcon = false,
  placeholder = 'Search',
}: IProps) {
  return (
    <div className="flex space-x-4">
      <div className="flex-1 min-w-0">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>

          <input
            onChange={onSearchChange}
            id="search"
            value={searchTerm}
            type="search"
            name="search"
            autoComplete="off"
            className="focus:ring-0 focus:border-zinc-900 block w-full pl-10 sm:text-sm border rounded-md"
            placeholder={placeholder}
            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
          />
        </div>
      </div>

      {includeSearchIcon && (
        <button
          type="button"
          onClick={onClick}
          className="inline-flex justify-center px-4 py-2 border  shadow-sm text-sm font-medium rounded-md text bg-white hover:bg-gray-light focus:outline-none"
        >
          <FunnelIcon className="h-5 w-5 text" aria-hidden="true" />
          <span className="sr-only">Search</span>
        </button>
      )}
    </div>
  );
}
