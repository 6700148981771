type Thickness = 'thin' | 'thick';

interface IBorderActiveVerticalProps {
  thickness?: Thickness;
  rightOffset?: number;
}

const thicknessMapping: Record<Thickness, string> = {
  thin: 'w-[3px]',
  thick: 'w-[4px]',
};

export default function BorderActiveVertical({
  thickness = 'thin',
  rightOffset = 0,
}: IBorderActiveVerticalProps) {
  const thicknessClass = thicknessMapping[thickness];

  const className = `${thicknessClass} absolute bg-cyan rounded-lg h-full`;

  return <div className={className} style={{ right: `${rightOffset}px` }} />;
}
