import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useContext, useState } from 'react';
import { AppContext } from '../../context/context';
import { deleteToast } from '../../services/toastService';
import { IToast } from './model/IToast';
import { ToastType } from './constants/ToastTypes';
import Text from '../text/Text';
import HorizontalDivider from '../dividers/HorizontalDivider';

interface IProps {
  toast: IToast;
}

export default function ToastListItem({ toast }: IProps) {
  const { dispatch } = useContext(AppContext);
  const [currentToast] = useState(toast);

  const handleOnCloseClick = () => {
    dispatch(deleteToast(currentToast.id));
  };

  const renderSwitchIcons = (type: ToastType) => {
    switch (type) {
      case ToastType.Success:
        return <CheckCircleIcon className="h-6 w-6 text-black" aria-hidden="true" />;
      case ToastType.Warning:
        return <ExclamationCircleIcon className="h-6 w-6 text-black" aria-hidden="true" />;
      case ToastType.Error:
        return <ExclamationCircleIcon className="h-6 w-6 text-black" aria-hidden="true" />;

      default:
        return <CheckCircleIcon className="h-6 w-6 text-black" aria-hidden="true" />;
    }
  };

  const renderSwitchBackground = (type: ToastType): string => {
    switch (type) {
      case ToastType.Success:
        return 'bg-green-light';
      case ToastType.Warning:
        return 'bg-yellow-light';
      case ToastType.Error:
        return 'bg-red-light';

      default:
        return 'bg-green-light';
    }
  };

  return (
    <div
      className={`mt-2 max-w-sm w-full rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${renderSwitchBackground(
        toast.type,
      )}`}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">{renderSwitchIcons(toast.type)}</div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <Text as="p" size="small" weight="medium" brightness="dark">
              {toast.title}
            </Text>
            <HorizontalDivider distance="medium" />
            <Text as="p" size="small" brightness="dark">
              {toast.message}
            </Text>
          </div>
          <div className="ml-4 flex-shrink-0 flex">
            <button
              type="button"
              onClick={handleOnCloseClick}
              className="bg-transparent rounded-md inline-flex text-black hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
