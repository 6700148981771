import { Draggable } from 'react-beautiful-dnd';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CardList } from '../../shared/components/cards/CardList';
import { useDeletePipelineItemMutation, usePipelineItemsQuery } from './queries/itemQueries';
import { Card } from '../../shared/components/cards/Card';
import { usePipelineStoreContext } from './context/pipeline-context';
import networkDummy from '../../assets/images/network-icon.svg';
import HeaderSubHeader from '../../shared/components/headers/HeaderSubHeader';
import ProfileAvatar, { UserProfileAvatar } from '../Profile/ProfileAvatar';
import { useNetworkMembersQuery } from '../Networks/queries/networkMemberQueries';
import { useContactQuery } from '../Contacts/queries/contactQueries';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { IPipelineItemDto } from './models/IPipelineItemDto';
import { usePipelineQuery } from './queries/pipelineQueries';
import { INetworkMember } from '../../shared/model/INetworkMember';
import { classNames } from '../../shared/utils/stringUtils';

export type IPipelineItemCardProps = {
  children?: React.ReactNode;
};

export function PipelineItemCardContainer({ children }: IPipelineItemCardProps) {
  return <div className="flex flex-row space-x-2">{children}</div>;
}

interface IPipelineItemMembersProps {
  resourceId: string;
}
export function PipelineItemMembers({ resourceId }: IPipelineItemMembersProps) {
  const networkMembersQuery = useNetworkMembersQuery(resourceId);
  if (!networkMembersQuery.isSuccess || networkMembersQuery.isLoading) return <></>;

  return (
    <div className="flex flex-row space-x-1 mt-1">
      {networkMembersQuery.data?.map((member: INetworkMember) => (
        <UserProfileAvatar
          key={member.userId}
          userId={member.userId}
          widthClass="w-5"
          heightClass="h-5"
        />
      ))}
    </div>
  );
}

function ProfileImageWrapper({ id }: { id: string }) {
  const profileQuery = useContactQuery(id);

  return (
    <ProfileAvatar
      avatarProps={{
        src: profileQuery?.data?.photoUrl,
        widthClass: 'w-8',
        heightClass: 'h-8',
        alt: profileQuery?.data?.firstName,
      }}
    />
  );
}

interface IPipelineItemListProps {
  stageId: string;
}

export default function PipelineItemList({ stageId }: IPipelineItemListProps) {
  const { activePipelineSettings, selectItem, resetPipelineSettings } = usePipelineStoreContext();
  const pipelineItemsQuery = usePipelineItemsQuery({
    pipelineId: activePipelineSettings.pipelineId,
    stageId,
  });
  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);
  const deletePipelineItemMutation = useDeletePipelineItemMutation();

  const handleDeleteItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: IPipelineItemDto,
  ) => {
    e.stopPropagation();
    resetPipelineSettings();
    deletePipelineItemMutation.mutate({
      itemId: item.id,
      pipelineId: activePipelineSettings.pipelineId,
      stageId: item.stageId,
    });
  };

  if (!pipelineItemsQuery.isSuccess || pipelineItemsQuery.isLoading) return <></>;

  return (
    <CardList>
      {pipelineItemsQuery.data.map((item: IPipelineItemDto, index: number) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(provided) => (
            <Card
              as="div"
              draggableProvided={provided}
              className={classNames(
                'group',
                activePipelineSettings.item.id === item.id
                  ? ' outline-cyan-500 outline outline-offset-[-2px]'
                  : '',
                item.resourceRemovedTime ? 'opacity-50 !cursor-default' : '',
              )}
              onClick={() => selectItem(item, stageId)}
            >
              <PipelineItemCardContainer>
                {item.resourceType === 'Network' && (
                  <img alt="temp" src={networkDummy} className="h-8 w-8" />
                )}

                {item.resourceType === 'Contact' && <ProfileImageWrapper id={item.resourceId} />}

                <HeaderSubHeader
                  size="small"
                  text={item.name}
                  subText={`${new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 0 }).format(
                    item.value,
                  )} ${pipelineQuery?.data?.currencyIsoCode}`}
                />
              </PipelineItemCardContainer>
              {item.resourceType === 'Network' && (
                <PipelineItemMembers resourceId={item.resourceId} />
              )}
              <IconButton
                icon={
                  <XMarkIcon className="w-4 h-4 absolute right-1.5 top-1.5 invisible group-hover:visible" />
                }
                color={ButtonColors.Red}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleDeleteItem(e, item)
                }
              />
            </Card>
          )}
        </Draggable>
      ))}
    </CardList>
  );
}
