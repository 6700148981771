import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { Card } from '../cards/Card';

export default function ListItemLink({ children, to, ...props }: LinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const cardBgColor = match ? 'outline-cyan-500 outline outline-offset-[-2px]' : '';

  return (
    <Card className={`${cardBgColor} relative`}>
      <Link to={to} className="flex items-center space-x-3" {...props}>
        {children}
      </Link>
    </Card>
  );
}
