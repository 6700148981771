import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { useInitialDataPropsFromQuery } from '../../../shared/hooks/queryHooks';
import { IConversationReadModel } from '../../../shared/model/IConversationReadModel';
import {
  getConversationById,
  getConversations,
} from '../../../shared/services/conversationService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { getInitialDataPropsFromQuery } from '../../../shared/utils/queryClientUtils';
import IParticipantReadModel from '../../../shared/model/IPartisipantsReadModel';
import { useMyUserId } from '../../../shared/hooks/accountHooks';

interface UseConversationQueryProps {
  networkId?: string;
  connectionId?: string;
}

export const conversationKeys = {
  all: ['conversations'] as const,
  lists: () => [...conversationKeys.all, 'list'] as const,
  list: (props: UseConversationQueryProps) => [...conversationKeys.lists(), props] as const,
  details: () => [...conversationKeys.all, 'detail'] as const,
  detail: (id?: string) => [...conversationKeys.details(), id] as const,
};

export const conversationQuery = (queryClient: QueryClient, conversationId?: string) =>
  ({
    queryKey: conversationKeys.detail(conversationId),
    queryFn: () =>
      typeof conversationId === 'undefined'
        ? Promise.reject()
        : getConversationById(conversationId),
    enabled: !!conversationId,
    ...getInitialDataPropsFromQuery(
      queryClient,
      conversationKeys.lists(),
      (conversation: IConversationReadModel) => conversation.id === conversationId,
    ),
  } as const);

export function useConversationQuery(conversationId?: string) {
  const queryClient = useQueryClient();
  return useQuery(conversationQuery(queryClient, conversationId));
}

export function useConversationParticipantsForMentionQuery<TData>(
  conversationId: string,
  searchTerm: string | null,
  transformFunction: (participant: IParticipantReadModel) => TData,
) {
  const queryClient = useQueryClient();
  const myUserId = useMyUserId();
  const lowerCaseSearchTerm = searchTerm?.toLowerCase();
  return useQuery({
    ...conversationQuery(queryClient, conversationId),
    select: (conversation) =>
      conversation.participants
        .filter(
          (participant) =>
            lowerCaseSearchTerm !== undefined &&
            participant.userId !== myUserId &&
            participant.displayName
              .toLowerCase()
              .split(' ')
              .some((s: string) => s.startsWith(lowerCaseSearchTerm)),
        )
        .slice(0, 9)
        .map(transformFunction),
    enabled: searchTerm !== null && searchTerm !== undefined,
  });
}

export function useNetworkConversationQuery(networkId?: string) {
  return useQuery({
    queryKey: conversationKeys.list({ networkId }),
    queryFn: () =>
      getConversations(new QueryParam('networkId', networkId)).then((pagedResult) =>
        pagedResult.data.length > 0
          ? pagedResult.data[0]
          : Promise.reject(new Error('Network conversation not found.')),
      ),
    enabled: !!networkId,
    ...useInitialDataPropsFromQuery<IConversationReadModel>(
      conversationKeys.lists(),
      (conversation) => conversation.networkId === networkId,
    ),
  });
}

export function useConnectionConversationQuery(connectionId?: string) {
  return useQuery({
    queryKey: conversationKeys.list({ connectionId }),
    queryFn: () =>
      getConversations(new QueryParam('connectionId', connectionId)).then((pagedResult) =>
        pagedResult.data.length > 0
          ? pagedResult.data[0]
          : Promise.reject(new Error('Connection conversation not found.')),
      ),
    enabled: !!connectionId,
    ...useInitialDataPropsFromQuery<IConversationReadModel>(
      conversationKeys.lists(),
      (conversation) => conversation.connectionId === connectionId,
    ),
  });
}
