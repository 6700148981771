import { AtSymbolIcon, LinkIcon, ShareIcon } from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

import {
  ContextMenu,
  ContextMenuDivider,
  ContextMenuItem,
  ContextMenuLinkItem,
} from '../../../shared/components/context-menu/ContextMenu';
import { useFileDropContext } from '../../../shared/components/file/FileDropContainer';
import { INSERT_MENTION_COMMAND } from '../../../shared/lexical/mentions/MentionsPlugin';
import { MessageEditorAction } from './messageEditorMenuActions';

export type MessageEditorActionProps = {
  externalMenuActions?: MessageEditorAction[];
};

export function MessageEditorActionMenu({ externalMenuActions = [] }: MessageEditorActionProps) {
  const fileDropContext = useFileDropContext();
  const isEditMode = false;
  const [editor] = useLexicalComposerContext();

  return (
    <ContextMenu icon={<PlusCircleIcon className="h-6 w-6 text" aria-hidden="true" />}>
      {fileDropContext && !isEditMode && (
        <ContextMenuItem
          label="Attach file"
          onClick={fileDropContext.openFileDialog}
          icon={<LinkIcon className="h-6 w-6" aria-hidden="true" />}
        />
      )}
      <ContextMenuItem
        label="Share contact"
        onClick={() => {
          editor.dispatchCommand(INSERT_MENTION_COMMAND, 'share');
          setTimeout(() => {
            editor.focus();
          }, 500);
        }}
        icon={<ShareIcon className="h-6 w-6" aria-hidden="true" />}
      />
      <ContextMenuItem
        label="Mention"
        onClick={() => {
          editor.dispatchCommand(INSERT_MENTION_COMMAND, 'mention');
          setTimeout(() => {
            editor.focus();
          }, 500);
        }}
        icon={<AtSymbolIcon className="h-6 w-6" aria-hidden="true" />}
      />
      {externalMenuActions.length > 0 && <ContextMenuDivider />}
      {externalMenuActions.map((action) => (
        <ContextMenuLinkItem
          key={action.to}
          label={action.label}
          to={action.to}
          icon={action.icon}
          onClick={action.onClick}
        />
      ))}
    </ContextMenu>
  );
}
