import { useState } from 'react';
import { addDays, addHours, format, nextMonday, set } from 'date-fns';
import { BellIcon, CalendarIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { ActivityEntityType, IActivityDto } from '../../shared/model/IActitityDto';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ActivityAssignToModal from './ActivityAssignToModal';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import Button from '../../shared/components/buttons/Button';
import {
  useDeleteActivityMutation,
  useExpirationOnActivityMutation,
  useReminderOnActivityMutation,
  useSetAssignedToOnActivityMutation,
} from './queries/activitiesQueries';
import {
  ContextMenuDatePicker,
  ContextMenuDivider,
  ContextMenu,
  ContextMenuItem,
} from '../../shared/components/context-menu/ContextMenu';
import { CardMenuBar } from '../../shared/components/cards/CardMenuBar';
import { Filler } from '../../shared/components/layout/Filler';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Text from '../../shared/components/text/Text';

interface IActivityActionMenuBarProps {
  activity: IActivityDto;
  showAssignedTo: boolean;
  entityType: ActivityEntityType;
}

export function ActivityActionMenuBar({
  activity,
  showAssignedTo,
  entityType,
}: IActivityActionMenuBarProps) {
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const deleteMutation = useDeleteActivityMutation(activity.parentId, entityType);
  const reminderMutation = useReminderOnActivityMutation(activity.parentId, entityType);
  const expirationMutation = useExpirationOnActivityMutation(activity.parentId, entityType);
  const assigneeMutation = useSetAssignedToOnActivityMutation(activity.parentId, entityType);

  const resetTimePartOfDate = (date: Date) =>
    set(date, { hours: 9, seconds: 0, minutes: 0, milliseconds: 0 });
  const nowBaseDate = new Date();
  const todayBaseDate = resetTimePartOfDate(nowBaseDate);

  const handleDelete = () => {
    deleteMutation.mutate({ activityId: activity.id });
  };

  const handleSetReminder = (remindAtTime: string) => {
    reminderMutation.mutate({ activityId: activity.id, remindAtTime });
  };

  const handleSetExpire = (expireAtTime: string) => {
    expirationMutation.mutate({ activityId: activity.id, expireAtTime });
  };

  const handleSetAssignedTo = (assignedTo: { id: string }) => {
    assigneeMutation.mutate({ activityId: activity.id, userId: assignedTo.id });
  };

  return (
    <>
      <CardMenuBar>
        <ContextMenu icon={<CalendarIcon className="w-5 h-5" />} label="Expires">
          <ContextMenuItem
            icon={<CalendarIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Today"
            definition={format(todayBaseDate, 'iii')}
            onClick={() => handleSetExpire(todayBaseDate.toISOString())}
          />
          <ContextMenuItem
            icon={<CalendarIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Tomorrow"
            definition={format(addDays(todayBaseDate, 1), 'iii')}
            onClick={() => handleSetExpire(addDays(todayBaseDate, 1).toISOString())}
          />
          <ContextMenuItem
            icon={<CalendarIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Next week"
            definition={format(nextMonday(todayBaseDate), 'iii')}
            onClick={() => handleSetExpire(nextMonday(todayBaseDate).toISOString())}
          />
          <ContextMenuDivider />
          <ContextMenuDatePicker
            icon={<CalendarIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Pick a date"
            initialDate={activity.expireTime}
            onSelect={(date) => handleSetExpire(date.toISOString())}
          />
        </ContextMenu>

        <ContextMenu icon={<BellIcon className="w-5 h-5" />} label="Remind">
          <ContextMenuItem
            icon={<BellIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Later"
            definition={format(addHours(nowBaseDate, 3), 'iii, HH:mm')}
            onClick={() => handleSetReminder(addHours(nowBaseDate, 3).toISOString())}
          />
          <ContextMenuItem
            icon={<BellIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Tomorrow"
            definition={format(addDays(todayBaseDate, 1), 'iii, HH:mm')}
            onClick={() => handleSetReminder(addDays(todayBaseDate, 1).toISOString())}
          />
          <ContextMenuItem
            icon={<BellIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Next week"
            definition={format(nextMonday(todayBaseDate), 'iii, HH:mm')}
            onClick={() => handleSetReminder(nextMonday(todayBaseDate).toISOString())}
          />
          <ContextMenuDivider />
          <ContextMenuDatePicker
            icon={<BellIcon className="w-5 h-5  flex-none hover:text-white" />}
            label="Pick a date & time"
            initialDate={activity.reminderTime}
            hasTime
            onSelect={(date) => handleSetReminder(date.toISOString())}
          />
        </ContextMenu>

        {showAssignedTo && (
          <>
            <IconButton
              color={ButtonColors.Gray}
              icon={<UserPlusIcon className="w-5 h-5" />}
              onClick={() => {
                setIsAssigneeModalOpen(true);
              }}
              label="Assign"
            />
            <ActivityAssignToModal
              parentId={activity.parentId}
              activityEntityType={entityType}
              activityId={activity.id}
              isOpen={isAssigneeModalOpen}
              isPending={assigneeMutation.isPending}
              onCloseCallback={(result) => {
                if (result) {
                  handleSetAssignedTo({ id: result.id });
                }
                setIsAssigneeModalOpen(false);
              }}
            />
          </>
        )}
        <Filler />
        <div>
          <IconButton
            color={ButtonColors.Red}
            icon={<TrashIcon className="w-5 h-5" />}
            onClick={() => setIsConfirmDeleteOpen(true)}
            label="Delete"
          />
          <ConfirmDialog title="Delete Task" isOpen={isConfirmDeleteOpen}>
            <Text as="p">Are you sure you want to delete this task?</Text>
            <HorizontalDivider distance="large" />
            <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
              <Button
                color={ButtonColors.Red}
                text="Delete"
                isDisabled={deleteMutation.isPending}
                onClick={() => {
                  handleDelete();
                  setIsConfirmDeleteOpen(false);
                }}
              />
              <Button
                color={ButtonColors.White}
                text="Cancel"
                onClick={() => setIsConfirmDeleteOpen(false)}
              />
            </ButtonGroup>
          </ConfirmDialog>
        </div>
      </CardMenuBar>
    </>
  );
}
