import { MouseEventHandler, useState } from 'react';
import TabContent from '../../../shared/components/tabs/TabContent';
import TabContentWrapper from '../../../shared/components/tabs/TabContentWrapper';
import TabHeaderWrapper from '../../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../../shared/components/tabs/TabNavItem';
import { IPersonProfileDto } from '../../../shared/model/IPersonProfileDto';

import ContactUserTabBody from '../ContactUserTabBody';
import { IMenuItemActionButton } from '../../../shared/model/IMenuItemActionButton';
import MenuItemActionButton from '../../../shared/components/tabs/MenuItemActionButton';
import TabButtonItem from '../../../shared/components/tabs/TabButtonItem';

interface IProps {
  profile?: IPersonProfileDto | null;
  isConnectionAccepted: boolean;
  isConnectionInitiated: boolean;
  isFollowing: boolean;
  onFollowContact: MouseEventHandler;
  onCreateConnectionRequest: MouseEventHandler;
}

export default function ContactExternalProfileInfoTabsMobile({
  profile,
  isConnectionInitiated,
  isConnectionAccepted,
  isFollowing,
  onFollowContact,
  onCreateConnectionRequest,
}: IProps) {
  const [activeTabId, setActiveTabId] = useState('tab_info');

  const handleSetActiveTab = (id: string) => {
    setActiveTabId(id);
  };

  const menuItemActionButtons: IMenuItemActionButton[] = [];

  if (!isConnectionInitiated && !isConnectionAccepted) {
    menuItemActionButtons.push({
      id: 'Connect',
      children: <MenuItemActionButton text="Connect" onClick={onCreateConnectionRequest} />,
    });
  }

  if (isConnectionInitiated && !isConnectionAccepted) {
    menuItemActionButtons.push({
      id: 'Connecting..',
      children: (
        <MenuItemActionButton text="Connecting.." disabled onClick={onCreateConnectionRequest} />
      ),
    });
  }

  if (!isConnectionAccepted && !isFollowing) {
    menuItemActionButtons.push({
      id: 'Follow',
      children: <MenuItemActionButton text="Follow" onClick={onFollowContact} />,
    });
  }

  return (
    <>
      <TabHeaderWrapper>
        <TabNavItem
          id="tab_info"
          activeId={activeTabId}
          title="Info"
          setActiveTab={handleSetActiveTab}
        />

        <TabButtonItem menuItemActionButtons={menuItemActionButtons} isActive={false} />
      </TabHeaderWrapper>
      <TabContentWrapper>
        <TabContent id="tab_info" activeTabId={activeTabId}>
          <ContactUserTabBody user={profile} />
        </TabContent>
      </TabContentWrapper>
    </>
  );
}
