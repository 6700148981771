import { useState } from 'react';
import { XIcon } from 'lucide-react';
import Text from '../text/Text';
import { Popover, PopoverContent, PopoverTrigger } from '../popovers/Popover';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';
import IconButton from '../buttons/IconButton';
import { ButtonColors } from '../../constants/ButtonColors';

interface IProps {
  title?: string;
  description?: React.ReactNode | string;
}

export default function Howto({ title, description }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handlePopoverToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      <div className="relative mt-auto ml-auto">
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger onClick={handlePopoverToggle} className="ml-auto">
            <QuestionMarkIcon className="w-8 h-8" />
          </PopoverTrigger>
          <PopoverContent align="end" alignOffset={25}>
            <div className="flex flex-col gap-3 bg-white border-red-light border-[1px] rounded-lg mx-2 sm:m-auto w-[calc(100vw-1rem)] sm:w-[30rem] min-h-52">
              <div className="bg-red flex items-center gap-2 rounded-t-lg h-10 px-2 ">
                <IconButton
                  icon={<XIcon />}
                  title="Close"
                  onClick={() => setIsOpen(false)}
                  color={ButtonColors.White}
                />
                {/* Pixel hack */}
                <div className="mb-[1px]">
                  <Text size="large" color="white" weight="medium">
                    {title}
                  </Text>
                </div>
              </div>
              <div className="relative left-2 mr-5">
                <Text size="normal" breakMode="words">
                  {description}
                </Text>
              </div>
              {/* <div className=" pl-24 pt-16 ml-28">
                <Text size="small" color="normal" weight="normal">
                   More questions? Chat with us <span className="underline">here!</span>
                </Text>
                <ChatIcon className="relative left-4 w-8 h-8   mt-auto" />
              </div> */}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}
