import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import TabContent from '../../shared/components/tabs/TabContent';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import { IContactDto } from '../../shared/model/IContactDto';
import ContactPrivateNotes from './ContactPrivateNotes';
import { ActivityEntityType } from '../../shared/model/IActitityDto';
import Activities from '../Activities/Activities';
import { IConversationReadModel } from '../../shared/model/IConversationReadModel';
import ContactShareContact from './ContactShareContact';
import MenuItemActionButton from '../../shared/components/tabs/MenuItemActionButton';
import { IMenuItemActionButton } from '../../shared/model/IMenuItemActionButton';
import { ToastType } from '../../shared/components/toasts/constants/ToastTypes';
import { AppContext } from '../../shared/context/context';
import { deleteContact } from '../../shared/services/contactService';
import { createToast } from '../../shared/services/toastService';
import { EventBus } from '../../shared/utils/eventBus';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import TabButtonItem from '../../shared/components/tabs/TabButtonItem';
import Attachments from '../../shared/components/attachments/Attachments';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Text from '../../shared/components/text/Text';
import useContactDefaultTabNavigation from './hooks/useContactDefaultTabNavigation';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import { IPipelineEditItemProps } from '../Pipelines/models/IPipelineEditItemProps';
import { ContactInfoTab } from './ContactInfoTab';
import Howto from '../../shared/components/message-popups/HowTo';

interface IProps {
  contact: IContactDto;
  conversation?: IConversationReadModel;
  pipelineEditItemProps?: IPipelineEditItemProps;
}

export default function ContactInfoTabs({ contact, conversation, pipelineEditItemProps }: IProps) {
  const [, setSearchParams] = useSearchParams();
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [showRemoveContactWarning, setShowRemoveContactWarning] = useState(false);
  const defaultTab = useContactDefaultTabNavigation(!!contact?.connectionId);
  const activeTabId = searchParams.get('tab') || defaultTab;

  const handleSetActiveTab = (id: string) => {
    setSearchParams({ tab: id });
  };

  const handleContinueDeleteContact = () => {
    setShowRemoveContactWarning(false);
    deleteContact(contact?.id as string)
      .then(() => {
        setTimeout(() => {
          EventBus.getInstance().dispatch('refreshContactList');
          navigate('/contacts', { replace: true });
        }, 1000);

        dispatch(createToast('Success', ToastType.Success, 'Contact Deleted'));
      })
      .catch(() => {
        dispatch(createToast('Error', ToastType.Error, 'Could not delete contact'));
      });
  };

  const handleShowDeleteWarning = () => {
    setShowRemoveContactWarning(true);
  };

  const handleCancelDeleteContact = () => {
    setShowRemoveContactWarning(false);
  };

  const menuItemActionButtons: IMenuItemActionButton[] = [
    {
      id: 'Remove Contact',
      children: <MenuItemActionButton text="Remove Contact " onClick={handleShowDeleteWarning} />,
    },
  ];

  return (
    <TabWrapper>
      <TabHeaderWrapper>
        {contact?.connectionId && (
          <TabNavItem
            id="tab_info"
            activeId={activeTabId}
            title="Info"
            setActiveTab={handleSetActiveTab}
          />
        )}
        <TabNavItem
          id="tab_notes"
          activeId={activeTabId}
          title="Notes"
          setActiveTab={handleSetActiveTab}
        />
        <TabNavItem
          id="tab_activities"
          activeId={activeTabId}
          title="Tasks"
          setActiveTab={handleSetActiveTab}
        />
        {contact?.connectionId && (
          <TabNavItem
            id="tab_attachments"
            activeId={activeTabId}
            title="Attachments"
            setActiveTab={handleSetActiveTab}
          />
        )}
        <TabNavItem
          id="tab_share"
          activeId={activeTabId}
          title="Share"
          setActiveTab={handleSetActiveTab}
        />

        <TabButtonItem isActive={false} menuItemActionButtons={menuItemActionButtons} />
      </TabHeaderWrapper>
      <TabContentWrapper>
        <TabContent id="tab_info" activeTabId={activeTabId}>
          <ContactInfoTab contact={contact} pipelineEditItemProps={pipelineEditItemProps} />
        </TabContent>
        <TabContent id="tab_notes" activeTabId={activeTabId}>
          <ContactPrivateNotes contact={contact ?? ({} as IContactDto)} showHeader={false} />
        </TabContent>
        <TabContent id="tab_activities" activeTabId={activeTabId}>
          {contact && <Activities parentId={contact.id} type={ActivityEntityType.Contact} />}
        </TabContent>
        {conversation && (
          <TabContent id="tab_attachments" activeTabId={activeTabId}>
            <Attachments conversationId={conversation.id as string} />
            <Howto
              title="Attatchments"
              description="View the attatchments you have sent in the chat."
            />
          </TabContent>
        )}

        <TabContent id="tab_share" activeTabId={activeTabId}>
          <ContactShareContact contact={contact} />
        </TabContent>
      </TabContentWrapper>
      <ConfirmDialog isOpen={showRemoveContactWarning} title="Remove Contact">
        <Text as="p">Are you sure you want to remove this contact?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Remove" onClick={handleContinueDeleteContact} />
          <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelDeleteContact} />
        </ButtonGroup>
      </ConfirmDialog>
    </TabWrapper>
  );
}
