import {
  InfiniteData,
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import {
  IUpdateActivityDto,
  blockActivity,
  completeActivity,
  createActivity,
  deleteActivity,
  getActivities,
  moveActivityTask,
  pauseActivity,
  setAssignedToOnActivity,
  setExpirationOnActivity,
  setReminderOnActivity,
  startActivity,
  updateActivity,
} from '../../../shared/services/activitiesService';
import {
  ActivityEntityType,
  ActivityStatus,
  ActivityType,
  IActivityDto,
} from '../../../shared/model/IActitityDto';

import { CalculateDragDropPositions } from '../../../shared/utils/dragDropUtils';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import { IPagedResult } from '../../../shared/model/IPagedResult';
import ConvertFlatListToInfiniteDataPages from '../../../shared/utils/pageUtils';
import { IMoveActivityDto } from '../../../shared/model/IMoveActivityDto';
import { updateInfiniteQueryData } from '../../../shared/utils/queryClientUtils';

interface IUseActivitiesProps {
  parentId: string;
  entityType: ActivityEntityType;
}

export const activityKeys = {
  all: ['activities'] as const,
  lists: () => [...activityKeys.all, 'list'] as const,
  list: (props: IUseActivitiesProps) => [...activityKeys.all, 'list', props] as const,
  detail: (id: string) => [...activityKeys.all, 'detail', id] as const,
};

const limit = 50;
export function useActivitiesPagesQuery(parentId: string, entityType: ActivityEntityType) {
  return useInfiniteApiQuery({
    queryKey: activityKeys.list({ parentId, entityType }),
    queryFn: () => getActivities(parentId, entityType, limit),
  });
}

function updateCachedActivity<TVariables extends { activityId: string }>(
  queryClient: QueryClient,
  variables: TVariables,
  keyProps: IUseActivitiesProps,
  transform: (activity: IActivityDto, variables: TVariables) => IActivityDto,
) {
  const updateFunc = (data: IActivityDto[]) => {
    const updatedData = data.map((activity) => {
      if (activity.id === variables.activityId) {
        return transform(activity, variables);
      }
      return activity;
    });

    return updatedData;
  };

  updateInfiniteQueryData<IActivityDto>(
    queryClient,
    activityKeys.list({ parentId: keyProps.parentId, entityType: keyProps.entityType }),
    updateFunc,
  );
}

function useOptimisticUpdateProps<TVariables extends { activityId: string }>(
  keyProps: IUseActivitiesProps,
  transform: (activity: IActivityDto, variables: TVariables) => IActivityDto,
) {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return {
    onMutate: (variables: TVariables) =>
      updateCachedActivity(queryClient, variables, keyProps, transform),
    onSuccess: (_: unknown, variables: TVariables) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list(keyProps) });
      queryClient.invalidateQueries({ queryKey: activityKeys.detail(variables.activityId) });
    },
    onError: (error: unknown, _: unknown, rollback: unknown) => {
      if (typeof rollback === 'function') rollback();
      dispatchError(error);
    },
  };
}

export function useListActivitiesQuery(parentId: string, entityType: ActivityEntityType) {
  return useQuery({
    queryKey: activityKeys.list({ parentId, entityType }),
    queryFn: () => getActivities(parentId, entityType),
  });
}

export function useDeleteActivityMutation(parentId: string, entityType: ActivityEntityType) {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      deleteActivity(parentId, entityType as ActivityEntityType, activityId),

    onSuccess: (_, { activityId }) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list({ parentId, entityType }) });
      queryClient.removeQueries({ queryKey: activityKeys.detail(activityId) });
    },
    onError: (error, _, rollback) => {
      if (typeof rollback === 'function') rollback();
      dispatchError(error);
    },
  });
}

interface IEditActivityProps {
  activityId: string;
  title?: string;
  text?: string;
}

export function useEditActivityMutation(parentId: string, entityType: ActivityEntityType) {
  return useMutation({
    mutationFn: ({ activityId, title, text }: IEditActivityProps) => {
      const updateActivityDto: IUpdateActivityDto = {
        title: title || undefined,
        text: text || undefined,
      };
      return updateActivity(parentId, entityType, activityId, updateActivityDto);
    },
    ...useOptimisticUpdateProps<IEditActivityProps>(
      { parentId, entityType },
      (activity, { title, text }) => ({
        ...activity,
        title: title || activity.title,
        text: text || activity.text,
      }),
    ),
  });
}

export function usePauseActivityMutation(parentId: string, entityType: ActivityEntityType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      pauseActivity(parentId, entityType as ActivityEntityType, activityId),
    ...useOptimisticUpdateProps({ parentId, entityType }, (activity) => ({
      ...activity,
      status: ActivityStatus.Pending,
    })),
  });
}

export function useCompleteActivityMutation(parentId: string, entityType: ActivityEntityType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      completeActivity(parentId, entityType as ActivityEntityType, activityId),
    ...useOptimisticUpdateProps({ parentId, entityType }, (activity) => ({
      ...activity,
      status: ActivityStatus.Completed,
    })),
  });
}

export function useStartActivityMutation(parentId: string, entityType: ActivityEntityType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      startActivity(parentId, entityType as ActivityEntityType, activityId),
    ...useOptimisticUpdateProps({ parentId, entityType }, (activity) => ({
      ...activity,
      status: ActivityStatus.InProgress,
    })),
  });
}

export function useBlockActivityMutation(parentId: string, entityType: ActivityEntityType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      blockActivity(parentId, entityType as ActivityEntityType, activityId),
    ...useOptimisticUpdateProps({ parentId, entityType }, (activity) => ({
      ...activity,
      status: ActivityStatus.Blocked,
    })),
  });
}

interface ISetReminderOnActivityProps {
  activityId: string;
  remindAtTime: string;
}

export function useReminderOnActivityMutation(parentId: string, entityType: ActivityEntityType) {
  return useMutation({
    mutationFn: ({ activityId, remindAtTime }: ISetReminderOnActivityProps) =>
      setReminderOnActivity(parentId, entityType as ActivityEntityType, activityId, remindAtTime),
    ...useOptimisticUpdateProps<ISetReminderOnActivityProps>(
      { parentId, entityType },
      (activity, { remindAtTime }) => ({
        ...activity,
        reminderTime: remindAtTime,
      }),
    ),
  });
}

interface ISetExpirationOnActivityProps {
  activityId: string;
  expireAtTime: string;
}

export function useExpirationOnActivityMutation(parentId: string, entityType: ActivityEntityType) {
  return useMutation({
    mutationFn: ({ activityId, expireAtTime }: ISetExpirationOnActivityProps) =>
      setExpirationOnActivity(parentId, entityType as ActivityEntityType, activityId, expireAtTime),
    ...useOptimisticUpdateProps<ISetExpirationOnActivityProps>(
      { parentId, entityType },
      (activity, { expireAtTime }) => ({
        ...activity,
        expireTime: expireAtTime,
      }),
    ),
  });
}

interface ICreateActivityProps {
  title: string;
  type: ActivityType;
}

export function useCreateActivityMutation(parentId: string, entityType: ActivityEntityType) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ title, type }: ICreateActivityProps) =>
      createActivity(parentId, entityType as ActivityEntityType, title, type, false),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list({ parentId, entityType }) });
    },
    onError: useDispatchApiError(),
  });
}

interface ISetAssignedToOnActivityProps {
  activityId: string;
  userId: string;
}

export function useSetAssignedToOnActivityMutation(
  parentId: string,
  entityType: ActivityEntityType,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ activityId, userId }: ISetAssignedToOnActivityProps) =>
      setAssignedToOnActivity(parentId, entityType as ActivityEntityType, activityId, userId),
    onSuccess: (_, { activityId }) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list({ parentId, entityType }) });
      queryClient.invalidateQueries({ queryKey: activityKeys.detail(activityId) });
    },
    onError: useDispatchApiError(),
  });
}

interface IMoveActivityProps {
  parentId: string;
  entityType: ActivityEntityType;
  activityId: string;
  destinationIndex: number;
  activityMove: IMoveActivityDto;
}

export function useMoveActivityMutation() {
  const dispatchError = useDispatchApiError();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ parentId, entityType, activityId, activityMove }: IMoveActivityProps) =>
      moveActivityTask(parentId, entityType, activityId, activityMove),
    onMutate: ({ parentId, entityType, activityId, destinationIndex }) => {
      const activities = queryClient.getQueryData(
        activityKeys.list({ parentId, entityType }),
      ) as InfiniteData<IPagedResult<IActivityDto>>;

      const activitiesData = activities?.pages.flatMap((i) => i.data)?.flat() as IActivityDto[];

      const { targetItems } = CalculateDragDropPositions<IActivityDto>(
        activitiesData,
        activitiesData,
        activityId,
        destinationIndex,
      );

      activities.pages = ConvertFlatListToInfiniteDataPages<IActivityDto>(
        activities,
        targetItems,
        limit,
      );

      queryClient.setQueryData(activityKeys.list({ parentId, entityType }), activities);

      return { activities };
    },
    onSuccess: (_, { activityId, parentId, entityType }) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list({ parentId, entityType }) });
      queryClient.invalidateQueries({ queryKey: activityKeys.detail(activityId) });
    },
    onError: (error, { parentId, entityType }, oldData) => {
      if (oldData) {
        queryClient.setQueryData(activityKeys.list({ parentId, entityType }), oldData);
      }
      dispatchError(error);
    },
  });
}
