import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import Modal from '../../shared/components/modal/Modal';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import NetworkCreateEdit from './NetworkCreateEdit';
import InviteMember from './NetworkInviteMember';
import { useMyUserId } from '../../shared/hooks/accountHooks';
import Text from '../../shared/components/text/Text';
import { useNetworkStoreContext } from './context/network-context';
import { useDeleteNetworkMutation, useNetworkQuery } from './queries/networkQueries';
import { useRemoveNetworkMemberMutation } from './queries/networkMemberQueries';

interface INetworkInfoModalsProps {
  networkId: string;
  onDeleteNetwork?: () => void;
  onLeaveNetwork?: () => void;
}

export default function NetworkInfoModals({
  networkId,
  onDeleteNetwork,
  onLeaveNetwork,
}: INetworkInfoModalsProps) {
  const myUserId = useMyUserId();
  const networkQuery = useNetworkQuery(networkId);
  const deleteNetworkMutation = useDeleteNetworkMutation();
  const removeNetworkMemberMutation = useRemoveNetworkMemberMutation();

  const {
    isConfirmDeleteNetworkOpen,
    setIsConfirmDeleteNetworkOpen,
    isConfirmLeaveNetworkOpen,
    setIsConfirmLeaveNetworkOpen,
    isEditNetworkModalOpen,
    setIsEditNetworkModalOpen,
    isInviteMemberModalOpen,
    setInviteMemberModalOpen,
  } = useNetworkStoreContext();

  const handleContinueLeaveNetwork = () => {
    setIsConfirmLeaveNetworkOpen(false);
    if (!myUserId) return;
    removeNetworkMemberMutation.mutate(
      { member: { name: '', networkId, userId: myUserId } },
      {
        onSuccess: () => {
          setIsConfirmLeaveNetworkOpen(false);
          if (onLeaveNetwork) onLeaveNetwork();
        },
      },
    );
  };

  const handleContinueDeleteNetwork = () => {
    setIsConfirmDeleteNetworkOpen(false);
    deleteNetworkMutation.mutate({ networkId, networkName: networkQuery.data?.name as string });
    if (onDeleteNetwork) onDeleteNetwork();
  };

  return (
    <>
      <Modal
        isOpen={isInviteMemberModalOpen}
        showCloseButton
        onCloseClick={() => setInviteMemberModalOpen(false)}
        title="Invite members"
      >
        <InviteMember callback={() => setInviteMemberModalOpen(false)} networkId={networkId} />
      </Modal>

      <Modal isOpen={isEditNetworkModalOpen} onCloseClick={() => setIsEditNetworkModalOpen(false)}>
        <NetworkCreateEdit
          network={networkQuery.data}
          onCancel={() => setIsEditNetworkModalOpen(false)}
          onSuccess={() => setIsEditNetworkModalOpen(false)}
        />
      </Modal>

      <ConfirmDialog title="Delete Network" isOpen={isConfirmDeleteNetworkOpen}>
        <Text as="p">Are you sure you want to delete this network?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Delete" onClick={handleContinueDeleteNetwork} />
          <Button
            color={ButtonColors.White}
            text="Cancel"
            onClick={() => setIsConfirmDeleteNetworkOpen(false)}
          />
        </ButtonGroup>
      </ConfirmDialog>
      <ConfirmDialog title="Leave Network" isOpen={isConfirmLeaveNetworkOpen}>
        <Text as="p">Are you sure you want to leave this network?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Leave" onClick={handleContinueLeaveNetwork} />
          <Button
            color={ButtonColors.White}
            text="Cancel"
            onClick={() => setIsConfirmLeaveNetworkOpen(false)}
          />
        </ButtonGroup>
      </ConfirmDialog>
    </>
  );
}
