export const environment: 'Development' | 'Staging' | 'Production' =
  process.env.REACT_APP_ENVIRONMENT === 'Development' ||
  process.env.REACT_APP_ENVIRONMENT === 'Staging' ||
  process.env.REACT_APP_ENVIRONMENT === 'Production'
    ? process.env.REACT_APP_ENVIRONMENT
    : 'Development';

export const appVersion: string =
  typeof process.env.REACT_APP_VERSION !== 'undefined' ? process.env.REACT_APP_VERSION : '0';

// Backend API URL
export const baseURL: string | undefined =
  typeof process.env.REACT_APP_API_URL !== 'undefined' ? process.env.REACT_APP_API_URL : '';

// Base URL to profile images storage
export const profileImagesURL: string | undefined =
  typeof process.env.REACT_APP_CONTACT_STORAGE_BASE_URL !== 'undefined' &&
  typeof process.env.REACT_APP_CONTACT_STORAGE_PROFILE_FOLDER !== 'undefined'
    ? `${process.env.REACT_APP_CONTACT_STORAGE_BASE_URL}${process.env.REACT_APP_CONTACT_STORAGE_PROFILE_FOLDER}`
    : '';

// The name of the B2C tenant.
export const b2cTenantName: string =
  typeof process.env.REACT_APP_AUTH_B2C_TENANT_NAME !== 'undefined'
    ? process.env.REACT_APP_AUTH_B2C_TENANT_NAME
    : '';

// The Application ID for the backend API in B2C
export const b2cClientId: string =
  typeof process.env.REACT_APP_AUTH_B2C_CLIENT_ID !== 'undefined'
    ? process.env.REACT_APP_AUTH_B2C_CLIENT_ID
    : '';

export const b2cApiScope: string =
  typeof process.env.REACT_APP_AUTH_B2C_API_SCOPE !== 'undefined'
    ? process.env.REACT_APP_AUTH_B2C_API_SCOPE
    : '';

export const b2cLogoutRedirectUrl: string =
  typeof process.env.REACT_APP_AUTH_LOGOUT_REDIRECT_URL !== 'undefined'
    ? process.env.REACT_APP_AUTH_LOGOUT_REDIRECT_URL
    : '/signin';

// The instrumentation key for Application Insights
export const applicationInsightsInstrumentationKey: string =
  typeof process.env.REACT_APP_APPINSIGHTS_KEY !== 'undefined'
    ? process.env.REACT_APP_APPINSIGHTS_KEY
    : '';

// Giphy api key
export const giphyApiKey: string =
  typeof process.env.REACT_APP_GIPHY_API_KEY !== 'undefined'
    ? process.env.REACT_APP_GIPHY_API_KEY
    : '';
