import { forwardRef, useImperativeHandle, useRef } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from '@lexical/react/LexicalAutoLinkPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LexicalEditor } from 'lexical';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { URL_REGEX } from '../../../shared/constants/RegexValidations';
import MessageEditorFileList from './MessageEditorFileList';
import { IMessageReadModel } from '../../../shared/model/IMessageReadModel';
import ScrollBarWrapper from '../../../shared/components/scrolling/ScrollBarWrapper';
import { useFileDropContext } from '../../../shared/components/file/FileDropContainer';
import { MentionsPlugin } from '../../../shared/lexical/mentions/MentionsPlugin';
import { EditorRefPlugin } from '../../../shared/lexical/EditorRefPlugin';
import { MessageEditorToolbarPlugin } from './MessageEditorToolbarPlugin';
import { IMessageSubmitProps } from './useMessageSubmitCommand';
import { MARKDOWN_TRANSFORMERS, MESSAGE_EDITOR_CONFIG } from './messageEditorConfig';
import { MessageEditorReplyToMessagePlugin } from './MessageEditorReplyToMessagePlugin';
import { MessageEditorSubmitButtonPlugin } from './MessageEditorSubmitButtonPlugin';
import { MessageEditorEditButtonsPlugin } from './MessageEditorEditButtonsPlugin';
import { EmojiPlugin } from '../../../shared/lexical/emojis/EmojiPlugin';
import { ImagePlugin } from '../../../shared/lexical/images/ImagePlugin';
import { ArrowKeyNodeSelectionPlugin } from '../../../shared/lexical/ArrowKeyNodeSelectionPlugin';
import Text from '../../../shared/components/text/Text';
import { MessageEditorActionMenu, MessageEditorActionProps } from './MessageEditorActionMenu';

function Placeholder({ replyToMessage }: { replyToMessage?: IMessageReadModel }) {
  return (
    <div className="absolute bottom-2 left-4 right-4 pointer-events-none">
      <Text as="span" variant="italic" brightness="light">
        {replyToMessage ? 'Reply to message...' : 'Add a message...'}
      </Text>
    </div>
  );
}

function ButtonContainer({ children }: { children: React.ReactNode }) {
  return <div className="flex gap-2 p-2">{children}</div>;
}

interface IProps {
  conversationId: string;
  message?: IMessageReadModel;
  replyToMessage?: IMessageReadModel;
  onReplyToMessageChanged?: (message?: IMessageReadModel) => void;
  onSubmit?: (params: IMessageSubmitProps) => void;
  onCancel?: () => void;
}

export type MessageEditorRefType = {
  focus: () => void;
};

export const MessageEditor = forwardRef<MessageEditorRefType, IProps & MessageEditorActionProps>(
  (
    {
      conversationId,
      message,
      replyToMessage,
      externalMenuActions,
      onReplyToMessageChanged,
      onSubmit,
      onCancel,
    },
    ref,
  ) => {
    const fileDropContext = useFileDropContext();
    const editorRef = useRef<LexicalEditor | null>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (!editorRef.current) return;
        editorRef.current.focus();
      },
    }));

    const handleSubmit = (params: IMessageSubmitProps) => {
      if (onSubmit) onSubmit(params);
    };

    const handleCancel = () => {
      if (onCancel) onCancel();
    };

    const isEditMode = !!message;
    const initialConfig = {
      ...MESSAGE_EDITOR_CONFIG,
      editorState:
        message?.text && message.text.length > 0
          ? () => $convertFromMarkdownString(message.text, MARKDOWN_TRANSFORMERS)
          : undefined,
    };

    return (
      <LexicalComposer initialConfig={initialConfig}>
        <>
          <div className="flex flex-1 flex-row items-end max-w my-2 md:my-4">
            <ButtonContainer>
              <MessageEditorActionMenu externalMenuActions={externalMenuActions} />
            </ButtonContainer>
            <div className="flex flex-1 items-end relative pl-2 pr-3 rounded-md bg-white border shadow-sm sm:text-sm">
              <div className="flex-1 min-h-[2.5rem] overflow-y-hidden ">
                {fileDropContext && (
                  <MessageEditorFileList
                    files={fileDropContext.files}
                    onFileRemoved={fileDropContext.removeFiles}
                  />
                )}
                {replyToMessage && onReplyToMessageChanged && (
                  <MessageEditorReplyToMessagePlugin
                    replyToMessage={replyToMessage}
                    onReplyToMessageChanged={onReplyToMessageChanged}
                  />
                )}
                <ScrollBarWrapper className="max-h-96">
                  <PlainTextPlugin
                    contentEditable={
                      <ContentEditable className="h-full min-h-[2.5rem] outline-none p-2 whitespace-pre-wrap break-words text text-base" />
                    }
                    placeholder={<Placeholder replyToMessage={replyToMessage} />}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                </ScrollBarWrapper>
              </div>
              <ButtonContainer>
                <MessageEditorToolbarPlugin />
              </ButtonContainer>
            </div>

            {!isEditMode && (
              <ButtonContainer>
                <MessageEditorSubmitButtonPlugin
                  onSubmit={handleSubmit}
                  replyToMessage={replyToMessage}
                />
              </ButtonContainer>
            )}
          </div>
          {isEditMode && (
            <MessageEditorEditButtonsPlugin onSubmit={handleSubmit} onCancel={handleCancel} />
          )}
        </>
        {/* Plugins without UI */}
        <HistoryPlugin />
        <AutoLinkPlugin
          matchers={[
            createLinkMatcherWithRegExp(URL_REGEX, (text) =>
              text.startsWith('http') ? text : `https://${text}`,
            ),
          ]}
        />
        <ClearEditorPlugin />
        <MentionsPlugin conversationId={conversationId} />

        <EmojiPlugin />
        <ImagePlugin />
        <EditorRefPlugin editorRef={editorRef} />
        <ArrowKeyNodeSelectionPlugin />
      </LexicalComposer>
    );
  },
);
