import Text from '../text/Text';

export type TextSize = 'large' | 'normal' | 'small';

interface ISubHeaderProps {
  text: string;
  subText: string | React.ReactNode;
  size?: TextSize;
}
export default function HeaderSubHeader({ text, subText, size = 'normal' }: ISubHeaderProps) {
  const mappings = {
    large: (
      <>
        <Text size="large">{text}</Text>
        <Text size="normal" opacity="75" leading="snug">
          {subText}
        </Text>
      </>
    ),
    normal: (
      <>
        <Text size="normal">{text}</Text>
        <Text size="xSmall" opacity="75" leading="snug">
          {subText}
        </Text>
      </>
    ),
    small: (
      <>
        <Text size="small" weight="medium">{text}</Text>
        <Text size="xSmall" opacity="75" leading="loose">
          {subText}
        </Text>
      </>
    ),
  };

  return <div className="flex flex-col text-left">{mappings[size]}</div>;
}
