import { useQueryClient } from '@tanstack/react-query';
import { matchPath, resolvePath, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { conversationQuery } from './queries/conversationQueries';
import { contactConnectionQuery } from '../Contacts/queries/contactQueries';
import queryStringBuilder from '../../shared/utils/query-string-builder/queryStringBuilder';
import QueryParam from '../../shared/utils/query-string-builder/QueryParam';

interface IProps {
  conversationId: string;
  messageId?: string;
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function ConversationLink({
  conversationId,
  messageId,
  className,
  children,
  onClick,
}: IProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();

  const getConversation = () =>
    queryClient.fetchQuery(conversationQuery(queryClient, conversationId));
  const getContact = (connectionId: string) =>
    queryClient.fetchQuery(contactConnectionQuery(queryClient, connectionId));

  const internalOnClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const queryString = messageId
      ? queryStringBuilder().add(new QueryParam('messageId', messageId)).toQueryString()
      : '';
    const conversation = await getConversation();
    if (conversation.networkId) {
      const to = `networks/${conversation.networkId}${queryString}`;
      const replace = !!matchPath(currentPath, resolvePath(to).pathname);
      navigate(to, { replace });
    } else if (conversation.connectionId) {
      const contact = await getContact(conversation.connectionId);
      const to = `contacts/${contact.id}${queryString}`;
      const replace = !!matchPath(currentPath, resolvePath(to).pathname);
      navigate(to, { replace });
    } else {
      throw Error('Invalid link');
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) onClick(event);
    if (!event.isDefaultPrevented()) internalOnClick(event);
  };

  // "to" is set to dummy value, never used
  return (
    <Link to="conversations" onClick={handleClick} className={className}>
      {children}
    </Link>
  );
}
