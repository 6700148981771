import { IContactDto } from '../../shared/model/IContactDto';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import { IPipelineEditItemProps } from '../Pipelines/models/IPipelineEditItemProps';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';

interface IProps {
  user: IContactDto | IPersonProfileDto | undefined | null;
  pipelineEditItemProps?: IPipelineEditItemProps;
}

export default function ContactUserTabBody({ user, pipelineEditItemProps }: IProps) {
  if (!user) return <div />;
  return (
    <>
      <ProfilePropertyList profile={user} />
      {pipelineEditItemProps?.renderEditItem && <>{pipelineEditItemProps.renderEditItem}</>}
    </>
  );
}
