import { ContactProfileLink } from './ContactProfileLink';
import { useUserProfileQuery } from './queries/userProfileQueries';

interface IProps {
  userId: string;
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

// Temporary solution until tenancy migration is complete
// When we use userId everywhere we can move the actual implementation here.
export function ContactUserProfileLink({ userId, className, children, onClick }: IProps) {
  const userProfileQuery = useUserProfileQuery(userId);
  return (
    <ContactProfileLink
      personProfileId={userProfileQuery.data?.accountId || ''}
      className={className}
      onClick={onClick}
    >
      {children}
    </ContactProfileLink>
  );
}
