import { IConnectionRequestDto } from '../model/IConnectionRequestDto';
import { IContactDto } from '../model/IContactDto';
import { IMoveContactNoteDto } from '../model/IMoveContactNoteDto';
import { IContactNoteDto } from '../model/IContactNoteDto';
import { ICreateContactDto } from '../model/ICreateContactDto';
import { IShareContactRequestDto } from '../model/IShareContactRequestDto';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';
import { IPagedResult } from '../model/IPagedResult';

export const getAllContacts = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/contacts${queryString}`)
    .then<Array<IContactDto>>((response) => response.data.data);
};

export const getContact = (id: string) =>
  apiClient.get(`v1/contacts/${id}`).then<IContactDto>((response) => response.data);

export const createContact = (contact: ICreateContactDto) =>
  apiClient.post('v1/contacts', contact).then<IContactDto>((response) => response.data);

export const updateContact = (contact: IContactDto, id: string) =>
  apiClient.patch(`v1/contacts/${id}`, contact, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });

export const deleteContact = (id: string) => apiClient.delete(`v1/contacts/${id}`);

export const followContact = (personProfileId: string | undefined, contactId?: string) => {
  const queryString = queryStringBuilder()
    .add(new QueryParam('contactId', contactId))
    .toQueryString();
  return apiClient
    .put(`v1/profiles/${personProfileId}/follow${queryString}`)
    .then((response) => response.data);
};

export const connectWithPersonProfile = (requesteePersonProfileId: string | undefined) =>
  apiClient
    .post(`v1/profiles/${requesteePersonProfileId}/connect`)
    .then<IConnectionRequestDto>((response) => response.data);

export const connectWithPersonProfileDeffered =
  (requesteePersonProfileId: string | undefined) => () =>
    apiClient.post(`v1/profiles/${requesteePersonProfileId}/connect`);

export const getContactNotes = (id: string, limit?: number) => {
  const queryString = limit
    ? queryStringBuilder().add(new QueryParam('limit', limit)).toQueryString()
    : '';
  return apiClient
    .get(`v1/contacts/${id}/notes${queryString}`)
    .then<IPagedResult<IContactNoteDto>>((response) => response.data);
};

export const createContactNote = (id: string, note: IContactNoteDto) =>
  apiClient.post(`v1/contacts/${id}/notes`, note);

export const updateContactNote = (id: string, noteId: string, note: IContactNoteDto) =>
  apiClient.patch(`v1/contacts/${id}/notes/${noteId}`, note, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });

export const deleteContactNote = (id: string, noteId: string) =>
  apiClient.delete(`v1/contacts/${id}/notes/${noteId}`);

export const shareContact = (id: string, targetAccountIds: string[], externalRef?: string) =>
  apiClient
    .post(`v1/contacts/${id}/share`, { targetAccountIds, externalRef })
    .then<IShareContactRequestDto>((response) => response.data);

export const moveContactNote = (id: string, noteId: string, moveNote: IMoveContactNoteDto) =>
  apiClient.put(`v1/contacts/${id}/notes/${noteId}/move`, moveNote);
