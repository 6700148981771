import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createViewSlice, ViewsSlice } from './slices/viewSlice';
import { createDemoSlice, DemoSlice } from './slices/demoSlice';

export const useGlobalStore = create<ViewsSlice & DemoSlice>()(
  persist(
    devtools((...a) => ({
      ...createViewSlice(...a),
      // TODO: Demo slice is just here for documentation purposes
      // When we add the next slice, we can replace this with the new one
      ...createDemoSlice(...a),
    })),
    {
      name: 'global-storage',
      storage: createJSONStorage(() => sessionStorage),
      // Partialize lets us specify which part of the slices we want to persist
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => ['views'].includes(key))),
    },
  ),
);
