/* eslint-disable no-param-reassign */
import axios from 'axios';
import { msalInstance } from '../auth/authConfig';
import { baseURL } from './configuration';
import { getAccessToken } from './msalUtils';

const apiClient = axios.create({
  baseURL,
});

apiClient.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken(msalInstance);

    if (typeof config.headers !== 'undefined') {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default apiClient;
