import {
  ChatBubbleLeftIcon,
  ClipboardDocumentListIcon,
  GlobeAltIcon,
} from '@heroicons/react/24/outline';
import TabNavItem from '../tabs/TabNavItem';

interface IProps {
  activeTab: 'chat' | 'social' | 'sorted';
  onClick: (type: 'chat' | 'social' | 'sorted') => void;
}

export default function ActionHeaderSocials({ activeTab, onClick }: IProps) {
  return (
    <>
      <div className="flex justify-between whitespace-nowrap font-medium text-sm pb-1">
        <TabNavItem
          id="chat"
          activeId={activeTab}
          icon={<ChatBubbleLeftIcon className="w-5 h-5" />}
          title="Chat"
          setActiveTab={onClick}
        />

        <TabNavItem
          id="sorted"
          activeId={activeTab}
          icon={<ClipboardDocumentListIcon className="w-5 h-5" />}
          title="A-Z"
          setActiveTab={onClick}
        />

        <TabNavItem
          id="social"
          activeId={activeTab}
          icon={<GlobeAltIcon className="w-5 h-5" />}
          title="Social"
          setActiveTab={onClick}
        />
      </div>
    </>
  );
}
