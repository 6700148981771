import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router';
import IconButton from '../../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import networkDummy from '../../../assets/images/network-icon.svg';
import Text from '../../../shared/components/text/Text';
import { useNetworkQuery } from '../queries/networkQueries';

interface IProps {
  networkId: string;
}

export default function NetworkInfoHeaderMobile({ networkId }: IProps) {
  const networkQuery = useNetworkQuery(networkId);
  const network = networkQuery.data;

  const navigate = useNavigate();
  const navigateBackToRoot = () => {
    navigate('/networks', { replace: true });
  };

  const handleNavigateToProfile = () => {
    navigate({
      pathname: '.',
      search: '?tab=tab_info',
    });
  };

  return (
    <header className="flex items-center h-14 py-10">
      <IconButton
        color={ButtonColors.Gray}
        icon={<ChevronLeftIcon className="mx-2 h-8 w-8" aria-hidden="true" />}
        onClick={navigateBackToRoot}
      />
      <button
        type="button"
        onClick={handleNavigateToProfile}
        className="flex flex-1 items-center text-left"
      >
        <img src={networkDummy} alt={network?.name} className="rounded-full w-10 h-10" />

        <div className="flex flex-col mx-4 w-full">
          <div className="max-w-[240px]">
            <Text as="h3" weight="medium" truncate>
              {network?.name}
            </Text>
          </div>

          {network?.description && (
            <div className="max-w-[240px]">
              <Text as="p" size="small" brightness="light" truncate>
                {network?.description}
              </Text>
            </div>
          )}
        </div>
      </button>
    </header>
  );
}
