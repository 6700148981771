interface IProps {
  children: React.ReactNode;
}

export default function NavigationBar({ children }: IProps) {
  return (
    <nav className="flex flex-col flex-none w-80 py-4 px-6 relative overflow-y-hidden mb-4 mt-4">
      {children}
    </nav>
  );
}
