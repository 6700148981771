import ContactInfoContextMenu from '../../../features/Contacts/ContactInfoContextMenu';
import { IMenuItemActionButton } from '../../model/IMenuItemActionButton';

interface IProps {
  isActive: boolean;
  menuItemActionButtons: IMenuItemActionButton[];
}

export default function TabButtonItem({ isActive, menuItemActionButtons }: IProps) {
  return (
    <div className={`absolute right-[20px] ${isActive ? 'border-cyan-500 border-b-[2px]' : ''}`}>
      <nav className="flex -mb-px" aria-label="Tabs">
        <div className="border-transparent text hover:text-light hover:border-gray-300 whitespace-nowrap font-medium text-sm">
          <ContactInfoContextMenu menuItemActionButtons={menuItemActionButtons} />
        </div>
      </nav>
    </div>
  );
}
