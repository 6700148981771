import { IPublicClientApplication } from '@azure/msal-browser';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { baseURL } from '../utils/configuration';
import { getAccessToken } from '../utils/msalUtils';

export default function createSignalRConnection(
  msalInstance: IPublicClientApplication,
): HubConnection {
  return new HubConnectionBuilder()
    .withUrl(`${baseURL}/live`, {
      accessTokenFactory: () => getAccessToken(msalInstance),
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Error)
    .build();
}
