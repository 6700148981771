import { IMenuItemActionButton } from '../../../shared/model/IMenuItemActionButton';
import { usePipelineStoreContext } from '../context/pipeline-context';
import { usePipelinePrivilegesQuery } from '../queries/pipelinePriviligesQueries';
import MenuItemActionButton from '../../../shared/components/tabs/MenuItemActionButton';

export default function usePipelineInfoActionButtons(pipelineId: string) {
  const privilegesQuery = usePipelinePrivilegesQuery(pipelineId);
  const privileges = privilegesQuery.data;

  const { setInviteMemberModalOpen, setIsConfirmDeleteOpen, setIsConfirmLeaveOpen } =
    usePipelineStoreContext();

  const menuItemActionButtons: IMenuItemActionButton[] = [];

  if (privileges?.canInviteMember) {
    menuItemActionButtons.push({
      id: 'Invite',
      children: (
        <MenuItemActionButton text="Invite" onClick={() => setInviteMemberModalOpen(true)} />
      ),
    });
  }
  if (privileges?.canDelete) {
    menuItemActionButtons.push({
      id: 'Delete',
      children: <MenuItemActionButton text="Delete" onClick={() => setIsConfirmDeleteOpen(true)} />,
    });
  } else {
    menuItemActionButtons.push({
      id: 'Leave',
      children: <MenuItemActionButton text="Leave" onClick={() => setIsConfirmLeaveOpen(true)} />,
    });
  }

  return menuItemActionButtons;
}
