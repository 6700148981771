import { CardList, ICardListProps } from '../../shared/components/cards/CardList';
import { ProfileCard } from '../../shared/components/cards/ProfileCard';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { MessageType } from '../../shared/constants/MessageTypes';
import { ISharedContactDto } from '../../shared/model/IMessageReadModel';
import { useHasConnectionQuery } from '../Contacts/queries/connectionQueries';
import { useIsFollowingQuery } from '../Contacts/queries/contactQueries';
import { useConnectWithProfileMutation } from '../Request/queries/connectionRequestQueries';
import {
  useAcceptShareContactRequestMutation,
  useRejectShareContactRequestMutation,
  useListShareContactRequestsQuery,
} from '../Request/queries/shareContactRequestQueries';

interface ISharedContactCardProps {
  sharedContact: ISharedContactDto;
  messageType?: MessageType;
}

function SharedContactCard({ sharedContact, messageType }: ISharedContactCardProps) {
  const hasConnectionQuery = useHasConnectionQuery(sharedContact.accountId);
  const isFollowingQuery = useIsFollowingQuery(sharedContact.accountId);
  const shareContactRequestQuery = useListShareContactRequestsQuery({
    externalRef: sharedContact.messageRef,
    searchType: 'Received',
  });
  const connectWithProfileMutation = useConnectWithProfileMutation();
  const acceptShareContactRequestMutation = useAcceptShareContactRequestMutation();
  const rejectShareContactRequestMutation = useRejectShareContactRequestMutation();

  const getActions = () => {
    if (
      !shareContactRequestQuery.isSuccess ||
      !shareContactRequestQuery.data[0] ||
      shareContactRequestQuery.data[0].status !== 'Pending' ||
      !(!sharedContact.accountId || hasConnectionQuery.isSuccess) ||
      !(!sharedContact.accountId || isFollowingQuery.isSuccess)
    ) {
      return undefined;
    }

    let actions;
    if (shareContactRequestQuery.data[0].sharedPersonProfileId) {
      const {
        sharedPersonProfileId: personProfileId,
        firstName,
        lastName,
      } = shareContactRequestQuery.data[0];
      actions = [
        {
          key: 'connect',
          label: 'Connect',
          color: ButtonColors.Cyan,
          onClick: () => {
            acceptShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]);
            connectWithProfileMutation.mutate({ personProfileId, firstName, lastName });
          },
          isDisabled:
            hasConnectionQuery.data ||
            acceptShareContactRequestMutation.isPending ||
            connectWithProfileMutation.isPending ||
            (acceptShareContactRequestMutation.isSuccess && connectWithProfileMutation.isSuccess),
        },
        {
          key: 'follow',
          label: 'Follow',
          color: ButtonColors.Cyan,
          onClick: () => acceptShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]),
          isDisabled:
            isFollowingQuery.data ||
            acceptShareContactRequestMutation.isPending ||
            acceptShareContactRequestMutation.isSuccess,
        },
      ];
    } else {
      actions = [
        {
          key: 'create',
          label: 'Add contact',
          color: ButtonColors.Cyan,
          onClick: () => acceptShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]),
          isDisabled:
            acceptShareContactRequestMutation.isPending ||
            acceptShareContactRequestMutation.isSuccess,
        },
      ];
    }

    actions.push({
      key: 'ignore',
      label: 'Ignore',
      color: ButtonColors.Gray,
      onClick: () => rejectShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]),
      isDisabled:
        rejectShareContactRequestMutation.isPending || rejectShareContactRequestMutation.isSuccess,
    });

    return actions;
  };

  return (
    <ProfileCard
      profile={{ ...sharedContact }}
      actions={getActions()}
      linkToPersonProfileId={sharedContact.accountId}
      linkToContactId={messageType === MessageType.Outgoing ? sharedContact.messageRef : undefined}
      actionsPosition="below"
      width="medium"
    />
  );
}

interface IProps extends Pick<ICardListProps, 'alignHorizontal'> {
  sharedContacts: ISharedContactDto[];
  messageType?: MessageType;
}

export function MessageSharedContacts({ sharedContacts, messageType, ...cardListProps }: IProps) {
  return (
    <CardList direction="row" {...cardListProps}>
      {sharedContacts.map((sharedContact) => (
        <SharedContactCard
          key={sharedContact.messageRef}
          sharedContact={sharedContact}
          messageType={messageType}
        />
      ))}
    </CardList>
  );
}
