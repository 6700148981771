import { forwardRef, ReactNode } from 'react';

interface IProps {
  id?: string;
  className?: string;
  children: ReactNode;
}

const ScrollBarWrapper = forwardRef<HTMLDivElement, IProps>(
  ({ children, id, className = '' }: IProps, ref) => (
    <div
      ref={ref}
      id={id}
      className={`scrollbar-thin h-full hover:scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thumb-transparent z-0 ${className}`}
    >
      {children}
    </div>
  ),
);

export default ScrollBarWrapper;
