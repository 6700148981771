import { IRecommendContact } from '../model/IConnectionRequestDto';
import { IRecommendContactDto } from '../model/IRecommendContactDto';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export const recommendContact = (_recommendContact: IRecommendContactDto) =>
  apiClient.post('v1/recommendcontactrequests', _recommendContact).then((response) => response.data);

export const acceptRecommendContactRequest = (id: string) =>
  apiClient.put(`v1/recommendcontactrequests/${id}/accept`);

export const rejectRecommendContactRequest = (id: string) =>
  apiClient.put(`v1/recommendcontactrequests/${id}/reject`);

export const getRecommendedContactRequests = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/recommendcontactrequests/${queryString}`)
    .then<IRecommendContact[]>((response) => response.data.data);
};
