import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import { ICreateContactDto } from '../../shared/model/ICreateContactDto';
import TextFieldEmailInput from '../../shared/components/inputs/text/TextFieldEmailInput';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import QueryParam from '../../shared/utils/query-string-builder/QueryParam';
import { getAllProfiles } from '../../shared/services/profileService';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import {
  useCreateContactMutation,
  useFollowMutation,
  useIsFollowingQuery,
} from './queries/contactQueries';
import { useConnectWithProfileMutation } from '../Request/queries/connectionRequestQueries';
import { useCreateInvitationMutation } from '../Request/queries/invitationQueries';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { useHasConnectionQuery } from './queries/connectionQueries';
import { ProfileCard } from '../../shared/components/cards/ProfileCard';
import Text from '../../shared/components/text/Text';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import SubmitButton from '../../shared/components/buttons/SubmitButton';

interface IExistingProfileCardProps {
  profile: IPersonProfileDto;
  callback: () => void;
}

function ExistingProfileCard({ profile, callback }: IExistingProfileCardProps) {
  const hasConnectionQuery = useHasConnectionQuery(profile.id);
  const isFollowingQuery = useIsFollowingQuery(profile.id);
  const connectWithProfileMutation = useConnectWithProfileMutation();
  const followMutation = useFollowMutation();
  return (
    <ProfileCard
      profile={profile}
      as="div"
      actionsPosition="inline"
      actions={[
        {
          key: 'add',
          label: 'Add',
          color: ButtonColors.Cyan,
          isDisabled: followMutation.isPending || (isFollowingQuery.data ?? false),
          onClick: () =>
            followMutation.mutate(
              { personProfileId: profile.id, ...profile },
              { onSuccess: () => callback() },
            ),
        },
        {
          key: 'connect',
          label: 'Connect',
          color: ButtonColors.Cyan,
          isDisabled: connectWithProfileMutation.isPending || (hasConnectionQuery.data ?? false),
          onClick: () => {
            followMutation.mutate({ personProfileId: profile.id, ...profile });
            connectWithProfileMutation.mutate(
              { personProfileId: profile.id, ...profile },
              { onSuccess: () => callback() },
            );
          },
        },
      ]}
    />
  );
}

/* We are using the optional callback function for communicating with the modal via the
   parent component "Contacts". I'm sure we can find a more beautiful solution to this later */

interface IProps {
  callback?: () => void;
}

export default function ContactCreateContact({ callback }: IProps) {
  const [showUnsavedDataWarning, setShowUnsavedDataWarning] = useState(false);
  const [invokeCallbackOnSubmit, setInvokeCallbackOnSubmit] = useState(true);
  const [inviteEmailAddress, setInviteEmailAddress] = useState('');
  const [isVerifyEmailStepActive, setIsVerifyEmailStepActive] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isUserAlreadyRegistered, setIsUserAlreadyRegistered] = useState(false);
  const [existingPerson, setExistingPerson] = useState({} as IPersonProfileDto);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ICreateContactDto>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      location: '',
      company: '',
      title: '',
      additionalInformation: '',
    },
  });

  const createContactMutation = useCreateContactMutation();
  const createInvitationMutation = useCreateInvitationMutation();

  const invokeCallback = () => {
    if (typeof callback !== 'undefined') {
      callback();
    }
  };

  const onSubmit: SubmitHandler<ICreateContactDto> = (contact) => {
    createContactMutation.mutate(
      { contact },
      {
        onSuccess: () => {
          reset();
          if (invokeCallbackOnSubmit) {
            invokeCallback();
          }
        },
      },
    );
  };

  const handleContinueEditing = () => {
    setShowUnsavedDataWarning(false);
  };

  const handleContinueClosing = () => {
    reset();
    invokeCallback();
  };

  const handleInviteUser = () => {
    createInvitationMutation.mutate(
      { email: inviteEmailAddress },
      { onSuccess: () => handleContinueClosing() },
    );
  };

  const handleCancelSaveContact = () => {
    if (isDirty) {
      setShowUnsavedDataWarning(true);
    } else {
      reset();
      invokeCallback();
    }
  };

  const validateEmail = (email: string) => {
    if (email.match(REGEX_EMAIL)) {
      return true;
    }

    return false;
  };

  const handleSetInviteEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmailAddress(e.target.value);

    if (validateEmail(e.target.value)) {
      getAllProfiles(new QueryParam('searchTerm', e.target.value), new QueryParam('Limit', 1)).then(
        (result) => {
          if (result.data.length === 1) {
            setIsEmailVerified(true);
            setIsUserAlreadyRegistered(true);
            setExistingPerson(result.data[0]);
          } else {
            setIsEmailVerified(true);
            setIsUserAlreadyRegistered(false);
          }
        },
      );
    } else {
      setIsEmailVerified(false);
      setIsUserAlreadyRegistered(false);
    }
  };

  const handleGoToCreateStep = () => {
    setIsVerifyEmailStepActive(false);
    setValue('emailAddress', inviteEmailAddress);
  };

  return (
    <>
      {isVerifyEmailStepActive ? (
        <div className="text-left">
          <div>
            <TextFieldEmailInput
              text={inviteEmailAddress}
              label="Find contact"
              onChange={handleSetInviteEmailAddress}
            />
            <Text as="p" size="small" color="normal" brightness="light" leading="normal">
              {!isUserAlreadyRegistered ? (
                <>
                  {!isEmailVerified ? (
                    <>Enter an email to see if the contact exist in Yoin.</>
                  ) : (
                    <>
                      The user does not exist in Yoin. You can invite the user below or create a
                      contact manually.
                    </>
                  )}
                </>
              ) : (
                <>{`The user ${existingPerson.firstName} ${existingPerson.lastName} exists.`}</>
              )}
            </Text>

            {isEmailVerified && isUserAlreadyRegistered && (
              <ExistingProfileCard profile={existingPerson} callback={handleContinueClosing} />
            )}
            <div className="mt-4">
              <ButtonGroup>
                <Button color={ButtonColors.White} text="Cancel" onClick={handleContinueClosing} />
                <Button
                  color={ButtonColors.Cyan}
                  text="Send invitation"
                  onClick={handleInviteUser}
                  isDisabled={
                    !isEmailVerified ||
                    isUserAlreadyRegistered ||
                    createInvitationMutation.isPending
                  }
                />
                <Button
                  color={ButtonColors.White}
                  text="Create new contact"
                  onClick={handleGoToCreateStep}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="text-left space-y-8">
          <div>
            <Text as="h1" size="xLarge" weight="medium">
              Personal Information
            </Text>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="First Name"
                  name="firstName"
                  type="text"
                  register={register}
                  errors={errors}
                  errorMessage="Required and between 2 and 50 characters"
                  validationRules={{ required: true, minLength: 2, maxLength: 50 }}
                />
              </div>
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Last Name"
                  name="lastName"
                  type="text"
                  register={register}
                  errors={errors}
                  errorMessage="Required and between 2 and 50 characters"
                  validationRules={{ required: true, minLength: 2, maxLength: 50 }}
                />
              </div>

              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Email"
                  name="emailAddress"
                  type="text"
                  register={register}
                  errors={errors}
                  errorMessage="Must be a valid email"
                  validationRules={{ pattern: REGEX_EMAIL }}
                />
              </div>
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Phone Number"
                  name="phoneNumber"
                  type="text"
                  register={register}
                />
              </div>
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Location"
                  name="location"
                  type="text"
                  register={register}
                />
              </div>
              <div className="sm:col-span-3" />
              <div className="sm:col-span-3">
                <TextFieldInput labelText="Title" name="title" type="text" register={register} />
              </div>
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Company"
                  name="company"
                  type="text"
                  register={register}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <div className="mt-1">
                  <TextAreaInput
                    name="additionalInformation"
                    register={register}
                    labelText="Additional Information"
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end gap-x-3">
                <Button
                  color={ButtonColors.White}
                  text="Cancel"
                  onClick={handleCancelSaveContact}
                />
                <SubmitButton
                  text="Create"
                  isDisabled={!isValid || createContactMutation.isPending}
                  onClick={() => setInvokeCallbackOnSubmit(true)}
                />
              </div>
            </div>
          </div>
        </form>
      )}

      <ConfirmDialog isOpen={showUnsavedDataWarning} title="You have unsaved data">
        <Text as="p"> Your changes will be lost if you leave</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Cyan} text="Leave" onClick={handleContinueClosing} />
          <Button
            color={ButtonColors.White}
            text="Continue Editing"
            onClick={handleContinueEditing}
          />
        </ButtonGroup>
      </ConfirmDialog>
    </>
  );
}
