import { SubmitHandler, useForm } from 'react-hook-form';
import Modal from '../modal/Modal';
import SubmitButton from '../buttons/SubmitButton';
import TextFieldInput from '../inputs/text/TextFieldInput';
import { IConversationAttachmentDto } from '../../model/IConversationAttachmentDto';
import ButtonGroup from '../buttons/ButtonGroup';
import { ButtonColors } from '../../constants/ButtonColors';
import Button from '../buttons/Button';

interface IRenameForm {
  name: string;
}

interface IProps {
  attachment: IConversationAttachmentDto;
  isOpen: boolean;
  onCloseCallback: (attachment: IConversationAttachmentDto, newName?: string) => void;
}

export default function RenameAttachmentModal({ attachment, isOpen, onCloseCallback }: IProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IRenameForm>({
    mode: 'onChange',
    defaultValues: {
      name: attachment.name ?? '',
    },
  });

  const onClose = () => {
    reset();
    onCloseCallback(attachment);
  };

  const onSubmit: SubmitHandler<IRenameForm> = (form) => {
    onCloseCallback(attachment, form.name);
  };

  return (
    <Modal isOpen={isOpen} showCloseButton onCloseClick={() => onClose()}>
      <form onSubmit={handleSubmit(onSubmit)} className="text-left space-y-8">
        <div>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Name is required and cannot be exceed 255 characters"
            validationRules={{ required: true, maxLength: 255 }}
          />
        </div>
        <ButtonGroup>
          <Button color={ButtonColors.White} text="Cancel" onClick={onClose} />
          <SubmitButton text="Rename" isDisabled={!isValid} />
        </ButtonGroup>
      </form>
    </Modal>
  );
}
