/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEventHandler } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ButtonColors } from '../../constants/ButtonColors';
import { useButtonStyle } from './styles/useButtonStyle';

interface IProps {
  text: string;
  isDisabled: boolean;
  onClick?: MouseEventHandler | SubmitHandler<any>;
}

export default function SubmitButton({ text, isDisabled, onClick }: IProps) {
  const buttonStyleProps = useButtonStyle({ color: ButtonColors.Cyan });
  return (
    <input
      type="submit"
      value={text}
      disabled={isDisabled}
      {...buttonStyleProps}
      onClick={onClick}
    />
  );
}
