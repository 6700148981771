import { MouseEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainPanelDesktop from '../../shared/components/page/MainPanelDesktop';
import MainPanelMobile from '../../shared/components/page/MainPanelMobile';
import Spinner from '../../shared/components/loading/Spinner';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import ContactInfoHeaderMobile from './mobile/ContactInfoHeaderMobile';
import ContactExternalProfileInformation from './ContactExternalProfileInformation';
import ContactExternalProfileInfoTabsMobile from './mobile/ContactExternalProfileInfoTabsMobile';
import { usePersonProfileQuery } from './queries/personProfileQueries';
import {
  useConnectionIsInitatedQuery,
  useConnectWithProfileMutation,
} from '../Request/queries/connectionRequestQueries';
import { useHasConnectionQuery } from './queries/connectionQueries';
import { useFollowMutation, useIsFollowingQuery } from './queries/contactQueries';
import { useIsMe } from '../../shared/hooks/accountHooks';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';

export default function ContactExternalProfile() {
  const [isProfileYou, setIsProfileYou] = useState(true);

  const params = useParams();

  const isMe = useIsMe();
  const currentProfile = usePersonProfileQuery(params.id);
  const isConnectionInitiated = useConnectionIsInitatedQuery(params.id);
  const isRequestAccepted = useHasConnectionQuery(params.id);
  const isFollowing = useIsFollowingQuery(params.id);

  const connectWithPersonProfile = useConnectWithProfileMutation();
  const followPersonProfile = useFollowMutation();

  useEffect(() => {
    if (!currentProfile.data) return;

    setIsProfileYou(isMe(currentProfile.data.id));
  }, [currentProfile.data, isMe]);

  const handleFollowContact: MouseEventHandler<Element> = () => {
    if (!currentProfile.data) return;
    const _profile = currentProfile.data;
    followPersonProfile.mutate({
      personProfileId: _profile.id,
      firstName: _profile.firstName,
      lastName: _profile.lastName,
    });
  };

  const handleCreateConnectionRequest: MouseEventHandler<Element> = () => {
    if (!currentProfile.data) return;
    const _profile = currentProfile.data;
    connectWithPersonProfile.mutate({
      personProfileId: _profile.id,
      firstName: _profile.firstName,
      lastName: _profile.lastName,
    });
  };

  if (
    currentProfile.isLoading ||
    isConnectionInitiated.isLoading ||
    isRequestAccepted.isLoading ||
    isFollowing.isLoading
  ) {
    return (
      <div className="flex justify-center justify-items-center mt-20 w-full h-full">
        <Spinner />
      </div>
    );
  }

  if (
    currentProfile.isSuccess &&
    isConnectionInitiated.isSuccess &&
    isRequestAccepted.isSuccess &&
    isFollowing.isSuccess
  ) {
    return (
      <>
        <WrapperDesktop>
          <MainPanelDesktop>
            <ContactExternalProfileInformation
              profile={currentProfile.data}
              isProfileYou={isProfileYou}
              isConnectionInitiated={isConnectionInitiated.data}
              isConnectionAccepted={isRequestAccepted.data}
              isFollowing={isFollowing.data}
              onFollowContact={handleFollowContact}
              onCreateConnectionRequest={handleCreateConnectionRequest}
            />
          </MainPanelDesktop>
        </WrapperDesktop>
        <WrapperMobile>
          <MainPanelMobile>
            <ContactInfoHeaderMobile user={currentProfile.data} />
            <ContactExternalProfileInfoTabsMobile
              profile={currentProfile.data}
              isConnectionInitiated={isConnectionInitiated.data}
              isConnectionAccepted={isRequestAccepted.data}
              isFollowing={isFollowing.data}
              onFollowContact={handleFollowContact}
              onCreateConnectionRequest={handleCreateConnectionRequest}
            />
          </MainPanelMobile>
        </WrapperMobile>
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[currentProfile, isConnectionInitiated, isRequestAccepted, isFollowing]}
      defaultErrorTexts={[{ code: 404, text: 'The profile could not be found' }]}
    />
  );
}
