export type ResourceType = 'contact' | 'contact-passive' | 'network' | 'pipeline';

export const stringToResourceTypeMapping: Record<string, ResourceType> = {
  Contact: 'contact-passive',
  Network: 'network',
  Pipeline: 'pipeline',
};

export type ColorSetting = {
  textColor: string;
  backgroundColor: string;
};

const useColorSetting = (resourceType?: ResourceType): ColorSetting => {
  switch (resourceType) {
    case 'contact-passive':
      return { backgroundColor: 'bg-yellow', textColor: 'text-white' };
    case 'contact':
      return { backgroundColor: 'bg-green', textColor: 'text-white' };
    case 'network':
      return { backgroundColor: 'bg-purple', textColor: 'text-white' };
    case 'pipeline':
      return { backgroundColor: 'bg-cyan', textColor: 'text-white' };
    default:
      return { backgroundColor: 'bg-white', textColor: '!text-cyan-dark' };
  }
};

export default useColorSetting;
