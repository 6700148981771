import { ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { BellIcon } from '@heroicons/react/24/solid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import Button from '../../shared/components/buttons/Button';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import getProfileImageUrl from '../../shared/services/profileImageService';
import { ContactProfileLink } from '../Contacts/ContactProfileLink';
import ProfileAvatar from '../Profile/ProfileAvatar';
import { ResourceLink } from './ResourceLink';
import Request from '../Request/Request';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { NotificationResponseType } from '../../shared/constants/NotificationConstants';
import Text from '../../shared/components/text/Text';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Switch from '../../shared/components/switch/Switch';

interface IProps {
  notifications: INotificationDto[];
  hasNextPage: boolean | undefined;
  notificationType?: string;
  hasResponse?: boolean;
  closeCallback: () => void;
  fetchNextPage: () => void;
  onExecuteAction: (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => void;
}

export default function NotificationsTray({
  notifications,
  hasNextPage,
  notificationType = 'Request',
  hasResponse = false,
  closeCallback,
  fetchNextPage,
  onExecuteAction,
}: IProps) {
  const [activeTabId, setActiveTabId] = useState<string>('notifications');
  const [notificationFilter, setNotificationFilter] = useState<string>('all');

  const handleExecuteAction = (notification: INotificationDto, action: Action) =>
    onExecuteAction(action.execute(notification.id), notification, action);

  const handleAnswerSwitchChange = (value: string) => {
    setNotificationFilter(value);
  };

  return (
    <div className="flex flex-col h-full md:w-[30rem] whitespace-nowrap bg-white md:p-6 rounded-lg border">
      <div className="flex md:absolute items-end justify-end md:top-5 md:right-0 h-12 md:h-min pr-6 md:pr-4">
        <button
          type="button"
          className="bg-white rounded-md text hover:text-light focus:outline-none"
          onClick={closeCallback}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <TabHeaderWrapper>
        <TabNavItem
          id="notifications"
          title="Notifications"
          icon={<BellIcon className="h-5 w-5" />}
          activeId={activeTabId}
          setActiveTab={setActiveTabId}
        />
        <TabNavItem
          id="requests"
          title="Pending requests"
          icon={<ClockIcon className="w-5 h-5 stroke-2" />}
          activeId={activeTabId}
          setActiveTab={setActiveTabId}
        />
      </TabHeaderWrapper>
      <TabContentWrapper>
        <TabContent id="notifications" activeTabId={activeTabId}>
          <div className="flex flex-col gap-4 h-full">
            <div className="ml-auto">
              <Switch
                items={[
                  { label: 'All', value: 'all' },
                  { label: 'Unanswered', value: 'unanswered' },
                ]}
                onChange={handleAnswerSwitchChange}
              />
            </div>
            <ScrollBarWrapper
              id="notifications-scrollbar"
              className="flex-1 divide-y divide-solid divide-gray-300"
            >
              <InfiniteScroll
                dataLength={notifications.length}
                next={fetchNextPage}
                hasMore={hasNextPage ?? false}
                scrollableTarget="notifications-scrollbar"
                loader={
                  <div className="py-4 text-center">
                    <Text as="p" size="small">
                      Loading...
                    </Text>
                  </div>
                }
              >
                {notifications.length === 0 && (
                  <Text as="p" brightness="light">
                    Currently no notifications
                  </Text>
                )}
                <div className="flex-1 overflow-y-auto">
                  {notifications
                    .filter((notification) => {
                      if (notificationFilter === 'unanswered') {
                        return (
                          notification.type === notificationType &&
                          !notification.isResponded === !hasResponse
                        );
                      }
                      return notifications;
                    })
                    .map((notification) => (
                      <div
                        key={notification.id}
                        className="relative flex items-start space-x-3 py-4"
                      >
                        <div className="flex-shrink-1">
                          <ContactProfileLink personProfileId={notification.payload['source.id']}>
                            <ProfileAvatar
                              avatarProps={{
                                src: getProfileImageUrl(notification.payload['source.id']),
                                alt: notification?.message,
                                widthClass: 'w-10',
                                heightClass: 'h-10',
                              }}
                            />
                          </ContactProfileLink>
                        </div>
                        <div className="min-w-0 flex-1 flex flex-col">
                          {!notification.isRead && (
                            <div className="rounded-full h-2 w-2 bg-cyan absolute right-0 top-5" />
                          )}

                          <Text as="span" size="large" weight="medium">
                            <ContactProfileLink personProfileId={notification.payload['source.id']}>
                              {notification.payload['source.displayName']}
                            </ContactProfileLink>
                          </Text>
                          <HorizontalDivider distance="small" />
                          <Text as="span" size="xSmall" brightness="light">
                            <TimeAgo date={notification?.createdTime} />
                          </Text>
                          <div className="bg-gray-light text p-2 my-2 rounded-lg whitespace-normal">
                            <Text as="p" leading="snug" size="small">
                              {notification.message}
                            </Text>
                            {notification.type === 'Request' &&
                              notification.isResponded &&
                              notification.response === 'Accept' && (
                                <Text as="p" leading="snug" size="small">
                                  You accepted this request.
                                </Text>
                              )}
                            { notification.type === 'Request' &&
                              notification.isResponded &&
                              notification.response === 'Reject' && (
                                <Text as="p" leading="snug" size="small">
                                  You reject this request.
                                </Text>
                              )}

                            {notification.resourceLink && (
                              <>
                                <HorizontalDivider distance="small" />
                                <Text as="p" leading="snug" size="small">
                                  <ResourceLink
                                    onClick={closeCallback}
                                    {...notification.resourceLink}
                                  />
                                </Text>
                              </>
                            )}
                          </div>
                          {!notification.isResponded &&
                            notification.actions &&
                            notification.actions.length > 0 && (
                              <ButtonGroup>
                                {notification.actions.map((action) => (
                                  <Button
                                    color={
                                      action.type === NotificationResponseType.Accept
                                        ? ButtonColors.Cyan
                                        : ButtonColors.White
                                    }
                                    text={action.text}
                                    isDisabled={notification.isResponded}
                                    onClick={() => handleExecuteAction(notification, action)}
                                  />
                                ))}
                              </ButtonGroup>
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              </InfiniteScroll>
            </ScrollBarWrapper>
          </div>
        </TabContent>
        <TabContent id="requests" activeTabId={activeTabId}>
          <Request />
        </TabContent>
      </TabContentWrapper>
    </div>
  );
}
