import { XMarkIcon } from '@heroicons/react/24/solid';
import IconButton from '../../../shared/components/buttons/IconButton';
import { IMessageReadModel } from '../../../shared/model/IMessageReadModel';
import { useLexicalMarkdownContent } from '../../../shared/lexical/markdown/useLexicalMarkdownContent';
import { useStandaloneEditor } from '../../../shared/lexical/useStandaloneEditor';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import { MESSAGE_EDITOR_CONFIG } from './messageEditorConfig';
import Text from '../../../shared/components/text/Text';

interface IReplyToMessageProps {
  replyToMessage: IMessageReadModel;
  onReplyToMessageChanged: (message?: IMessageReadModel) => void;
}

export function MessageEditorReplyToMessagePlugin({
  replyToMessage,
  onReplyToMessageChanged,
}: IReplyToMessageProps) {
  const { editor } = useStandaloneEditor(MESSAGE_EDITOR_CONFIG);
  const content = useLexicalMarkdownContent({
    markdown: replyToMessage.text,
    editor,
    asPlainText: true,
    maxLength: 400,
  });
  return (
    <div className="border-l-4 border pl-2 my-2">
      <span className="flex gap-1">
        <Text as="span" size="small" variant="italic">
          Replying to:{' '}
        </Text>
        <div className="flex gap-1">
          <Text as="span" size="small">
            {replyToMessage.displayName}{' '}
          </Text>
          <IconButton
            onClick={() => {
              onReplyToMessageChanged(undefined);
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            icon={<XMarkIcon className="w-3.5 h-3.5 mt-0.5" />}
            color={ButtonColors.Red}
          />
        </div>
      </span>

      <Text as="span" size="xSmall" brightness="light">
        {content}
      </Text>
    </div>
  );
}
