import { useState } from 'react';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SearchBar from '../../shared/components/search/SearchBar';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import useDebounce from '../../shared/hooks/useDebounce';
import { useChatContactsQuery } from '../Contacts/queries/contactQueries';
import SelectList from '../../shared/components/lists/SelectList';
import { IContactDto } from '../../shared/model/IContactDto';
import Header2 from '../../shared/components/headers/Header2';
import { ResourceCallback } from './PipelineCreateItem';

interface IPipelineCreateContactItemProps {
  onSelect: (getResourceInfo: ResourceCallback) => void;
}

// TODO: Should be consolidated with PipelineCreateNetworkItem as they are very similar
export default function PipelineCreateContactItem({ onSelect }: IPipelineCreateContactItemProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);
  // TODO: Fix a more slimmed query without conversation information
  const contactsQuery = useChatContactsQuery(debouncedSearchTerm);

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleSelectContact = (contact: IContactDto) =>
    onSelect(() =>
      Promise.resolve({ resourceId: contact.id, name: `${contact.firstName} ${contact.lastName}` }),
    );

  if (!contactsQuery.isSuccess) return null;

  return (
    <SpacingContainer>
      <Header2>Contact</Header2>
      <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
      <ScrollBarWrapper className="max-h-40">
        <SelectList
          data={contactsQuery.data ?? []}
          isLoading={contactsQuery.isLoading}
          itemIdProperty={({ id }) => id}
          itemHeadlineProperty={({ firstName, lastName }) => `${firstName} ${lastName}`}
          itemInformationProperty={({ company }) => company}
          itemImgSrcProperty={({ photoUrl }) => photoUrl}
          onSelectItem={handleSelectContact}
        />
      </ScrollBarWrapper>
    </SpacingContainer>
  );
}
