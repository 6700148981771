import { ToastType } from '../components/toasts/constants/ToastTypes';
import { ToastActionTypes, ToastActions } from '../context/reducers';
import { DefaultErrorText, getApiErrorMessage } from '../utils/apiClientUtils';

export const createToast = (
  title: string,
  type: ToastType,
  message: string,
  autoClose = true,
  delay = 4000,
): ToastActions => ({
  type: ToastActionTypes.Create,
  payload: { title, type, message, delay, autoClose },
});

export const deleteToast = (id: number): ToastActions => ({
  type: ToastActionTypes.Delete,
  payload: { id },
});

export const createErrorToast = (error: unknown, defaultErrorTexts?: DefaultErrorText[]) => {
  const message = getApiErrorMessage(error, defaultErrorTexts);
  return createToast('Error', ToastType.Error, message || 'Unknown error');
};
