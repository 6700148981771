import { Link } from 'react-router-dom';
import { IResourceLink, ResourceType } from '../../shared/model/INotificationDto';
import { ContactProfileLink } from '../Contacts/ContactProfileLink';
import { ConversationLink } from '../Messaging/ConversationLink';

interface IProps extends IResourceLink {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function ResourceLink({ type, id, url, text, onClick }: IProps) {
  const _text = text || 'Go to resource';
  const className = 'anchor-light';
  if (type === ResourceType.Profile) {
    return (
      <ContactProfileLink onClick={onClick} personProfileId={id} className={className}>
        {_text}
      </ContactProfileLink>
    );
  }
  if (type === ResourceType.Conversation) {
    return (
      <ConversationLink onClick={onClick} conversationId={id} className={className}>
        {_text}
      </ConversationLink>
    );
  }

  return (
    <Link onClick={onClick} to={url} className={className}>
      {_text}
    </Link>
  );
}
