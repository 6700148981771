import { ButtonColors } from '../../constants/ButtonColors';
import { useButtonStyle } from './styles/useButtonStyle';

interface IProps {
  text: string;
  isDisabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

export default function UploadButton({ text, isDisabled = false, onChange }: IProps) {
  const disabledClassName = isDisabled ? 'opacity-30 pointer-events-none cursor-not-allowed' : '';
  const buttonStyleProps = useButtonStyle({
    color: ButtonColors.Cyan,
    customClassName: disabledClassName,
  });
  return (
    <label {...buttonStyleProps} htmlFor="uploadFile">
      {text}
      <input
        type="file"
        accept="image/*"
        id="uploadFile"
        name="uploadFile"
        onChange={onChange}
        className="hidden"
      />
    </label>
  );
}
