import { StateCreator } from 'zustand';
import { DemoSlice } from './demoSlice';

export type ViewName = 'contact' | 'network' | 'pipeline' | 'settings';

export interface IView {
  name: ViewName;
  isSidePanelOpen: boolean;
}

const initialViews: IView[] = [
  {
    name: 'contact',
    isSidePanelOpen: true,
  },
  {
    name: 'network',
    isSidePanelOpen: true,
  },
  {
    name: 'pipeline',
    isSidePanelOpen: true,
  },
];

export interface ViewsSlice {
  views: IView[];
  setSidePanelOpen: (name: ViewName, isOpen: boolean) => void;
  toggleSidePanel: (name: ViewName) => void;
  getViewSettings: (name: ViewName) => IView;
}

export const createViewSlice: StateCreator<
  ViewsSlice & DemoSlice,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  ViewsSlice
> = (set, get) => ({
  views: initialViews,
  setSidePanelOpen: (name, isOpen) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            isSidePanelOpen: isOpen,
          };
        }
        return view;
      }),
    })),
  toggleSidePanel: (name) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            isSidePanelOpen: !view.isSidePanelOpen,
          };
        }
        return view;
      }),
    })),
  getViewSettings: (name) => get().views.find((view) => view.name === name) as IView,
});
