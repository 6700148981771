import { ChangeEvent, useMemo, useState } from 'react';
import Button from '../../shared/components/buttons/Button';
import SelectListMultiSelect from '../../shared/components/lists/SelectListMultiSelect';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SearchBar from '../../shared/components/search/SearchBar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import useDebounce from '../../shared/hooks/useDebounce';
import { IContactDto } from '../../shared/model/IContactDto';
import { useContactsForShareQuery } from './queries/contactQueries';
import {
  useShareContactMutation,
  useListShareContactRequestsQuery,
} from '../Request/queries/shareContactRequestQueries';
import { ShareContactRequestStatuses } from '../../shared/model/IShareContactRequestDto';
import { TagColor } from '../../shared/components/tags/Tag';
import { ContactSharedContactsInfo } from './ContactSharedContactsInfo';
import Switch from '../../shared/components/switch/Switch';
import { mergeContactsAndShareRequests } from './mergecontacts/MergeContactsAndShareRequests';
import HowTo from '../../shared/components/message-popups/HowTo';

const statusColorMap: Record<ShareContactRequestStatuses, TagColor> = {
  Accepted: 'green',
  Pending: 'yellow',
  Rejected: 'red',
};

interface IProps {
  contact: IContactDto;
}

export default function ContactShareContact({ contact }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<
    ReturnType<typeof mergeContactsAndShareRequests>
  >([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const contactsQuery = useContactsForShareQuery(debouncedSearchTerm, contact.id);
  const shareContactRequestsQuery = useListShareContactRequestsQuery({
    externalRef: contact.id,
    searchType: 'Sent',
    status: [],
  });
  const shareContactMutation = useShareContactMutation();

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleShareContact = () => {
    selectedContacts.forEach((selectedContact) => {
      shareContactMutation.mutate({
        contactId: contact.id,
        externalRef: contact.id,
        targetAccountIds: [selectedContact.personProfileId as string],
      });
    });
  };

  const contactsWithShareStatus = useMemo(() => {
    if (!contactsQuery.isSuccess || !shareContactRequestsQuery.isSuccess) {
      return [];
    }

    return mergeContactsAndShareRequests(contactsQuery.data, shareContactRequestsQuery.data);
  }, [
    contactsQuery.data,
    shareContactRequestsQuery.data,
    contactsQuery.isSuccess,
    shareContactRequestsQuery.isSuccess,
  ]);

  const [selectedOption, setSelectedOption] = useState('shareContactTo');

  const handleSwitchChange = (selectedValue: string) => {
    setSelectedOption(selectedValue);
  };

  return (
    <div className="flex flex-col h-full ">
      {contact.connectionId && (
        <div className="ml-auto py-2 ">
          <Switch
            items={[
              { label: 'Share this contact', value: 'shareContactTo' },
              { label: `Share contacts to ${contact.firstName}`, value: 'sharedContacts' },
            ]}
            onChange={handleSwitchChange}
          />
        </div>
      )}
      {selectedOption === 'sharedContacts' && <ContactSharedContactsInfo contact={contact} />}

      {selectedOption === 'shareContactTo' && (
        <ScrollBarWrapper className="flex flex-col ">
          <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
          <SelectListMultiSelect
            data={contactsWithShareStatus}
            isLoading={contactsQuery.isPending || shareContactRequestsQuery.isPending}
            onSelectItems={setSelectedContacts}
            selectedItems={selectedContacts}
            itemIdProperty={({ id }) => id}
            itemHeadlineProperty={({ firstName, lastName }) => `${firstName} ${lastName}`}
            itemInformationProperty={({ emailAddress }) => emailAddress}
            itemImgSrcProperty={({ photoUrl }) => photoUrl}
            itemTagsProperty={({ shareInformation }) =>
              shareInformation
                ? [
                    {
                      title: shareInformation.status,
                      color: statusColorMap[shareInformation.status],
                    },
                  ]
                : []
            }
          />

          {!contactsQuery.isPending &&
            !contactsQuery.isError &&
            !shareContactRequestsQuery.isPending &&
            !shareContactRequestsQuery.isError && (
              <div className="mt-auto gap-2 flex">
                <Button
                  color={ButtonColors.Cyan}
                  text={`Share ${contact.firstName} to ${selectedContacts.length} contact(s)`}
                  onClick={handleShareContact}
                  isDisabled={selectedContacts.length === 0 || shareContactMutation.isPending}
                  isFullWidth
                />
                <HowTo
                  title="Share"
                  description="Share this contact to on or more of your connections. You can share the contact to multiple connections at once."
                />
              </div>
            )}
        </ScrollBarWrapper>
      )}
    </div>
  );
}
