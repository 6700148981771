import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../../../shared/services/userService';

const userProfileKeys = {
  all: ['user-profile'] as const,
  details: () => [...userProfileKeys.all, 'detail'] as const,
  detail: (id: string) => [...userProfileKeys.details(), id] as const,
};

const STALE_TIME = 10 * 60 * 1000;

export function userProfileQueryBase(userId: string) {
  return {
    queryKey: userProfileKeys.detail(userId),
    enabled: !!userId,
    queryFn: () => getUserProfile(userId),
    staleTime: STALE_TIME,
  };
}

export function useUserProfileQuery(userId: string) {
  return useQuery(userProfileQueryBase(userId));
}
