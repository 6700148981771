import { useState } from 'react';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import {
  useCreateUpdateNetworkMutation,
  useListMyNetworksQuery,
} from '../Networks/queries/networkQueries';
import Button from '../../shared/components/buttons/Button';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SelectList from '../../shared/components/lists/SelectList';
import { ICreateUpdateNetworkDto, INetworkDto } from '../../shared/model/INetwork';
import SearchBar from '../../shared/components/search/SearchBar';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import useDebounce from '../../shared/hooks/useDebounce';
import Header2 from '../../shared/components/headers/Header2';
import { ResourceCallback } from './PipelineCreateItem';
import { NetworkCreateEditForm } from '../Networks/NetworkCreateEditForm';

interface IPipelineCreateNetworkItemProps {
  onSelect: (getResourceInfo: ResourceCallback) => void;
}

type ViewType = 'Select' | 'Create';

// TODO: Should be consolidated with PipelineCreateContactItem as they are very similar
export default function PipelineCreateNetworkItem({ onSelect }: IPipelineCreateNetworkItemProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [viewState, setViewState] = useState<ViewType>('Select');

  const debouncedSearchTerm = useDebounce(searchTerm);

  const {
    data: networks,
    isLoading: isNetworksLoading,
    isSuccess,
  } = useListMyNetworksQuery(debouncedSearchTerm);
  const createUpdateNetworkMutation = useCreateUpdateNetworkMutation();

  const handleSelectNetwork = (network: INetworkDto) =>
    onSelect(() => Promise.resolve({ resourceId: network.id, name: network.name }));

  const handleCreateNetwork = (network: ICreateUpdateNetworkDto) =>
    onSelect(async () => {
      const result = await createUpdateNetworkMutation.mutateAsync({ network });
      return { resourceId: result.id, name: result.name };
    });

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  if (!isSuccess) return null;

  return (
    <>
      {viewState === 'Create' ? (
        <NetworkCreateEditForm
          submitButtonText="Next"
          cancelButtonText="Back"
          onSubmit={handleCreateNetwork}
          onCancel={() => setViewState('Select')}
        />
      ) : (
        <SpacingContainer>
          <div className="flex flex-row justify-between items-center">
            <Header2>Network</Header2>
            <Button
              color={ButtonColors.White}
              text="Create new"
              onClick={() => setViewState('Create')}
            />
          </div>
          <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
          <ScrollBarWrapper className="max-h-60">
            <SelectList
              data={networks ?? []}
              isLoading={isNetworksLoading}
              itemIdProperty={({ id }) => id}
              itemHeadlineProperty={({ name }) => name}
              itemInformationProperty={({ description }) => description}
              onSelectItem={handleSelectNetwork}
              itemImgSrcProperty={() => ''}
            />
          </ScrollBarWrapper>
        </SpacingContainer>
      )}
    </>
  );
}
