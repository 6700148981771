import { forwardRef } from 'react';
import { SubmitHandler } from 'react-hook-form';

interface IProps {
  text: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  onClick: React.MouseEventHandler<Element> | SubmitHandler<any>;
}

const MenuItemActionButton = forwardRef<HTMLButtonElement, IProps>(
  ({ text, disabled, icon, onClick }: IProps, ref) => (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${
        icon ? 'flex flex-row px-1' : 'block px-4'
      } py-2 text-left text-sm text hover:bg-gray-light w-full disabled:text-disabled`}
    >
      {icon && <span className="pr-2">{icon}</span>}
      {text}
    </button>
  ),
);

export default MenuItemActionButton;
