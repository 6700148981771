import SidePanelHeadline from '../../shared/components/page/SidePanelHeadline';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import useColorSetting from '../../shared/hooks/useColorSetting';
import { IContactDto } from '../../shared/model/IContactDto';
import { IConversationReadModel } from '../../shared/model/IConversationReadModel';
import { useConnectionConversationQuery } from '../Messaging/queries/conversationQueries';
import { useMessagesQuery } from '../Messaging/queries/messageQueries';
import { IPipelineEditItemProps } from '../Pipelines/models/IPipelineEditItemProps';

import ContactInfoTabs from './ContactInfoTabs';
import ContactInfoTabsMobile from './mobile/ContactInfoTabsMobile';

interface IProps {
  contact: IContactDto;
  isConnectionInitiated?: boolean;
  pipelineEditItemProps?: IPipelineEditItemProps;
}

export default function ContactInfo({
  contact,
  isConnectionInitiated,
  pipelineEditItemProps,
}: IProps) {
  const colorSetting = contact?.connectionId
    ? useColorSetting('contact')
    : useColorSetting('contact-passive');

  const conversationQuery = useConnectionConversationQuery(contact.connectionId);
  const conversation: IConversationReadModel | undefined = conversationQuery.data;
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);

  return (
    <>
      <WrapperDesktop>
        <SidePanelHeadline
          headline={`${contact?.firstName} ${contact?.lastName}`}
          subHeadline={contact?.emailAddress}
          imageType="Contact"
          imageSrc={contact?.photoUrl}
          colorSetting={colorSetting}
          resourceId={contact.id}
          pipelineEditItemProps={pipelineEditItemProps}
        />
        <ContactInfoTabs
          contact={contact}
          conversation={conversation}
          pipelineEditItemProps={pipelineEditItemProps}
        />
      </WrapperDesktop>
      <WrapperMobile>
        <ContactInfoTabsMobile
          contact={contact}
          messages={messagesQuery}
          conversation={conversation}
          isConnectionInitiated={isConnectionInitiated}
        />
      </WrapperMobile>
    </>
  );
}
