import RequestItem from './RequestItem';
import RequestItemsList from './RequestItemsList';
import { IInvitationDto } from '../../shared/model/IInvitationDto';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useResendInvitationMutation } from './queries/invitationQueries';

interface IProps {
  invitations: IInvitationDto[];
}

export default function Invitations({ invitations }: IProps) {
  const resendInvitationMutation = useResendInvitationMutation();

  function handleResendInvitation(invitationId: string) {
    resendInvitationMutation.mutate({ id: invitationId });
  }

  return (
    <div>
      <ul>
        {invitations.map((request) => (
          <RequestItemsList
            key={request.id}
            id={request.id}
            renderBody={
              <RequestItem title={request.emailAddress}>
                Invitation to Yoin sent <TimeAgo date={request.createdTime} /> ago.
              </RequestItem>
            }
            renderButton={
              <Button
                color={ButtonColors.Cyan}
                text="Re-Send"
                onClick={() => handleResendInvitation(request.id)}
                isDisabled={!request.canBeResent || resendInvitationMutation.isPending}
              />
            }
          />
        ))}
      </ul>
    </div>
  );
}
