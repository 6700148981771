import { IConnectionRequestDto } from '../model/IConnectionRequestDto';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export const getSentConnectionRequests = () =>
  apiClient
    .get('v1/connectionrequests?type=sent')
    .then<Array<IConnectionRequestDto>>((response) => response.data.data);

export const getRecievedConnectionRequests = () =>
  apiClient
    .get('v1/connectionrequests?type=received')
    .then<Array<IConnectionRequestDto>>((response) => response.data.data);

export const getConnectionRequests = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/connectionrequests${queryString}`)
    .then<Array<IConnectionRequestDto>>((response) => response.data.data);
};

export const acceptConnectionRequest = (id: string) =>
  apiClient.put(`v1/connectionrequests/${id}/accept`).then((response) => response.data.data);

export const rejectConnectionRequest = (id: string) =>
  apiClient.put(`v1/connectionrequests/${id}/reject`).then((response) => response.data.data);

export const deleteConnectionRequest = (id: string) =>
  apiClient.delete(`v1/connectionrequests/${id}`);
