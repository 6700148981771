import { useSearchParams } from 'react-router-dom';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import useNetworkTabNavigation from './hooks/useNetworkTabNavigation';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import NetworkInfoActionButtons from './NetworkInfoActionButtons';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import NetworkTabBody from './NetworkTabBody';
import NetworkTabBodyMembers from './NetworkTabBodyMembers';
import Activities from '../Activities/Activities';
import Attachments from '../../shared/components/attachments/Attachments';
import { INetworkDto } from '../../shared/model/INetwork';
import { ActivityEntityType } from '../../shared/model/IActitityDto';
import { IPipelineEditItemProps } from '../Pipelines/models/IPipelineEditItemProps';
import Howto from '../../shared/components/message-popups/HowTo';

interface IProps {
  network: INetworkDto | undefined;
  networkId: string;
  conversationId: string;
  pipelineEditItemProps?: IPipelineEditItemProps;
}

export default function NetworkInfoTabs({
  network,
  networkId,
  conversationId,
  pipelineEditItemProps,
}: IProps) {
  const activeTabId = useNetworkTabNavigation();
  const [, setSearchParams] = useSearchParams();

  const handleSetActiveTab = (id: string) => {
    setSearchParams({ tab: id });
  };

  return (
    <TabWrapper>
      <TabHeaderWrapper>
        <TabNavItem
          id="tab_info"
          activeId={activeTabId}
          title="Info"
          setActiveTab={handleSetActiveTab}
        />
        <TabNavItem
          id="tab_members"
          activeId={activeTabId}
          title="Members"
          setActiveTab={handleSetActiveTab}
        />

        <TabNavItem
          id="tab_activities"
          activeId={activeTabId}
          title="Tasks"
          setActiveTab={handleSetActiveTab}
        />

        <TabNavItem
          id="tab_attachments"
          activeId={activeTabId}
          title="Attachments"
          setActiveTab={handleSetActiveTab}
        />
        <NetworkInfoActionButtons networkId={networkId} />
      </TabHeaderWrapper>

      <TabContentWrapper>
        <TabContent id="tab_info" activeTabId={activeTabId}>
          <NetworkTabBody network={network} pipelineEditItemProps={pipelineEditItemProps} />
        </TabContent>
        <TabContent id="tab_members" activeTabId={activeTabId}>
          <NetworkTabBodyMembers networkId={networkId} />
        </TabContent>
        <TabContent id="tab_activities" activeTabId={activeTabId}>
          {network && <Activities parentId={networkId} type={ActivityEntityType.Network} />}
        </TabContent>

        <TabContent id="tab_attachments" activeTabId={activeTabId}>
          <Attachments conversationId={conversationId} />
          <Howto
            title="Attachments "
            description="View the attatchments you have sent within the networks. "
          />
        </TabContent>
      </TabContentWrapper>
    </TabWrapper>
  );
}
