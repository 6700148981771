import { useNavigate } from 'react-router';
import SelectCardList, { SelectCardListItem } from '../../shared/components/lists/SelectCardList';
import { usePipelinesQuery } from './queries/pipelineQueries';
import pipelineDummy from '../../assets/mocks/pipelinesMock.png';

export default function PipelineSelect() {
  const navigate = useNavigate();
  const pipelinesQuery = usePipelinesQuery();

  if (!pipelinesQuery.data) return <></>;

  return (
    <>
      <SelectCardList orientation="horizontal">
        {pipelinesQuery.data
          .sort((a, b) => new Date(b.updatedTime).getTime() - new Date(a.updatedTime).getTime())
          .map((pipeline) => (
            <SelectCardListItem
              id={pipeline.id}
              key={pipeline.id}
              title={pipeline.name}
              description={pipeline.updatedTime}
              imageUrl={pipelineDummy}
              onClick={() => navigate(`../${pipeline.id}`)}
            />
          ))}
      </SelectCardList>
    </>
  );
}
