import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useCheckMobileScreen from '../../../shared/hooks/useCheckMobileScreen';

export default function useNetworkTabNavigation() {
  const [searchParams] = useSearchParams();
  const isMobile = useCheckMobileScreen();

  const [activeTab, setActiveTab] = useState<string>(
    isMobile ? 'tab_chat' : 'tab_info',
  );
  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams.get('tab')]);
  return activeTab;
}
