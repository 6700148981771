import { PlusIcon } from '@heroicons/react/24/outline';
import Text from '../text/Text';
import ButtonLikeElement from './ButtonLikeElement';

interface IAddButtonProps {
  text?: string;
  onClick: () => void;
}

export default function AddButton({ text, onClick }: IAddButtonProps) {
  return (
    <ButtonLikeElement onClick={onClick}>
      <PlusIcon className="h-6 w-6 text-cyan-alternative stroke-[3px] mr-1" aria-hidden="true" />
      {text && (
        <Text size="xSmall" leading="snug" color="cyan" brightness="dark" weight="bold">
          {text}
        </Text>
      )}
    </ButtonLikeElement>
  );
}
