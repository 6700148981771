import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { ICreateUpdateOrganizationDto } from '../models/ICreateUpdateOrganizationDto';
import { createOrganization, getOrganizations } from './organizationServices';

type OrganizationSearchField = 'Name';

interface IOrganizationProps {
  searchTerm?: string;
  searchFields?: OrganizationSearchField[];
  limit?: number;
}

const organizationKeys = {
  all: ['organziations'] as const,
  lists: () => [...organizationKeys.all, 'lists'] as const,
  list: (props: IOrganizationProps) => [...organizationKeys.lists(), props] as const,
  details: () => [...organizationKeys.all, 'detail'] as const,
  detail: (id: string) => [...organizationKeys.details(), id] as const,
};

export function useOrganizationsQuery(props: IOrganizationProps = {}) {
  return useInfiniteApiQuery({
    queryKey: organizationKeys.list(props),
    queryFn: () =>
      getOrganizations(
        new QueryParam('limit', props.limit || 250),
        new QueryParam('searchTerm', props.searchTerm),
        ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
      ),
  });
}

export function useCreateOrganizationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ICreateUpdateOrganizationDto) => createOrganization(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
