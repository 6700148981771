/* eslint-disable no-console */
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ICreateUpdateNetworkDto, INetworkDto } from '../../shared/model/INetwork';
import Button from '../../shared/components/buttons/Button';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import { useCreateUpdateNetworkMutation } from './queries/networkQueries';
import Text from '../../shared/components/text/Text';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { NetworkCreateEditForm } from './NetworkCreateEditForm';

/* We are using the optional callback function for communicating with the modal via the
   parent component "Networks". I'm sure we can find a more beautiful solution to this later */

interface IProps {
  network?: INetworkDto;
  onSuccess?: (networkId: string) => void;
  onCancel?: () => void;
}

export default function NetworkCreateEdit({ network, onSuccess, onCancel }: IProps) {
  const [showUnsavedDataWarning, setShowUnsavedDataWarning] = useState(false);

  const createUpdateNetwork = useCreateUpdateNetworkMutation();

  const invokeOnCancel = () => {
    if (typeof onCancel !== 'undefined') {
      onCancel();
    }
  };

  const onSubmit: SubmitHandler<ICreateUpdateNetworkDto> = (networkToSubmit) => {
    createUpdateNetwork.mutate(
      { id: network?.id, network: networkToSubmit },
      {
        onSuccess: (_network) => {
          if (typeof onSuccess !== 'undefined') {
            onSuccess(_network.id);
          }
        },
      },
    );
  };

  const handleCancelSave = (isDirty: boolean, event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setShowUnsavedDataWarning(true);
    } else {
      invokeOnCancel();
    }
  };

  const handleContinueEditing = () => {
    setShowUnsavedDataWarning(false);
  };

  const handleContinueClosing = () => {
    invokeOnCancel();
  };

  return (
    <>
      <NetworkCreateEditForm
        network={network}
        isDisabled={createUpdateNetwork.isPending}
        onSubmit={onSubmit}
        onCancel={handleCancelSave}
      />
      <ConfirmDialog isOpen={showUnsavedDataWarning} title="You have unsaved data">
        <Text as="p">Your changes will be lost if you leave</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Cyan} text="Leave" onClick={handleContinueClosing} />
          <Button
            color={ButtonColors.White}
            text="Continue Editing"
            onClick={handleContinueEditing}
          />
        </ButtonGroup>
      </ConfirmDialog>
    </>
  );
}
