import { IPrivilegesDto } from '../../../shared/model/IPrivilegesDto';
import { IInvitePipelineRequestDto } from '../../../shared/model/IConnectionRequestDto';
import queryStringBuilder from '../../../shared/utils/query-string-builder/queryStringBuilder';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import apiClient from '../../../shared/utils/apiClient';
import { IPipelineMember } from '../models/IPipelineDto';

export const getMembers = (id: string) =>
  apiClient
    .get(`v1/pipelines/${id}/members`)
    .then<Array<IPipelineMember>>((response) => response.data.data);

export const deleteMember = (id: string, userId: string) =>
  apiClient.delete(`v1/pipelines/${id}/members/${userId}`);

export const invite = (id: string | undefined, targetUserId: string | undefined) => {
  const inviteDto = { targetUserId };
  return apiClient
    .post(`v1/pipelines/${id}/invite`, inviteDto)
    .then((response) => response.data.data);
};
export const getPrivileges = (id: string) =>
  apiClient.get(`v1/pipelines/${id}/privileges`).then<IPrivilegesDto>((result) => result.data);

export const getInviteRequest = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/invitepipelinerequests/${queryString}`)
    .then<Array<IInvitePipelineRequestDto>>((response) => response.data.data);
};

export const deleteInviteRequest = (id: string) =>
  apiClient.delete(`v1/invitepipelinerequests/${id}`);

export const acceptInvitation = (id: string) =>
  apiClient.post(`v1/invitepipelinerequests/${id}/accept`);

export const rejectInvitation = (id: string) =>
  apiClient.post(`v1/invitepipelinerequests/${id}/reject`);
