import { Card } from './Card';
import Text from '../text/Text';

interface IPipelineItemCardProps {
  text: string;
  imageUrl: string;
  onClick?: () => void;
}

export default function CreateActionCardd({ text, imageUrl, onClick }: IPipelineItemCardProps) {
  return (
    <Card as="div" className="cursor-pointer !p-0 hover:shadow-none" onClick={onClick}>
      <div className="flex flex-row">
        <div className="p-2">
          <div
            style={{ backgroundImage: `url(${imageUrl})` }}
            className="flex flex-col items-center justify-center w-20 h-12 bg-contain bg-no-repeat ml-3"
          />
        </div>

        <div className="flex flex-row justify-center items-center ml-4 space-x-2 bg-purple p-3 rounded-r-lg">
          <Text size="normal" color="purple" weight="bold" brightness="dark">
            {text}
          </Text>
        </div>
      </div>
    </Card>
  );
}
