import { Card } from '../cards/Card';

interface IProps {
  isSelected?: boolean;
  children: React.ReactNode;
}

export default function ListItem({ isSelected, children }: IProps) {
  return (
    <Card
      className={[
        'flex items-center space-x-3 relative cursor-pointer',
        isSelected ? 'outline-cyan-500 outline outline-offset-[-2px]' : 'bg-white',
      ].join(' ')}
    >
      {children}
    </Card>
  );
}
