import { UsersIcon, ShareIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MenuItemActionButton from '../../shared/components/tabs/MenuItemActionButton';
import PipelineCreateNetworkItem from './PipelineCreateNetworkItem';
import { useCreatePipelineItemMutation } from './queries/itemQueries';
import PipelineCreateContactItem from './PipelineCreateContactItem';
import { usePipelineStoreContext } from './context/pipeline-context';
import { Card } from '../../shared/components/cards/Card';
import { Popover, PopoverContent, PopoverTrigger } from '../../shared/components/popovers/Popover';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import CurrencyInput from '../../shared/components/inputs/number/CurrencyInput';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Label from '../../shared/components/text/Label';
import convertFormattedCurrencyToNumber from '../../shared/utils/numberUtils';
import Header2 from '../../shared/components/headers/Header2';
import { PipelineResourceType } from './models/IPipelineItemDto';
import { AppContext } from '../../shared/context/context';
import { createErrorToast } from '../../shared/services/toastService';
import { usePipelineQuery } from './queries/pipelineQueries';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';

export interface ICreateItemSettingsDto {
  stageId: string;
  type: PipelineResourceType;
}

interface IPipelineCreateItemProps {
  stageId: string;
}

type ResourceInfo = {
  resourceId: string;
  name: string;
};

export type ResourceCallback = () => Promise<ResourceInfo>;

function SelectType({ onSelect }: { onSelect: (type: PipelineResourceType) => void }) {
  return (
    <>
      <MenuItemActionButton
        text="Add Network"
        icon={
          // TODO: Create a concept for coloured icons on shape
          <div className="bg-cyan-dark rounded-full w-5 h-5">
            <ShareIcon className="w-5 h-5 text-white p-1" />
          </div>
        }
        onClick={() => onSelect('Network')}
      />
      <MenuItemActionButton
        text="Add Contact"
        icon={
          // TODO: Create a concept for coloured icons on shape
          <div className="bg-cyan-dark rounded-full w-5 h-5">
            <UsersIcon className="w-5 h-5 text-white p-1" />
          </div>
        }
        onClick={() => onSelect('Contact')}
      />
    </>
  );
}

type CreateItemProps = {
  type: PipelineResourceType;
  currencyIsoCode: string;
  onCreate: (value: number) => Promise<void>;
  onCancel: () => void;
};

function CreateItem({ type, currencyIsoCode, onCreate, onCancel }: CreateItemProps) {
  const { register, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: 0,
    },
  });

  const handleCreateItem = async () => {
    await onCreate(convertFormattedCurrencyToNumber(getValues('value')));
    reset();
  };

  return (
    <SpacingContainer>
      <Header2>Choose {type.toLowerCase()} value</Header2>
      <SpacingContainerItem>
        <Label htmlFor="value" text={`Amount (${currencyIsoCode})`} />
        <CurrencyInput {...register('value')} />
      </SpacingContainerItem>
      <ButtonGroup>
        <Button color={ButtonColors.White} text="Back" onClick={onCancel} />
        <Button color={ButtonColors.Cyan} text="Add" onClick={handleCreateItem} />
      </ButtonGroup>
    </SpacingContainer>
  );
}

type WizardState = {
  type?: PipelineResourceType;
  getResourceInfo?: ResourceCallback;
};

type WizardProps = IPipelineCreateItemProps & {
  open: boolean;
  onChange: (open: boolean) => void;
};

function Wizard({ stageId, open, onChange }: WizardProps) {
  const [wizardState, setWizardState] = useState<WizardState>({});

  const { activePipelineSettings } = usePipelineStoreContext();
  const { pipelineId } = activePipelineSettings;
  const pipelineQuery = usePipelineQuery(pipelineId);
  const createPipelineItemMutation = useCreatePipelineItemMutation();
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    if (!open) {
      setWizardState({});
    }
  }, [open]);

  if (!pipelineQuery.isSuccess) {
    return null;
  }

  const handleSelectType = (type: PipelineResourceType) => {
    setWizardState((state) => ({ ...state, type }));
  };

  const handleSelectResource = (getResourceInfo: ResourceCallback) => {
    setWizardState((state) => ({ ...state, getResourceInfo }));
  };

  const handleCreateItem = async (value: number) => {
    const { getResourceInfo } = wizardState;
    if (!getResourceInfo) {
      dispatch(createErrorToast(new Error("Couldn't create new item")));
      return;
    }

    const { resourceId, name } = await getResourceInfo();
    await createPipelineItemMutation.mutateAsync({
      pipelineId,
      stageId,
      item: {
        name,
        value,
        resourceId,
      },
    });

    onChange(false);
  };

  if (!wizardState.type) {
    return <SelectType onSelect={handleSelectType} />;
  }

  if (wizardState.type === 'Network' && !wizardState.getResourceInfo) {
    return <PipelineCreateNetworkItem onSelect={handleSelectResource} />;
  }

  if (wizardState.type === 'Contact' && !wizardState.getResourceInfo) {
    return <PipelineCreateContactItem onSelect={handleSelectResource} />;
  }

  return (
    <CreateItem
      type={wizardState.type}
      currencyIsoCode={pipelineQuery.data.currencyIsoCode}
      onCreate={handleCreateItem}
      onCancel={() => setWizardState(({ type }) => ({ type }))}
    />
  );
}

export default function PipelineCreateItem({ stageId }: IPipelineCreateItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <PlusIcon className="h-6 w-6 text-cyan-alternative stroke-[3px]" aria-hidden="true" />
      </PopoverTrigger>
      <PopoverContent
        align="start"
        sideOffset={-15}
        alignOffset={15}
      >
        <Card as="div" className="w-64" isInteractive={false}>
          <Wizard stageId={stageId} open={isOpen} onChange={setIsOpen} />
        </Card>
      </PopoverContent>
    </Popover>
  );
}
