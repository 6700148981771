import react, { useContext, useEffect, useState } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSpinDelay } from 'spin-delay';
import { IContactDto } from '../../shared/model/IContactDto';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import Modal from '../../shared/components/modal/Modal';
import ContactCreateContact from './ContactCreateContact';
import SearchBar from '../../shared/components/search/SearchBar';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import NavigationList from '../../shared/components/navigation/NavigationList';
import useDebounce from '../../shared/hooks/useDebounce';
import NavigationBar from '../../shared/components/navigation/NavigationBar';
import NavigationHeader from '../../shared/components/navigation/NavigationHeader';
import NavigationBarMobile from '../../shared/components/navigation/NavigationBarMobile';
import ActionHeader from '../../shared/components/headers/ActionHeader';
import { EventBus } from '../../shared/utils/eventBus';
import { AppContext } from '../../shared/context/context';
import setupConversations from '../../shared/context/setup-conversations';
import { ConversationActionTypes } from '../../shared/context/reducers';
import useIsBaseRoute from '../../shared/hooks/useIsNavigationMode';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import {
  contactKeys,
  useAlphabeticallySortedContactsQuery,
  useChatContactsQuery,
} from './queries/contactQueries';
import { profileKeys, useSearchPersonProfilesQuery } from './queries/personProfileQueries';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import { useMyUserId } from '../../shared/hooks/accountHooks';
import ActionHeaderSocials from '../../shared/components/headers/ActionHeaderSocials';
import { useLexicalMarkdownToPlainText } from '../../shared/lexical/markdown/useLexicalMarkdownToPlainText';
import { useStandaloneEditor } from '../../shared/lexical/useStandaloneEditor';
import { MESSAGE_EDITOR_CONFIG } from '../Messaging/editor/messageEditorConfig';
import Text from '../../shared/components/text/Text';

function Contacts() {
  const { state, dispatch } = useContext(AppContext);
  const [isCreateContactModalOpen, setIsCreateContactModalOpen] = react.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const myUserId = useMyUserId();
  const isMobile = useCheckMobileScreen();

  const queryClient = useQueryClient();

  const isBaseRoute = useIsBaseRoute();

  const contacts = useChatContactsQuery(debouncedSearchTerm);
  const sortedContacts = useAlphabeticallySortedContactsQuery(debouncedSearchTerm);
  const profiles = useSearchPersonProfilesQuery(debouncedSearchTerm);

  const { editor } = useStandaloneEditor(MESSAGE_EDITOR_CONFIG);
  const markdownToPlainText = useLexicalMarkdownToPlainText(editor);

  const isLoading = useSpinDelay(profiles.isLoading || contacts.isLoading || !state.conversations, {
    delay: 1000,
    minDuration: 0,
  });
  const [activeTab, setActiveTab] = useState<'chat' | 'social' | 'sorted'>('chat');

  useEffect(() => {
    if (!myUserId) return undefined;
    setSearchTerm('');

    const onRefreshContactList = EventBus.getInstance().on('refreshContactList', () => {
      queryClient.invalidateQueries({ queryKey: profileKeys.lists() });
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });

      setupConversations().then((conversations) => {
        dispatch({
          type: ConversationActionTypes.SetConversations,
          payload: { conversations, userId: myUserId },
        });
      });
    });

    return () => {
      onRefreshContactList.unregister();
    };
  }, [myUserId]);

  useEffect(() => {
    if (!contacts.data?.length || !matchPath(location.pathname, '/contacts') || isMobile) return;
    navigate(contacts.data[0].id);
  }, [contacts.isFetched]);

  const handleOnSearchChange = (event: react.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleOpenCreateContactModal = () => {
    setIsCreateContactModalOpen(true);
  };

  const handleCloseCreateContactModal = () => {
    setIsCreateContactModalOpen(false);
    EventBus.getInstance().dispatch('refreshContactList');
  };

  const renderGroupedContacts = () => {
    if (!sortedContacts.data) return null;

    return Object.entries(sortedContacts.data).map(([group, _contacts]) => (
      <div key={group}>
        <div className="ml-3 mt-2">
          <Text as="h3" weight="medium">
            {group}
          </Text>
        </div>
        <NavigationList
          imageType="Contact"
          data={_contacts as IContactDto[]}
          isLoading={isLoading}
          itemIdProperty={({ id }) => id}
          itemUrlProperty={({ id }) => id}
          itemHeadlineProperty={({ firstName, lastName }) => `${firstName} ${lastName}`}
          itemInformationProperty={({ company, title }) =>
            `${title} ${title !== '' && company !== '' ? 'at' : ''} ${company}`.trim()
          }
          itemImgSrcProperty={({ photoUrl }) => photoUrl}
          highlightInformationProperty={({ latestMessage }) => latestMessage?.isUnread ?? false}
          showHighlightIndicator={({ latestMessage }) => latestMessage?.isUnread ?? false}
        />
      </div>
    ));
  };

  const navigationSection = () => (
    <>
      <NavigationHeader>
        <ActionHeader text="Contacts" onClick={() => handleOpenCreateContactModal()} icon="plus" />
        <ActionHeaderSocials activeTab={activeTab} onClick={setActiveTab} />
        <SearchBar
          onSearchChange={handleOnSearchChange}
          searchTerm={searchTerm as string}
          placeholder={
            activeTab === 'social' ? 'Search to find people in Yoin' : 'Search to find contacts'
          }
        />
      </NavigationHeader>
      {/* // TODO: Class should not be here, it should be based on structure */}
      <ScrollBarWrapper className="mt-3">
        {activeTab === 'chat' && (
          <NavigationList
            imageType="Contact"
            data={contacts.data?.filter((i) => i.connectionId) as IContactDto[]}
            isLoading={isLoading}
            itemIdProperty={({ id }) => id}
            itemUrlProperty={({ id }) => id}
            itemHeadlineProperty={({ firstName, lastName }) => `${firstName} ${lastName}`}
            itemInformationProperty={({ latestMessage }) =>
              markdownToPlainText(latestMessage?.text)
            }
            itemSecondaryInformationProperty={({ latestMessage }) =>
              latestMessage?.createdTimeFriendly ?? ''
            }
            itemImgSrcProperty={({ photoUrl }) => photoUrl}
            highlightInformationProperty={({ latestMessage }) => latestMessage?.isUnread ?? false}
            showHighlightIndicator={({ latestMessage }) => latestMessage?.isUnread ?? false}
          />
        )}

        {activeTab === 'social' && (
          <NavigationList
            imageType="Contact"
            data={profiles?.data?.data as IPersonProfileDto[]}
            itemIdProperty={({ id }) => id}
            itemUrlProperty={({ id }) => `external/${id}`}
            itemHeadlineProperty={({ firstName, lastName }) => `${firstName} ${lastName}`}
            itemInformationProperty={({ emailAddress }) => emailAddress}
            itemImgSrcProperty={({ photoUrl }) => photoUrl}
          />
        )}
        {activeTab === 'sorted' && renderGroupedContacts()}
      </ScrollBarWrapper>
    </>
  );

  return (
    <>
      <WrapperDesktop>
        <NavigationBar>{navigationSection()}</NavigationBar>
      </WrapperDesktop>
      <WrapperMobile>
        {isBaseRoute && <NavigationBarMobile>{navigationSection()}</NavigationBarMobile>}
      </WrapperMobile>

      <Outlet />

      <Modal
        isOpen={isCreateContactModalOpen}
        showCloseButton={false}
        onCloseClick={() => handleCloseCreateContactModal()}
      >
        <ContactCreateContact callback={handleCloseCreateContactModal} />
      </Modal>
    </>
  );
}

export default Contacts;
