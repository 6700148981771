import { IPagedResult } from '../../../shared/model/IPagedResult';
import apiClient from '../../../shared/utils/apiClient';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import queryStringBuilder from '../../../shared/utils/query-string-builder/queryStringBuilder';
import { ICreateUpdateOrganizationDto } from '../models/ICreateUpdateOrganizationDto';
import { IOrganizationDto } from '../models/IOrganizationDto';

export function createOrganization(data: ICreateUpdateOrganizationDto) {
  return apiClient
    .post('v1/organizations', data)
    .then<IOrganizationDto>((response) => response.data);
}

export function updateOrganization(id: string, data: ICreateUpdateOrganizationDto) {
  return apiClient.patch(`v1/organizations/${id}`, data);
}

export function deleteOrganization(id: string) {
  return apiClient.delete(`v1/organizations/${id}`);
}

export function getOrganization(id: string) {
  return apiClient
    .get(`v1/organizations/${id}`)
    .then<IOrganizationDto>((response) => response.data);
}

export function getOrganizations(...queryParams: QueryParam[]) {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/organizations${queryString}`)
    .then<IPagedResult<IOrganizationDto>>((response) => response.data);
}
