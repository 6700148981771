/* eslint-disable react/function-component-definition */
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useMsal } from '@azure/msal-react';
import { createContext, Dispatch, useEffect, useMemo, useReducer } from 'react';
import { IToast } from '../components/toasts/model/IToast';
import { IMessageReadModel } from '../model/IMessageReadModel';
import {
  toastReducer,
  ToastActions,
  conversationReducer,
  ConversationActions,
  ConversationActionTypes,
  connectionReducer,
  ConnectionActions,
  ConnectionActionTypes,
} from './reducers';
import { IConversationDto } from '../model/IConversationDto';
import setupConversations from './setup-conversations';
import { useMyUserId } from '../hooks/accountHooks';
import createSignalRConnection from '../services/signalRService';

export type InitialStateType = {
  toastMessages: IToast[];
  conversations: IConversationDto[] | undefined;
  connection: HubConnection;
};

const initialState = {
  toastMessages: [],
  conversations: undefined,
  connection: {} as HubConnection,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ToastActions | ConversationActions | ConnectionActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { toastMessages, conversations, connection }: InitialStateType,
  action: ToastActions | ConversationActions | ConnectionActions,
) => ({
  toastMessages: toastReducer(toastMessages, action),
  conversations: conversationReducer(conversations, action),
  connection: connectionReducer(connection, action),
});

const AppProvider = ({ children }: { children: React.ReactNode}) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const { instance: msalInstance } = useMsal();
  const myUserId = useMyUserId();

  useEffect(() => {
    if (!myUserId) return undefined;

    setupConversations().then((conversations) => {
      dispatch({
        type: ConversationActionTypes.SetConversations,
        payload: { conversations, userId: myUserId },
      });
    });

    const signalRConnection = createSignalRConnection(msalInstance);
    if (signalRConnection instanceof Object) {
      dispatch({
        type: ConnectionActionTypes.SetConnection,
        payload: { connection: signalRConnection },
      });

      signalRConnection.on('ReceiveMessage', (data: IMessageReadModel) => {
        dispatch({
          type: ConversationActionTypes.AddMessageToConversation,
          payload: { message: data, userId: myUserId },
        });
      });

      signalRConnection.on('DeleteMessage', (data: IMessageReadModel) => {
        dispatch({
          type: ConversationActionTypes.DeleteMessageFromConversation,
          payload: { message: data },
        });
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      signalRConnection.on('ReceiveConversationParticipant', (data: any) => {
        dispatch({
          type: ConversationActionTypes.UpdateLastRead,
          payload: { participant: data, userId: myUserId },
        });
      });

      signalRConnection.onreconnecting(() => {
        // TODO: Add log error
      });

      signalRConnection.onreconnected(() => {
        if (signalRConnection.state === HubConnectionState.Connected) {
          // TODO: Add logging
        }
      });

      signalRConnection.start();
    }

    return () => {
      signalRConnection.stop();
    };
  }, [myUserId, msalInstance]);

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
