import { HTMLProps } from 'react';
import { As } from '../../utils/types';

type Size = 'small' | 'medium' | 'large';
type ValidTags = 'div' | 'section' | 'header' | 'footer' | 'main' | 'aside' | 'article' | 'nav';
type Orientation = 'horizontal' | 'vertical' | 'both';

export type ISpacingContainerProps = As<ValidTags> & {
  size?: Size;
  orientation?: Orientation;
  children: React.ReactNode;
};

const horizontalSizeMapping: Record<Size, string> = {
  small: 'space-x-2',
  medium: 'space-x-4',
  large: 'space-x-6',
};

const verticalSizeMapping: Record<Size, string> = {
  small: 'space-y-2',
  medium: 'space-y-4',
  large: 'space-y-6',
};

export default function SpacingContainer({
  as = 'div',
  size = 'medium',
  orientation = 'vertical',
  children,
}: ISpacingContainerProps) {
  let _className = '';

  if (orientation === 'vertical') {
    _className = verticalSizeMapping[size];
  } else if (orientation === 'horizontal') {
    _className = horizontalSizeMapping[size];
  } else if (orientation === 'both') {
    _className = `${verticalSizeMapping[size]} ${horizontalSizeMapping[size]}`;
  }

  const Tag: string = as;

  const attributes: HTMLProps<HTMLElement> = {
    className: _className,
  };

  return <Tag {...attributes}>{children}</Tag>;
}
