import { useState } from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ImageCrop } from '../../shared/components/images/ImageCrop';
import Modal, { ModalSize } from '../../shared/components/modal/Modal';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Text from '../../shared/components/text/Text';

interface IProps {
  isOpen: boolean;
  isUploadDisabled: boolean;
  imgSrc: string;
  onCancelClick: React.MouseEventHandler<Element>;
  onUploadClick: (blob: Blob) => void;
}

export function UploadProfilePhotoModal({
  isOpen,
  isUploadDisabled,
  imgSrc,
  onCancelClick,
  onUploadClick,
}: IProps) {
  const [blob, setBlob] = useState<Blob | null>();

  const handleToBlob = (_blob: Blob | null) => {
    setBlob(_blob);
  };

  const handleUpload: React.MouseEventHandler<Element> = () => {
    if (!blob) return;
    onUploadClick(blob);
  };

  return (
    <Modal isOpen={isOpen} onCloseClick={onCancelClick} modalSize={ModalSize.Medium}>
      <div className="pb-4">
        <ImageCrop imgSource={imgSrc} toBlobCallback={handleToBlob} />
      </div>
      <div className="pb-4">
        <Text as="p" size="small">
          Choose the area of your photo you want to use and then click &quot;Upload&quot;. You can
          resize and move the selection as you choose.
        </Text>
      </div>
      <ButtonGroup>
        <Button text="Cancel" color={ButtonColors.White} onClick={onCancelClick} />
        <Button
          text="Upload"
          color={ButtonColors.Cyan}
          onClick={handleUpload}
          isDisabled={isUploadDisabled && !blob}
        />
      </ButtonGroup>
    </Modal>
  );
}
