interface IButtonElementProps {
  children: React.ReactNode;
  onClick: () => void;
}

/**
 * Renders a button-like element without any button style.
 *
 * @param {React.ReactNode} props.children - The content to be displayed inside the button.
 * @param {() => void} props.onClick - The function to call when the button is clicked.
 * @returns {JSX.Element} A button element with custom content and click behavior.
 */

export default function ButtonLikeElement({ children, onClick }: IButtonElementProps) {
  return (
    <button type="button" onClick={onClick} className="flex flex-row w-full items-center">
      {children}
    </button>
  );
}
