import { classNames } from '../../utils/stringUtils';
import BorderActiveHorizontal from '../indicators/BorderActiveHorizontal';

interface IProps<T extends string> {
  id: T;
  activeId: string;
  title: string;
  icon?: React.ReactNode;
  setActiveTab: (id: T) => void;
}

export default function TabNavItem<T extends string>({
  id,
  activeId,
  title,
  icon,
  setActiveTab,
}: IProps<T>) {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <div className="block relative">
      <nav className="-mb-px flex space-x-8 relative" aria-label="Tabs">
        <button
          type="button"
          key={id}
          onClick={handleClick}
          className={classNames(
            id === activeId
              ? '!font-bold text-cyan-dark'
              : 'border-transparent text hover:text-light hover:border-gray-300',
            'flex gap-1 whitespace-nowrap pb-2 px-1 font-medium text-md items-center ',
          )}
        >
          <span className={classNames(id === activeId ? ' text-cyan' : '')}>
            {!!icon && icon}
          </span>
          {title}
        </button>
      </nav>
      {id === activeId && <BorderActiveHorizontal thickness="thick" bottomOffset={-7} />}
    </div>
  );
}
