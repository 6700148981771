import { LinkIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useHasConnectionQuery } from '../Contacts/queries/connectionQueries';
import {
  useConnectionIsInitatedQuery,
  useConnectWithProfileMutation,
} from '../Request/queries/connectionRequestQueries';
import { useFollowMutation, useIsFollowingQuery } from '../Contacts/queries/contactQueries';
import { usePersonProfileQuery } from '../Contacts/queries/personProfileQueries';
import { ProfileIdentifier } from './Types';

export function ProfileActionMenu({ id }: ProfileIdentifier) {
  const profileQuery = usePersonProfileQuery(id);
  const isConnectionInitiated = useConnectionIsInitatedQuery(id);
  const isConnected = useHasConnectionQuery(id);
  const isFollowing = useIsFollowingQuery(id);

  const connectMutation = useConnectWithProfileMutation();
  const followMutation = useFollowMutation();

  const handleConnect = () => {
    if (!profileQuery.data) return;

    connectMutation.mutate({
      personProfileId: profileQuery.data.id,
      firstName: profileQuery.data.firstName,
      lastName: profileQuery.data.lastName,
    });
  };

  const handleFollow = () => {
    if (!profileQuery.data) return;

    followMutation.mutate({
      personProfileId: profileQuery.data.id,
      firstName: profileQuery.data.firstName,
      lastName: profileQuery.data.lastName,
    });
  };

  return (
    <>
      <IconButton
        icon={<UserPlusIcon className="w-5 h-5" />}
        color={ButtonColors.Gray}
        isDisabled={
          !profileQuery.isSuccess ||
          !isConnectionInitiated.isSuccess ||
          !isConnected.isSuccess ||
          isConnected.data ||
          isConnectionInitiated.data
        }
        onClick={handleConnect}
        label="Connect"
      />
      <IconButton
        icon={<LinkIcon className="w-5 h-5" />}
        color={ButtonColors.Gray}
        isDisabled={!profileQuery.isSuccess || !isFollowing.isSuccess || isFollowing.data}
        onClick={handleFollow}
        label="Follow"
      />
    </>
  );
}
