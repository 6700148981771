import { FieldValues, Message, Path, UseFormRegister, ValidationRule } from 'react-hook-form';
import ValidationErrorMessage from './ValidationErrorMessage';
import Label from '../../text/Label';

interface IProps<T extends FieldValues> {
  type?: string;
  labelText?: string;
  name: Path<T>;
  disabled?: boolean;
  placeholder?: string;
  register: UseFormRegister<T>;
  errors?: any;
  errorMessage?: string;
  validationRules?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
  }>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onInputBlur?: React.FocusEventHandler<HTMLInputElement>;
  inputCss?: string;
  inputFocusCss?: string;
  infoText?: string;
}

export default function TextFieldInput<T extends object>({
  type = 'text',
  labelText = '',
  name,
  disabled = false,
  register,
  errors = null,
  placeholder = '',
  errorMessage = '',
  validationRules = {},
  onKeyUp,
  onInputBlur,
  inputCss,
  inputFocusCss = 'focus:ring-0 focus:border-zinc-400',
  infoText,
}: IProps<T>) {
  const hasErrors = errors !== null && errors[name];
  const hasLabelText = !!labelText;
  const { onBlur, ...registration } = register(name, validationRules);
  return (
    <>
      <Label
        htmlFor={name}
        text={labelText}
        infoText={infoText}
        extra={<>{hasErrors && <ValidationErrorMessage message={errorMessage} />}</>}
      />

      <div className={`${hasLabelText || hasErrors ? 'mt-1' : ''}`}>
        <input
          type={type}
          id={name}
          disabled={disabled}
          onBlur={(e) => {
            if (onInputBlur) {
              onInputBlur(e);
            }
            onBlur(e);
          }}
          {...registration}
          className={`shadow-sm placeholder:text-gray-400 block w-full border-gray-300 rounded-md disabled:bg-gray-200 ${inputFocusCss} ${inputCss} ${
            errors !== null && errors[name]
              ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
              : ''
          }`}
          placeholder={placeholder}
          autoComplete="off"
          onKeyUp={onKeyUp}
        />
      </div>
    </>
  );
}
