import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { getAccessToken } from '../utils/msalUtils';

enum AuthenticationStatus {
  NotAuthenticated,
  AuthenticationInProgress,
  Authenticated,
}

const useAuthenticationStatus = (): AuthenticationStatus => {
  const [authenticationStatus, setAuthenticationStatus] = useState(
    AuthenticationStatus.AuthenticationInProgress,
  );
  const { inProgress, instance: msalInstance } = useMsal();
  const accounts = msalInstance.getAllAccounts();
  const hasSignedInAccount = accounts.length > 0;

  useEffect(() => {
    const setStatus = async () => {
      if (!hasSignedInAccount && inProgress === InteractionStatus.None) {
        setAuthenticationStatus(AuthenticationStatus.NotAuthenticated);
        return;
      }

      if (
        hasSignedInAccount &&
        inProgress !== InteractionStatus.Startup &&
        inProgress !== InteractionStatus.HandleRedirect
      ) {
        // Before setting authentication status to Authenticated,
        // check that it is possible to acquire an access token.
        if ((await getAccessToken(msalInstance)) === '') {
          // Should not happen but getAccessToken returns empty string if
          // there isn't any active account.
          setAuthenticationStatus(AuthenticationStatus.NotAuthenticated);
          return;
        }
        setAuthenticationStatus(AuthenticationStatus.Authenticated);
        return;
      }

      setAuthenticationStatus(AuthenticationStatus.AuthenticationInProgress);
    };

    setStatus();
  }, [hasSignedInAccount, inProgress]);

  return authenticationStatus;
};

export { useAuthenticationStatus, AuthenticationStatus };
