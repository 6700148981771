import { XCircleIcon } from '@heroicons/react/24/outline';

interface IProps {
  message: string;
}

export default function ValidationErrorMessage({ message }: IProps) {
  return (
    <div className="inline-flex ml-1.5 align-bottom">
      <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      <span className="text-sm font-medium text-red-400 ml-1">{message}</span>
    </div>
  );
}
