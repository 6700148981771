import { XMarkIcon } from '@heroicons/react/24/outline';
import { ExpandableCard } from '../../shared/components/cards/ExpandableCard';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useIsMe } from '../../shared/hooks/accountHooks';
import { ContactProfileLink } from '../Contacts/ContactProfileLink';
import { ProfileActionMenu } from '../Profile/ProfileActionMenu';
import ProfileAvatar from '../Profile/ProfileAvatar';
import { LazyProfilePropertyList } from '../Profile/ProfilePropertyList';
import { CardMenuBar } from '../../shared/components/cards/CardMenuBar';
import { Filler } from '../../shared/components/layout/Filler';
import Text from '../../shared/components/text/Text';
import { useUserProfileQuery } from '../Contacts/queries/userProfileQueries';
import { IPipelinePrivileges, IPipelineMember } from './models/IPipelineDto';

interface IProps {
  member: IPipelineMember;
  isOwner: boolean;
  privileges: IPipelinePrivileges | undefined;
  onRemove: () => void;
}

export function PipelineMemberCard({ member, isOwner, privileges, onRemove }: IProps) {
  const userProfileQuery = useUserProfileQuery(member.userId);
  const isMe = useIsMe();

  if (userProfileQuery.isSuccess) {
    const avatar = (
      <ProfileAvatar
        avatarProps={{
          src: userProfileQuery.data.photoUrl,
          alt: member.name,
          widthClass: 'w-10',
          heightClass: 'h-10',
        }}
      />
    );
    const header = (
      <>
        <Text as="p" size="small" weight="medium">
          {member.name}
        </Text>
        <Text as="p" size="xSmall" brightness="light" whitespace="preWrap">
          {isOwner ? 'Owner' : ' '}
        </Text>
      </>
    );
    return (
      <ExpandableCard
        className="space-y-2"
        renderHead={(isOpen) => (
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              {isOpen && (
                <ContactProfileLink personProfileId={userProfileQuery.data.accountId}>
                  {avatar}
                </ContactProfileLink>
              )}
              {!isOpen && avatar}
            </div>
            <div className="flex-none overflow-x-hidden px-1">
              {isOpen && (
                <ContactProfileLink personProfileId={userProfileQuery.data.accountId}>
                  {header}
                </ContactProfileLink>
              )}
              {!isOpen && header}
            </div>
          </div>
        )}
      >
        <div className="mt-2">
          <LazyProfilePropertyList id={userProfileQuery.data.accountId} />
        </div>
        {!isMe(userProfileQuery.data.accountId) && (
          <CardMenuBar>
            <ProfileActionMenu id={userProfileQuery.data.accountId} />
            <Filler />
            {privileges?.canRemoveMember && (
              <div className="ml-auto">
                <IconButton
                  icon={<XMarkIcon className="h-5 w-5" />}
                  color={ButtonColors.Red}
                  title="Remove member"
                  onClick={onRemove}
                  label="Remove"
                />
              </div>
            )}
          </CardMenuBar>
        )}
      </ExpandableCard>
    );
  }

  return null;
}
