import React, { useReducer, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import RadioButton from '../../shared/components/radiobuttons/RadioButton';
import RadioButtonGroup from '../../shared/components/radiobuttons/RadioButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import UploadButton from '../../shared/components/buttons/UploadButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupDirections } from '../../shared/constants/ButtonGroupDirections';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { ProfilePrivacy } from '../../shared/constants/ProfilePrivacy';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import {
  useMyPersonProfileMutation,
  useMyProfilePhotoMutation,
} from '../Contacts/queries/personProfileQueries';
import ProfileAvatar from './ProfileAvatar';
import { UploadProfilePhotoModal } from './UploadProfilePhotoModal';
import Text from '../../shared/components/text/Text';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import Header1 from '../../shared/components/headers/Header1';

interface IProps {
  myProfile: IPersonProfileDto;
}

export default function MyProfileForm({ myProfile }: IProps) {
  const myProfileMutation = useMyPersonProfileMutation();
  const myProfilePhotoMutation = useMyProfilePhotoMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<IPersonProfileDto>({
    mode: 'onChange',
    values: myProfile,
  });
  const [isPhotoModalOpen, photoModalToggle] = useReducer((state) => !state, false);
  const [imgSource, setImgSource] = useState('');
  const isMobile = useCheckMobileScreen();

  const onSubmit: SubmitHandler<IPersonProfileDto> = (myPersonProfile) => {
    myProfileMutation.mutate({ myPersonProfile });
  };

  const handleUploadProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || e.currentTarget.files.length === 0) return;

    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgSource(reader.result?.toString() || '');
      photoModalToggle();
    });
    reader.readAsDataURL(file);
    // eslint-disable-next-line no-param-reassign
    e.currentTarget.value = '';
  };

  const handlePhotoModalCancel = () => {
    photoModalToggle();
  };

  const handlePhotoModalUpload = (file: Blob) => {
    myProfilePhotoMutation.mutate(
      { file },
      {
        onSuccess: () => {
          photoModalToggle();
        },
      },
    );
  };

  const handleRemoveProfilePicture = () => {
    myProfilePhotoMutation.mutate({});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-3 divide-x-0 md:divide-x-2 ">
        <div className="px-4 md:px-12 flex flex-col gap-6">
          <div>
            <Header1>Profile</Header1>
            <div className="mt-10 flex basis-48 items-center gap-6">
              <ProfileAvatar
                avatarProps={{
                  src: myProfile.photoUrl,
                  alt: myProfile.firstName,
                  widthClass: 'w-32',
                  heightClass: 'h-32',
                }}
              />
              <div>
                <ButtonGroup direction={ButtonGroupDirections.Column}>
                  <UploadButton
                    text="Change photo"
                    onChange={(e) => handleUploadProfilePicture(e)}
                    isDisabled={myProfilePhotoMutation.isPending}
                  />
                  <Button
                    text="Remove photo"
                    color={ButtonColors.Red}
                    onClick={handleRemoveProfilePicture}
                    isDisabled={myProfilePhotoMutation.isPending}
                  />
                </ButtonGroup>
              </div>
            </div>
            <HorizontalDivider />
            <Text as="p" size="small" brightness="light" leading="snug">
              Recommended image size 256 x 256px and maximum file size 1MB
            </Text>
          </div>
          {!isMobile && (
            <div>
              <TextAreaInput
                labelText="About me"
                name="additionalInformation"
                register={register}
                errors={errors}
                errorMessage="Maximum 4000 characters"
                validationRules={{ maxLength: 4000 }}
                className="bg-white"
              />
            </div>
          )}
        </div>
        <div className="px-4 md:px-12 flex flex-col gap-6 md:m-0 mb-4 mt-4">
          <div className="flex flex-row gap-6">
            <div className="flex-1">
              <TextFieldInput
                labelText="First name"
                name="firstName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required & between 2 & 400 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 400 }}
              />
            </div>
            <div className="flex-1">
              <TextFieldInput
                labelText="Last name"
                name="lastName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required & between 2 & 400 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 400 }}
              />
            </div>
          </div>
          <div>
            <TextFieldInput
              labelText="Phone number"
              name="phoneNumber"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 400 characters"
              validationRules={{ maxLength: 400 }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Email address"
              name="emailAddress"
              type="text"
              disabled
              register={register}
              errors={errors}
              errorMessage="Required & between 2 & 400 characters"
              validationRules={{
                required: true,
                minLength: 2,
                maxLength: 400,
                pattern: REGEX_EMAIL,
              }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Company name"
              name="company"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 400 characters"
              validationRules={{ maxLength: 400 }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Title"
              name="title"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 400 characters"
              validationRules={{ maxLength: 400 }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Location"
              name="location"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 400 characters"
              validationRules={{ maxLength: 400 }}
            />
          </div>
          {isMobile && (
            <div>
              <TextAreaInput
                labelText="About me"
                name="additionalInformation"
                register={register}
                errors={errors}
                errorMessage="Maximum 4000 characters"
                validationRules={{ maxLength: 4000 }}
                className="bg-white"
              />
            </div>
          )}
        </div>
        <div className="px-4 md:px-12">
          <RadioButtonGroup
            name="visibility"
            label="Profile privacy"
            description="Choose the privacy of the profile"
          >
            <RadioButton
              name="visibility"
              label={ProfilePrivacy.Public}
              value={ProfilePrivacy.Public}
              register={register}
            />
            <RadioButton
              name="visibility"
              label={ProfilePrivacy.Private}
              value={ProfilePrivacy.Private}
              register={register}
            />

            <Text as="p" size="small" brightness="light" leading="snug">
              Having a public profile makes your profile searchable within Yoin.
            </Text>
          </RadioButtonGroup>
        </div>
      </div>

      <div className="px-12 pt-6">
        <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
          <Button
            text="Revert changes"
            color={ButtonColors.White}
            isDisabled={!isDirty || myProfileMutation.isPending}
            onClick={() => reset(myProfile)}
          />
          <SubmitButton text="Save" isDisabled={!isValid || !isDirty || myProfileMutation.isPending} />
        </ButtonGroup>
      </div>
      <UploadProfilePhotoModal
        imgSrc={imgSource}
        isOpen={isPhotoModalOpen}
        isUploadDisabled={myProfilePhotoMutation.isPending}
        onUploadClick={handlePhotoModalUpload}
        onCancelClick={handlePhotoModalCancel}
      />
    </form>
  );
}
