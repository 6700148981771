import {
  InfiniteData,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  keepPreviousData,
} from '@tanstack/react-query';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';

type InfiniteApiQueryOptions<TData> = {
  queryKey: QueryKey;
  queryFn: QueryFunction<IPagedResult<TData>, QueryKey>;
  staleTime?: number;
  enabled?: boolean;
  useKeepPreviousData?: boolean;
  select?: (
    data: InfiniteData<IPagedResult<TData>, string | undefined>,
  ) => InfiniteData<TData[], string | undefined>;
};

export function useInfiniteApiQuery<TData>({
  queryKey,
  queryFn,
  staleTime,
  enabled,
  useKeepPreviousData,
  select: providedSelect,
}: InfiniteApiQueryOptions<TData>) {
  const pagedQueryFn = (context: QueryFunctionContext<QueryKey, string | undefined>) => {
    const { pageParam } = context;
    return pageParam
      ? apiClient.get(pageParam).then<Awaited<ReturnType<typeof queryFn>>>((result) => result.data)
      : queryFn(context);
  };
  const getNextPageParam = (lastPage: IPagedResult<TData>) => lastPage.next;
  const select =
    providedSelect ||
    ((data: InfiniteData<IPagedResult<TData>, string | undefined>) => {
      const newData = { pageParams: data.pageParams, pages: data.pages.map((page) => page.data) };
      return newData;
    });

  const result = useInfiniteQuery({
    queryKey,
    queryFn: pagedQueryFn,
    getNextPageParam,
    initialPageParam: undefined,
    staleTime,
    enabled,
    select,
    placeholderData: useKeepPreviousData ? keepPreviousData : undefined,
  });

  return result;
}
