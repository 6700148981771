import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { INetworkMember } from '../../../shared/model/INetworkMember';
import {
  deleteNetworkMember,
  getMembers,
  inviteToNetwork,
} from '../../../shared/services/networkService';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { networkKeys } from './networkQueries';

interface INetworkMemberKeyProps {
  networkId: string;
}

interface IRemoveNetworkMemberProps {
  member: INetworkMember;
}

interface IInviteNetworkMemberProps {
  networkId: string;
  userId: string;
}

const networkMemberKeys = {
  all: ['network-members'] as const,
  lists: () => [...networkMemberKeys.all, 'list'] as const,
  list: (props: INetworkMemberKeyProps) => [...networkMemberKeys.lists(), props] as const,
  details: () => [...networkMemberKeys.all, 'detail'] as const,
  detail: (id: string) => [...networkMemberKeys.details(), id] as const,
};

function networkMembersBaseQuery(networkId?: string) {
  return {
    queryKey: networkMemberKeys.list({ networkId: networkId || '' }),
    queryFn: () => (networkId ? getMembers(networkId) : Promise.resolve([])),
    enabled: !!networkId,
  };
}

export function useNetworkMembersQuery(networkId?: string) {
  return useQuery(networkMembersBaseQuery(networkId));
}

export function useNetworkMembersWithFilterQuery(
  networkId: string,
  filter: (member: INetworkMember) => boolean,
) {
  return useQuery({
    ...networkMembersBaseQuery(networkId),
    select: (members: INetworkMember[]) => members.filter(filter),
  });
}

export function useRemoveNetworkMemberMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ member }: IRemoveNetworkMemberProps) =>
      deleteNetworkMember(member.networkId, member.userId),
    onSuccess: (_, { member }) => {
      queryClient.invalidateQueries({
        queryKey: networkMemberKeys.list({ networkId: member.networkId }),
      });

      queryClient.invalidateQueries({ queryKey: networkKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}

export function useInviteNetworkMemberMutation() {
  return useMutation({
    mutationFn: ({ networkId, userId }: IInviteNetworkMemberProps) =>
      inviteToNetwork(networkId, userId),

    onError: useDispatchApiError(),
  });
}
