import { useCallback, useContext } from 'react';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { groupBy, sortBy } from 'lodash';
import { ToastType } from '../../../shared/components/toasts/constants/ToastTypes';
import { AppContext } from '../../../shared/context/context';
import { contactConversationFilter } from '../../../shared/filters/filters';
import { useIsMe } from '../../../shared/hooks/accountHooks';
import { IContactDto, ContactType } from '../../../shared/model/IContactDto';
import { IConversationDto } from '../../../shared/model/IConversationDto';
import {
  createContact,
  deleteContact,
  followContact,
  getAllContacts,
  getContact,
  updateContact,
} from '../../../shared/services/contactService';
import sortConversation, { EntityType } from '../../../shared/services/conversationService';
import { createToast } from '../../../shared/services/toastService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { profileKeys } from './personProfileQueries';
import { getInitalDataPropsFromQueries } from '../../../shared/utils/queryClientUtils';
import { ICreateContactDto } from '../../../shared/model/ICreateContactDto';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';

type ContactSearchField = 'FirstName' | 'LastName' | 'EmailAddress' | 'PhoneNumber' | 'Company';

interface IUseContactsProps {
  limit?: number;
  searchTerm?: string;
  searchFields?: ContactSearchField[];
  personProfileId?: string;
  connectionId?: string;
  type?: ContactType;
}

export const contactKeys = {
  all: ['contacts'] as const,
  lists: () => [...contactKeys.all, 'list'] as const,
  list: (props: IUseContactsProps) => [...contactKeys.lists(), props] as const,
  details: () => [...contactKeys.all, 'detail'] as const,
  detail: (id: string) => [...contactKeys.details(), id] as const,
};

const STALE_TIME = 1000 * 60; // 1 minute, contacts aren't updated that often.

export const contactsBaseQuery = (props: IUseContactsProps) => ({
  queryKey: contactKeys.list(props),
  queryFn: () =>
    getAllContacts(
      new QueryParam('limit', props.limit || 250), // Since we don't have pagination yet, we need to set a high limit
      new QueryParam('searchTerm', props.searchTerm),
      new QueryParam('profileId', props.personProfileId),
      new QueryParam('connectionId', props.connectionId),
      new QueryParam('type', props.type),
      ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
    ),
  staleTime: STALE_TIME,
  placeholderData: keepPreviousData,
});

export const contactBaseQuery = (id: string, queryClient: QueryClient) => ({
  queryKey: contactKeys.detail(id as string),
  queryFn: () => getContact(id as string),
  ...getInitalDataPropsFromQueries<IContactDto>(
    queryClient,
    contactKeys.lists(),
    (contact) => contact.id === id,
  ),
  staleTime: STALE_TIME,
});

export const contactPersonProfileQuery = (personProfileId: string) =>
  ({
    queryKey: contactKeys.list({ personProfileId }),
    queryFn: () => getAllContacts(new QueryParam('profileId', personProfileId)),
    staleTime: STALE_TIME,
  } as const);

export const contactConnectionQuery = (queryClient: QueryClient, connectionId: string) =>
  ({
    queryKey: contactKeys.list({ connectionId }),
    queryFn: () =>
      getAllContacts(new QueryParam('connectionId', connectionId)).then((contacts) =>
        contacts.length > 0
          ? contacts[0]
          : Promise.reject(new Error('Connection contact not found!')),
      ),
    ...getInitalDataPropsFromQueries(
      queryClient,
      contactKeys.lists(),
      (contact: IContactDto) => contact.connectionId === connectionId,
    ),
  } as const);

export function useChatContactsQuery(searchTerm?: string) {
  const { state } = useContext(AppContext);
  const isMe = useIsMe();
  return useQuery({
    ...contactsBaseQuery({ searchTerm }),
    enabled: !!state.conversations,
    select: (contacts: IContactDto[]) => {
      const activeContacts = contacts.filter((i) => i.connectionId);
      const contactConversations = state.conversations?.filter(contactConversationFilter);

      const contactsSorted = sortConversation(
        contactConversations as IConversationDto[],
        activeContacts,
        EntityType.contact,
        isMe,
      );

      // Add passive contacts last
      contactsSorted.push(...contacts.filter((i) => !i.connectionId));

      return contactsSorted;
    },
  });
}

export function useAlphabeticallySortedContactsQuery(searchTerm?: string) {
  const alphabeticalContactsSelector = useCallback((contacts: IContactDto[]) => {
    const sortedContacts = sortBy(contacts, [
      (contact: IContactDto) => `${contact.firstName} ${contact.lastName}`.toLowerCase(),
    ]);

    const groupedContacts = groupBy(sortedContacts, (contact) =>
      `${contact.firstName} ${contact.lastName}`.charAt(0).toUpperCase(),
    );
    return groupedContacts;
  }, []);

  return useQuery({
    ...contactsBaseQuery({ searchTerm }),
    select: alphabeticalContactsSelector,
  });
}

export function useContactsForShareQuery(searchTerm?: string, excludeContactId?: string) {
  return useQuery({
    ...contactsBaseQuery({ searchTerm, type: ContactType.Follow }),
    select: (contacts: IContactDto[]) =>
      contacts.filter((contact) => contact.id !== excludeContactId && contact.connectionId),
  });
}

export function useContactsForSharedQuery(searchTerm?: string, excludeContactId?: string) {
  return useQuery({
    ...contactsBaseQuery({ searchTerm }),
    select: (contacts: IContactDto[]) =>
      contacts.filter((contact) => contact.id !== excludeContactId),
  });
}

export function useContactsForMentionShareQuery<TData>(
  searchTerm: string | null,
  transformFunc: (contacts: IContactDto) => TData,
  excludePersonProfileIds?: string[],
) {
  return useQuery({
    ...contactsBaseQuery({
      limit: 10,
      searchTerm: searchTerm || undefined,
      searchFields: ['FirstName', 'LastName'],
    }),
    select: (contacts: IContactDto[]) =>
      contacts
        .filter(
          (contact) =>
            !(
              contact.personProfileId &&
              excludePersonProfileIds &&
              excludePersonProfileIds.includes(contact.personProfileId)
            ),
        )
        .map(transformFunc),
    enabled: searchTerm !== null && searchTerm !== undefined,
  });
}

export function useContactQuery(id?: string, enabled = true) {
  const queryClient = useQueryClient();
  return useQuery({ ...contactBaseQuery(id as string, queryClient), enabled: !!id && enabled });
}

export function useContactsQuery(searchTerm?: string, excludeContactId?: string) {
  return useQuery({
    ...contactsBaseQuery({ searchTerm, type: ContactType.Follow }),
    select: (contacts: IContactDto[]) =>
      contacts.filter((contact) => contact.id !== excludeContactId && contact.connectionId),
  });
}

export function useIsFollowingQuery(personProfileId?: string) {
  return useQuery({
    ...contactPersonProfileQuery(personProfileId as string),
    select: (data) => data.length > 0,
    enabled: !!personProfileId,
  });
}

interface IFollowPersonProfileProps {
  personProfileId: string;
  firstName: string;
  lastName: string;
  contactId?: string;
}

export function useFollowMutation() {
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  return useMutation({
    mutationFn: ({ personProfileId, contactId }: IFollowPersonProfileProps) =>
      followContact(personProfileId, contactId),
    onSuccess: (_, { firstName, lastName }) => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
      queryClient.invalidateQueries({ queryKey: profileKeys.lists() });
      dispatch(
        createToast(
          'Success!',
          ToastType.Success,
          `You are now following ${firstName} ${lastName}`,
        ),
      );
    },
    onError: useDispatchApiError(),
  });
}

export function useDeleteContactMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contactId }: { contactId: string }) =>
      deleteContact(contactId).then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}

export function useUpdateContactMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contact, id }: { contact: IContactDto; id: string }) =>
      updateContact(contact, id).then((response) => response.data),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
      queryClient.invalidateQueries({ queryKey: contactKeys.detail(id) });
    },
    onError: useDispatchApiError(),
  });
}

interface ICreateContactProps {
  contact: ICreateContactDto;
}

export function useCreateContactMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contact }: ICreateContactProps) => createContact(contact),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
