import Text from '../../shared/components/text/Text';

interface IProps {
  numberOfNewNotifications: number;
}
export default function SidebarNavNotification({ numberOfNewNotifications }: IProps) {
  return (
    <div className="absolute left-[20px] top-[-5px] flex items-center justify-center w-5 h-5 bg-red rounded-full fade-in-element">
      <Text as="span" weight="bold" leading="none" color="white" size="small">
        {numberOfNewNotifications}
      </Text>
    </div>
  );
}
