import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ButtonColors } from '../../constants/ButtonColors';
import { stringBuilder } from '../../utils/stringUtils';
import IconButton from '../buttons/IconButton';
import { BreakoutButton } from '../buttons/BreakoutButton';
import { Card, ICardProps } from './Card';

export type ExpandableCardRefType = {
  toggle: () => void;
};

export type ExpandableCardRef = React.Ref<ExpandableCardRefType>;

export type IExpandableCardProps = ICardProps & {
  defaultOpen?: boolean;
  disableOpenOnClick?: boolean;
  renderHead: (isOpen: boolean) => React.ReactElement;
  onOpen?: () => void;
  onClose?: () => void;
};

export const ExpandableCard = forwardRef(
  (
    {
      renderHead,
      onOpen,
      onClose,
      className,
      disableOpenOnClick = false,
      defaultOpen = false,
      children,
      ...props
    }: IExpandableCardProps,
    ref: ExpandableCardRef,
  ) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const firstRender = useRef(true);

    useImperativeHandle(ref, () => ({
      toggle: () => setIsOpen(!isOpen),
    }));

    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }

      if (isOpen) {
        if (onOpen) onOpen();
      } else if (onClose) onClose();
    }, [isOpen]);

    const cardCss = stringBuilder()
      .add(isOpen ? '!border-cyan-500 !border-[2px] ' : 'cursor-pointer')
      .add(className)
      .add('relative')
      .toString();

    return (
      <Card className={cardCss} {...props}>
        <>
          <div className="flex flex-row">
            <div className="flex-1 overflow-x-hidden">{renderHead(isOpen)}</div>
            <div className="ml-auto">
              {isOpen && (
                <IconButton
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  icon={<ChevronUpIcon className="w-5 h-5" />}
                  color={ButtonColors.Gray}
                />
              )}
              {!isOpen && disableOpenOnClick && (
                <IconButton
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  icon={<ChevronDownIcon className="w-5 h-5" />}
                  color={ButtonColors.Gray}
                />
              )}
              {!isOpen && !disableOpenOnClick && (
                <>
                  <ChevronDownIcon className="w-5 h-5  text-disabled disabled:text-disabled hover:text-gray-400" />
                  <BreakoutButton
                    text="Expand"
                    hidden
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {isOpen && children}
        </>
      </Card>
    );
  },
);
