import { useQuery } from '@tanstack/react-query';
import { INetworkPrivileges } from '../../../shared/model/INetworkPrivileges';
import { getNetworkPrivileges } from '../../../shared/services/networkService';

const networkPrivilegesKeys = {
  all: ['network-privileges'] as const,
  details: () => [...networkPrivilegesKeys.all, 'detail'] as const,
  detail: (id: string) => [...networkPrivilegesKeys.details(), id] as const,
};

export function useNetworkPrivilegesQuery(networkId?: string) {
  return useQuery({
    queryKey: networkPrivilegesKeys.detail(networkId as string),
    queryFn: () => getNetworkPrivileges(networkId as string),
    enabled: !!networkId,
    select: (data): INetworkPrivileges => ({
      canEdit: data.privileges.includes('Network.ReadWrite'),
      canDelete: data.privileges.includes('Network.Delete'),
      canInviteMember: data.privileges.includes('Network.Member.Invite'),
      canRemoveMember: data.privileges.includes('Network.Member.Remove'),
    }),
  });
}
