import { IActivityDto, ActivityEntityType, ActivityType } from '../model/IActitityDto';
import { IMoveActivityDto } from '../model/IMoveActivityDto';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export interface IUpdateActivityDto {
  title?: string;
  text?: string;
}

const resolveEntityType = (entityType: ActivityEntityType) =>
  entityType === ActivityEntityType.Contact ? 'contacts' : 'networks';
export const getActivities = (parentId: string, entityType: ActivityEntityType, limit?: number) => {
  const queryString = limit
    ? queryStringBuilder().add(new QueryParam('limit', limit)).toQueryString()
    : '';
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient
    .get(`v1/${resolvedResourceType}/${parentId}/activities${queryString}`)
    .then<IPagedResult<IActivityDto>>((response) => response.data);
};

export const createActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  title: string,
  type: ActivityType,
  autoAssignToCreator: boolean,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient
    .post(`v1/${resolvedResourceType}/${parentId}/activities`, { title, type, autoAssignToCreator })
    .then<IActivityDto>((response) => response.data);
};

export const updateActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
  payload: IUpdateActivityDto,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.patch(
    `v1/${resolvedResourceType}/${parentId}/activities/${activityId}`,
    payload,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' },
    },
  );
};

export const deleteActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.delete(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}`);
};

export const completeActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.post(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}/complete`);
};

export const pauseActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.post(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}/pause`);
};

export const startActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.post(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}/start`);
};

export const blockActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.post(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}/block`);
};

export const setReminderOnActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
  remindAtTime: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.put(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}/reminder`, {
    remindAtTime,
  });
};

export const setExpirationOnActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
  expireAtTime: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.put(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}/expire`, {
    expireAtTime,
  });
};

export const setAssignedToOnActivity = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
  userId: string,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.post(`v1/${resolvedResourceType}/${parentId}/activities/${activityId}/assign`, {
    userId,
  });
};

export const moveActivityTask = (
  parentId: string,
  entityType: ActivityEntityType,
  activityId: string,
  moveActivity: IMoveActivityDto,
) => {
  const resolvedResourceType = resolveEntityType(entityType);
  return apiClient.put(
    `v1/${resolvedResourceType}/${parentId}/activities/${activityId}/move`,
    moveActivity,
  );
};
