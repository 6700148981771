import { IContactDto } from '../../../shared/model/IContactDto';
import { IShareContactRequestDto } from '../../../shared/model/IShareContactRequestDto';

export function mergeContactsAndShareRequests(
  contacts: IContactDto[],
  shareContactRequests: IShareContactRequestDto[],
): IContactDto[] {
  return contacts.map((contact) => {
    const matchingRequests = shareContactRequests?.filter(
      (datareq) => datareq.targetPersonProfileId === contact.personProfileId,
    );

    if (matchingRequests.length > 0) {
      const acceptedRequest = matchingRequests.find((datareq) => datareq.status === 'Accepted');
      if (acceptedRequest) {
        return { ...contact, shareInformation: acceptedRequest };
      }

      const pendingRequest = matchingRequests.find((datareq) => datareq.status === 'Pending');
      if (pendingRequest) {
        return { ...contact, shareInformation: pendingRequest };
      }

      const rejectedRequest = matchingRequests.find((datareq) => datareq.status === 'Rejected');
      if (rejectedRequest) {
        return { ...contact, shareInformation: rejectedRequest };
      }
    }

    return { ...contact, shareInformation: undefined };
  });
}
