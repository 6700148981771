import { UserPlusIcon } from '@heroicons/react/24/outline';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import NetworkMembers from './NetworkMembers';
import { useNetworkPrivilegesQuery } from './queries/networkPriviligesQueries';
import { useNetworkQuery } from './queries/networkQueries';
import { useNetworkStoreContext } from './context/network-context';
import Header3 from '../../shared/components/headers/Header3';
import Button from '../../shared/components/buttons/Button';
import Howto from '../../shared/components/message-popups/HowTo';

interface IProps {
  networkId: string;
}

export default function NetworkTabBodyMembers({ networkId }: IProps) {
  const { setInviteMemberModalOpen } = useNetworkStoreContext();
  const networkPrivilegesQuery = useNetworkPrivilegesQuery(networkId);
  const networkPrivileges = networkPrivilegesQuery.data;
  const networkQuery = useNetworkQuery(networkId);
  const network = networkQuery.data;

  if (!network || !networkPrivileges) return <></>;

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      <header className="flex flex-row mb-4 justify-between">
        <Header3>Members ({network.memberCount})</Header3>

        {networkPrivileges.canInviteMember && (
          <Button
            onClick={() => setInviteMemberModalOpen(true)}
            icon={<UserPlusIcon className="h-4 w-4 inline-block pr-1" aria-hidden="true" />}
            color={ButtonColors.White}
            text="Invite members"
          />
        )}
      </header>
      <NetworkMembers networkId={networkId} />
      <Howto
        title="Members"
        description="See the members of this network. You can connect with and follow
        contacts you are not yet connected to. Additionally, you can
        remove contacts from the network without removing them from your contact list."
      />
    </div>
  );
}
