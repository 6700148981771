import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IPagedResult } from '../../../shared/model/IPagedResult';
import { IPersonProfileDto } from '../../../shared/model/IPersonProfileDto';
import {
  deleteMyProfilePicture,
  getAllProfiles,
  getMyProfile,
  getProfile,
  updateMyProfile,
  uploadMyProfilePicture,
} from '../../../shared/services/profileService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { getInitalDataPropsFromQueries } from '../../../shared/utils/queryClientUtils';

interface IUsePersonProfilesProps {
  limit?: number;
  searchTerm?: string;
  excludeSelf?: boolean;
  excludeContacts?: boolean;
}

interface IMutateMyPersonProfileProps {
  myPersonProfile: IPersonProfileDto;
}

interface IMutateMyProfilePhotoProps {
  file?: Blob;
}

export const profileKeys = {
  all: ['person-profiles'] as const,
  lists: () => [...profileKeys.all, 'list'] as const,
  list: (props: IUsePersonProfilesProps) => [...profileKeys.lists(), props] as const,
  details: () => [...profileKeys.all, 'detail'] as const,
  detail: (id: string) => [...profileKeys.details(), id] as const,
  my: () => [...profileKeys.details(), 'my'] as const,
};

export function useMyPersonProfileQuery(enabled = true) {
  return useQuery({
    queryKey: profileKeys.my(),
    queryFn: () => getMyProfile(),
    staleTime: Infinity,
    placeholderData: {} as IPersonProfileDto,
    enabled,
  });
}

export function personProfileBaseQuery(id: string, queryClient: QueryClient) {
  return {
    queryKey: profileKeys.detail(id),
    queryFn: () => getProfile(id),
    staleTime: 5 * 60 * 1000, // Increase stale time to go a little easy on the backend.
    ...getInitalDataPropsFromQueries<IPersonProfileDto>(
      queryClient,
      profileKeys.lists(),
      (profile) => profile.id === id,
    ),
  };
}

export function usePersonProfileQuery(id?: string) {
  const queryClient = useQueryClient();
  return useQuery({
    ...personProfileBaseQuery(id || '', queryClient),
    enabled: !!id,
  });
}

export function useSearchPersonProfilesQuery(searchTerm?: string) {
  return useQuery({
    queryKey: profileKeys.list({ searchTerm, excludeSelf: true, excludeContacts: true }),
    queryFn: () =>
      searchTerm && searchTerm.length > 0
        ? getAllProfiles(
            new QueryParam('searchTerm', searchTerm),
            new QueryParam('excludeSelf', 'true'),
            new QueryParam('excludeContacts', 'true'),
          )
        : Promise.resolve({} as IPagedResult<IPersonProfileDto>),
    enabled: !!searchTerm,
  });
}

export function useSearchPersonProfilesForInvitationQuery(searchTerm?: string) {
  return useQuery({
    queryKey: profileKeys.list({ searchTerm, excludeSelf: true, excludeContacts: true }),
    queryFn: () =>
      searchTerm && searchTerm.length > 0
        ? getAllProfiles(
            new QueryParam('searchTerm', searchTerm),
            new QueryParam('excludeSelf', 'true'),
            new QueryParam('excludeContacts', 'false'),
            new QueryParam('searchFields', 'EmailAddress'),
          )
        : Promise.resolve({} as IPagedResult<IPersonProfileDto>),
    enabled: !!searchTerm,
  });
}

export function useMyPersonProfileMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ myPersonProfile }: IMutateMyPersonProfileProps) =>
      updateMyProfile(myPersonProfile),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: profileKeys.my() });
    },
    onError: useDispatchApiError(),
  });
}

export function useMyProfilePhotoMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ file }: IMutateMyProfilePhotoProps) => {
      if (typeof file === 'undefined') {
        return deleteMyProfilePicture();
      }

      return uploadMyProfilePicture(file);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: profileKeys.my() });
    },
    onError: useDispatchApiError(),
  });
}
