import React from 'react';
import Text from '../text/Text';
import Label from '../text/Label';
import SpacingContainer from '../spacing/SpacingContainer';

interface IProps {
  name: string;
  label: string;
  description: string;
  children: React.ReactNode;
}

export default function RadioButtonGroup({ name, label, description, children }: IProps) {
  return (
    <SpacingContainer>
      <Label htmlFor={name} text={label} />
      <Text as="p" size="small" brightness="light" leading="normal">
        {description}
      </Text>

      {children}
    </SpacingContainer>
  );
}
