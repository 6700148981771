/* eslint-disable react/jsx-no-useless-fragment */

import React from 'react';

interface IProps {
  id: string;
  activeTabId: string;
  children: React.ReactNode;
}

export default function TabContent({ id, activeTabId, children }: IProps) {
  return <>{id === activeTabId && <>{children}</>}</>;
}
