import { useParams } from 'react-router-dom';
import ContactInfo from './ContactInfo';
import MainPanelDesktop from '../../shared/components/page/MainPanelDesktop';
import MainPanelMobile from '../../shared/components/page/MainPanelMobile';
import Spinner from '../../shared/components/loading/Spinner';
import ContactPassive from './ContactPassive';
import ContactInfoHeaderMobile from './mobile/ContactInfoHeaderMobile';
import { useMessagesQuery } from '../Messaging/queries/messageQueries';
import Messaging from '../Messaging/Messaging';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import { useConnectionIsInitatedQuery } from '../Request/queries/connectionRequestQueries';
import ContactClosedSidePanel from './ContactClosedSidePanel';
import { useConnectionConversationQuery } from '../Messaging/queries/conversationQueries';
import { useContactQuery } from './queries/contactQueries';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import {
  createActivityAction,
  createContactNoteAction,
  MessageEditorAction,
} from '../Messaging/editor/messageEditorMenuActions';
import SidePanel from '../../shared/components/page/SidePanel';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import { ViewName } from '../../shared/store/slices/viewSlice';
import useColorSetting, { ColorSetting } from '../../shared/hooks/useColorSetting';

export default function Contact() {
  const currentView: ViewName = 'contact';
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const viewSettings = getViewSettings(currentView);

  const params = useParams();

  const messageEditorActions: MessageEditorAction[] = [
    createContactNoteAction('?tab=tab_notes', () => setSidePanelOpen(currentView, true)),
    createActivityAction('?tab=tab_activities', () => setSidePanelOpen(currentView, true)),
  ];

  const contactQuery = useContactQuery(params.id);
  const conversationQuery = useConnectionConversationQuery(contactQuery.data?.connectionId);
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);
  const isConnectionInitiatedQuery = useConnectionIsInitatedQuery(
    contactQuery.data?.personProfileId,
  );

  const colorSetting: ColorSetting = contactQuery?.data?.connectionId
    ? useColorSetting('contact')
    : useColorSetting('contact-passive');

  const isLoading =
    contactQuery.isLoading ||
    conversationQuery.isLoading ||
    messagesQuery.isLoading ||
    isConnectionInitiatedQuery.isLoading;

  if (isLoading) {
    return (
      <div className="flex justify-center justify-items-center mt-20 w-full h-full">
        <Spinner />
      </div>
    );
  }

  if (
    contactQuery.isSuccess &&
    (!contactQuery.data.connectionId ||
      (conversationQuery.isSuccess &&
        messagesQuery.isSuccess &&
        isConnectionInitiatedQuery.isSuccess))
  ) {
    return (
      <>
        <WrapperDesktop>
          <MainPanelDesktop>
            {contactQuery.data.connectionId && conversationQuery.data ? (
              <Messaging
                messages={messagesQuery}
                conversation={conversationQuery.data}
                externalMenuActions={messageEditorActions}
              />
            ) : (
              <ContactPassive
                contact={contactQuery.data}
                isConnectionInitiated={isConnectionInitiatedQuery.data || false}
              />
            )}
          </MainPanelDesktop>
          <SidePanel
            renderClosed={() => (
              <ContactClosedSidePanel
                imageSrc={contactQuery.data.photoUrl}
                contact={contactQuery.data}
              />
            )}
            isOpen={viewSettings.isSidePanelOpen}
            colorSetting={colorSetting}
            openSidePanel={() => setSidePanelOpen(currentView, true)}
            closeSidePanel={() => setSidePanelOpen(currentView, false)}
            onToggle={() => toggleSidePanel(currentView)}
          >
            <ContactInfo
              contact={contactQuery.data}
              isConnectionInitiated={isConnectionInitiatedQuery.data}
            />
          </SidePanel>
        </WrapperDesktop>
        <WrapperMobile>
          <MainPanelMobile>
            <ContactInfoHeaderMobile user={contactQuery.data} />
            {contactQuery.data.connectionId ? (
              <ContactInfo contact={contactQuery.data} />
            ) : (
              <ContactInfo
                contact={contactQuery.data}
                isConnectionInitiated={isConnectionInitiatedQuery.data}
              />
            )}
          </MainPanelMobile>
        </WrapperMobile>
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[contactQuery, conversationQuery, messagesQuery, isConnectionInitiatedQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The contact could not be found' }]}
    />
  );
}
