import React, { ReactNode } from 'react';

interface requestItemsListProps {
  id: string;
  renderBody: ReactNode;
  renderButton?: ReactNode;
}

export default function RequestItemsList({ id, renderBody, renderButton }: requestItemsListProps) {
  return (
    <div key={id}>
      <div className="mb-6">
        <div className="flex gap-4 mb-1">{renderBody}</div>
        {renderButton && <div className="flex gap-4 ml-14">{renderButton}</div>}
      </div>
    </div>
  );
}
