import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createFile,
  deleteFile,
  getFiles,
  renameFile,
} from '../../../services/conversationFileService';
import { useDispatchApiError } from '../../../hooks/useDispatchApiError';
import { useInfiniteApiQuery } from '../../../hooks/useInfiniteApiQuery';
import { attachmentKeys } from './attachmentQueries';

interface IFileAttachmentsProps {
  conversationId: string;
  searchTerm?: string;
}

export const fileAttachmentKeys = {
  all: ['file-attachments'] as const,
  pagedLists: () => [...fileAttachmentKeys.all, 'pagedList'] as const,
  pagedList: (props: IFileAttachmentsProps) => [...fileAttachmentKeys.pagedLists(), props] as const,
};

export function useFileAttachmentsQuery(props: IFileAttachmentsProps) {
  return useInfiniteApiQuery({
    queryKey: fileAttachmentKeys.pagedList(props),
    queryFn: () => getFiles(props.conversationId, props.searchTerm),
  });
}

interface ICreateFileAttachmentProps {
  conversationId: string;
  name: string;
}

export function useCreateFileAttachmentMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ conversationId, name }: ICreateFileAttachmentProps) =>
      createFile(conversationId, name),
    onSuccess: (_, { conversationId }) => {
      queryClient.invalidateQueries({ queryKey: fileAttachmentKeys.pagedList({ conversationId }) });
      queryClient.invalidateQueries({ queryKey: attachmentKeys.pagedList({ conversationId }) });
    },
    onError: useDispatchApiError(),
  });
}

interface IRenameFileAttachmentProps {
  id: string;
  conversationId: string;
  name: string;
}

export function useRenameFileAttachmentMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, conversationId, name }: IRenameFileAttachmentProps) =>
      renameFile(conversationId, id, name),
    onSuccess: (_, { conversationId }) => {
      queryClient.invalidateQueries({ queryKey: fileAttachmentKeys.pagedList({ conversationId }) });
      queryClient.invalidateQueries({ queryKey: attachmentKeys.pagedList({ conversationId }) });
    },
    onError: useDispatchApiError(),
  });
}

interface IDeleteFileAttachmentProps {
  id: string;
  conversationId: string;
}

export function useDeleteFileAttachmentMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, conversationId }: IDeleteFileAttachmentProps) =>
      deleteFile(conversationId, id),
    onSuccess: (_, { conversationId }) => {
      queryClient.invalidateQueries({ queryKey: fileAttachmentKeys.pagedList({ conversationId }) });
      queryClient.invalidateQueries({ queryKey: attachmentKeys.pagedList({ conversationId }) });
    },
    onError: useDispatchApiError(),
  });
}
