/* eslint-disable no-param-reassign */
import missingImage from '../../assets/images/missing-image.png';
import { useUserProfileQuery } from '../Contacts/queries/userProfileQueries';

export interface IProfileAvatarProps {
  src?: string;
  alt?: string;
  widthClass?: string;
  heightClass?: string;
}

interface IProps {
  avatarProps: IProfileAvatarProps;
  includeBorder?: boolean;
}

export default function ProfileAvatar({ avatarProps, includeBorder = false }: IProps) {
  const imgUrl = avatarProps.src || '';
  return (
    <img
      src={imgUrl}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = missingImage;
      }}
      alt={avatarProps.alt}
      className={`${avatarProps.widthClass} ${avatarProps.heightClass} rounded-full bg-gray-dark ${
        includeBorder ? 'ring-4 ring-white' : ''
      }`}
    />
  );
}

interface IUserProfileAvatarProps {
  userId: string;
  includeBorder?: boolean;
  widthClass?: string;
  heightClass?: string;
}

export function UserProfileAvatar({
  userId,
  includeBorder,
  widthClass,
  heightClass,
}: IUserProfileAvatarProps) {
  const userProfileQuery = useUserProfileQuery(userId);

  if (!userProfileQuery.isSuccess) return null;

  return (
    <ProfileAvatar
      avatarProps={{
        src: userProfileQuery.data.photoUrl,
        alt: `${userProfileQuery.data.firstName} ${userProfileQuery.data.lastName}`,
        widthClass,
        heightClass,
      }}
      includeBorder={includeBorder}
    />
  );
}
