import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ToastType } from '../../../shared/components/toasts/constants/ToastTypes';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import { AppContext } from '../../../shared/context/context';
import { useAccountId } from '../../../shared/hooks/accountHooks';
import { IConnectionRequestDto } from '../../../shared/model/IConnectionRequestDto';
import {
  deleteConnectionRequest,
  getConnectionRequests,
} from '../../../shared/services/connectionRequestService';
import { connectWithPersonProfile } from '../../../shared/services/contactService';
import { createToast } from '../../../shared/services/toastService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';

export interface IUseConnectionRequestsProps {
  personProfileId?: string;
  status?: ConnectionRequestStatuses;
  type?: 'Sent' | 'Received';
}

const connectionRequestKeys = {
  all: ['connection-requests'] as const,
  lists: () => [...connectionRequestKeys.all, 'list'] as const,
  list: (props: IUseConnectionRequestsProps) => [...connectionRequestKeys.lists(), props] as const,
};

export function useConnectionRequestsQuery({
  personProfileId,
  status,
  type,
}: IUseConnectionRequestsProps) {
  return useQuery({
    queryKey: connectionRequestKeys.list({ personProfileId, status, type }),
    queryFn: () =>
      getConnectionRequests(
        new QueryParam('type', type),
        new QueryParam('status', status),
        new QueryParam('personProfileId', personProfileId),
      ),
  });
}

export function useConnectionIsInitatedQuery(personProfileId?: string) {
  return useQuery({
    queryKey: connectionRequestKeys.list({
      personProfileId,
      status: ConnectionRequestStatuses.Pending,
    }),
    queryFn: () =>
      getConnectionRequests(
        new QueryParam('personProfileId', personProfileId),
        new QueryParam('status', ConnectionRequestStatuses.Pending),
      ),
    select: (data) => data.length > 0,
    enabled: !!personProfileId,
  });
}

export interface ICreateConnectionRequestProps {
  personProfileId: string;
  firstName: string;
  lastName: string;
}

export function useConnectWithProfileMutation() {
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  const dispatchError = useDispatchApiError();
  const myAccountId = useAccountId();
  return useMutation({
    mutationFn: ({ personProfileId }: ICreateConnectionRequestProps) =>
      connectWithPersonProfile(personProfileId),
    onMutate: ({ personProfileId }) => {
      const connectionRequest: IConnectionRequestDto = {
        id: '',
        requestorPersonProfileId: myAccountId ?? '',
        requesteePersonProfileId: personProfileId,
        status: ConnectionRequestStatuses.Pending,
        createdTime: '',
      };
      queryClient.setQueryData(
        connectionRequestKeys.list({
          personProfileId,
          status: ConnectionRequestStatuses.Pending,
        }),
        (data?: IConnectionRequestDto[]) => {
          if (!data) return [connectionRequest];
          return [...data, connectionRequest];
        },
      );
      return connectionRequest;
    },
    onSuccess: (_, { firstName, lastName }) => {
      queryClient.invalidateQueries({ queryKey: connectionRequestKeys.lists() });
      dispatch(
        createToast(
          'Success!',
          ToastType.Success,
          `A connection request has been sent to ${firstName} ${lastName}`,
        ),
      );
    },
    onError: (error: unknown, { personProfileId }) => {
      queryClient.setQueryData(
        connectionRequestKeys.list({
          personProfileId,
          status: ConnectionRequestStatuses.Pending,
        }),
        (data?: IConnectionRequestDto[]) =>
          data?.filter((cr) => cr.requesteePersonProfileId !== personProfileId) ?? [],
      );
      dispatchError(error);
    },
  });
}

interface IDeleteConnectionRequestProps {
  id: string;
}

export function useDeleteConnectionRequestMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IDeleteConnectionRequestProps) => deleteConnectionRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: connectionRequestKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
