import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import logoY from '../../assets/images/logoY.svg';
import Notifications from '../Notifications/Notifications';
import { Filler } from '../../shared/components/layout/Filler';
import HeaderNavigation from './HeaderNavigation';

function HeaderDesktopContainer({ children }: { children: React.ReactNode }) {
  return <header className="flex flex-shrink-0 h-24 w-full items-start">{children}</header>;
}

function HeaderDesktopActions({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-row border !border-t-0 p-2 pt-0 mr-12 rounded-b-lg bg-white h-1/2">
      <div className="flex flex-row mt-2.5 px-6 rounded-b-lg justify-center items-center">
        {children}
      </div>
    </div>
  );
}

export default function HeaderDesktop() {
  return (
    <HeaderDesktopContainer>
      <Link to="/" className="flex items-start justify-start mt-4 p-5">
        <img className="hidden 2xl:block h-10 w-auto ml-[50px]" src={logo} alt="Yoin" />
        <img className="block 2xl:hidden h-7 w-auto ml-1" src={logoY} alt="Yoin" />
      </Link>
      <Filler />
      <HeaderDesktopActions>
        <Notifications />
        <HeaderNavigation />
      </HeaderDesktopActions>
    </HeaderDesktopContainer>
  );
}
