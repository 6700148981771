import { ButtonColors } from '../../../constants/ButtonColors';
import { ButtonStyle } from '../Button';

const colorLookup: { [key in ButtonColors]: string } = {
  [ButtonColors.White]: 'disabled:text-black text-black bg-white hover:bg-gray-50 border-gray-400',
  [ButtonColors.Gray]:
    'disabled:text-black text-black bg-gray hover:bg-gray-light border-gray-dark',
  [ButtonColors.Green]:
    'disabled:bg-green-light text-white bg-green hover:bg-green-dark border-transparent',
  [ButtonColors.Cyan]:
    'disabled:text-white-600 text-white bg-cyan disabled:bg-cyan-light hover:bg-cyan-dark border-transparent',
  [ButtonColors.Red]:
    'disabled:text-white-600 hover:text-white-700 text-white bg-red hover:bg-red-dark border-transparent',
};

export interface IButtonStyleProps {
  color: ButtonColors;
  isFullWidth?: boolean;
  buttonStyle?: ButtonStyle;
  customClassName?: string;
}

export const useButtonStyle = ({
  color,
  isFullWidth = false,
  buttonStyle = 'filled',
  customClassName,
}: IButtonStyleProps) => {
  const className = [
    'inline-flex items-center px-4 py-2 border text-sm font-medium rounded-full shadow-sm cursor-pointer focus:outline-none disabled:opacity-30 disabled:cursor-not-allowed h-9',
    isFullWidth ? 'w-full justify-center' : '',
    buttonStyle === 'filled' ? 'border-0' : 'border-2 bg-transparent',
    colorLookup[color],
    customClassName,
  ].join(' ');

  return { className };
};
