import { PhoneIcon } from '@heroicons/react/24/outline';
import { ButtonColors } from '../../constants/ButtonColors';
import IconButton from '../buttons/IconButton';

interface ICallLinkProps {
  phoneNumber?: string;
}

export function CallLink({ phoneNumber }: ICallLinkProps) {
  const handleCall = () => {
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <IconButton
      color={ButtonColors.Gray}
      isDisabled={!phoneNumber}
      icon={
        <div className="inline-flex w-8 h-8 items-center justify-center">
          <PhoneIcon className="w-5 h-5" />
        </div>
      }
      onClick={handleCall}
    />
  );
}
