import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import PipelineCreatePipeline from './PipelineCreatePipeline';
import PipelineSelect from './PipelineSelect';
import Header1 from '../../shared/components/headers/Header1';
import WhiteBox from '../../shared/components/structure/WhiteBox';
import Divider from '../../shared/components/dividers/Divider';
import { usePipelineStoreContext } from './context/pipeline-context';
import Text from '../../shared/components/text/Text';

interface IPipelineSelectContainerProps {
  children: React.ReactNode;
}

function PipelineSelectContainer({ children }: IPipelineSelectContainerProps) {
  return <div className="flex flex-col flex-1">{children}</div>;
}

export default function PipelineSelectLayout() {
  // TODO: Restructure to get rid of this
  const { closeSidebar } = usePipelineStoreContext();

  useEffect(() => {
    closeSidebar();
  });

  return (
    <>
      <Header1>Boards</Header1>

      <PipelineSelectContainer>
        <WhiteBox>
          <PipelineCreatePipeline />
        </WhiteBox>

        <div className="flex flex-row items-center space-x-1 mt-4 mb-2">
          {/* <Text color="normal" brightness="light">
            Filter:
          </Text> */}
          <Text color="cyan" brightness="dark" weight="semibold">
            All boards
          </Text>
          {/* <ChevronDownIcon className="w-4 h-4 text-cyan-dark mt-1" /> */}
        </div>
        <PipelineSelect />
      </PipelineSelectContainer>
    </>
  );
}
