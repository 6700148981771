import { useMemo, useState } from 'react';
import { useListShareContactRequestsQuery } from '../Request/queries/shareContactRequestQueries';
import { IContactDto } from '../../shared/model/IContactDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SelectListMultiSelect from '../../shared/components/lists/SelectListMultiSelect';
import { TagColor } from '../../shared/components/tags/Tag';
import { useContactsForShareQuery } from './queries/contactQueries';
import {
  ShareContactRequestStatuses,
} from '../../shared/model/IShareContactRequestDto';
import { mergeContactsAndShareRequests } from './mergecontacts/MergeContactsAndShareRequests';

const statusColorMap: Record<ShareContactRequestStatuses, TagColor> = {
  Accepted: 'green',
  Pending: 'yellow',
  Rejected: 'red',
};

interface IProps {
  contact: IContactDto;
  status?: ShareContactRequestStatuses | 'All';
  searchTerm?: string;
}

export function ContactSharedContactsKpiList({ contact, status = 'All', searchTerm }: IProps) {
  const [selectedContacts, setSelectedContacts] = useState<
    ReturnType<typeof mergeContactsAndShareRequests>
  >([]);

  const contactsQuery = useContactsForShareQuery(searchTerm, contact.id);

  const shareContactRequestsQuery = useListShareContactRequestsQuery({
    externalRef: contact.id,
    searchType: 'Sent',
    status: status === 'All' ? ['Accepted', 'Pending', 'Rejected'] : [status],
  });
  const contactsWithShareStatus = useMemo(() => {
    if (!contactsQuery.isSuccess || !shareContactRequestsQuery.isSuccess) {
      return [];
    }
    const mergedContacts = mergeContactsAndShareRequests(
      contactsQuery.data,
      shareContactRequestsQuery.data,
    );

    const filteredContacts = mergedContacts.filter(
      (_contact) =>
        _contact.shareInformation &&
        (_contact.shareInformation.status === 'Accepted' ||
          _contact.shareInformation.status === 'Pending' ||
          _contact.shareInformation.status === 'Rejected'),
    );

    return filteredContacts;
  }, [
    contactsQuery.data,
    contactsQuery.isSuccess,
    shareContactRequestsQuery.data,
    shareContactRequestsQuery.isSuccess,
  ]);
  const shareContactQuery = shareContactRequestsQuery.isPending;

  return (
    <div className="flex flex-col h-full ">
      <ScrollBarWrapper>
        <SelectListMultiSelect
          key={searchTerm}
          data={contactsWithShareStatus}
          isLoading={shareContactQuery}
          onSelectItems={setSelectedContacts}
          selectedItems={selectedContacts}
          itemIdProperty={({ id }) => id}
          itemHeadlineProperty={({ firstName, lastName }) => `${firstName} ${lastName}  `}
          itemInformationProperty={({ emailAddress }) => emailAddress}
          itemImgSrcProperty={({ photoUrl }) => photoUrl}
          itemTagsProperty={({ shareInformation }) =>
            shareInformation
              ? [
                  {
                    title: shareInformation.status,
                    color: statusColorMap[shareInformation.status],
                  },
                ]
              : []
          }
        />
      </ScrollBarWrapper>
    </div>
  );
}
