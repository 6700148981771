type DividerDistance = 'small' | 'medium' | 'large';

const distanceClassMap: Record<DividerDistance, string> = {
  small: 'mt-0.5 mb-0.5',
  medium: 'mt-1 mb-1',
  large: 'mt-2 mb-2',
};

interface IHorizontalDividerProps {
  distance?: DividerDistance;
}

export default function HorizontalDivider({ distance = 'medium' }: IHorizontalDividerProps) {
  const dividerDistanceClass = distanceClassMap[distance];

  return <div className={`${dividerDistanceClass} h-[1px] w-full`} />;
}
