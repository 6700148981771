import QueryParam from './QueryParam';

export default function queryStringBuilder() {
  let queryParams: QueryParam[] = [];

  const api = {
    add(...params: QueryParam[]) {
      queryParams = queryParams.concat(params);
      return api;
    },

    toQueryString(): string {
      const queryString = queryParams.filter((p) => typeof p.value !== 'undefined' && p.value !== '')
        .map((p) => `${p.name}=${p.value}`)
        .join('&');

      return queryString.length > 0 ? `?${queryString}` : '';
    },
  };
  return api;
}
