import SidePanelHeadline from '../../shared/components/page/SidePanelHeadline';
import NetworkInfoTabs from './NetworkInfoTabs';
import WrapperDesktop from '../../shared/components/wrappers/WrapperDesktop';
import { useMessagesQuery } from '../Messaging/queries/messageQueries';
import { useNetworkConversationQuery } from '../Messaging/queries/conversationQueries';
import { useNetworkMembersQuery } from './queries/networkMemberQueries';
import { useNetworkQuery } from './queries/networkQueries';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import WrapperMobile from '../../shared/components/wrappers/WrapperMobile';
import NetworkInfoTabsMobile from './mobile/NetworkInfoTabsMobile';
import NetworkInfoModals from './NetworkInfoModals';
import useColorSetting from '../../shared/hooks/useColorSetting';
import { IPipelineEditItemProps } from '../Pipelines/models/IPipelineEditItemProps';

interface IProps {
  networkId: string;
  pipelineEditItemProps?: IPipelineEditItemProps;
  // The reason we have these two functions is because we want to perform differnt actions
  // depending on where the NetworkInfo component is used.
  // [SCENARIO 1]: In the Network view, navigate back to the Network list after delete
  // [SCENARIO 2]: In the Pipeline view, change the sidebar to EditPipeline after delete
  onDeleteNetwork?: () => void;
  onLeaveNetwork?: () => void;
}

export default function NetworkInfo({
  networkId,
  onDeleteNetwork,
  onLeaveNetwork,
  pipelineEditItemProps,
}: IProps) {
  const networkQuery = useNetworkQuery(networkId);
  const network = networkQuery.data;
  const conversationQuery = useNetworkConversationQuery(networkId);
  const conversationId = conversationQuery.data?.id;
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);
  const networkMembersQuery = useNetworkMembersQuery(networkId);
  const colorSetting = useColorSetting('network');

  if (networkQuery.isSuccess && networkMembersQuery.isSuccess && conversationId) {
    return (
      <>
        <WrapperDesktop>
          <SidePanelHeadline
            headline={network?.name}
            subHeadline={`${network?.visibility} & ${network?.type}`}
            imageType="Network"
            colorSetting={colorSetting}
            resourceId={networkId}
            pipelineEditItemProps={pipelineEditItemProps}
          />
          <NetworkInfoTabs
            networkId={networkId}
            network={network}
            conversationId={conversationId}
            pipelineEditItemProps={pipelineEditItemProps}
          />
        </WrapperDesktop>
        <WrapperMobile>
          <NetworkInfoTabsMobile networkId={networkId} />
        </WrapperMobile>
        <NetworkInfoModals
          networkId={networkId}
          onDeleteNetwork={onDeleteNetwork}
          onLeaveNetwork={onLeaveNetwork}
        />
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[networkQuery, networkMembersQuery, conversationQuery, messagesQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The network could not be found.' }]}
    />
  );
}
