import { useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import TextAreaInputAutosized from '../../shared/components/inputs/text/TextAreaInputAutosized';
import { IContactNoteDto } from '../../shared/model/IContactNoteDto';

interface IProps {
  onCreateNote: (note: IContactNoteDto) => void;
}

export default function PrivateNoteCreate({ onCreateNote }: IProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const defaultValues = {
    id: '',
    contactId: '',
    note: '',
    createdTime: '',
    updatedTime: '',
  };

  const {
    register,
    reset,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<IContactNoteDto>({
    mode: 'onChange',
    defaultValues,
  });

  const createNote = () => {
    const data = getValues();
    data.note = data.note.trimEnd();

    onCreateNote(data);
    reset(defaultValues);
  };

  const onKeyPressEventHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key !== 'Enter' || event.shiftKey || !isValid || !isDirty) {
      return;
    }
    event.preventDefault();
    createNote();
  };

  return (
    <>
      <div className="relative rounded shadow-sm border-gray-300">
        <TextAreaInputAutosized
          ref={textAreaRef}
          name="note"
          register={register}
          placeholder="Start typing to add a note"
          errors={errors}
          errorMessage="Length must be between 1 & 4000"
          validationRules={{ required: true, maxLength: 4000 }}
          onKeyDown={onKeyPressEventHandler}
          inputCss="pr-10 pl-3 text-light border-gray-300 border-[1px] placeholder-gray-400 rounded-md"
        />
      </div>
    </>
  );
}
