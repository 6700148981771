interface IProps {
  children: React.ReactNode;
}
export default function TabHeaderWrapper({ children }: IProps) {
  return (
    <div className="flex space-x-3 sm:pl-0 px-5 md:px-0 mx-0 md:mx-6 mb-0 mt-0 md:mt-12 ">
      {children}
    </div>
  );
}
