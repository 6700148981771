import ListItemLink from '../lists/ListItemLink';
import ProfileAvatar from '../../../features/Profile/ProfileAvatar';
import { ImageType } from '../../constants/ImageTypes';
import networkDummy from '../../../assets/images/network-icon.svg';
import { NavigationListLoader } from './NavigationListLoader';
import { CardList } from '../cards/CardList';
import Text from '../text/Text';

interface IProps<T> {
  isLoading?: boolean;
  headline?: string;
  data: T[];
  imageType: ImageType;
  itemIdProperty: (item: T) => string;
  itemUrlProperty: (item: T) => string;
  itemHeadlineProperty: (item: T) => string;
  itemInformationProperty: (item: T) => string;
  itemSecondaryInformationProperty?: (item: T) => string;
  itemImgSrcProperty: (item: T) => string;
  highlightInformationProperty?: (item: T) => boolean;
  showHighlightIndicator?: (item: T) => boolean;
}

export default function NavigationList<T extends object>({
  data,
  isLoading = false,
  headline,
  imageType,
  itemIdProperty,
  itemUrlProperty,
  itemHeadlineProperty,
  itemInformationProperty,
  itemSecondaryInformationProperty = () => '',
  itemImgSrcProperty,
  highlightInformationProperty = () => false,
  showHighlightIndicator = () => false,
}: IProps<T>) {
  if (isLoading) {
    return (
      <div className="flex flex-col h-full items-start justify-start mt-4">
        <NavigationListLoader />
      </div>
    );
  }

  if ((!data && !isLoading) || (data?.length === 0 && !isLoading)) return <></>;

  return (
    <>
      {headline && <h1 className="text-1xl ml-6 mt-4 mb-2 font-medium text">{headline}</h1>}

      <CardList>
        {data?.map((item) => (
          <ListItemLink key={itemIdProperty(item)} to={itemUrlProperty(item)}>
            <div className="flex-shrink-0">
              {imageType === 'Contact' && (
                <ProfileAvatar
                  avatarProps={{
                    src: itemImgSrcProperty(item),
                    alt: itemHeadlineProperty(item),
                    widthClass: 'w-12',
                    heightClass: 'h-12',
                  }}
                />
              )}
              {imageType === 'Network' && (
                <img
                  src={networkDummy}
                  alt={itemHeadlineProperty(item)}
                  className="rounded-full w-12 h-12"
                />
              )}
            </div>
            <div className="flex-1 min-w-0">
              <Text as="p" size="small" weight="bold">
                {itemHeadlineProperty(item)}
              </Text>
              <div className="flex flex-row gap-1 w-full">
                <Text
                  as="span"
                  size="xSmall"
                  brightness={highlightInformationProperty(item) ? 'normal' : 'light'}
                  weight={highlightInformationProperty(item) ? 'bold' : 'normal'}
                  truncate
                >
                  {itemInformationProperty(item)}
                </Text>
                {itemSecondaryInformationProperty(item) && (
                  <Text as="span" size="xSmall" brightness="light" truncate>
                    {`• ${itemSecondaryInformationProperty(item)}`}
                  </Text>
                )}

                {showHighlightIndicator(item) && (
                  <div className="absolute right-2 top-2 h-2 w-2 rounded-full bg-cyan-alternative" />
                )}
              </div>
            </div>
          </ListItemLink>
        ))}
      </CardList>
    </>
  );
}
