import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import ProfileAvatar from '../../../features/Profile/ProfileAvatar';
import { ImageType } from '../../constants/ImageTypes';
import networkDummy from '../../../assets/images/network-icon.svg';
import { ColorSetting } from '../../hooks/useColorSetting';
import { IPipelineEditItemProps } from '../../../features/Pipelines/models/IPipelineEditItemProps';
import Text from '../text/Text';

interface IProps {
  headline: string | undefined;
  subHeadline?: string | undefined;
  actions?: JSX.Element;
  imageType: ImageType | string;
  imageSrc?: string;
  colorSetting: ColorSetting;
  resourceId?: string;
  pipelineEditItemProps?: IPipelineEditItemProps;
}

export default function SidePanelHeadline({
  headline,
  subHeadline,
  actions,
  imageType,
  imageSrc,
  colorSetting,
  resourceId,
  pipelineEditItemProps,
}: IProps) {
  const itemType = imageType.toString().toLowerCase();

  const resourceLink =
    imageType === 'Network' ? `/networks/${resourceId}` : `/contacts/${resourceId}`;

  return (
    <header
      className={`flex items-center p-4 relative rounded-t-lg h-20 ${colorSetting.backgroundColor} ${colorSetting.textColor}`}
    >
      <Link to={resourceLink}>
        <div className="absolute top-12">
          {imageType === 'Contact' && (
            <ProfileAvatar
              avatarProps={{
                src: imageSrc,
                alt: headline,
                widthClass: 'w-16',
                heightClass: 'h-16',
              }}
            />
          )}
          {imageType === 'Network' && (
            <img src={networkDummy} alt={headline} className="rounded-full w-14 h-14" />
          )}
          {imageType === 'Pipeline' && (
            <div className="bg-cyan-dark flex flex-col justify-center items-center rounded-full w-14 h-14">
              {/* // TODO: Convert to image */}
              <Cog6ToothIcon className="w-9 h-9 text-white" />
            </div>
          )}
        </div>

        <div className="flex-column ml-20 absolute top-12">
          <h1
            className={`text-2xl font-medium text-white truncate max-w-[240px] ${colorSetting.textColor}`}
          >
            {headline}
          </h1>
          {resourceId && pipelineEditItemProps?.showLinkToResource && (
            <div className="flex flex-1 items-center mt-1">
              <ArrowTopRightOnSquareIcon className="w-4 h-4 text-light mr-1" />

              <Text size="xSmall" brightness="light">
                Go to {itemType} {subHeadline && <span className="mx-1">| {subHeadline}</span>}
              </Text>
            </div>
          )}
        </div>
        {actions && actions}
      </Link>
    </header>
  );
}
