interface IProps {
  children: React.ReactNode;
}

export default function NavigationBarMobile({ children }: IProps) {
  return (
    <nav className="flex flex-col flex-1 overflow-y-hidden relative transition-all ease-in-out px-5 pb-5">
      {children}
    </nav>
  );
}
