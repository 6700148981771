import { MouseEventHandler } from 'react';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import ProfileAvatar from '../Profile/ProfileAvatar';
import ContactExternalProfileActionButtons from './ContactExternalProfileActionButtons';
import ContactUserInformation from './ContactUserInformation';
import Text from '../../shared/components/text/Text';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';

interface IProps {
  profile: IPersonProfileDto;
  isProfileYou: boolean;
  isConnectionAccepted: boolean;
  isConnectionInitiated: boolean;
  isFollowing: boolean;
  onFollowContact: MouseEventHandler;
  onCreateConnectionRequest: MouseEventHandler;
}
export default function ContactExternalProfileInformation({
  profile,
  isProfileYou,
  isConnectionAccepted,
  isConnectionInitiated,
  isFollowing,
  onFollowContact,
  onCreateConnectionRequest,
}: IProps) {
  return (
    <>
      <div className="mt-8">
        <header className="flex items-center mb-6">
          <ProfileAvatar
            includeBorder
            avatarProps={{
              src: profile?.photoUrl,
              alt: profile?.firstName,
              widthClass: 'w-14',
              heightClass: 'h-14',
            }}
          />
          <div className="flex flex-col ml-4">
            <Text as="h1" weight="medium" size="xLarge">
              {profile?.firstName} {profile?.lastName}
            </Text>
            <HorizontalDivider distance="small" />
            <Text as="span" size="small" brightness="light">
              {profile?.emailAddress}
            </Text>
          </div>
        </header>

        <ContactUserInformation user={profile} />

        <div className="flex flex-row mt-6">
          {!isProfileYou && (
            <ContactExternalProfileActionButtons
              isConnectionAccepted={isConnectionAccepted}
              isConnectionInitiated={isConnectionInitiated}
              isFollowing={isFollowing}
              onFollowContact={onFollowContact}
              onCreateConnectionRequest={onCreateConnectionRequest}
            />
          )}
        </div>
      </div>
    </>
  );
}
