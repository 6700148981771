import { FunnelIcon, PlusIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import IconButton from '../buttons/IconButton';
import { ButtonColors } from '../../constants/ButtonColors';
import { Filler } from '../layout/Filler';

import Header1 from './Header1';

interface IProps {
  text: string;
  icon: 'user' | 'plus';
  onClick: () => void;
}

export default function ActionHeader({ text, icon, onClick }: IProps) {
  return (
    <div className="flex mt-4 mb-4 md:mt-0">
      <Header1>{text}</Header1>

      <Filler />
      <div className="invisible">
        <IconButton icon={<FunnelIcon className="w-6 h-6" />} color={ButtonColors.Cyan} />
      </div>
      <div className="place-self-end">
        {icon === 'user' && (
          <IconButton
            color={ButtonColors.Cyan}
            icon={<UserPlusIcon className="w-6 h-6" />}
            onClick={onClick}
          />
        )}
        {icon === 'plus' && (
          <IconButton
            color={ButtonColors.Cyan}
            icon={<PlusIcon className="w-8 h-8 stroke-[3px]" />}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
}
