import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import {
  deleteInviteRequest,
  getInviteRequest,
} from '../../Pipelines/services/pipelineMemberService';

interface IUsePipelineInviteRequestProps {
  type?: 'Sent' | 'Received';
  status?: ConnectionRequestStatuses;
}

const pipelineInviteRequestKeys = {
  all: ['pipeline-invite-request'] as const,
  lists: () => [...pipelineInviteRequestKeys.all, 'list'] as const,
  list: (props: IUsePipelineInviteRequestProps) =>
    [...pipelineInviteRequestKeys.lists(), props] as const,
};

export function usePipelineInviteRequestsQuery({ type, status }: IUsePipelineInviteRequestProps) {
  return useQuery({
    queryKey: pipelineInviteRequestKeys.list({ type, status }),
    queryFn: () => getInviteRequest(new QueryParam('type', type), new QueryParam('status', status)),
  });
}

interface IDeletePipelineInviteRequestProps {
  id: string;
}

export function useDeletePipelineInviteRequestMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IDeletePipelineInviteRequestProps) => deleteInviteRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: pipelineInviteRequestKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
