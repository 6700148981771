import { useState } from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { CardList } from '../../shared/components/cards/CardList';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { INetworkMember } from '../../shared/model/INetworkMember';
import { NetworkMemberCard } from './NetworkMemberCard';
import {
  useNetworkMembersQuery,
  useRemoveNetworkMemberMutation,
} from './queries/networkMemberQueries';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Text from '../../shared/components/text/Text';
import { useNetworkPrivilegesQuery } from './queries/networkPriviligesQueries';
import { useNetworkQuery } from './queries/networkQueries';
import { INetworkPrivileges } from '../../shared/model/INetworkPrivileges';

interface INetworkMembersProps {
  networkId: string;
}

export default function NetworkMembers({ networkId }: INetworkMembersProps) {
  const networkPrivilegesQuery = useNetworkPrivilegesQuery(networkId);
  const networkPrivileges = networkPrivilegesQuery.data as INetworkPrivileges;
  const networkQuery = useNetworkQuery(networkId);
  const network = networkQuery.data;
  const membersQuery = useNetworkMembersQuery(networkId);
  const members = membersQuery.data;

  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<INetworkMember | undefined>(undefined);

  const removeNetworkMemberMutation = useRemoveNetworkMemberMutation();

  const handleRemove = (member: INetworkMember) => {
    setMemberToRemove(member);
    setIsConfirmRemoveOpen(true);
  };

  const handleConfirmDelete = () => {
    if (!memberToRemove) return;
    removeNetworkMemberMutation.mutate(
      { member: memberToRemove },
      {
        onSuccess: () => {
          setIsConfirmRemoveOpen(false);
          setMemberToRemove(undefined);
        },
      },
    );
  };

  const handleCancelDelete = () => {
    setIsConfirmRemoveOpen(false);
    setMemberToRemove(undefined);
  };

  return (
    <>
      <ScrollBarWrapper>
        <CardList>
          {members?.map((member) => (
            <NetworkMemberCard
              key={member.userId}
              member={member}
              isOwner={network?.tenantId === member.userId}
              networkPrivileges={networkPrivileges}
              onRemove={() => handleRemove(member)}
            />
          ))}
        </CardList>
      </ScrollBarWrapper>
      <ConfirmDialog isOpen={isConfirmRemoveOpen} title="Remove member">
        <Text as="p" leading="snug">
          {`Are you sure that you want to remove ${memberToRemove?.name} from the network?`}
        </Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Remove" onClick={handleConfirmDelete} />
          <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelDelete} />
        </ButtonGroup>
      </ConfirmDialog>
    </>
  );
}
