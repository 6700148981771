import { useState } from 'react';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { CardList } from '../../shared/components/cards/CardList';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Text from '../../shared/components/text/Text';
import { usePipelineStoreContext } from './context/pipeline-context';
import { PipelineMemberCard } from './PipelineMemberCard';
import {
  usePipelineMembersQuery,
  useRemovePipelineMemberMutation,
} from './queries/pipelineMemberQueries';
import { usePipelineQuery } from './queries/pipelineQueries';
import { usePipelinePrivilegesQuery } from './queries/pipelinePriviligesQueries';
import { IPipelineMember } from './models/IPipelineDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import Howto from '../../shared/components/message-popups/HowTo';

export default function PipelineMembers() {
  const { activePipelineSettings, setInviteMemberModalOpen } = usePipelineStoreContext();
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const privilegesQuery = usePipelinePrivilegesQuery(activePipelineSettings.pipelineId);
  const privileges = privilegesQuery.data;
  const [memberToRemove, setMemberToRemove] = useState<IPipelineMember | undefined>(undefined);
  const membersQuery = usePipelineMembersQuery(activePipelineSettings.pipelineId);
  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);
  const pipeline = pipelineQuery.data;
  const members = membersQuery.data;
  const removePipelineMemberMutation = useRemovePipelineMemberMutation();

  const handleRemove = (member: IPipelineMember) => {
    setMemberToRemove(member);
    setIsConfirmRemoveOpen(true);
  };

  const handleConfirmDelete = () => {
    if (!memberToRemove) return;
    removePipelineMemberMutation.mutate(
      { member: memberToRemove },
      {
        onSuccess: () => {
          setIsConfirmRemoveOpen(false);
          setMemberToRemove(undefined);
        },
      },
    );
  };

  const handleCancelDelete = () => {
    setIsConfirmRemoveOpen(false);
    setMemberToRemove(undefined);
  };

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      {privileges?.canInviteMember && (
        <ButtonGroup>
          <Button
            onClick={() => setInviteMemberModalOpen(true)}
            icon={<UserPlusIcon className="h-4 w-4 inline-block pr-1" aria-hidden="true" />}
            color={ButtonColors.White}
            text="Invite members"
          />
        </ButtonGroup>
      )}
      <ScrollBarWrapper>
        <CardList>
          {members?.map((member) => (
            <PipelineMemberCard
              key={member.userId}
              member={member}
              isOwner={pipeline?.tenantId === member.userId}
              privileges={privileges}
              onRemove={() => handleRemove(member)}
            />
          ))}
        </CardList>
      </ScrollBarWrapper>
      <ConfirmDialog isOpen={isConfirmRemoveOpen} title="Remove member">
        <Text as="p" leading="snug">
          {`Are you sure that you want to remove ${memberToRemove?.name} from the pipeline?`}
        </Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Remove" onClick={handleConfirmDelete} />
          <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelDelete} />
        </ButtonGroup>
      </ConfirmDialog>
      <Howto
        title="Members"
        description="Invite other people to add and make changes to your board and stages. "
      />
    </div>
  );
}
