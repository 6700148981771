import { usePersonProfileQuery } from '../Contacts/queries/personProfileQueries';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useDeleteConnectionRequestMutation } from './queries/connectionRequestQueries';
import RequestItem from './RequestItem';
import RequestItemsList from './RequestItemsList';
import { IConnectionRequestDto } from '../../shared/model/IConnectionRequestDto';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import { RequestLoader } from './RequestLoader';

interface IRequestItemProps {
  requesteePersonProfileId: string;
  createdTime: Date | string;
}

function RequestItemWrapper({ requesteePersonProfileId, createdTime }: IRequestItemProps) {
  const requesteeProfileQuery = usePersonProfileQuery(requesteePersonProfileId);

  if (requesteeProfileQuery.isLoading) {
    return <RequestLoader />;
  }

  if (requesteeProfileQuery.isSuccess) {
    const requesteeName = `${requesteeProfileQuery.data.firstName} ${requesteeProfileQuery.data.lastName}`;

    return (
      <RequestItem
        title={requesteeName}
        linkToPersonProfileId={requesteePersonProfileId}
        photoUrl={requesteeProfileQuery.data.photoUrl}
      >
        Connection request sent <TimeAgo date={createdTime} /> ago.
      </RequestItem>
    );
  }

  return <div />;
}

interface IProps {
  connectionRequests: IConnectionRequestDto[];
}

export default function ConnectionRequest({ connectionRequests }: IProps) {
  const deleteConnectionRequestMutation = useDeleteConnectionRequestMutation();

  return (
    <div>
      <ul>
        {connectionRequests.map((request) => (
          <RequestItemsList
            key={request.id}
            id={request.id}
            renderBody={
              <RequestItemWrapper
                requesteePersonProfileId={request.requesteePersonProfileId}
                createdTime={request.createdTime}
              />
            }
            renderButton={
              <Button
                color={ButtonColors.White}
                text="Cancel"
                onClick={() => deleteConnectionRequestMutation.mutate(request)}
                isDisabled={deleteConnectionRequestMutation.isPending}
              />
            }
          />
        ))}
      </ul>
    </div>
  );
}
