import { Card } from '../cards/Card';
import TimeAgo from '../dates/TimeAgo';
import SpacingContainer from '../spacing/SpacingContainer';
import Text from '../text/Text';

interface ISharedSelectCardListItemProps {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  onClick: () => void;
}

export function SelectCardListItem({
  id,
  title,
  description,
  imageUrl,
  onClick,
}: ISharedSelectCardListItemProps) {
  return (
    <Card
      as="div"
      key={id}
      className="cursor-pointer !p-0 hover:brightness-105 hover:shadow-none"
      onClick={onClick}
    >
      <div className="flex flex-col p-2 items-start">
        <div
          style={{ backgroundImage: `url(${imageUrl})` }}
          className="w-52 h-32 bg-cover bg-no-repeat"
        />
        <SpacingContainer>
          <Text size="small">{title}</Text>
        </SpacingContainer>
        <Text size="xSmall" opacity="75">
          <TimeAgo date={description} /> ago
        </Text>
      </div>
    </Card>
  );
}

type Orientation = 'horizontal' | 'vertical';

interface ISharedSelectCardListProps {
  orientation?: Orientation;
  children: React.ReactNode;
}

export default function SelectCardList({
  orientation = 'horizontal',
  children,
}: ISharedSelectCardListProps) {
  return (
    <section className="flex justify-left items-start overflow-x-hidden">
      <nav
        className={`flex px-1 ${
          orientation === 'horizontal'
            ? 'flex-row overflow-x-scroll space-x-4 horizontal-scroll'
            : 'flex-col overflow-y-scroll space-y-4 overflow-hidden'
        }`}
      >
        {children}
      </nav>
    </section>
  );
}
