import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';
import { IPagedResult, isPagedResult } from '../model/IPagedResult';

const MILLISECS_PER_SECOND = 1000;
const MAX_RETRY_DELAY = 30 * MILLISECS_PER_SECOND;

export const updateInfiniteQueryData = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  updateFunc: (pageData: TData[]) => TData[],
  pageFilter?: (
    page: IPagedResult<TData>,
    pageIndex: number,
    allPages: IPagedResult<TData>[],
  ) => boolean,
) =>
  queryClient.setQueriesData(
    { queryKey },
    (data: InfiniteData<IPagedResult<TData>> | undefined) => {
      if (!data) return { pageParams: [], pages: [] };

      return {
        pageParams: data.pageParams,
        pages: data.pages.map((page, pageIndex) => {
          if (pageFilter && !pageFilter(page, pageIndex, data.pages)) return page;

          return {
            next: page.next,
            data: updateFunc(page.data),
          };
        }),
      };
    },
  );

const findQueryItem = <TData>(
  queryData: IPagedResult<TData> | Array<TData> | undefined,
  filter: (data: TData) => boolean,
) => {
  if (!queryData) return undefined;
  return isPagedResult(queryData) ? queryData.data.find(filter) : queryData.find(filter);
};

const getFirstMatchedQueryFromQueries = <TData, TResult extends IPagedResult<TData> | Array<TData>>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => {
  const tupleFilter = ([, _data]: [QueryKey, TResult | undefined]) => {
    if (!_data || !Array.isArray(_data)) return false;

    return (isPagedResult(_data) ? _data.data.findIndex(filter) : _data.findIndex(filter)) !== -1;
  };

  return queryClient.getQueriesData<TResult>({ queryKey }).find(tupleFilter);
};

const getFirstMatchedItemFromQueries = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => {
  const tuple = getFirstMatchedQueryFromQueries(queryClient, queryKey, filter);

  if (!tuple) return undefined;
  return findQueryItem(tuple[1], filter);
};

const getFirstMatchedStateFromQueries = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => {
  const tuple = getFirstMatchedQueryFromQueries(queryClient, queryKey, filter);
  if (!tuple) return undefined;
  return queryClient.getQueryState(tuple[0]);
};

export const getInitialDataPropsFromQuery = <
  TData,
  TResult extends IPagedResult<TData> | Array<TData>,
>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => ({
  initialData: () => {
    const queryData = queryClient.getQueryData<TResult>(queryKey);
    return findQueryItem(queryData, filter);
  },
  initialDataUpdatedAt: () => queryClient.getQueryState(queryKey)?.dataUpdatedAt,
});

export const getInitalDataPropsFromQueries = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => ({
  initialData: () => getFirstMatchedItemFromQueries(queryClient, queryKey, filter),
  initialDataUpdatedAt: () =>
    getFirstMatchedStateFromQueries(queryClient, queryKey, filter)?.dataUpdatedAt,
});

export const exponentialRetryDelay = (attempt: number) =>
  Math.min(
    attempt > 1 ? 2 ** attempt * MILLISECS_PER_SECOND : MILLISECS_PER_SECOND,
    MAX_RETRY_DELAY,
  );
