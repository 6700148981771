import { useCreateStageMutation } from './queries/stageQueries';
import { usePipelineStoreContext } from './context/pipeline-context';
import AddButton from '../../shared/components/buttons/AddButton';

function PipelineCreateStageContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex w-52 flex-col p-3 ">
      <div className="flex flex-row items-center">{children}</div>
    </div>
  );
}

export default function PipelineCreateStage() {
  const { selectStage, activePipelineSettings, setActiveEditPipelineTab, setActiveSidePanelView } =
    usePipelineStoreContext();
  const createStageMutation = useCreateStageMutation();

  const handleCreateStage = () => {
    setActiveSidePanelView('EditPipeline');
    setActiveEditPipelineTab('tab_stages');

    createStageMutation.mutate(
      {
        pipelineId: activePipelineSettings.pipelineId,
        stage: { name: 'New Stage', weight: 100 },
      },
      {
        onSuccess: (stage) => {
          selectStage(stage.id);
        },
      },
    );
  };

  return (
    <PipelineCreateStageContainer>
      <AddButton text="Create stage" onClick={handleCreateStage} />
    </PipelineCreateStageContainer>
  );
}
