import { profileImagesURL } from '../utils/configuration';

export const getProfileImageUrl = (profileId: string | undefined): string => {
  if (typeof profileId === 'undefined' || profileId === null) return '';

  const idCleaned = profileId.replace(/-/g, '');
  // Date is used for cache busting, should perhaps be controlled in another way
  return `${profileImagesURL}${idCleaned}`;
};

export default getProfileImageUrl;
