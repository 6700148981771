import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { ButtonColors } from '../../constants/ButtonColors';
import IconButton from '../buttons/IconButton';

interface IMailLinkProps {
  email?: string;
}

export function MailLink({ email }: IMailLinkProps) {
  const handleMail = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <IconButton
      color={ButtonColors.Gray}
      isDisabled={!email}
      icon={
        <div className="inline-flex w-8 h-8 items-center justify-center">
          <EnvelopeIcon className="w-5 h-5" />
        </div>
      }
      onClick={handleMail}
    />
  );
}
