import { ActivityEntityType } from '../../shared/model/IActitityDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import ActivitiesList from './ActivitiesList';
import ActivityCreateInput from './ActivityCreateInput';
import Howto from '../../shared/components/message-popups/HowTo';

const activityEntityTypeHelpTextMap = {
  [ActivityEntityType.Contact]:
    'Keep track of your contacts by creating tasks. These tasks are kept private and not shared with your contact.',
  [ActivityEntityType.Network]:
    'Collaborate with your business network. Create, assign and keep track over important activities.',
};

interface IProps {
  parentId: string;
  type: ActivityEntityType;
}

export default function Activities({ parentId, type: entityType }: IProps) {
  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <ActivityCreateInput
        parentId={parentId}
        activityEntityType={entityType}
        placeholder="Start typing to add a task..."
      />
      <ScrollBarWrapper id="activities-scrollbar">
        <ActivitiesList entityType={entityType} parentId={parentId} />
      </ScrollBarWrapper>
      <Howto
        title="Tasks"
        description={activityEntityTypeHelpTextMap[entityType]}
      />
    </div>
  );
}
