import { usePipelineStoreContext } from './context/pipeline-context';
import { usePipelineItemQuery } from './queries/itemQueries';
import { useContactQuery } from '../Contacts/queries/contactQueries';
import { useNetworkQuery } from '../Networks/queries/networkQueries';

import { NetworkContextProvider } from '../Networks/context/network-context';
import NetworkInfo from '../Networks/NetworkInfo';
import PipelineEditItemFallback from './PipelineEditItemFallback';
import ContactInfo from '../Contacts/ContactInfo';
import { IContactDto } from '../../shared/model/IContactDto';
import PipelineEditItemForm from './PipelineEditItemForm';

export default function PipelineEditItem() {
  const { activePipelineSettings, setActiveSidePanelView } = usePipelineStoreContext();

  const pipelineItemQuery = usePipelineItemQuery({
    pipelineId: activePipelineSettings.pipelineId,
    stageId: activePipelineSettings.stageId,
    itemId: activePipelineSettings.item.id,
  });

  const resourceType = pipelineItemQuery?.data?.resourceType;
  const resourceId = pipelineItemQuery?.data?.resourceId;

  const contactQuery = useContactQuery(resourceId, resourceType === 'Contact');
  const networkQuery = useNetworkQuery(resourceId, resourceType === 'Network');

  if (!pipelineItemQuery.isSuccess) {
    return <></>;
  }

  if (!contactQuery.isSuccess && !networkQuery.isSuccess) {
    return <PipelineEditItemFallback pipelineItem={pipelineItemQuery.data} />;
  }

  const renderEditItem = <PipelineEditItemForm pipelineItem={pipelineItemQuery.data} />;

  return (
    <>
      {resourceType === 'Network' && networkQuery.isSuccess && (
        <NetworkContextProvider>
          <NetworkInfo
            networkId={resourceId as string}
            onDeleteNetwork={() => setActiveSidePanelView('EditPipeline')}
            onLeaveNetwork={() => setActiveSidePanelView('EditPipeline')}
            pipelineEditItemProps={{
              renderEditItem,
              showLinkToResource: true,
            }}
          />
        </NetworkContextProvider>
      )}

      {resourceType === 'Contact' && contactQuery.isSuccess && (
        <ContactInfo
          contact={contactQuery.data as IContactDto}
          pipelineEditItemProps={{ renderEditItem, showLinkToResource: true }}
        />
      )}
    </>
  );
}
