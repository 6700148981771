import { useState } from 'react';
import { useNavigate } from 'react-router';
import ProfileAvatar from '../Profile/ProfileAvatar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { IContactDto } from '../../shared/model/IContactDto';
import { IMenuItemActionButton } from '../../shared/model/IMenuItemActionButton';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import MenuItemActionButton from '../../shared/components/tabs/MenuItemActionButton';
import { useDeleteContactMutation } from './queries/contactQueries';
import ContactInfoContextMenu from './ContactInfoContextMenu';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Text from '../../shared/components/text/Text';

interface IProps {
  contact?: IContactDto | null;
  imageSrc?: string;
}

export default function ContactClosedSidePanel({ contact, imageSrc }: IProps) {
  const [showRemoveContactWarning, setShowRemoveContactWarning] = useState(false);
  const navigate = useNavigate();
  const deleteContactMutation = useDeleteContactMutation();

  const handleShowDeleteWarning = () => {
    setShowRemoveContactWarning(true);
  };

  const handleCancelDeleteContact = () => {
    setShowRemoveContactWarning(false);
  };

  const handleContinueDeleteContact = () => {
    setShowRemoveContactWarning(false);
    deleteContactMutation.mutate({ contactId: contact?.id as string });
    navigate('/contacts', { replace: true });
  };

  const menuItemActionButtons: IMenuItemActionButton[] = [
    {
      id: 'Remove Contact',
      children: <MenuItemActionButton text="Remove Contact" onClick={handleShowDeleteWarning} />,
    },
  ];

  return (
    <>
      <ProfileAvatar
        avatarProps={{
          src: imageSrc,
          widthClass: 'w-12',
          heightClass: 'h-12',
        }}
      />

      <ContactInfoContextMenu menuItemActionButtons={menuItemActionButtons} dotsColor="white" />

      <ConfirmDialog isOpen={showRemoveContactWarning} title="Remove Contact">
        <Text as="p">Are you sure you want to remove this contact?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Remove" onClick={handleContinueDeleteContact} />
          <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelDeleteContact} />
        </ButtonGroup>
      </ConfirmDialog>
    </>
  );
}
