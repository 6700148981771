import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { AppContext } from '../../shared/context/context';
import { createToast } from '../../shared/services/toastService';
import Button from '../../shared/components/buttons/Button';
import { ContactType } from '../../shared/model/IContactDto';
import SelectList from '../../shared/components/lists/SelectList';
import useDebounce from '../../shared/hooks/useDebounce';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';

import { isGuidNull } from '../../shared/utils/guidUtils';

import { useCreateInvitationMutation } from '../Request/queries/invitationQueries';
import {
  personProfileBaseQuery,
  useSearchPersonProfilesForInvitationQuery,
} from '../Contacts/queries/personProfileQueries';
import { contactsBaseQuery } from '../Contacts/queries/contactQueries';
import {
  useInvitePipelineMemberMutation,
  usePipelineMembersQuery,
} from './queries/pipelineMemberQueries';
import { ToastType } from '../../shared/components/toasts/constants/ToastTypes';
import SearchBar from '../../shared/components/search/SearchBar';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';

interface IListItem {
  userId: string;
  headline: string;
  information: string;
  photoUrl: string;
}

function PipelineInviteMemberSelectList({
  pipelineId,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  pipelineId: string;
  searchTerm: string;
  selectedItem: IListItem | undefined;
  onSelectItem: (item: IListItem) => void;
}) {
  const queryClient = useQueryClient();
  const pipelineMemberQuery = usePipelineMembersQuery(pipelineId);
  const contactsQuery = useQuery({
    ...contactsBaseQuery({ searchTerm, type: ContactType.Follow, limit: 20 }),
  });

  const [listItems, setListItems] = useState<IListItem[]>();
  useEffect(() => {
    async function GetListItems() {
      if (!pipelineMemberQuery.data || !contactsQuery.data) {
        return;
      }

      const result: IListItem[] = (
        await Promise.all(
          contactsQuery.data.map(async (contact) => {
            const personProfile = await queryClient.fetchQuery(
              personProfileBaseQuery(contact.personProfileId as string, queryClient),
            );

            return {
              userId: personProfile.userId,
              headline: `${contact.firstName} ${contact.lastName}`,
              information: contact.company,
              photoUrl: contact.photoUrl,
            };
          }),
        )
      ).filter((item) => !pipelineMemberQuery.data.some((member) => member.userId === item.userId));

      setListItems(result);
    }

    GetListItems();
  }, [pipelineMemberQuery.data, contactsQuery.data]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ userId }) => userId}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

/* We are using the optional callback function for communicating with the modal via the
   parent component "Networks". I'm sure we can find a more beautiful solution to this later */

interface IProps {
  pipelineId?: string;
  callback?: () => void;
}

export default function PipelineInviteMember({ pipelineId, callback }: IProps) {
  const { dispatch } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [selectedItem, setSelectedItem] = useState<IListItem>();

  const invitePipelineMember = useInvitePipelineMemberMutation();
  const createInvitationMutation = useCreateInvitationMutation();

  const profilesQuery = useSearchPersonProfilesForInvitationQuery(debouncedSearchTerm);

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
    setSelectedItem(undefined);
  };

  const invokeCallback = () => {
    if (typeof callback !== 'undefined') {
      callback();
    }
  };

  const handleInviteContact = () => {
    if (isGuidNull(pipelineId)) return;
    if (selectedItem) {
      invitePipelineMember.mutate(
        { pipelineId: pipelineId as string, userId: selectedItem.userId },
        {
          onSuccess: () => {
            dispatch(createToast('Success!', ToastType.Success, 'A contact has been invited'));
            invokeCallback();
          },
        },
      );
    } else if (debouncedSearchTerm.match(REGEX_EMAIL)) {
      const inviteExistingUser = profilesQuery.data?.data.length === 1;

      if (inviteExistingUser) {
        invitePipelineMember.mutate(
          {
            pipelineId: pipelineId as string,
            userId: profilesQuery.data?.data[0].userId as string,
          },
          {
            onSuccess: () => {
              dispatch(
                createToast(
                  'Success!',
                  ToastType.Success,
                  `An invitation has been sent to ${debouncedSearchTerm}`,
                ),
              );
              invokeCallback();
            },
          },
        );
      } else {
        createInvitationMutation.mutate(
          { email: debouncedSearchTerm },
          { onSuccess: () => invokeCallback() },
        );
      }
    }
  };

  const handleCancel = () => {
    invokeCallback();
  };

  return (
    <div className="text-left space-y-8">
      <div className="space-y-8">
        <header className="pt-6 pb-4 bg-white">
          <SearchBar
            onSearchChange={handleOnSearchChange}
            searchTerm={searchTerm}
            placeholder="Search contacts or email"
          />
        </header>
        <ScrollBarWrapper>
          {pipelineId && (
            <PipelineInviteMemberSelectList
              pipelineId={pipelineId}
              searchTerm={debouncedSearchTerm}
              selectedItem={selectedItem}
              onSelectItem={setSelectedItem}
            />
          )}
        </ScrollBarWrapper>
      </div>
      <div>
        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <Button color={ButtonColors.White} text="Cancel" onClick={handleCancel} />
            <Button
              color={ButtonColors.Cyan}
              text="Invite"
              isDisabled={!(selectedItem || searchTerm.match(REGEX_EMAIL))}
              onClick={handleInviteContact}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
