import { ICreatePipelineDto } from '../../../shared/model/ICreatePipelineDto';
import {
  IPipelineCreateBudgetDto,
  IPipelineDetailDto,
  IPipelineDto,
  IUpdatePipelineDto,
} from '../models/IPipelineDto';
import apiClient from '../../../shared/utils/apiClient';

export const getAllPipelines = () =>
  apiClient.get('v1/pipelines').then<Array<IPipelineDto>>((response) => response.data.data);

export const getPipeline = (id: string) =>
  apiClient.get(`v1/pipelines/${id}`).then<IPipelineDetailDto>((response) => response.data);

export const createPipeline = (pipeline: ICreatePipelineDto) =>
  apiClient.post('v1/pipelines', pipeline).then<IPipelineDto>((response) => response.data);

export const updatePipeline = (pipeline: IUpdatePipelineDto, id: string) =>
  apiClient.patch(`v1/pipelines/${id}`, pipeline, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });

export const createPipelineBudget = (
  pipelineId: string,
  pipelineBudget: IPipelineCreateBudgetDto,
) => apiClient.post(`v1/pipelines/${pipelineId}/budgets`, pipelineBudget);

export const updatePipelineBudget = (
  pipelineId: string,
  budgetId: string,
  pipelineBudget: IPipelineCreateBudgetDto,
) =>
  apiClient.patch(`v1/pipelines/${pipelineId}/budgets/${budgetId}`, pipelineBudget, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });

export const deletePipeline = (id: string) => apiClient.delete(`v1/pipelines/${id}`);
