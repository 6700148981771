import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import Divider from '../../shared/components/dividers/Divider';
import { IMenuItemActionButton } from '../../shared/model/IMenuItemActionButton';

interface IProps {
  menuItemActionButtons: IMenuItemActionButton[];
  // TODO: Restructure components to be more flexible (dynamic open
  // menu icon) instead of using this prop
  dotsColor?: 'white' | 'black';
}

export default function ContactInfoContextMenu({
  menuItemActionButtons,
  dotsColor = 'black',
}: IProps) {
  return (
    <>
      <Menu as="div" className="relative inline-block w-fit mb-2">
        <Menu.Button className="block focus:outline-none rounded-full">
          <EllipsisHorizontalIcon
            className={`cursor-pointer w-8 h-8 ${dotsColor === 'white' ? 'text-white' : 'text'}`}
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute z-30 right-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {menuItemActionButtons.map((item) => (
                <span key={item.id}>
                  <Menu.Item>{item.children}</Menu.Item>
                  {item.useDivider && <Divider />}
                </span>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
