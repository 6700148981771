import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { ColorSetting } from '../../hooks/useColorSetting';

interface ISidePanelToggleButtonProps {
  onToggle: () => void;
  isOpen: boolean;
  colorSetting: ColorSetting;
}

export default function SidePanelToggleButton({
  onToggle,
  isOpen,
  colorSetting,
}: ISidePanelToggleButtonProps) {
  return (
    <button
      type="button"
      onClick={onToggle}
      className={`flex justify-center items-center text cursor-pointer w-8 h-8 rounded-full z-10 ${colorSetting.backgroundColor}`}
    >
      {isOpen ? (
        <ChevronRightIcon className={`w-6 h-6 stroke-[3px] ${colorSetting.textColor}`} />
      ) : (
        <ChevronLeftIcon className={`w-6 h-6 stroke-[3px] ${colorSetting.textColor}`} />
      )}
    </button>
  );
}
