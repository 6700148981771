import ConnectionRequest from './ConnectionRequest';
import InviteToNetworkRequest from './InviteToNetworkRequest';
import Invitations from './Invitations';
import { useConnectionRequestsQuery } from './queries/connectionRequestQueries';
import { ConnectionRequestStatuses } from '../../shared/constants/ConnectionRequestStatuses';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { useNetworkInviteRequestsQuery } from './queries/networkInviteQueries';
import { useInvitationsQuery } from './queries/invitationQueries';
import { InvitationStatus } from '../../shared/model/IInvitationDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { RequestLoader } from './RequestLoader';
import { usePipelineInviteRequestsQuery } from './queries/pipelineInviteQueries';
import InviteToPipelineRequest from './InviteToPipelineRequest';

export default function Request() {
  const connectionRequestsQuery = useConnectionRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });
  const networkInviteRequestsQuery = useNetworkInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });
  const pipelineInviteRequestsQuery = usePipelineInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });
  const invitationsQuery = useInvitationsQuery({
    status: InvitationStatus.Pending,
  });

  if (
    connectionRequestsQuery.isLoading ||
    networkInviteRequestsQuery.isLoading ||
    invitationsQuery.isLoading
  ) {
    return (
      <div className="flex flex-col gap-6">
        <RequestLoader />
        <RequestLoader />
        <RequestLoader />
      </div>
    );
  }

  if (
    connectionRequestsQuery.isSuccess &&
    networkInviteRequestsQuery.isSuccess &&
    invitationsQuery.isSuccess &&
    pipelineInviteRequestsQuery.isSuccess
  ) {
    return (
      <ScrollBarWrapper className="pt-14">
        <ConnectionRequest connectionRequests={connectionRequestsQuery.data} />
        <InviteToNetworkRequest networkInviteRequests={networkInviteRequestsQuery.data} />
        <InviteToPipelineRequest pipelineInviteRequests={pipelineInviteRequestsQuery.data} />
        <Invitations invitations={invitationsQuery.data} />
      </ScrollBarWrapper>
    );
  }

  return (
    <ErrorComponent
      queryResults={[connectionRequestsQuery, networkInviteRequestsQuery, invitationsQuery]}
    />
  );
}
