import { useSearchParams } from 'react-router-dom';
import MenuItemActionButton from '../../../shared/components/tabs/MenuItemActionButton';
import useCheckMobileScreen from '../../../shared/hooks/useCheckMobileScreen';
import { IMenuItemActionButton } from '../../../shared/model/IMenuItemActionButton';
import { useNetworkStoreContext } from '../context/network-context';
import { useNetworkPrivilegesQuery } from '../queries/networkPriviligesQueries';

export default function useNetworkInfoActionButtons(networkId: string) {
  const networkPrivilegesQuery = useNetworkPrivilegesQuery(networkId);
  const networkPrivileges = networkPrivilegesQuery.data;
  const isMobile = useCheckMobileScreen();
  const [, setSearchParams] = useSearchParams();

  const {
    setInviteMemberModalOpen,
    setIsEditNetworkModalOpen,
    setIsConfirmDeleteNetworkOpen,
    setIsConfirmLeaveNetworkOpen,
  } = useNetworkStoreContext();

  const menuItemActionButtons: IMenuItemActionButton[] = [];

  if (isMobile) {
    menuItemActionButtons.push({
      id: 'Attachments',
      children: (
        <MenuItemActionButton
          text="Attachments"
          onClick={() => setSearchParams({ tab: 'tab_attachments' })}
        />
      ),
      useDivider: true,
    });
  }

  if (networkPrivileges?.canInviteMember) {
    menuItemActionButtons.push({
      id: 'Invite',
      children: (
        <MenuItemActionButton text="Invite" onClick={() => setInviteMemberModalOpen(true)} />
      ),
    });
  }
  if (networkPrivileges?.canEdit) {
    menuItemActionButtons.push({
      id: 'Edit',
      children: (
        <MenuItemActionButton text="Edit" onClick={() => setIsEditNetworkModalOpen(true)} />
      ),
    });
  }
  if (networkPrivileges?.canDelete) {
    menuItemActionButtons.push({
      id: 'Delete',
      children: (
        <MenuItemActionButton text="Delete" onClick={() => setIsConfirmDeleteNetworkOpen(true)} />
      ),
    });
  } else {
    menuItemActionButtons.push({
      id: 'Leave',
      children: (
        <MenuItemActionButton text="Leave" onClick={() => setIsConfirmLeaveNetworkOpen(true)} />
      ),
    });
  }

  return menuItemActionButtons;
}
