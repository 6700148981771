import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuthenticationStatus, AuthenticationStatus } from '../hooks/useAuthenticationStatus';

interface IProps {
  children: React.ReactNode;
}

export default function AuthRequired({ children }: IProps) {
  const authenticationStatus = useAuthenticationStatus();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.NotAuthenticated) {
      navigate('/signin', { replace: true });
    }
  }, [authenticationStatus]);

  if (authenticationStatus === AuthenticationStatus.Authenticated) {
    return <>{children}</>;
  }

  return <div />;
}
