import { BellIcon } from '@heroicons/react/24/outline';
import { useContext, useMemo, useState } from 'react';
import {
  useFloating,
  FloatingFocusManager,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
} from '@floating-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { ToastType } from '../../shared/components/toasts/constants/ToastTypes';
import { AppContext } from '../../shared/context/context';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import { createToast } from '../../shared/services/toastService';
import {
  useNotificationsQuery,
  useNotificationSubscription,
  useReadNotificationMutation,
} from './notificationQueries';
import NotificationsTray from './NotificationsTray';
import { NotificationResponseType } from '../../shared/constants/NotificationConstants';
import Text from '../../shared/components/text/Text';
import BorderActiveHorizontal from '../../shared/components/indicators/BorderActiveHorizontal';

export default function Notifications() {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const notifications = useNotificationsQuery();
  useNotificationSubscription();

  const flattenedNotifications = useMemo(
    () => notifications.data?.pages.flat() ?? ([] as INotificationDto[]),
    [notifications.data],
  );

  const numberOfUnreadNotifications = useMemo(
    () => flattenedNotifications?.filter((n: INotificationDto) => !n.isRead).length ?? 0,
    [flattenedNotifications],
  );

  const readNotification = useReadNotificationMutation();
  const handleClearNotifications = async () => {
    flattenedNotifications
      ?.filter((n) => !n.isRead)
      .forEach((n) => readNotification.mutate({ id: n.id }));
  };

  const handleToggleNotificationsTray = (_isOpen: boolean) => {
    if (!_isOpen) handleClearNotifications();
    setIsOpen(_isOpen);
  };

  const handleExecuteAction = (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => {
    if (action.type === NotificationResponseType.Accept) {
      promise
        .then(() => {
          dispatch(createToast('Success', ToastType.Success, notification.acceptMessage, true));
          notifications.setIsResponded(notification.id);
        })
        .finally(() => {
          if (action.invalidateQueryKeys.length === 0) return;

          setTimeout(() => {
            action.invalidateQueryKeys.forEach((queryKey) =>
              queryClient.invalidateQueries({ queryKey }),
            );
          }, 1000);
        });
    } else {
      promise.then(() => {
        notifications.setIsResponded(notification.id);
      });
    }
  };

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: handleToggleNotificationsTray,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <button
        ref={refs.setReference}
        type="button"
        {...getReferenceProps({ className: 'relative' })}
      >
        <span className="sr-only">View notifications</span>

        <BellIcon className="h-6 w-6 text-cyan-dark" aria-hidden="true" />
        {numberOfUnreadNotifications > 0 && (
          <div className="absolute top-[2px] right-[-3px] inline-flex items-center justify-center w-5 h-5 transform translate-x-1/2 -translate-y-1/2 bg-red rounded-full">
            <Text as="span" size="xSmall" weight="bold" color="white" leading="none">
              {numberOfUnreadNotifications}
            </Text>
          </div>
        )}
        {isOpen && <BorderActiveHorizontal thickness="thin" bottomOffset={-12} />}
      </button>

      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            className="absolute right-0 top-0 md:top-16 bottom-0 md:mt-2 md:mr-2 md:mb-2 z-40 w-screen md:max-w-max overflow-y-auto"
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            <NotificationsTray
              notifications={flattenedNotifications}
              hasNextPage={notifications.hasNextPage}
              closeCallback={() => handleToggleNotificationsTray(false)}
              fetchNextPage={() => notifications.fetchNextPage()}
              onExecuteAction={handleExecuteAction}
            />
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
}
