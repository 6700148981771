// Added this for demo purposes so we can easily can find how to extend the state with more
// slices in the future

import { StateCreator } from 'zustand';
import { ViewsSlice } from './viewSlice';

export interface DemoSlice {
  amount: number;
  addAmount: () => void;
}

export const createDemoSlice: StateCreator<ViewsSlice & DemoSlice, [], [], DemoSlice> = (set) => ({
  amount: 0,
  addAmount: () => set((state) => ({ amount: state.amount + 1 })),
});
