import { useForm } from 'react-hook-form';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { usePipelineQuery, useUpdatePipelineMutation } from './queries/pipelineQueries';
import { usePipelineStoreContext } from './context/pipeline-context';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import { useStagesQuery } from './queries/stageQueries';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { IUpdatePipelineDto } from './models/IPipelineDto';
import SidePanelHeadline from '../../shared/components/page/SidePanelHeadline';
import PipelineEditStages from './PipelineEditStages';
import PipelineEditBudget from './PipelineEditBudget';
import CurrencySelector from '../../shared/components/selectors/CurrencySelector';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import PipelineMembers from './PipelineMembers';
import PipelineModals from './PipelineModals';
import PipelineInfoActionButtons from './PipelineInfoActionButtons';
import useColorSetting from '../../shared/hooks/useColorSetting';
import { usePipelinePrivilegesQuery } from './queries/pipelinePriviligesQueries';
import Button from '../../shared/components/buttons/Button';
import Howto from '../../shared/components/message-popups/HowTo';

interface IPipelineEditPipelineProps {
  pipelineId: string;
}

export default function PipelineEditPipeline({ pipelineId }: IPipelineEditPipelineProps) {
  const { navigateToOverview, activeEditPipelineTab, setActiveEditPipelineTab } =
    usePipelineStoreContext();
  const pipelineQuery = usePipelineQuery(pipelineId);
  const privilegesQuery = usePipelinePrivilegesQuery(pipelineId);
  const stagesQuery = useStagesQuery({ pipelineId });
  const updatePipelineMutation = useUpdatePipelineMutation();

  const colorSetting = useColorSetting('pipeline');

  const {
    register,
    getValues,
    formState: { isDirty },
  } = useForm<IUpdatePipelineDto>({
    mode: 'onChange',
    values: pipelineQuery.data,
  });

  const save = () => {
    const form = getValues();

    const updatedPipeline = {
      name: form.name,
      currencyIsoCode: form.currencyIsoCode,
    };

    updatePipelineMutation.mutate({
      id: pipelineId,
      pipeline: updatedPipeline,
    });
  };

  if (!pipelineQuery.isSuccess || !stagesQuery.isSuccess || !privilegesQuery.isSuccess) {
    return <></>;
  }

  return (
    <>
      <SidePanelHeadline
        headline="Settings"
        subHeadline="Configure your pipeline"
        colorSetting={colorSetting}
        imageType="Pipeline"
      />

      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="tab_info"
            activeId={activeEditPipelineTab}
            title="Info"
            setActiveTab={() => setActiveEditPipelineTab('tab_info')}
          />
          {privilegesQuery.data.canReadMembers && (
            <TabNavItem
              id="tab_members"
              activeId={activeEditPipelineTab}
              title="Members"
              setActiveTab={() => setActiveEditPipelineTab('tab_members')}
            />
          )}
          <TabNavItem
            id="tab_stages"
            activeId={activeEditPipelineTab}
            title="Stages"
            setActiveTab={() => setActiveEditPipelineTab('tab_stages')}
          />
          {privilegesQuery.data.canReadBudgets && (
            <TabNavItem
              id="tab_budget"
              activeId={activeEditPipelineTab}
              title="Budget"
              setActiveTab={() => setActiveEditPipelineTab('tab_budget')}
            />
          )}
          <PipelineInfoActionButtons pipelineId={pipelineId} />
        </TabHeaderWrapper>
        <TabContentWrapper>
          {/* // TODO: Move to a separate component */}
          <TabContent id="tab_info" activeTabId={activeEditPipelineTab}>
            <SpacingContainer>
              <SpacingContainerItem>
                <TextFieldInput name="name" type="text" register={register} labelText="Name" />
              </SpacingContainerItem>
              <SpacingContainerItem>
                <CurrencySelector register={register} name="currencyIsoCode" />
              </SpacingContainerItem>
              <ButtonGroup>
                <Button
                  color={ButtonColors.Cyan}
                  text="Save"
                  onClick={save}
                  isDisabled={!isDirty}
                />
              </ButtonGroup>
            </SpacingContainer>
            <Howto title="Info" description="Change the name and currency of your board." />
          </TabContent>

          <TabContent id="tab_members" activeTabId={activeEditPipelineTab}>
            <PipelineMembers />
          </TabContent>
          <TabContent id="tab_stages" activeTabId={activeEditPipelineTab}>
            <PipelineEditStages />
          </TabContent>
          <TabContent id="tab_budget" activeTabId={activeEditPipelineTab}>
            <PipelineEditBudget />
          </TabContent>
          <PipelineModals
            onDelete={() => navigateToOverview()}
            onLeave={() => navigateToOverview()}
          />
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
