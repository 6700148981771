import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import Button from '../../shared/components/buttons/Button';
import { ICreatePipelineDto } from '../../shared/model/ICreatePipelineDto';
import { Card } from '../../shared/components/cards/Card';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import { useCreatePipelineMutation } from './queries/pipelineQueries';
import CreateActionCard from '../../shared/components/cards/CreateActionCard';
import pipelineDummy from '../../assets/mocks/pipelinesMock.png';
import SelectCardList from '../../shared/components/lists/SelectCardList';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import CurrencySelector from '../../shared/components/selectors/CurrencySelector';
import { Popover, PopoverContent, PopoverTrigger } from '../../shared/components/popovers/Popover';

export default function PipelineCreatePipeline() {
  const createPipelineMutation = useCreatePipelineMutation();
  const navigate = useNavigate();

  const { register, getValues, reset } = useForm<ICreatePipelineDto>({
    mode: 'onChange',
  });

  const handleCreatePipeline = () => {
    const _pipeline: ICreatePipelineDto = {
      name: getValues('name'),
      currencyIsoCode: getValues('currencyIsoCode'),
    };

    createPipelineMutation.mutate(
      { pipeline: _pipeline },
      {
        onSuccess: (pipeline) => {
          navigate(pipeline.id);
        },
      },
    );
  };

  return (
    <>
      <SelectCardList orientation="horizontal">
        <Popover
          onOpenChange={(open) => {
            if (open) return;
            reset({
              name: '',
              currencyIsoCode: 'SEK',
            });
          }}
        >
          <PopoverTrigger>
            <CreateActionCard text="Create Pipe" imageUrl={pipelineDummy} />
          </PopoverTrigger>
          <PopoverContent side="right">
            <Card as="div">
              <SpacingContainer>
                <SpacingContainerItem>
                  <TextFieldInput name="name" type="text" register={register} labelText="Name" />
                </SpacingContainerItem>
                <SpacingContainerItem>
                  <CurrencySelector register={register} name="currencyIsoCode" />
                </SpacingContainerItem>
                <ButtonGroup>
                  <Button color={ButtonColors.White} text="Create" onClick={handleCreatePipeline} />
                </ButtonGroup>
              </SpacingContainer>
            </Card>
          </PopoverContent>
        </Popover>
      </SelectCardList>
    </>
  );
}
