import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteInviteNetworkRequest,
  getInviteNetworkRequest,
} from '../../../shared/services/networkService';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';

interface IUseNetworkInviteRequestProps {
  type?: 'Sent' | 'Received';
  status?: ConnectionRequestStatuses;
}

const networkInviteRequestKeys = {
  all: ['network-invite-request'] as const,
  lists: () => [...networkInviteRequestKeys.all, 'list'] as const,
  list: (props: IUseNetworkInviteRequestProps) =>
    [...networkInviteRequestKeys.lists(), props] as const,
};

export function useNetworkInviteRequestsQuery({ type, status }: IUseNetworkInviteRequestProps) {
  return useQuery({
    queryKey: networkInviteRequestKeys.list({ type, status }),
    queryFn: () =>
      getInviteNetworkRequest(new QueryParam('type', type), new QueryParam('status', status)),
  });
}

interface IDeleteNetworkInviteRequestProps {
  id: string;
}

export function useDeleteNetworkInviteRequestMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IDeleteNetworkInviteRequestProps) => deleteInviteNetworkRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: networkInviteRequestKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
