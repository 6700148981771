import ContentLoader from 'react-content-loader';

export function RequestLoader() {
  return (
    <ContentLoader
      speed={2}
      backgroundColor="#eeeff1"
      foregroundColor="#e0e3e6"
      style={{ width: '100%', height: '70px' }}
    >
      <circle cx="20" cy="30" r="20" />
      <rect x="55" y="10" rx="8" ry="8" width="150" height="16" />
      <rect x="55" y="36" rx="8" ry="8" width="272" height="16" />
    </ContentLoader>
  );
}
