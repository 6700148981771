import React from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { useForm } from 'react-hook-form';
import { AccountInfo } from '@azure/msal-browser';
import {
  useCreateOrganizationMutation,
  useOrganizationsQuery,
} from './queries/organizationQueries';
import Text from '../../shared/components/text/Text';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import Divider from '../../shared/components/dividers/Divider';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { ICreateUpdateOrganizationDto } from './models/ICreateUpdateOrganizationDto';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import Modal from '../../shared/components/modal/Modal';
import { loginRequest } from '../../shared/auth/authConfig';

interface ICreateOrganizationModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

function CreateOrganizationModal({ isOpen, setIsOpen }: ICreateOrganizationModalProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ICreateUpdateOrganizationDto>();

  const createOrganizationMutation = useCreateOrganizationMutation();

  function close() {
    setIsOpen(false);
    reset();
  }

  return (
    <Modal isOpen={isOpen} onCloseClick={() => close()} showCloseButton>
      <form
        onSubmit={handleSubmit((organization) =>
          createOrganizationMutation.mutate(organization, { onSuccess: close }),
        )}
      >
        <TextFieldInput
          labelText="Name"
          name="name"
          register={register}
          errors={errors}
          validationRules={{ required: true, maxLength: 400 }}
        />
        <HorizontalDivider />
        <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
          <SubmitButton
            text="Create"
            isDisabled={!isDirty || !isValid || createOrganizationMutation.isPending}
          />
        </ButtonGroup>
      </form>
    </Modal>
  );
}

function Account({ account }: { account: AccountInfo }) {
  return (
    <div className="border rounded-md">
      <div>
        <Text as="h3" size="normal" weight="medium">
          {account.name}
        </Text>
      </div>
      <div>
        <Text>{account.homeAccountId}</Text>
      </div>
      <HorizontalDivider />
      {account.idTokenClaims && (
        <div>
          <Text as="h4" size="normal">
            Claims
          </Text>
          {Object.keys(account.idTokenClaims).map((key) => (
            <div key={key}>
              <Text weight="medium">{key}: </Text>
              {account.idTokenClaims && (
                <Text>
                  <>{account.idTokenClaims[key]}</>
                </Text>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export function Organizations() {
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = React.useState(false);
  const organizationQuery = useOrganizationsQuery();
  const { accounts, instance } = useMsal();
  const activeAccount = useAccount();

  function switchTenant(tenantId: string) {
    if (!tenantId) return;

    // instance.loginRedirect({
    //   ...loginRequest,
    //   redirectStartPage: '/organizations',
    //   prompt: 'none',
    //   extraQueryParameters: { tenantId },
    // });
    instance.ssoSilent({
      ...loginRequest,
      prompt: 'none',
      extraQueryParameters: { tenantId },
    });

    // Vilken använder vi? Fördelen med loginRedirect är att vi laddar om applikationen och
    // uppdaterar med säkerhet data från backend. Får testa detta mer när vi har ett riktigt
    // case.
  }

  if (organizationQuery.isSuccess) {
    return (
      <>
        <div className="p-10">
          <ScrollBarWrapper>
            <Text as="h1" size="xLarge" weight="bold" leading="loose">
              Organizations
            </Text>
            <Divider />
            <Text as="h2" size="large" weight="bold" leading="loose">
              My Tenants
            </Text>
            <div className="space-x-2">
              <Text>Personal</Text>
              <Button
                color={ButtonColors.Gray}
                text="Switch"
                onClick={() => switchTenant(activeAccount?.idTokenClaims?.sub || '')}
              />
            </div>
            <div className="space-x-2">
              <Text>Error test</Text>
              <Button
                color={ButtonColors.Gray}
                text="Switch"
                onClick={() => switchTenant('303EB0CF-1730-439A-87E0-05DC6943A172')}
              />
            </div>
            {organizationQuery.data.pages.flat().map((organization) => (
              <div key={organization.id} className="space-x-2">
                <Text>
                  {organization.name} ({organization.id})
                </Text>
                <Button
                  color={ButtonColors.Gray}
                  text="Switch"
                  onClick={() => switchTenant(organization.id)}
                />
              </div>
            ))}
            <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
              <Button
                color={ButtonColors.Cyan}
                text="Create Organization"
                onClick={() => setIsCreateOrganizationOpen(true)}
              />
            </ButtonGroup>
            <Divider />
            <Text as="h2" size="large" weight="bold" leading="loose">
              My Accounts
            </Text>
            {accounts.map((account) => (
              <>
                <Account key={account.homeAccountId + account.tenantId} account={account} />
                <HorizontalDivider />
              </>
            ))}
            <HorizontalDivider />
            <Text as="h2" size="large" weight="bold" leading="loose">
              Current active account
            </Text>
            {activeAccount ? <Account account={activeAccount} /> : <Text>No active account</Text>}
            <CreateOrganizationModal
              isOpen={isCreateOrganizationOpen}
              setIsOpen={setIsCreateOrganizationOpen}
            />
          </ScrollBarWrapper>
        </div>
      </>
    );
  }

  return null;
}
