import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import Modal from '../../shared/components/modal/Modal';
import Text from '../../shared/components/text/Text';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { useMyUserId } from '../../shared/hooks/accountHooks';
import { usePipelineStoreContext } from './context/pipeline-context';
import PipelineInviteMember from './PipelineInviteMember';
import { useRemovePipelineMemberMutation } from './queries/pipelineMemberQueries';
import { useDeletePipelineMutation } from './queries/pipelineQueries';

interface IProps {
  onDelete?: () => void;
  onLeave?: () => void;
}

export default function PipelineModals({ onDelete, onLeave }: IProps) {
  const myUserId = useMyUserId();
  const deleteMutation = useDeletePipelineMutation();
  const removeMemberMutation = useRemovePipelineMemberMutation();

  const {
    isInviteMemberModalOpen,
    setInviteMemberModalOpen,
    activePipelineSettings,
    isConfirmDeleteOpen,
    setIsConfirmDeleteOpen,
    isConfirmLeaveOpen,
    setIsConfirmLeaveOpen,
  } = usePipelineStoreContext();

  const handleContinueLeave = () => {
    setIsConfirmLeaveOpen(false);
    if (!myUserId) return;

    removeMemberMutation.mutate(
      {
        member: {
          name: '',
          pipelineId: activePipelineSettings.pipelineId,
          userId: myUserId,
        },
      },
      {
        onSuccess: () => {
          setIsConfirmLeaveOpen(false);
          if (onLeave) onLeave();
        },
      },
    );
  };

  const handleContinueDelete = () => {
    setIsConfirmDeleteOpen(false);
    deleteMutation.mutate({ pipelineId: activePipelineSettings.pipelineId });
    if (onDelete) onDelete();
  };
  return (
    <>
      <Modal
        isOpen={isInviteMemberModalOpen}
        showCloseButton
        onCloseClick={() => setInviteMemberModalOpen(false)}
        title="Invite members"
      >
        <PipelineInviteMember
          callback={() => setInviteMemberModalOpen(false)}
          pipelineId={activePipelineSettings.pipelineId}
        />
      </Modal>
      <ConfirmDialog title="Delete Pipeline" isOpen={isConfirmDeleteOpen}>
        <Text as="p">Are you sure you want to delete this pipeline?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Delete" onClick={handleContinueDelete} />
          <Button
            color={ButtonColors.White}
            text="Cancel"
            onClick={() => setIsConfirmDeleteOpen(false)}
          />
        </ButtonGroup>
      </ConfirmDialog>
      <ConfirmDialog title="Leave Pipeline" isOpen={isConfirmLeaveOpen}>
        <Text as="p">Are you sure you want to leave this pipeline?</Text>
        <HorizontalDivider distance="large" />
        <ButtonGroup justify={ButtonGroupJustifyTypes.Center}>
          <Button color={ButtonColors.Red} text="Leave" onClick={handleContinueLeave} />
          <Button
            color={ButtonColors.White}
            text="Cancel"
            onClick={() => setIsConfirmLeaveOpen(false)}
          />
        </ButtonGroup>
      </ConfirmDialog>
    </>
  );
}
