import { IConnectionDto } from '../model/IConnectionDto';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export function getMyConnections(...queryParams: QueryParam[]) {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/connections/my${queryString}`)
    .then<IPagedResult<IConnectionDto>>((response) => response.data);
}
