import {
  DescriptionList,
  IDescriptionListItem,
} from '../../shared/components/lists/DescriptionList';
import { IContactDto } from '../../shared/model/IContactDto';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import { usePersonProfileQuery } from '../Contacts/queries/personProfileQueries';
import { ProfileIdentifier } from './Types';

function toItems(profile: Partial<IPersonProfileDto | IContactDto>) {
  const items = [] as IDescriptionListItem[];
  if (profile.emailAddress) {
    items.push({ name: 'Email', value: profile.emailAddress, type: 'email' });
  }
  if (profile.phoneNumber) items.push({ name: 'Phone', value: profile.phoneNumber, type: 'phone' });
  if (profile.title) items.push({ name: 'Title', value: profile.title });
  if (profile.company) items.push({ name: 'Company', value: profile.company });
  if (profile.location) items.push({ name: 'Location', value: profile.location });
  if (profile.additionalInformation) {
    items.push({
      name: 'Additional information',
      value: profile.additionalInformation,
      columnSpan: 2,
    });
  }

  return items;
}

interface IProfilePropertyListProps {
  profile: Partial<IPersonProfileDto | IContactDto>;
}

export function ProfilePropertyList({ profile }: IProfilePropertyListProps) {
  return <DescriptionList items={toItems(profile)} />;
}

export function LazyProfilePropertyList({ id }: ProfileIdentifier) {
  const profileQuery = usePersonProfileQuery(id);
  if (profileQuery.isSuccess) return <DescriptionList items={toItems(profileQuery.data)} />;
  return <div />;
}
