import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { IPipelineMember } from '../models/IPipelineDto';
import { deleteMember, getMembers, invite } from '../services/pipelineMemberService';
import { pipelineKeys } from './pipelineQueries';

interface IPipelineMemberKeyProps {
  pipelineId: string;
}

interface IRemovePipelineMemberProps {
  member: IPipelineMember;
}

interface IInvitePipelineMemberProps {
  pipelineId: string;
  userId: string;
}

const pipelineMemberKeys = {
  all: ['pipeline-members'] as const,
  lists: () => [...pipelineMemberKeys.all, 'list'] as const,
  list: (props: IPipelineMemberKeyProps) => [...pipelineMemberKeys.lists(), props] as const,
  details: () => [...pipelineMemberKeys.all, 'detail'] as const,
  detail: (id: string) => [...pipelineMemberKeys.details(), id] as const,
};

function pipelineMembersBaseQuery(pipelineId?: string) {
  return {
    queryKey: pipelineMemberKeys.list({ pipelineId: pipelineId || '' }),
    queryFn: () => (pipelineId ? getMembers(pipelineId) : Promise.resolve([])),
    enabled: !!pipelineId,
  };
}

export function usePipelineMembersQuery(pipelineId?: string) {
  return useQuery(pipelineMembersBaseQuery(pipelineId));
}

export function usePipelineMembersWithFilterQuery(
  pipelineId: string,
  filter: (member: IPipelineMember) => boolean,
) {
  return useQuery({
    ...pipelineMembersBaseQuery(pipelineId),
    select: (members: IPipelineMember[]) => members.filter(filter),
  });
}

export function useRemovePipelineMemberMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ member }: IRemovePipelineMemberProps) =>
      deleteMember(member.pipelineId, member.userId),
    onSuccess: (_, { member }) => {
      queryClient.invalidateQueries({
        queryKey: pipelineMemberKeys.list({ pipelineId: member.pipelineId }),
      });
      queryClient.invalidateQueries({ queryKey: pipelineKeys.list() });
    },
    onError: useDispatchApiError(),
  });
}

export function useInvitePipelineMemberMutation() {
  return useMutation({
    mutationFn: ({ pipelineId, userId }: IInvitePipelineMemberProps) =>
      invite(pipelineId, userId),

    onError: useDispatchApiError(),
  });
}
