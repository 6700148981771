export enum NotificationType {
  General = 'General',
  Invitation = 'Invitation',
  Request = 'Request',
  Response = 'Response',
}

export enum NotificationResponseType {
  Accept = 'Accept',
  Reject = 'Reject',
}

export enum NotificationArea {
  Message = 'Message',
  Connection = 'Connection',
  Contact = 'Contact',
  Network = 'Network',
  Invitation = 'Invitation',
  Pipeline = 'Pipeline',
}
