import { createContext, useState, useContext, ReactNode } from 'react';

interface INetworkStore {
  isInviteMemberModalOpen: boolean;
  setInviteMemberModalOpen: (isOpen: boolean) => void;
  isConfirmDeleteNetworkOpen: boolean;
  setIsConfirmDeleteNetworkOpen: (isOpen: boolean) => void;
  isEditNetworkModalOpen: boolean;
  setIsEditNetworkModalOpen: (isOpen: boolean) => void;
  isConfirmLeaveNetworkOpen: boolean;
  setIsConfirmLeaveNetworkOpen: (isOpen: boolean) => void;
}

const useNetworkStore = (): INetworkStore => {
  const [isInviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);
  const [isConfirmDeleteNetworkOpen, setIsConfirmDeleteNetworkOpen] = useState(false);
  const [isEditNetworkModalOpen, setIsEditNetworkModalOpen] = useState(false);
  const [isConfirmLeaveNetworkOpen, setIsConfirmLeaveNetworkOpen] = useState(false);

  return {
    isInviteMemberModalOpen,
    setInviteMemberModalOpen,
    isConfirmDeleteNetworkOpen,
    setIsConfirmDeleteNetworkOpen,
    isEditNetworkModalOpen,
    setIsEditNetworkModalOpen,
    isConfirmLeaveNetworkOpen,
    setIsConfirmLeaveNetworkOpen,
  };
};

export const NetworkStoreContext = createContext<INetworkStore | null>(null);

export function NetworkContextProvider({ children }: { children: ReactNode }) {
  return (
    <NetworkStoreContext.Provider value={useNetworkStore()}>
      {children}
    </NetworkStoreContext.Provider>
  );
}

export const useNetworkStoreContext = (): INetworkStore => {
  const store = useContext(NetworkStoreContext);

  if (!store) {
    throw new Error('useNetworkStoreContext must be used within a NetworkContextProvider');
  }

  return store;
};
