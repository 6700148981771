/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-empty-function */

import { useEffect, useState } from 'react';
import ProfileAvatar from '../../../features/Profile/ProfileAvatar';
import { CardList } from '../cards/CardList';
import Spinner from '../loading/Spinner';
import ListItem from './ListItem';
import Text from '../text/Text';
import HorizontalDivider from '../dividers/HorizontalDivider';

interface IProps<T> {
  isLoading: boolean;
  headline?: string;
  data: T[];
  itemIdProperty: (item: T) => string | undefined;
  itemHeadlineProperty: (item: T) => string;
  itemInformationProperty: (item: T) => string;
  itemImgSrcProperty: (item: T) => string;
  onSelectItem?: (entity: T) => void;
  selectedItem?: T;
}

export default function SelectList<T extends object>({
  data,
  isLoading = false,
  headline,
  itemIdProperty,
  itemHeadlineProperty,
  itemInformationProperty,
  itemImgSrcProperty,
  onSelectItem: onSelectEntity = () => {},
  selectedItem,
}: IProps<T>) {
  const [selectedItemId, setSelectedItemId] = useState<string | undefined | null>(null);
  useEffect(() => {
    if (selectedItem) {
      setSelectedItemId(itemIdProperty(selectedItem));
    } else {
      setSelectedItemId(null);
    }
  }, [selectedItem]);
  return (
    <>
      {headline && <h1 className="text-1xl mt-4 mb-2 font-medium text-gray-900">{headline}</h1>}
      {isLoading ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {!data?.length ? (
            <>
              <HorizontalDivider distance="small" />
              <Text as="span" size="small" brightness="dark" variant="italic">
                No items found
              </Text>
            </>
          ) : (
            <CardList>
              {data?.map((item) => (
                <div key={itemIdProperty(item)} onClick={() => onSelectEntity(item)}>
                  <ListItem isSelected={itemIdProperty(item) === selectedItemId}>
                    <div className="flex-shrink-0">
                      <ProfileAvatar
                        avatarProps={{
                          src: itemImgSrcProperty(item),
                          alt: itemHeadlineProperty(item),
                          widthClass: 'w-10',
                          heightClass: 'h-10',
                        }}
                      />
                    </div>
                    <div className="flex-1 overflow-x-hidden min-w-0">
                      <Text as="p" size="small" weight="medium" truncate>
                        {itemHeadlineProperty(item)}
                      </Text>
                      <Text as="p" size="xSmall" brightness="light" truncate>
                        {itemInformationProperty(item)}
                      </Text>
                    </div>
                  </ListItem>
                </div>
              ))}
            </CardList>
          )}
        </>
      )}
    </>
  );
}
