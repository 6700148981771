import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import Header2 from '../../shared/components/headers/Header2';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import {
  ICreateUpdateNetworkDto,
  INetworkDto,
  NetworkTypes,
  NetworkVisibilities,
} from '../../shared/model/INetwork';

type Props = {
  submitButtonText?: string;
  cancelButtonText?: string;
  network?: INetworkDto;
  isDisabled?: boolean;
  onSubmit: SubmitHandler<ICreateUpdateNetworkDto>;
  onCancel: (isDirty: boolean, event: React.BaseSyntheticEvent) => void;
};

const defaultValues = {
  name: '',
  description: '',
  type: NetworkTypes.Private,
  visibility: NetworkVisibilities.Hidden,
};

export function NetworkCreateEditForm({
  submitButtonText,
  cancelButtonText = 'Cancel',
  network,
  isDisabled = false,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ICreateUpdateNetworkDto>({
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues, ...network },
  });

  const _onSubmit = (_network: ICreateUpdateNetworkDto) => {
    if (!isValid || !isDirty) return;

    onSubmit(_network);
    reset();
  };

  const _onCancel = (event: React.BaseSyntheticEvent) => {
    onCancel(isDirty, event);
    if (event.isDefaultPrevented()) return;

    reset();
  };

  const _submitButtonText = submitButtonText || (network ? 'Save' : 'Create');

  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="text-left">
      <SpacingContainer>
        <Header2>Create new network</Header2>
        <SpacingContainerItem>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Must enter a name"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <TextAreaInput
            labelText="Description"
            name="description"
            rows={3}
            register={register}
            errors={errors}
            errorMessage="Must enter a description"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <ButtonGroup>
          <Button
            color={ButtonColors.White}
            text={cancelButtonText}
            isDisabled={isDisabled}
            onClick={_onCancel}
          />
          <SubmitButton text={_submitButtonText} isDisabled={!isValid || !isDirty || isDisabled} />
        </ButtonGroup>
      </SpacingContainer>
    </form>
  );
}
