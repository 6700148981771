interface IProps {
  widthClass?: string; // TODO: add type
  heightClass?: string;
}

export function usePopoverStyle({ widthClass = '', heightClass = '' }: IProps) {
  const className = [
    'rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 max-w-full',
    widthClass,
    heightClass,
  ].join(' ');
  return {
    className,
  };
}
