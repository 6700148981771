import { IContactDto } from '../../shared/model/IContactDto';
import { IPersonProfileDto } from '../../shared/model/IPersonProfileDto';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';
import { Card } from '../../shared/components/cards/Card';

interface IProps {
  user: Partial<IContactDto | IPersonProfileDto>;
}
export default function ContactUserInformation({ user }: IProps) {
  return (
    <Card isInteractive={false} as="div" className="w-fit">
      <ProfilePropertyList profile={user} />
    </Card>
  );
}
